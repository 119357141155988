import React from 'react';
import { Typography, Row, Col, Button } from 'antd';
import { faCreditCard, faUniversity } from '@fortawesome/pro-light-svg-icons';

import Option from 'components/Shared/Option';
import { FormattedMessage, useIntl } from 'react-intl';

const { Title, Paragraph } = Typography;

interface Props {
  setupDirectDebit(): void;
  loading: boolean;
  setupCard(): void;
  demoModeSetup(): void;
  demoMode: boolean;
}

const PaymentMethod = ({
  loading,
  setupCard,
  setupDirectDebit,
  demoModeSetup,
  demoMode,
}: Props): JSX.Element => {
  const intl = useIntl();
  return (
    <div style={{ maxWidth: '99%' }}>
      <Title level={3}>
        <FormattedMessage
          id="setupPayment"
          defaultMessage="Please setup you payment method"
        />
      </Title>
      <Paragraph>
        <FormattedMessage
          id="setupPaymentDescription"
          defaultMessage="      You will be automatically invoiced and charged through this payment method
      for the licences and services that you purchase through Hytalk. You can
      pay for your services either through direct debit or debit/credit card."
        />
      </Paragraph>
      <Row gutter={16}>
        <Col span="12">
          <Option
            icon={faUniversity}
            text={intl.formatMessage({
              id: 'payDirectDebit',
              defaultMessage: 'Pay via Direct Debit',
            })}
            onClick={setupDirectDebit}
            disabled={loading || demoMode}
          />
        </Col>
        <Col span="12">
          <Option
            icon={faCreditCard}
            text={intl.formatMessage({
              id: 'payCard',
              defaultMessage: 'Pay via Card',
            })}
            onClick={setupCard}
            disabled={loading || demoMode}
          />
        </Col>

        {demoMode && (
          <Button
            type="primary"
            style={{ marginLeft: 10 }}
            onClick={demoModeSetup}
          >
            <FormattedMessage
              id="skipDemo"
              defaultMessage="Skip setting up payment in demo mode"
            />
          </Button>
        )}
      </Row>
    </div>
  );
};
export default PaymentMethod;
