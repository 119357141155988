import { gql } from '@apollo/client';

export const DeleteOneUser = gql`
  mutation deleteOneUser($where: UserWhereUniqueInput!) {
    deleteOneUser(where: $where) {
      id
    }
  }
`;

export interface DeleteOneUserArgs {
  where: { id: string };
}

export interface DeleteOneUserRes {
  deleteOneUser: {
    id: string;
  };
}
