import React, { lazy, Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/Shared/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { useStoreState } from 'state';
import useAuth from 'hooks/useAuth';
import { OrgType } from 'state/user-model';

export const AppRouter = (): JSX.Element => {
  const { getCurrentUser, loading } = useAuth();
  const orgType = useStoreState((state) => state.user.type);
  const isHytera = useStoreState((state) => state.user.hytera);

  useEffect(() => {
    getCurrentUser();
  }, []);

  const toPrefix = () => {
    if (orgType === OrgType.nvoyy) {
      return lazy(() => import('./Admin/Router'));
    }
    if (isHytera) {
      return lazy(() => import('./Hytera/Router'));
    }
    if (orgType === OrgType.distributor) {
      return lazy(() => import('./Distributor/Router'));
    }
    return lazy(() => import('./Dealer/Router'));
  };

  if (loading) return <Loading cover="content" />;

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Redirect
          exact
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />

        <Route path={APP_PREFIX_PATH} component={toPrefix()} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppRouter);
