import { gql } from '@apollo/client';

export const OrgUsers = gql`
  query auth0Users($data: UniqueId!) {
    auth0Users(data: $data) {
      id
      name
      email
    }
  }
`;

export interface OrgUsersArgs {
  data: {
    id?: string;
  };
}

export interface OrgUsersRes {
  auth0Users: {
    id: string;
    name: string;
    email: string;
  }[];
}
