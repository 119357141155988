import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import SideNav from 'components/Layout/Navigation/SideNav';
import Loading from 'components/Shared/Loading';
import MobileNav from 'components/Layout/Navigation/MobileNav';
import HeaderNav from 'components/Layout/Navigation/HeaderNav';
import { Layout, Grid } from 'antd';

import navigationConfig from 'configs/NavigationConfig';
import { SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from 'react-css-theme-switcher';

const { Content } = Layout;
const { useBreakpoint } = Grid;

interface Props extends Omit<RouteComponentProps, 'history' | 'match'> {
  children: JSX.Element;
}

const AppLayout = ({ location, children }: Props) => {
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    location.pathname
  );
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');

  const getLayoutGutter = () => SIDE_NAV_WIDTH;

  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      <Layout className="app-container">
        <SideNav routeInfo={currentRouteInfo} />
        <Layout className="" style={{ paddingLeft: getLayoutGutter() }}>
          <div
            className="app-content"
            style={{
              padding:
                location.pathname.includes('/dealers/view') ||
                location.pathname.includes('settings') ||
                location.pathname.includes('products')
                  ? 0
                  : undefined,
            }}
          >
            <Content>{children}</Content>
          </div>
        </Layout>
      </Layout>
      {isMobile && <MobileNav routeInfo={currentRouteInfo} />}
    </Layout>
  );
};

export default React.memo(AppLayout);
