import { gql } from '@apollo/client';

export const AgreeTerms = gql`
  mutation updateOneUser(
    $id: String!
    $termsAgreement: TermsAgreementUpdateOneWithoutUserInput!
  ) {
    updateOneUser(
      where: { id: $id }
      data: { termsAgreement: $termsAgreement }
    ) {
      id
      termsAgreement {
        id
        expired
      }
    }
  }
`;

export interface AgreeTermsArgs {
  id: string;
  termsAgreement: {
    create: {
      terms: {
        connect: {
          id: string;
        };
      };
    };
  };
}

export interface AgreeTermsRes {
  user: {
    id: string;
    termsAgreement: {
      id: string;
      expired: boolean;
    };
  };
}
