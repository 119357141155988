/* eslint-disable @typescript-eslint/no-unused-vars */
import { OnboardingStep } from 'graphql/organisations/enums';
import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useStoreState } from 'state';

type Props = Omit<RouteComponentProps, 'match'>;
interface Return {
  userOnboarded: OnboardingStep;
  orgOnboarding: OnboardingStep | undefined;
  currentStep: number;
}

const useDealerOnboarding = ({ location, history }: Props): Return => {
  const demo = useStoreState((state) => state.theme.demoMode);
  const userOnboarded = demo
    ? OnboardingStep.details
    : useStoreState((state) => state.user.onboarding);
  const orgOnboarding = demo
    ? OnboardingStep.details
    : useStoreState((state) => state.user.organisation?.onboarding);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const orgComplete = orgOnboarding === OnboardingStep.complete;
  const userComplete = userOnboarded === OnboardingStep.complete;

  useEffect(() => {
    if (userComplete) {
      if (orgComplete) history.push('/app');
    }
    // eslint-disable-next-line
  }, [orgOnboarding]);

  useEffect(() => {
    if (location.pathname.includes('terms-conditions')) {
      setCurrentStep(0);
    } else if (location.pathname.includes('business-details')) {
      if (userComplete) return setCurrentStep(0);
      return setCurrentStep(1);
    } else if (location.pathname.includes('terms-of-business')) {
      if (userComplete) return setCurrentStep(1);
      return setCurrentStep(2);
    } else if (location.pathname.includes('vat-id')) {
      if (userComplete) return setCurrentStep(2);
      return setCurrentStep(3);
    } else if (location.pathname.includes('payment-method')) {
      if (userComplete) return setCurrentStep(3);
      return setCurrentStep(4);
    } else if (location.pathname.includes('getting-started')) {
      if (userComplete) return setCurrentStep(4);
      return setCurrentStep(5);
    }
    return setCurrentStep(0);
    // eslint-disable-next-line
  }, [location]);

  return {
    userOnboarded,
    orgOnboarding,
    currentStep,
  };
};

export default useDealerOnboarding;
