import antdHrHR from 'antd/es/locale/hr_HR';
import hrMsg from '../locales/hr_HR.json';

const SkLang = {
  antd: antdHrHR,
  locale: 'hr-HR',
  messages: {
    ...hrMsg,
  },
};
export default SkLang;
