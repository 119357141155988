import antdCsCZ from 'antd/es/locale/cs_CZ';
import csMsg from '../locales/cs_CZ.json';

const CsLang = {
  antd: antdCsCZ,
  locale: 'cs-CZ',
  messages: {
    ...csMsg,
  },
};
export default CsLang;
