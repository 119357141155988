import { gql } from '@apollo/client';
import TermsType from '../enums';

export const TermsWithContent = gql`
  query Terms($where: TermWhereInput) {
    terms(where: $where) {
      id
      name
      description
      defaultTerms
      type
      content
      grContent
      deContent
      frContent
      skContent
      hrContent
      czContent
    }
  }
`;

export interface TermsWithContentArgs {
  where?: {
    OR: {
      defaultTerms?: {
        equals: boolean;
      };
    }[];
  };
}

export interface TermsWithContentRes {
  terms: {
    id: string;
    name: string;
    description: string;
    defaultTerms: boolean;
    type: TermsType;
    content: string;
    grContent: string;
    deContent: string;
    frContent: string;
    skContent: string;
    czContent: string;
    hrContent: string;
  }[];
}
