import React from 'react';
import { APP_NAME } from 'configs/AppConfig';
import utils from 'utils';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

interface GetLogoArgs {
  logoType?: string;
}

const getLogo = (props: GetLogoArgs) => {
  const { logoType } = props;
  if (logoType === 'light') {
    return '/img/logo-white.svg';
  }

  return '/img/logo.svg';
};

interface GetLogoDisplayArgs {
  isMobile: boolean;
  mobileLogo?: boolean;
}

const getLogoDisplay = ({ isMobile, mobileLogo }: GetLogoDisplayArgs) => {
  if (isMobile && !mobileLogo) {
    return 'd-none';
  }
  return 'logo';
};

interface Props {
  mobileLogo: boolean | undefined;
  logoType: string | undefined;
}

const Logo = ({ mobileLogo, logoType }: Props): JSX.Element => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');

  return (
    <div
      className={getLogoDisplay({ isMobile, mobileLogo })}
      style={{
        width: 110,
        marginLeft: 10,
      }}
    >
      <img
        src={getLogo({ logoType })}
        alt={`${APP_NAME} logo`}
        style={{ width: 110 }}
      />
    </div>
  );
};

export default Logo;
