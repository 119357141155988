import React from 'react';
import useStyles from './Loading.styles';

const LoginOne = (): JSX.Element => {
  // const theme = useStoreState((state) => state.theme.currentTheme);

  // const backgroundStyle = {
  //   background:
  //     theme === 'dark'
  //       ? 'linear-gradient(to right, #45a247, #21409a)'
  //       : 'linear-gradient(to right, #67B26F, #4ca2cd)',
  //   minHeight: '100vh',
  //   display: 'flex',
  //   alignItems: 'center',
  // };

  const classes = useStyles();
  return (
    <div className={classes.cover}>
      <div className={classes.position}>
        <div className={classes.loadingCard}>
          <div className={classes.loadingLogo} />

          <div className={classes.loadingSpinner}>
            <span className={classes.loader} />
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="h-100" style={backgroundStyle}>
  //     <div className="container d-flex flex-column justify-content-center h-100">
  //       <Row justify="center">
  //         <Col xs={20} sm={20} md={20} lg={7}>
  //           <Card>
  //             <div className="my-4">
  //               <div className="text-center">
  //                 <img
  //                   className="img-fluid"
  //                   src={`/img/${
  //                     theme === 'light' ? 'logo.svg' : 'logo-white.svg'
  //                   }`}
  //                   alt=""
  //                 />
  //               </div>
  //               <Row justify="center">
  //                 <Col xs={24} sm={24} md={20} lg={20}>
  //                   <Loading />
  //                 </Col>
  //               </Row>
  //             </div>
  //           </Card>
  //         </Col>
  //       </Row>
  //     </div>
  //   </div>
  // );
};

export default LoginOne;
