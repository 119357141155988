import React from 'react';
import { Drawer } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import Flex from 'components/TemplateComponents/Flex';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { NavType } from 'state';
import { NavItem } from 'configs/NavigationConfig';
import MenuContent from './MenuContent';
import Logo from './Logo';

interface Props {
  routeInfo: NavItem;
  hideGroupTitle?: boolean | undefined;
  localization?: boolean | undefined;
}

const MobileNav = ({
  routeInfo,
  hideGroupTitle,
  localization = true,
}: Props): JSX.Element => (
  <Drawer placement="left" closable={false} bodyStyle={{ padding: 5 }}>
    <Flex flexDirection="column" className="h-100">
      <Flex justifyContent="between" alignItems="center">
        <Logo mobileLogo logoType={undefined} />
        <div className="nav-close">
          <ArrowLeftOutlined />
        </div>
      </Flex>
      <div className="mobile-nav-menu">
        <Scrollbars autoHide>
          <MenuContent
            type={NavType.SIDE}
            routeInfo={routeInfo}
            hideGroupTitle={hideGroupTitle}
            localization={localization}
          />
        </Scrollbars>
      </div>
    </Flex>
  </Drawer>
);

MobileNav.defaultProps = {
  hideGroupTitle: undefined,
  localization: undefined,
};

export default MobileNav;
