import { gql } from '@apollo/client';
import Role from '../enums';

export const InviteUser = gql`
  mutation inviteUser($data: InviteUserData!) {
    inviteUser(data: $data) {
      id
      name
      email
      emailVerified
      role
    }
  }
`;

export interface InviteUserArgs {
  data: {
    email: string;
    name: string;
    role: Role;
    customer?: {
      id: string;
    };
    dealer?: {
      id: string;
    };
    distributor?: {
      id: string;
    };
  };
}

export interface InviteUserRes {
  inviteUser: {
    id: string;
    name: string;
    email: string;
    emailVerified: boolean;
    role: Role;
  };
}
