import React, { FunctionComponent, ComponentClass } from 'react';

interface Props {
  type:
    | string
    | FunctionComponent<{ className: string | undefined; style: unknown }>
    | ComponentClass<{ className: string | undefined; style: unknown }>;
  className?: string | undefined;
  style?: unknown;
}

const Icon = ({ type, className, style }: Props): JSX.Element => (
  <>{React.createElement(type, { className, style })}</>
);

Icon.defaultProps = {
  className: undefined,
  style: undefined,
};

export default Icon;
