import antdDeDE from 'antd/es/locale/de_DE';
import deMsg from '../locales/de_DE.json';

const DeLang = {
  antd: antdDeDE,
  locale: 'de-DE',
  messages: {
    ...deMsg,
  },
};
export default DeLang;
