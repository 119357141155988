import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import View from './TermsOfBusiness.view';
import useTermsOfBusiness from './useTermsOfBusiness';

type Props = Omit<RouteComponentProps, 'match' | 'location'>;

const TermsOfBusiness = ({ history }: Props): JSX.Element => {
  const {
    businessTerms,
    onSubmit,
    language,
    modalState,
    openModal,
    demo,
    closeModal,
  } = useTermsOfBusiness({ history });

  return (
    <View
      demo={demo}
      closeModal={closeModal}
      openModal={openModal}
      modalState={modalState}
      businessTerms={businessTerms}
      onSubmit={onSubmit}
      language={language}
    />
  );
};
export default TermsOfBusiness;
