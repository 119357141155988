const dev = {
  API_ENDPOINT_URL: 'https://jsonplaceholder.typicode.com',
};

const prod = {
  API_ENDPOINT_URL: 'https://api.prod.com',
};

const test = {
  API_ENDPOINT_URL: 'https://api.test.com',
};

const getEnv = () => {
  if (process.env.NODE_ENV === 'test') return test;
  if (process.env.NODE_ENV === 'production') return prod;
  return dev;
};
const env = getEnv();
export default env;
