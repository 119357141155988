import React, { useState } from 'react';
import { Spin, Card, Row, Col, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowRight,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';

const { Title } = Typography;

interface OptionProps {
  text: string;
  icon: IconDefinition;
  onClick(): void;
  disabled: boolean;
}

const Option = ({
  text,
  icon,
  onClick,
  disabled,
}: OptionProps): JSX.Element => {
  const [hover, setHover] = useState(false);

  return (
    <Card
      className="mt-3"
      style={{
        cursor: disabled ? 'default' : 'pointer',
        opacity: disabled ? 0.5 : 1,
      }}
      onMouseEnter={() => !disabled && setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => !disabled && onClick()}
    >
      <Row align="middle">
        <Col>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 55,
              width: 55,
              borderRadius: 100,
              backgroundColor: hover ? '#21B573' : '#EEEEEE',
            }}
          >
            <FontAwesomeIcon
              icon={icon}
              style={{
                fontSize: 28,
                color: hover ? '#FFF' : 'rgb(69, 85, 96)',
              }}
            />
          </div>
        </Col>
        <Col className="ml-4" flex={1}>
          <Title
            style={{ marginBottom: 0, color: hover ? '#21B573' : '#424242' }}
            level={4}
          >
            {text}
          </Title>
        </Col>
        <Col>
          {!disabled ? (
            <FontAwesomeIcon
              style={{ fontSize: 28, color: hover ? '#21B573' : '#424242' }}
              icon={faLongArrowRight}
            />
          ) : (
            <Spin />
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default Option;
