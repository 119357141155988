import {
  faBuilding,
  faUsers,
  faUser,
  faChartNetwork,
  faBoxUsd,
  faCog,
  faTachometerFast,
  // faBoxOpen,
  faFileInvoiceDollar,
  faSignalStream,
  // faWalkieTalkie,
  // faFolders,
  faBooks,
  faBook,
  faUsersCog,
  faBadgeDollar,
  // faSimCard,
  IconDefinition,
  faFileInvoice,
  faReceipt,
  faDownload,
  faArrowSquareRight,
  faBrowser,
  faKey,
  // faBoxOpen,
  faSimCard,
  // faDatabase,
  faVideo,
  // faShoppingBasket,
} from '@fortawesome/pro-light-svg-icons';
import Role from 'graphql/users/enums';
import { OrgType } from 'state/user-model';

import { defineMessage } from 'react-intl';

export interface MenuItem {
  key: string;
  path: string;
  externalPath?: string;
  title: string;
  icon?: IconDefinition;
  rightIcon?: IconDefinition;
  breadcrumb: boolean;
  roles?: Role[];
  orgTypes: OrgType[];
  intl?: { id: string; defaultMessage: string };
}

export interface SubMenuItem extends MenuItem {
  submenu: MenuItem[];
}

export interface NavItem extends MenuItem {
  submenu: SubMenuItem[];
}

export type NavTree = NavItem[];

const dealerOnlyItems: NavItem[] = [
  {
    key: 'customers',
    path: `/customers`,
    title: 'sidenav.customers',
    icon: faUsers,
    breadcrumb: false,
    submenu: [],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.dealer],
    intl: defineMessage({
      id: 'sidenav.customers',
      defaultMessage: 'Customer',
    }),
  },
  {
    key: 'services',
    path: `/services`,
    title: 'sidenav.hytalkServices',
    icon: faSignalStream,
    breadcrumb: false,
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.dealer],
    submenu: [],
    intl: defineMessage({
      id: 'sidenav.hytalkServices',
      defaultMessage: 'Services',
    }),
  },
  // {
  //   key: 'devices',
  //   path: `/devices`,
  //   title: 'sidenav.devices',
  //   icon: faWalkieTalkie,
  //   breadcrumb: false,
  //   roles: [Role.admin, Role.user],
  //   orgTypes: [OrgType.dealer],
  //   submenu: [],
  // },
  {
    key: 'sim-cards',
    path: `/products/sim-cards`,
    title: 'sidenav.simCards',
    icon: faSimCard,
    // orgTypes: [OrgType.nvoyy],

    breadcrumb: false,
    submenu: [
      // {
      //   key: 'sim-cards',
      //   path: `/products/sim-cards`,
      //   title: 'sidenav.simCards',
      //   icon: faSimCard,
      //   breadcrumb: false,
      //   roles: [Role.admin, Role.user],
      //   orgTypes: [OrgType.dealer],
      //   submenu: [],
      // },
    ],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.dealer],
  },
  {
    key: 'billing',
    path: `/billing`,
    title: 'sidenav.billing',
    icon: faFileInvoiceDollar,
    breadcrumb: false,
    roles: [Role.admin],
    orgTypes: [OrgType.dealer],
    intl: defineMessage({
      id: 'sidenav.billing',
      defaultMessage: 'Billing',
    }),
    submenu: [
      {
        key: 'billingDashboard',
        path: `/billing/dashboard`,
        title: 'sidenav.details',
        icon: faUsersCog,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.details',
          defaultMessage: 'Dashboard',
        }),
      },
      {
        key: 'priceLists',
        path: `/billing/price-list`,
        title: 'sidenav.priceList',
        icon: faBadgeDollar,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.priceList',
          defaultMessage: 'Price List',
        }),
      },
      // {
      //   key: 'orders',
      //   path: `/billing/orders`,
      //   title: 'sidenav.orders',
      //   icon: faShoppingBasket,
      //   breadcrumb: false,
      //   roles: [Role.admin, Role.user],
      //   orgTypes: [OrgType.dealer],
      //   submenu: [],
      // },
      {
        key: 'invoices',
        path: `/billing/invoices`,
        title: 'sidenav.invoices',
        icon: faFileInvoice,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.invoices',
          defaultMessage: 'Invoices',
        }),
      },
      {
        key: 'invoice-report',
        path: '/billing/invoices/report',
        title: 'sidenav.invoiceReport',
        icon: faFileInvoice,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.invoiceReport',
          defaultMessage: 'Invoices Report',
        }),
      },
      {
        key: 'payments',
        path: `/billing/payments`,
        title: 'sidenav.payments',
        icon: faReceipt,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.payments',
          defaultMessage: 'Payments',
        }),
      },
    ],
  },
  {
    key: 'settings',
    path: `/settings`,
    title: 'sidenav.settings',
    icon: faCog,
    breadcrumb: false,
    roles: [Role.admin],
    orgTypes: [OrgType.dealer],
    intl: defineMessage({
      id: 'sidenav.settings',
      defaultMessage: 'Settings',
    }),
    submenu: [
      {
        key: 'users',
        path: `/settings/users`,
        title: 'sidenav.users',
        icon: faUsers,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.users',
          defaultMessage: 'Users',
        }),
      },
      {
        key: 'sop-details',
        path: `/settings/sop-details`,
        title: 'sidenav.sopDetails',
        icon: faKey,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.sopDetails',
          defaultMessage: 'SOP Details',
        }),
      },
    ],
  },
  {
    key: 'resources',
    path: `/resources`,
    title: 'sidenav.resources',
    icon: faBooks,
    breadcrumb: false,
    roles: [Role.admin],
    orgTypes: [OrgType.dealer],
    intl: defineMessage({
      id: 'sidenav.resources',
      defaultMessage: 'Resources',
    }),
    submenu: [
      {
        key: 'resourcesManuals',
        path: `/resources/manuals`,
        title: 'sidenav.manuals',
        icon: faBook,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.manuals',
          defaultMessage: 'Manuals',
        }),
      },
      {
        key: 'resourcesDownloads',
        path: `/resources/downloads`,
        title: 'sidenav.downloads',
        icon: faDownload,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.downloads',
          defaultMessage: 'Downloads',
        }),
      },
    ],
  },
  {
    key: 'portals',
    path: `/portals`,
    title: 'sidenav.portals',
    icon: faBrowser,
    breadcrumb: false,
    roles: [Role.admin],
    orgTypes: [OrgType.dealer],
    intl: defineMessage({
      id: 'sidenav.portals',
      defaultMessage: 'Portals',
    }),
    submenu: [
      {
        key: 'sopPortal',
        externalPath: 'https://sop.hytalk.co.uk/hytalk/login',
        path: '',

        title: 'sidenav.sopPortal',
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        icon: faArrowSquareRight,
        intl: defineMessage({
          id: 'sidenav.sopPortal',
          defaultMessage: 'SOP Portal',
        }),
      },
      {
        key: 'dispatcher',
        path: '',
        externalPath: 'https://dispatch.hytalk.co.uk',
        title: 'sidenav.dispatcher',
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        icon: faArrowSquareRight,
        intl: defineMessage({
          id: 'sidenav.dispatcher',
          defaultMessage: 'Dispatcher',
        }),
      },
    ],
  },
];

const adminOnlyItems: NavItem[] = [
  {
    key: 'distributors',
    path: `/distributors`,
    title: 'sidenav.distributors',
    icon: faChartNetwork,
    breadcrumb: true,
    submenu: [],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.nvoyy],
    intl: defineMessage({
      id: 'sidenav.distributors',
      defaultMessage: 'Distributors',
    }),
  },
  {
    key: 'dealers',
    path: `/dealers`,
    title: 'sidenav.dealers',
    icon: faBuilding,
    breadcrumb: true,
    submenu: [],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.nvoyy],
    intl: defineMessage({
      id: 'sidenav.dealers',
      defaultMessage: 'Dealers',
    }),
  },
  // {
  //   key: 'customers',
  //   path: `/customers`,
  //   title: 'sidenav.customers',
  //   icon: faUsers,
  //   breadcrumb: true,
  //   submenu: [],
  //   roles: [Role.admin, Role.user],
  //   orgTypes: [OrgType.hytalk],
  // },
  {
    key: 'products',
    path: `/products/services`,
    title: 'sidenav.products',
    icon: faBoxUsd,
    breadcrumb: true,
    submenu: [],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.nvoyy],
    intl: defineMessage({
      id: 'sidenav.products',
      defaultMessage: 'Products',
    }),
  },
  {
    key: 'resources',
    path: `/resources`,
    title: 'sidenav.resources',
    icon: faBooks,
    breadcrumb: false,
    roles: [Role.admin],
    orgTypes: [OrgType.nvoyy],
    intl: defineMessage({
      id: 'sidenav.resources',
      defaultMessage: 'Resources',
    }),
    submenu: [
      {
        key: 'resourcesManuals',
        path: `/resources/manuals`,
        title: 'sidenav.manuals',
        icon: faBook,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.nvoyy],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.manuals',
          defaultMessage: 'Manuals',
        }),
      },
      {
        key: 'resourcesDownloads',
        path: `/resources/downloads`,
        title: 'sidenav.downloads',
        icon: faDownload,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.nvoyy],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.downloads',
          defaultMessage: 'Downloads',
        }),
      },
    ],
  },
  {
    key: 'settings',
    path: `/settings`,
    title: 'sidenav.settings',
    icon: faCog,
    breadcrumb: true,
    submenu: [],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.nvoyy],
    intl: defineMessage({
      id: 'sidenav.settings',
      defaultMessage: 'Settings',
    }),
  },
];

const distributorOnlyItems: NavItem[] = [
  {
    key: 'dealers',
    path: `/dealers`,
    title: 'sidenav.dealers',
    icon: faBuilding,
    breadcrumb: true,
    submenu: [],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.distributor],
    intl: defineMessage({
      id: 'sidenav.dealers',
      defaultMessage: 'Dealers',
    }),
  },
  {
    key: 'customers',
    path: `/customers`,
    title: 'sidenav.customers',
    icon: faUser,
    breadcrumb: true,
    submenu: [],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.distributor],
    intl: defineMessage({
      id: 'sidenav.customers',
      defaultMessage: 'Customers',
    }),
  },
  // {
  //   key: 'products',
  //   path: `/products`,
  //   title: 'sidenav.products',
  //   icon: faBoxUsd,
  //   breadcrumb: true,
  //   submenu: [],
  //   roles: [Role.admin, Role.user],
  //   orgTypes: [OrgType.distributor],
  //   intl: defineMessage({
  //     id: 'sidenav.products',
  //     defaultMessage: 'Products',
  //   }),
  // },
  {
    key: 'billing',
    path: `/billing`,
    title: 'sidenav.billing',
    icon: faFileInvoiceDollar,
    breadcrumb: false,
    roles: [Role.admin],
    orgTypes: [OrgType.distributor],
    intl: defineMessage({
      id: 'sidenav.billing',
      defaultMessage: 'Billing',
    }),
    submenu: [
      {
        key: 'billingDashboard',
        path: `/billing/dashboard`,
        title: 'sidenav.details',
        icon: faUsersCog,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.distributor],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.details',
          defaultMessage: 'Dashboard',
        }),
      },
      {
        key: 'priceLists',
        path: `/billing/price-list`,
        title: 'sidenav.priceList',
        icon: faBadgeDollar,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.distributor],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.priceList',
          defaultMessage: 'Price List',
        }),
      },
      // {
      //   key: 'orders',
      //   path: `/billing/orders`,
      //   title: 'sidenav.orders',
      //   icon: faShoppingBasket,
      //   breadcrumb: false,
      //   roles: [Role.admin, Role.user],
      //   orgTypes: [OrgType.dealer],
      //   submenu: [],
      // },
      {
        key: 'invoices',
        path: `/billing/invoices`,
        title: 'sidenav.invoices',
        icon: faFileInvoice,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.distributor],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.invoices',
          defaultMessage: 'Invoices',
        }),
      },
      {
        key: 'payments',
        path: `/billing/payments`,
        title: 'sidenav.payments',
        icon: faReceipt,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.distributor],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.payments',
          defaultMessage: 'Payments',
        }),
      },
    ],
  },
  {
    key: 'settings',
    path: `/settings`,
    title: 'sidenav.settings',
    icon: faCog,
    breadcrumb: false,
    roles: [Role.admin],
    orgTypes: [OrgType.distributor],
    intl: defineMessage({
      id: 'sidenav.settings',
      defaultMessage: 'Settings',
    }),
    submenu: [
      {
        key: 'users',
        path: `/settings/users`,
        title: 'sidenav.users',
        icon: faUsers,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.users',
          defaultMessage: 'Users',
        }),
      },
    ],
  },
];

const hyteraOnlyItems: NavItem[] = [
  {
    key: 'dealers',
    path: `/dealers`,
    title: 'sidenav.dealers',
    icon: faBuilding,
    breadcrumb: true,
    submenu: [],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.hytera],
    intl: defineMessage({
      id: 'sidenav.dealers',
      defaultMessage: 'Dealers',
    }),
  },
  {
    key: 'products',
    path: `/products`,
    title: 'sidenav.products',
    icon: faBoxUsd,
    breadcrumb: true,
    submenu: [],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.hytera],
    intl: defineMessage({
      id: 'sidenav.products',
      defaultMessage: 'Products',
    }),
  },
  {
    key: 'settings',
    path: `/settings`,
    title: 'sidenav.settings',
    icon: faCog,
    breadcrumb: false,
    roles: [Role.admin],
    orgTypes: [OrgType.hytera],
    intl: defineMessage({
      id: 'sidenav.settings',
      defaultMessage: 'Settings',
    }),
    submenu: [
      {
        key: 'users',
        path: `/settings/users`,
        title: 'sidenav.users',
        icon: faUsers,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.hytera],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.users',
          defaultMessage: 'Users',
        }),
      },
    ],
  },
];

const sharedItems: NavItem[] = [
  {
    key: 'dashboard',
    path: `/dashboard`,
    title: 'sidenav.dashboard',
    icon: faTachometerFast,
    breadcrumb: false,
    submenu: [],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.dealer, OrgType.nvoyy, OrgType.distributor],
    intl: defineMessage({
      id: 'sidenav.dashboard',
      defaultMessage: 'Dashboard',
    }),
  },
  {
    key: 'Dem',
    path: `/dem`,
    title: 'Dem',
    icon: faVideo,
    breadcrumb: false,
    submenu: [
      {
        key: 'dem dashboard',
        path: `/dem/dashboard`,
        title: 'sidenav.dashboard',
        icon: faTachometerFast,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'sidenav.dashboard',
          defaultMessage: 'Dashboard',
        }),
      },
      {
        key: 'dem companies',
        path: `/dem/companies`,
        title: 'Companies',
        icon: faBuilding,
        breadcrumb: false,
        roles: [Role.admin, Role.user],
        orgTypes: [OrgType.dealer],
        submenu: [],
        intl: defineMessage({
          id: 'Companies',
          defaultMessage: 'Companies',
        }),
      },
    ],
    roles: [Role.admin, Role.user],
    orgTypes: [OrgType.dealer],
    intl: defineMessage({
      id: 'sidenav.dem',
      defaultMessage: 'Dem',
    }),
  },
];

const navigationConfig = [
  ...sharedItems,
  ...adminOnlyItems,
  ...dealerOnlyItems,
  ...distributorOnlyItems,
  ...hyteraOnlyItems,
];

export default navigationConfig;
