import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Icon = <LoadingOutlined style={{ fontSize: 35 }} spin />;

export interface Props {
  align?: 'center' | 'left' | 'right';
  alignSelf?: 'center';
  cover?: string;
  size?: 'default' | 'small' | 'large' | undefined;
}

const Loading = ({
  align = 'center',
  cover = 'inline',
  alignSelf = 'center',
  size,
}: Props): JSX.Element => (
  <div
    className={`loading text-${align} align-self-${alignSelf} cover-${cover}`}
  >
    <Spin indicator={Icon} size={size} />
  </div>
);

export default Loading;
