import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { useStoreActions, useStoreState } from 'state';
import {
  useGetDealerPaymentMethodsQuery,
  useCreatePaymentMethodMutation,
  useCompleteOnboardingMutation,
  OnboardingStepEnum,
} from 'graphql/generated/graphql';
import { OnboardingStep } from 'graphql/organisations/enums';

interface Props extends Omit<RouteComponentProps, 'match' | 'location'> {
  status: string;
}
interface Return {
  setupDirectDebit: () => Promise<void>;
  setupCard: () => Promise<void>;
  loading: boolean;
  demoModeSetup: () => void;
  demoMode: boolean;
}

const usePaymentMethod = ({ status, history }: Props): Return => {
  const demo = useStoreState((state) => state.theme.demoMode);
  const stripe = useStripe();
  const [directDebit, setDirectDebit] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);
  const setOrgOnboarding = useStoreActions(
    (actions) => actions.user.setOrgOnboarding
  );
  const setFirstLogin = useStoreActions(
    (actions) => actions.user.setFirstLogin
  );
  const dealerId = demo
    ? ''
    : useStoreState((state) => state.user.organisation?.id);
  const onboardingStep = demo
    ? OnboardingStep.payment
    : useStoreState((state) => state.user.organisation?.onboarding);

  const { loading: methodsLoading } = useGetDealerPaymentMethodsQuery({
    variables: {
      where: {
        id: dealerId,
      },
    },
    onCompleted: ({ dealer }) => {
      if (demo) {
        setDirectDebit(['bacs_debit']);
      } else if (dealer && dealer.availablePaymentMethods) {
        if (dealer.availablePaymentMethods.includes('bacs_debit'))
          setDirectDebit(['bacs_debit']);
        if (dealer.availablePaymentMethods.includes('sepa_debit'))
          setDirectDebit(['sepa_debit']);
      }
    },
  });

  const [createPaymentSession] = useCreatePaymentMethodMutation();
  const [completeOnboarding] = useCompleteOnboardingMutation({
    onCompleted: () => {
      setOrgOnboarding({
        onboarding: OnboardingStep.complete,
      });
      setFirstLogin({
        firstLogin: true,
      });
    },
  });

  useEffect(() => {
    if (onboardingStep === OnboardingStep.details) {
      history.push('/onboarding/dealer/business-details');
    } else if (onboardingStep === OnboardingStep.terms) {
      history.push('/onboarding/dealer/terms-of-business');
    } else if (onboardingStep === OnboardingStep.vat) {
      history.push('/onboarding/dealer/vat-id');
    }
    // eslint-disable-next-line
  }, [onboardingStep]);

  useEffect(() => {
    if (status === 'success') {
      setLoading(true);
      completeOnboarding({
        variables: {
          data: {
            onboarding: { set: OnboardingStepEnum.Complete },
          },
          where: {
            id: dealerId,
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [status]);

  const setupPaymentSession = async (type: string[]) => {
    try {
      setLoading(true);

      const session = await createPaymentSession({
        variables: {
          where: { dealerId: dealerId || '' },
          data: {
            type,
            successUrl: `${process.env.REACT_APP_PATH}onboarding/dealer/payment-method/success`,
            cancelUrl: `${process.env.REACT_APP_PATH}onboarding/dealer/payment-method/processing`,
          },
        },
      });
      stripe?.redirectToCheckout({
        sessionId: session.data?.createStripePaymentMethod?.id || '',
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const demoModeSetup = () => {
    setOrgOnboarding({
      onboarding: OnboardingStep.complete,
    });
    setFirstLogin({
      firstLogin: true,
    });
    history.push('/demo');
  };

  const setupDirectDebit = async () => {
    setupPaymentSession(directDebit);
  };

  const setupCard = async () => {
    setupPaymentSession(['card']);
  };

  return {
    setupDirectDebit,
    setupCard,
    demoModeSetup,
    demoMode: demo,
    loading: loading || methodsLoading,
  };
};
export default usePaymentMethod;
