import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useStoreState, useStoreActions } from 'state';
import { OnboardingStep } from 'graphql/organisations/enums';
import TermsType from 'graphql/terms/enums';
import {
  OnboardingStepEnum,
  useSignDistributorTermsMutation,
} from 'graphql/generated/graphql';
import { FormData } from './TermsOfBusiness.view';
import { useDistributorOnboarding } from '../../Context';

type Props = Omit<RouteComponentProps, 'location' | 'match'>;
interface Return {
  businessTerms:
    | {
        id: string;
        name: string;
        description: string;
        defaultTerms: boolean;
        type: TermsType;
        content: string;
        hrContent: string;
        grContent: string;
        deContent: string;
        frContent: string;
        skContent: string;
        czContent: string;
      }
    | undefined;
  onSubmit: (data: FormData) => void;
  language: string;
  closeModal: () => void;
  openModal: () => void;
  modalState: boolean;
  demo: boolean;
}

const useTermsOfBusiness = ({ history }: Props): Return => {
  const language = useStoreState((state) => state.theme.locale);
  const demo = useStoreState((state) => state.theme.demoMode);
  const id = demo ? '' : useStoreState((state) => state.user.organisation?.id);
  const onboardingStep = demo
    ? OnboardingStep.terms
    : useStoreState((state) => state.user.organisation?.onboarding);
  const setOrgOnboarding = useStoreActions(
    (actions) => actions.user.setOrgOnboarding
  );
  const { state } = useDistributorOnboarding();

  useEffect(() => {
    if (onboardingStep === OnboardingStep.details) {
      history.push('/onboarding/dealer/business-details');
    } else if (onboardingStep === OnboardingStep.payment) {
      history.push('/onboarding/distributor/payment-method/processing');
    }
    // eslint-disable-next-line
  }, [onboardingStep]);
  const [modalState, setModalState] = useState<boolean>(false);
  const [signTerms] = useSignDistributorTermsMutation();

  const onSubmit = (data: FormData) => {
    if (demo) {
      setOrgOnboarding({
        onboarding: OnboardingStep.payment,
      });
      history.push('/onboarding/distributor/payment-method/processing');
    } else {
      signTerms({
        variables: {
          data: {
            onboarding: {
              set: OnboardingStepEnum.Payment,
            },
            termAgreement: {
              create: {
                jobTitle: data.jobTitle,
                name: data.name,
                signature: data.signature,
                terms: {
                  connect: {
                    id: state?.businessTerms?.id || '',
                  },
                },
              },
            },
          },
          where: { id: id || '' },
        },
        onCompleted: () => {
          history.push('/onboarding/distributor/payment-method/processing');
        },
      });
      setOrgOnboarding({
        onboarding: OnboardingStep.payment,
      });
    }
  };

  const closeModal = () => {
    setModalState(!modalState);
  };

  return {
    businessTerms: state.businessTerms,
    onSubmit,
    language,
    closeModal,
    openModal: closeModal,
    modalState,
    demo,
  };
};
export default useTermsOfBusiness;
