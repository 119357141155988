import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css';

Sentry.init({
  dsn: 'https://8a4dad5d638d4527996b640cca48e950@o1303815.ingest.sentry.io/6549734',
  integrations: [new BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Auth0Provider
    domain="auth.hytalk.co.uk"
    clientId="VjZ3W9iPmcSKjTmoWMJpviWRIu59HXsW"
    redirectUri={window.location.origin}
    audience="https://portal.hytalk.co.uk"
    scope="read:current_user update:current_user_metadata"
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
