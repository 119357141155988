/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React from 'react';

import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { RouteProps } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { onError } from '@apollo/client/link/error';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ApolloProviderWithAuth0 = ({ children }: RouteProps): JSX.Element => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_PATH,
  });

  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const authToken = await getAccessTokenSilently({
        audience: `https://portal.hytalk.co.uk`,
        scope: 'openid read:current_user',
      });
      return {
        headers: {
          ...headers,
          Authorization: authToken ? `Bearer ${authToken}` : '',
        },
      };
    }
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ''`,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        Sentry.captureMessage(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        // console.log(
        //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        // );
      });

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      Sentry.captureException(networkError);
    }
  });

  const client = new ApolloClient({
    link: errorLink.concat(authLink).concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',

        nextFetchPolicy(currentFetchPolicy) {
          if (
            currentFetchPolicy === 'network-only' ||
            currentFetchPolicy === 'cache-and-network'
          ) {
            // Demote the network policies (except "no-cache") to "cache-first"
            // after the first request.
            return 'cache-first';
          }
          // Leave all other fetch policies unchanged.
          return currentFetchPolicy;
        },
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

// eslint-disable-next-line import/prefer-default-export
export { ApolloProviderWithAuth0 };
