import { OnboardingStep } from 'graphql/organisations/enums';
import { useStoreActions } from 'state';

interface Return {
  completeOnboarding: () => void;
}

const useGettingStarted = (): Return => {
  const setOrgOnboarding = useStoreActions(
    (actions) => actions.user.setOrgOnboarding
  );
  const setFirstLogin = useStoreActions(
    (actions) => actions.user.setFirstLogin
  );

  const completeOnboarding = () => {
    setOrgOnboarding({
      onboarding: OnboardingStep.complete,
    });

    setFirstLogin({
      firstLogin: true,
    });
  };

  return {
    completeOnboarding,
  };
};
export default useGettingStarted;
