import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import {
  FilterConfirmProps,
  FilterDropdownProps,
} from 'antd/lib/table/interface';
import React from 'react';
import Highlighter from 'react-highlight-words';

type K<T> = keyof T;
interface GetColumnSearchPropsArgs<
  T extends { [key: string]: string | Date | number | string[] }
> {
  dataIndex: K<T>;
  state: {
    searchText: string;
    setSearchText: React.Dispatch<React.SetStateAction<string>>;
    searchedColumn: K<T> | '';
    setSearchedColumn: React.Dispatch<React.SetStateAction<K<T> | ''>>;
  };
}

interface GetColumnSearchPropsReturn<
  T extends { [key: string]: string | Date | number | string[] }
> {
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }: FilterDropdownProps) => JSX.Element;
  filterIcon: (filtered: boolean) => JSX.Element;
  onFilter: (value: string | number | boolean, record: T) => boolean;
  onFilterDropdownVisibleChange: (visible: boolean) => void;
  render: (text: string) => string | JSX.Element;
}

/**
 * args - @see {@link GetColumnSearchPropsArgs}
 * @param {string} args.dataIndex - Should match the 'dataIndex' property on the columns config object. Should also be a key of the table data source prop.
 * @param {object} args.state - An object containing { searchText, setSearchText, searchedColumn, setSearchedColumn }. These should be managed in the parent component, and passed into this function to handle column searching.
 * @returns @see {@link GetColumnSearchPropsReturn}
 *
 * @description This utility function accepts the data index of the target column, and the values and setter functions for the search managed by the parent component. The function returns configured props to pass directly into the objects within the columns array, enabling searching on that column.
 */
const getColumnSearchProps = <
  T extends { [key: string]: string | number | Date | string[] }
>({
  dataIndex,
  state,
}: GetColumnSearchPropsArgs<T>): GetColumnSearchPropsReturn<T> => {
  const { searchText, setSearchText, searchedColumn, setSearchedColumn } =
    state;
  const handleSearch = (
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps) => void,
    index: keyof T
  ) => {
    confirm();
    setSearchText(selectedKeys[0] as string);
    setSearchedColumn(index);
  };

  const handleReset = (
    selectedKeys: React.Key[],
    confirm: (param?: FilterConfirmProps) => void,
    index: keyof T,
    clearFilters?: () => void
  ) => {
    if (clearFilters) clearFilters();
    confirm();
    setSearchText('');
    setSearchedColumn(index);
  };

  let searchInput: Input;
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            if (node) searchInput = node;
          }}
          placeholder="Search..."
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              handleReset(selectedKeys, confirm, dataIndex, clearFilters)
            }
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string | number | boolean, record: T): boolean => {
      if (typeof value !== 'string') return false;

      if (record[dataIndex])
        return record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      return false;
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  };
};
export default getColumnSearchProps;
