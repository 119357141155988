import React, { useState, useEffect } from 'react';
import { Button, Layout, Select, Switch } from 'antd';
// eslint-disable-next-line
import NavNotification from './NavNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/pro-light-svg-icons';
import { useThemeSwitcher } from 'react-css-theme-switcher';

import { SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import utils, { LocalStorageKeys, typedLocalStorage } from 'utils';
import { useStoreState, useStoreActions } from 'state';

import { useHistory } from 'react-router-dom';
import { OrgType } from 'state/user-model';
import Logo from './Logo';
import NavProfile from './NavProfile';

const { Header } = Layout;

interface Props {
  isMobile: boolean;
  isOnboarding?: boolean;
}

export const HeaderNav = (props: Props): JSX.Element => {
  const { switcher, themes } = useThemeSwitcher();
  const history = useHistory();

  const { isMobile, isOnboarding } = props;
  // eslint-disable-next-line
  const [searchActive, setSearchActive] = useState(false);
  const headerNavColor = useStoreState((state) => state.theme.headerNavColor);
  const currentTheme = useStoreState((state) => state.theme.currentTheme);
  const currentLocale = useStoreState((state) => state.theme.locale);
  const switchTheme = useStoreActions((actions) => actions.theme.switchTheme);
  const switchLocale = useStoreActions((actions) => actions.theme.changeLocale);
  const isHytera = useStoreState((state) => state.user.hytera);
  const isNvoyy = useStoreState((state) => state.user.type) === OrgType.nvoyy;
  const isDemo = useStoreState((state) => state.theme.demoMode);
  const switchDemo = useStoreActions((actions) => actions.theme.setDemoMode);
  const onSearchClose = () => {
    setSearchActive(false);
  };
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === 'dark' ? '#00000' : '#ffffff'
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => `${SIDE_NAV_WIDTH - 100}px`;

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });
  if (isOnboarding)
    return (
      <Header
        className={`app-header ${navMode}`}
        style={{ backgroundColor: headerNavColor, top: '0px' }}
      >
        <div className="app-header-wrapper">
          <Logo logoType={navMode} mobileLogo={undefined} />
          <div
            className="nav"
            style={{ width: `calc(100% - ${getNavWidth()})` }}
          >
            <div className="nav-left">
              <ul className="ant-menu ant-menu-root ant-menu-horizontal" />
            </div>
            <div className="nav-right d-flex align-items-center">
              {isDemo && (
                <Button
                  type="ghost"
                  size="small"
                  style={{ marginRight: 10 }}
                  onClick={() => history.push('/onboarding')}
                >
                  Onboarding Demo
                </Button>
              )}
              {(isHytera || isNvoyy) && (
                <Select
                  size="small"
                  value={isDemo ? 'demo' : 'hytera'}
                  bordered={false}
                  showArrow={false}
                  style={{ width: '100px' }}
                  onSelect={(value: string) => {
                    switchDemo(value === 'demo');
                    if (value === 'demo') {
                      history.push('/demo');
                    } else {
                      history.push('/');
                    }
                  }}
                >
                  <Select.Option value="demo">Demo</Select.Option>
                  <Select.Option value="hytera">
                    {isNvoyy ? 'Nvoyy' : 'Hytera'}
                  </Select.Option>
                </Select>
              )}
              <Select
                size="small"
                value={currentLocale}
                bordered={false}
                showArrow={false}
                style={{ width: '100px', marginRight: 15 }}
                onSelect={(value: string) => {
                  typedLocalStorage.set(LocalStorageKeys.lang, value);
                  switchLocale(value);
                }}
              >
                <Select.Option value="en-GB">English</Select.Option>
                <Select.Option value="fr-FR">Français </Select.Option>
                <Select.Option value="de-DE">Deutsch </Select.Option>
                <Select.Option value="el-GR">Ελληνική </Select.Option>
                <Select.Option value="cs-CZ">Česky</Select.Option>
                <Select.Option value="sk-SK">Slovak</Select.Option>
                <Select.Option value="hr-HR">Hrvatski</Select.Option>
              </Select>
              <NavProfile />
            </div>
          </div>
        </div>
      </Header>
    );
  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className="app-header-wrapper">
        <Logo logoType={navMode} mobileLogo={undefined} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal" />
          </div>
          <div className="nav-right d-flex align-items-center">
            {isDemo && (
              <Button
                key="submit"
                type="ghost"
                size="small"
                style={{ marginRight: 10 }}
                onClick={() => history.push('/onboarding')}
              >
                Onboarding Demo
              </Button>
            )}
            {(isHytera || isNvoyy) && (
              <Select
                size="small"
                value={isDemo ? 'demo' : 'hytera'}
                style={{ width: '80px', marginRight: 15 }}
                onSelect={(value: string) => {
                  switchDemo(value === 'demo');
                  if (value === 'demo') {
                    history.push('/demo');
                  } else {
                    history.push('/');
                  }
                }}
              >
                <Select.Option value="demo">Demo</Select.Option>
                <Select.Option value="hytera">
                  {isNvoyy ? 'Nvoyy' : 'Hytera'}
                </Select.Option>
              </Select>
            )}
            <Select
              size="small"
              value={currentLocale}
              bordered={false}
              showArrow={false}
              style={{ width: '100px' }}
              onSelect={(value: string) => {
                typedLocalStorage.set(LocalStorageKeys.lang, value);
                switchLocale(value);
              }}
            >
              <Select.Option value="en-GB">English</Select.Option>
              <Select.Option value="fr-FR">Français </Select.Option>
              <Select.Option value="de-DE">Deutsch </Select.Option>
              <Select.Option value="el-GR">Ελληνική </Select.Option>
              <Select.Option value="cs-CZ">Česky</Select.Option>
              <Select.Option value="sk-SK">Slovak</Select.Option>
              <Select.Option value="hr-HR">Hrvatski</Select.Option>
            </Select>
            <Switch
              checkedChildren={
                <FontAwesomeIcon color="#F5F3CE" icon={faMoon} />
              }
              unCheckedChildren={
                <FontAwesomeIcon color="GoldenRod" icon={faSun} />
              }
              checked={currentTheme === 'dark'}
              onChange={(value) => {
                switchTheme(value ? 'dark' : 'light');
                typedLocalStorage.set(
                  LocalStorageKeys.theme,
                  value ? 'dark' : 'light'
                );

                switcher({ theme: value ? themes.dark : themes.light });
              }}
            />
            <NavProfile />
          </div>
        </div>
      </div>
    </Header>
  );
};

HeaderNav.defaultProps = {
  isOnboarding: false,
};

export default HeaderNav;
