import { gql } from '@apollo/client';

export const AgreeTermsAuth0 = gql`
  mutation ($data: EditUserMetaInput) {
    editUserMeta(data: $data)
  }
`;

export interface AgreeTermsAuth0Args {
  data: {
    id: string;
    termsVersion: string;
    onboardingStep: string;
  };
}
