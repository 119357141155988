import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Loading from 'views/Auth/Authentication/Loading';

export const AuthRouter = (): JSX.Element => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      if (!isLoading) loginWithRedirect();
    }
  }, []);

  return <Loading />;
};
export default AuthRouter;
