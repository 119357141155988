import { gql } from '@apollo/client';
import { OnboardingStep } from '../enums';

export const SignTerms = gql`
  mutation updateOneDealer(
    $where: DealerWhereUniqueInput!
    $data: DealerUpdateInput!
  ) {
    updateOneDealer(where: $where, data: $data) {
      id
      onboarding
      termAgreement {
        id
        expired
      }
    }
  }
`;

export interface SignTermsArgs {
  where: { id: string };
  data: {
    onboarding: {
      set: string;
    };
    termAgreement: {
      create: {
        signature: string;
        name: string;
        jobTitle: string;
        terms: {
          connect: {
            id: string;
          };
        };
      };
    };
  };
}

export interface SignTermsRes {
  signTerms: {
    id: string;
    onboarding: OnboardingStep;
    termAgreement: {
      id: string;
      expired: boolean;
    };
  };
}
