import React from 'react';
import { Typography, Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';

const { Title, Paragraph, Text } = Typography;

interface Props {
  completeOnboarding(): void;
}

const GettingStarted = ({ completeOnboarding }: Props): JSX.Element => (
  <div
    style={{
      maxWidth: '100%',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 30,
    }}
  >
    <Title level={3}>Get Started with HyTalk</Title>
    <Paragraph>
      Thank you for completing you account details, your registration is now
      complete. To get started you can continue into the Hytalk Portal where you
      will find documentation and downloads to help you with getting started or
      you can login to SOP and start configuring your customers.
    </Paragraph>
    <Paragraph>
      Your default login details for the SOP portal are displayed below, use
      these to login to the SOP portal for the first time and then please change
      them for security reasons. Please note that you have only one SOP account,
      so you will need to share these details with your colleagues. You can find
      these details in the settings section of the Hytalk Portal later.
    </Paragraph>
    <Title level={4}>Default SOP Login Details</Title>
    <Row gutter={8}>
      <Col>
        <Text strong>Username:</Text>
      </Col>
      <Col>
        <Text>testdealer@nvoyy.co.uk</Text>
      </Col>
    </Row>
    <Row gutter={8}>
      <Col>
        <Text strong>Password:</Text>
      </Col>
      <Col>
        <Text>wptA9CRsEt</Text>
      </Col>
    </Row>
    <Paragraph style={{ marginTop: 20 }}>
      You can find documentation and guides explaining how to use SOP in the
      resources section of the Hytalk Portal. We would recommend the Getting
      Started to get you up and running, this guide walks you through your first
      login, changing you password and setting up your first customer.
    </Paragraph>
    <Row justify="end" gutter={16} style={{ marginTop: 100 }}>
      <Col>
        <a
          href="https://sop.hytalk.co.uk/hytalk/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="primary" onClick={completeOnboarding}>
            Go to SOP
          </Button>
        </a>
      </Col>
      <Col>
        <Link to="/app">
          <Button type="primary" onClick={completeOnboarding}>
            Go to Hytalk Portal
          </Button>
        </Link>
      </Col>
    </Row>
  </div>
);
export default GettingStarted;
