import { gql } from '@apollo/client';

export const UpdateBusinessDetails = gql`
  mutation Mutation(
    $data: DealerUpdateInput!
    $where: DealerWhereUniqueInput!
  ) {
    updateOneDealer(data: $data, where: $where) {
      addresses {
        city
        country
        county
        postcode
        street1
        street2
        type
      }
      contact {
        email
        name
        phone
      }
      onboarding
    }
  }
`;

export interface UpdateBusinessDetailsArgs {
  where: { id: string };
  data: {
    onboarding: {
      set: string;
    };
    addresses?: {
      create: {
        street1: string;
        street2: string;
        city: string;
        county: string;
        country: string;
        postcode: string;
        type: string;
      }[];
    };

    contact?: {
      create: {
        name: string;
        email: string;
        phone: string;
      };
    };
    name?: { set: string };
  };
}

export interface UpdateBusinessDetailsRes {
  updateBusinessDetails: {
    id: string;
    addresses: {
      street1: { set: string };
      street2: { set: string };
      city: { set: string };
      county: { set: string };
      country: { set: string };
      postcode: { set: string };
      type: string;
    }[];
    contact: {
      name: string;
      email: string;
      phone: string;
    };
    name: string;
    onboarding: string;
  };
}
