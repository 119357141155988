import { gql } from '@apollo/client';
import Role from '../enums';

export const UpdateOneUser = gql`
  mutation updateOneUser(
    $where: UserWhereUniqueInput!
    $data: UserUpdateInput!
  ) {
    updateOneUser(where: $where, data: $data) {
      id
      name
      email
      role
    }
  }
`;

export interface UpdateOneUserArgs {
  where: {
    id: string;
  };
  data: {
    email: { set: string };
    name: { set: string };
    role: { set: Role };
  };
}

export interface UpdateOneUserRes {
  updateOneUser: {
    id: string;
    name: string;
    email: string;
    role: Role;
  };
}
