import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import View from './PaymentMethod.view';
import usePaymentMethod from './usePaymentMethod';

type Props = Omit<RouteComponentProps<{ status: string }>, 'location'>;

const PaymentMethod = ({ history, match }: Props): JSX.Element => {
  const { loading, setupCard, setupDirectDebit, demoModeSetup, demoMode } =
    usePaymentMethod({
      history,
      status: match.params.status,
    });

  return (
    <View
      loading={loading}
      setupCard={setupCard}
      demoModeSetup={demoModeSetup}
      setupDirectDebit={setupDirectDebit}
      demoMode={demoMode}
    />
  );
};

export default PaymentMethod;
