export interface Theme {
  primary: string;
  primaryLight: string;
  componentBackground: string;
  itemHoverBackground: string;
  itemSelectedBackground: string;
  bodyBackground: string;
  borderColor: string;
  imageBackgroundColor: string;
  gradientBackground: string;
  logo: string;
  colorScheme: string;
  hoverBackground: string;
  headerColor: string;
}

const theme: {
  dark: Theme;
  light: Theme;
} = {
  dark: {
    primary: '#21B573',
    primaryLight: '#67B26F',
    componentBackground: '#283142',
    itemHoverBackground: 'rgb(60, 67, 84)',
    itemSelectedBackground: 'rgb(60, 67, 84)',
    bodyBackground: '#1b2531',
    borderColor: '#4d5b75',
    imageBackgroundColor: 'rgb(59, 73, 98)',
    gradientBackground: 'linear-gradient(to right, #45a247, #21409a)',
    logo: 'url(/img/logo-white.svg)',
    colorScheme: 'dark',
    hoverBackground: 'rgb(92, 102, 123)',
    headerColor: '#FFF',
  },
  light: {
    primary: '#21B573',
    primaryLight: '#67B26F',
    componentBackground: '#FFF',
    itemHoverBackground: '#fafafb',
    itemSelectedBackground: '#fafafb',
    bodyBackground: '#fafafb',
    borderColor: 'rgb(237, 242, 249)',
    imageBackgroundColor: '#ECEFF1',
    gradientBackground: 'linear-gradient(to right, #67B26F, #4ca2cd)',
    logo: 'url(/img/logo.svg)',
    colorScheme: 'light',
    hoverBackground: 'rgba(0, 0, 0, 0.018)',
    headerColor: '#1a3353',
  },
};

export default theme;
