import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import View from './DistributorOnboarding.view';
import { DistributorOnboardingProvider } from '../Context';
import useDistributorOnboarding from './useDistributorOnboarding';

type Props = Omit<RouteComponentProps, 'match'>;

const Container = ({ location, history }: Props): JSX.Element => {
  const { currentStep, orgOnboarding, userOnboarded } =
    useDistributorOnboarding({
      location,
      history,
    });
  return (
    <View
      currentStep={currentStep}
      orgOnboarding={orgOnboarding}
      userOnboarded={userOnboarded}
    />
  );
};

const DistributorOnboarding = ({ location, history }: Props): JSX.Element => (
  <DistributorOnboardingProvider>
    <Container location={location} history={history} />
  </DistributorOnboardingProvider>
);

export default DistributorOnboarding;
