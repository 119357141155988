import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import View from './DealerOnboarding.view';
import { DealerOnboardingProvider } from '../Context';
import useDealerOnboarding from './useDealerOnboarding';

type Props = Omit<RouteComponentProps, 'match'>;

const Container = ({ location, history }: Props): JSX.Element => {
  const { currentStep, orgOnboarding, userOnboarded } = useDealerOnboarding({
    location,
    history,
  });
  return (
    <View
      currentStep={currentStep}
      orgOnboarding={orgOnboarding}
      userOnboarded={userOnboarded}
    />
  );
};

const DealerOnboarding = ({ location, history }: Props): JSX.Element => (
  <DealerOnboardingProvider>
    <Container location={location} history={history} />
  </DealerOnboardingProvider>
);

export default DealerOnboarding;
