import { gql } from '@apollo/client';

export const OnboardingComplete = gql`
  mutation onboardingComplete($where: OrganisationWhereUniqueInput!) {
    onboardingComplete(where: $where) {
      id
      termAgreement {
        id
        expired
      }
    }
  }
`;

export interface OnboardingCompleteArgs {
  where: { id: string };
}

export interface OnboardingCompleteRes {
  onboardingComplete: {
    id: string;
    onboarded: boolean;
  };
}
