import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import * as Sentry from '@sentry/react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import LogRocket from 'logrocket';
import { ApolloProviderWithAuth0 } from 'utils/Apollo';

import { Store, ThemeConfig } from './state';

import RootRouter from './navigation/Router';

LogRocket.init('ub3rsv/hytalk-portal');

LogRocket.getSessionURL((sessionURL) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL);
  });
});

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const stripePromise = loadStripe(
  // @ts-expect-error expect env variable
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
);

function App(): JSX.Element {
  return (
    <div className="App">
      <Store>
        <ApolloProviderWithAuth0>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={ThemeConfig.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Elements stripe={stripePromise}>
              <Router>
                <Switch>
                  <Route path="/" component={RootRouter} />
                </Switch>
              </Router>
            </Elements>
          </ThemeSwitcherProvider>
        </ApolloProviderWithAuth0>
      </Store>
    </div>
  );
}

export default App;
