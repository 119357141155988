/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useStoreState, useStoreActions } from 'state';
import { useDealerVatOnboardingMutation } from 'graphql/generated/graphql';
import { OnboardingStep } from 'graphql/organisations/enums';
import { notification } from 'antd';
import { useDealerOnboarding, Details } from '../../Context';
import { FormData } from './VatId.view';

type Props = Omit<RouteComponentProps, 'location' | 'match'>;
interface Return {
  details: Details | undefined;
  handleSubmit: (data: FormData) => Promise<void>;
  loading: boolean;
  saving: boolean;
  demo: boolean;
}

const useVatDetails = ({ history }: Props): Return => {
  const demo = useStoreState((state) => state.theme.demoMode);
  const id = demo ? '' : useStoreState((state) => state.user.organisation?.id);
  const onboardingStep = demo
    ? OnboardingStep.vat
    : useStoreState((state) => state.user.organisation?.onboarding);
  const setOrgOnboarding = useStoreActions(
    (actions) => actions.user.setOrgOnboarding
  );
  const { state } = useDealerOnboarding();

  useEffect(() => {
    if (onboardingStep === OnboardingStep.terms) {
      history.push('/onboarding/dealer/terms-of-business');
    } else if (onboardingStep === OnboardingStep.payment) {
      history.push('/onboarding/dealer/payment-method/processing');
    } else if (onboardingStep === OnboardingStep.complete) {
      history.push('/app');
    }
    // eslint-disable-next-line
  }, [onboardingStep]);

  const [updateDealer, { loading }] = useDealerVatOnboardingMutation({
    onCompleted: () => {
      setOrgOnboarding({
        onboarding: OnboardingStep.payment,
      });
      history.push('/onboarding/dealer/payment-method/processing');
    },
    onError: (error: { message: string }) => {
      notification.error({
        message: 'There was an error',
        description: `${error.message}`,
        placement: 'bottomRight',
      });
    },
  });

  const handleSubmit = async (data: FormData) => {
    if (demo) {
      setOrgOnboarding({
        onboarding: OnboardingStep.payment,
      });
      history.push('/onboarding/dealer/payment-method/processing');
    } else {
      updateDealer({
        variables: {
          data: {
            vatId: data.vatId,
          },
          where: { id: id || '' },
        },
      });
    }
  };
  return {
    details: state.details,
    handleSubmit,
    loading: false,
    saving: loading,
    demo,
  };
};
export default useVatDetails;
