import React from 'react';
import { Menu, Dropdown, Avatar, Row } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import Icon from 'components/Utils/Icon';
import { useStoreState } from 'state';
import useAuth from 'hooks/useAuth';
import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage } from 'react-intl';

interface MenuItem {
  title: string;
  icon: string;
  path: string;
}

const menuItem: MenuItem[] = [
  // {
  //   title: "Edit Profile",
  //   icon: EditOutlined ,
  //   path: "/"
  //   },
  //   {
  //   title: "Account Setting",
  //   icon: SettingOutlined,
  //   path: "/"
  //   },
  //   {
  //   title: "Billing",
  //   icon: ShopOutlined ,
  //   path: "/"
  // },
  //   {
  //   title: "Help Center",
  //   icon: QuestionCircleOutlined,
  //   path: "/"
  // }
];

const NavProfile = (): JSX.Element => {
  const name = useStoreState((state) => state.user.name);
  const email = useStoreState((state) => state.user.email);
  const profileImg = useStoreState((state) => state.user.image);
  const { signOut } = useAuth();
  const { logout } = useAuth0();

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex" style={{ alignItems: 'center' }}>
          <Avatar size={35} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">{name}</h4>
            <span className="text-muted">{email}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el) => (
            <Menu.Item key={el.title}>
              <a href={el.path}>
                <Row>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </Row>
              </a>
            </Menu.Item>
          ))}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={() => {
              signOut();
              logout({ returnTo: window.location.origin });
            }}
          >
            <Row>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">
                <FormattedMessage id="signOut" defaultMessage="Sign Out" />
              </span>
            </Row>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={['click']}>
      <Menu
        className="d-flex align-item-center"
        mode="horizontal"
        style={{ height: 70 }}
      >
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavProfile;
