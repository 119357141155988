import React, { useEffect, useRef } from 'react';
import { Row, Col, Typography, Button, Form, Input, Modal } from 'antd';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import SignatureInput from 'components/Shared/SignatureInput';
import { Term } from '../../Context';

const { Title } = Typography;

const Content = styled.div`
  height: calc(100% - 80px);
`;
const Terms = styled.div`
  overflow: auto;
`;

export interface FormData {
  signature: string;
  name: string;
  jobTitle: string;
}

interface Props {
  businessTerms: Term | undefined;
  onSubmit(data: FormData): void;
  language: string;
  closeModal: () => void;
  openModal: () => void;
  modalState: boolean;
  demo: boolean;
}

const TermsOfBusiness = ({
  businessTerms,
  onSubmit,
  language,
  closeModal,
  openModal,
  modalState,
  demo,
}: Props): JSX.Element => {
  const intl = useIntl();

  const setTermsContent = () => {
    if (businessTerms) {
      if (language === 'en-GB') {
        return businessTerms?.content;
      }
      if (language === 'el-GR') {
        return businessTerms.grContent || businessTerms?.content;
      }
      if (language === 'fr-FR') {
        return businessTerms?.frContent || businessTerms?.content;
      }
      if (language === 'de-DE') {
        return businessTerms?.deContent || businessTerms?.content;
      }
      if (language === 'sk-SK') {
        return businessTerms?.skContent || businessTerms?.content;
      }
      if (language === 'cs-CZ') {
        return businessTerms?.czContent || businessTerms?.content;
      }
      if (language === 'hr-HR') {
        return businessTerms?.hrContent || businessTerms?.content;
      }
      return businessTerms?.content;
    }
    return '';
  };

  const titleRef = useRef(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore ???
    titleRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  return (
    <Content ref={titleRef}>
      <Modal
        width={1000}
        title={intl.formatMessage({
          id: 'termsOfBusiness',
          defaultMessage: 'Terms of Business',
        })}
        onOk={closeModal}
        onCancel={closeModal}
        visible={modalState}
        footer={[
          <Button key="submit" type="primary" onClick={closeModal}>
            Ok
          </Button>,
        ]}
      >
        <Title level={3}>
          <FormattedMessage
            id="termsOfBusiness"
            defaultMessage="Terms of Business"
          />
        </Title>
        <ReactMarkdown>{setTermsContent()}</ReactMarkdown>
      </Modal>
      {language !== 'en-GB' && (
        <div>
          <Typography
            style={{
              color: 'red',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: '20px',
            }}
          >
            <FormattedMessage
              id="termsAgreementOther"
              defaultMessage="You are agreeing to these terms as they are presented in English. A localised version has been provided for reference only"
            />
            <Button
              type="primary"
              style={{ marginRight: '10px', marginLeft: '10px' }}
              onClick={openModal}
              htmlType="button"
              size="small"
            >
              <FormattedMessage
                id="viewIn"
                defaultMessage="View localised terms"
              />
            </Button>
          </Typography>
        </div>
      )}
      <Title level={3}>
        <FormattedMessage
          id="termsOfBusiness"
          defaultMessage="Terms of Business"
        />
      </Title>
      <Terms>
        <ReactMarkdown>{businessTerms?.content || ''}</ReactMarkdown>
        <Form<FormData> layout="vertical" onFinish={onSubmit}>
          <Row className="mt-5">
            <Col span="6" />
            <Col span="10">
              <Form.Item
                label={intl.formatMessage({
                  defaultMessage: 'Signature',
                  id: 'signature',
                })}
                name="signature"
                rules={[
                  {
                    required: !demo,
                    message: intl.formatMessage({
                      defaultMessage: 'Please sign the terms of business',
                      id: 'signBusinessTerms',
                    }),
                  },
                ]}
              >
                <SignatureInput onChange={undefined} />
              </Form.Item>
            </Col>
            <Col span="8">
              <Form.Item
                label="Full Name"
                name="name"
                rules={[
                  {
                    required: !demo,
                    message: intl.formatMessage({
                      id: 'pleaseComplete',
                      defaultMessage: 'Please complete this field.',
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Job Title"
                name="jobTitle"
                rules={[
                  {
                    required: !demo,
                    message: intl.formatMessage({
                      id: 'pleaseComplete',
                      defaultMessage: 'Please complete this field.',
                    }),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Row justify="end">
              <Button type="primary" htmlType="submit">
                <FormattedMessage
                  id="termsAgreement"
                  defaultMessage="I agree to the terms"
                />
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Terms>
    </Content>
  );
};

export default TermsOfBusiness;
