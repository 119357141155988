/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useStoreState, useStoreActions } from 'state';
import { useDealerDetailsOnboardingMutation } from 'graphql/generated/graphql';
import { OnboardingStep } from 'graphql/organisations/enums';
import { useDealerOnboarding, Details } from '../../Context';
import { FormData } from './BusinessDetails.view';

type Props = Omit<RouteComponentProps, 'location' | 'match'>;
interface Return {
  details: Details | undefined;
  handleSubmit: (data: FormData) => Promise<void>;
  loading: boolean;
  saving: boolean;
  demo: boolean;
}

const useBusinessDetails = ({ history }: Props): Return => {
  const demo = useStoreState((state) => state.theme.demoMode);
  const id = demo ? '' : useStoreState((state) => state.user.organisation?.id);
  const onboardingStep = demo
    ? OnboardingStep.details
    : useStoreState((state) => state.user.organisation?.onboarding);
  const setOrgOnboarding = useStoreActions(
    (actions) => actions.user.setOrgOnboarding
  );
  const { state } = useDealerOnboarding();

  useEffect(() => {
    if (onboardingStep === OnboardingStep.terms) {
      history.push('/onboarding/dealer/terms-of-business');
    } else if (onboardingStep === OnboardingStep.payment) {
      history.push('/onboarding/dealer/payment-method/processing');
    } else if (onboardingStep === OnboardingStep.complete) {
      history.push('/app');
    } else if (onboardingStep === OnboardingStep.vat) {
      history.push('/onboarding/dealer/vat-id');
    }
    // eslint-disable-next-line
  }, [onboardingStep]);

  const [updateDealer, { loading }] = useDealerDetailsOnboardingMutation();

  const handleSubmit = async (data: FormData) => {
    if (demo) {
      setOrgOnboarding({
        onboarding: OnboardingStep.terms,
      });
      history.push('/onboarding/dealer/terms-of-business');
    } else {
      updateDealer({
        variables: {
          data: {
            name: data.name,
            contact: {
              create: {
                name: data.accountsContact,
                email: data.accountsEmail,
                phone: data.accountsPhone,
              },
            },
            currency: data.currency,
            billingAddress: {
              line1: data.line1,
              line2: data.line2,
              city: data.city,
              county: data.county,
              country: data.country,
              postCode: data.postCode,
            },
          },
          where: { id: id || '' },
        },
      });
      setOrgOnboarding({
        onboarding: OnboardingStep.terms,
      });
      history.push('/onboarding/dealer/terms-of-business');
    }
  };
  return {
    details: state.details,
    handleSubmit,
    loading: false,
    saving: loading,
    demo,
  };
};
export default useBusinessDetails;
