import antdSkSK from 'antd/es/locale/sk_SK';
import skMsg from '../locales/sk_SK.json';

const SkLang = {
  antd: antdSkSK,
  locale: 'sk-SK',
  messages: {
    ...skMsg,
  },
};
export default SkLang;
