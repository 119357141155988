import React from 'react';
import { Switch, Route } from 'react-router-dom';

interface AuthLayoutProps {
  children: JSX.Element;
}

export const AuthLayout = ({ children }: AuthLayoutProps): JSX.Element => (
  <div className="auth-container">
    <Switch>
      <Route path="">{children}</Route>
    </Switch>
  </div>
);

export default AuthLayout;
