import { gql } from '@apollo/client';
import TermsType from '../enums';

export const Term = gql`
  query Term($id: String!) {
    term(where: { id: $id }) {
      id
      name
      description
      defaultTerms
      content
      grContent
      deContent
      frContent
      skContent
      czContent
      hrContent
      version
      type
    }
  }
`;

export interface TermArgs {
  id: string;
}

export interface TermRes {
  term: {
    id: string;
    name: string;
    description: string;
    defaultTerms: boolean;
    content: string;
    grContent: string;
    deContent: string;
    frContent: string;
    skContent: string;
    hrContent: string;
    czContent: string;
    version: number;
    type: TermsType;
  };
}
