import { gql } from '@apollo/client';

export const OrgUser = gql`
  query auth0User($data: UniqueId!) {
    auth0User(data: $data) {
      id
      name
      email
      role
      userId
    }
  }
`;

export interface OrgUserArgs {
  data: {
    id?: string;
  };
}

export interface OrgUserRes {
  auth0User: {
    id: string;
    name: string;
    email: string;
    role: string;
    userId: string;
  };
}
