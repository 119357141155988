import {
  useLazyQuery,
  QueryLazyOptions,
  OperationVariables,
} from '@apollo/client';
import { useStoreActions, SetUserPayload, useStoreState } from 'state';
import jwtDecode from 'jwt-decode';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import Role from 'graphql/users/enums';
import { CurrentUser, CurrentUserRes, Org } from 'graphql/auth/queries';
import { OrgType } from 'state/user-model';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { OnboardingStep } from 'graphql/organisations/enums';
import { LocalStorageKeys, typedLocalStorage } from '../utils';

interface DecodedToken {
  aud: string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  permissions: string[];
  'http://hytalk.com/appMeta': {
    onboardingStep: string;
    orgId: string;
    termsId: string;
    nvoyy: boolean;
  };
}
interface Return {
  rehydrateAuth: () => void;
  signOut: () => void;
  getCurrentUser: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
  loading: boolean;
}

const useAuth = (): Return => {
  const lang = navigator.language;
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0<{
    name: string;
    email: string;
    picture: string;
    iss: string;
    exp: number;
    'http://hytalk.com/appMeta': {
      onboardingStep: string;
      orgId: string;
      termsId: string;
    };
    permissions: string[];
  }>();
  const authenticated = useStoreActions(
    (actions) => actions.auth.authenticated
  );
  const expired = useStoreActions((actions) => actions.auth.expired);
  const handleSignOut = useStoreActions((actions) => actions.auth.signOut);
  const setUser = useStoreActions((actions) => actions.user.setUser);
  const clearUser = useStoreActions((actions) => actions.user.clearUser);
  const switchLocale = useStoreActions((actions) => actions.theme.changeLocale);
  const userOnboarding = useStoreState((state) => state.user.onboarding);
  interface HandleSuccessArgs extends SetUserPayload {
    access_token: string;
    type: OrgType;
  }

  const handleSuccess = ({
    id,
    access_token,
    name,
    email,
    image,
    organisation,
    onboarding,
    role,
    termsAgreement,
    type,
    firstLogin,
    hytera,
  }: HandleSuccessArgs) => {
    typedLocalStorage.set(LocalStorageKeys.access_token, access_token);
    LogRocket.identify(id, {
      name,
      email,
      userType: type,
    });
    Sentry.setUser({ id, email, name });

    setUser({
      id,
      email,
      name,
      image,
      role,
      organisation,
      onboarding,
      termsAgreement,
      type,
      hytera,
      firstLogin,
    });
    authenticated(access_token);
  };

  const getRoleAndOrgType = (data: string[]) => {
    const role = data.includes('admin');
    const dealer = data.find((element) => element === 'dealer');
    const customer = data.find((element) => element === 'customer');
    const distributor = data.find((element) => element === 'distributor');
    const nvoyy = data.find((element) => element === 'nvoyy');
    const hytera = data.find((element) => element === 'hytera');
    if (dealer) {
      return {
        role: role ? Role.admin : Role.user,
        org: OrgType.dealer,
        hytera: false,
      };
    }
    if (customer) {
      return {
        role: role ? Role.admin : Role.user,
        org: OrgType.customer,
        hytera: false,
      };
    }
    if (distributor) {
      return {
        role: role ? Role.admin : Role.user,
        org: OrgType.distributor,
        hytera: false,
      };
    }
    if (nvoyy) {
      return {
        role: role ? Role.admin : Role.user,
        org: OrgType.nvoyy,
        hytera: false,
      };
    }
    if (hytera) {
      return {
        role: role ? Role.admin : Role.user,
        org: OrgType.hytera,
        hytera: true,
      };
    }
    return {
      role: role ? Role.admin : Role.user,
      org: OrgType.customer,
      hytera: false,
    };
  };

  const getOrgType = <
    T extends { dealer: Org; customer: Org; distributor: Org }
  >(
    target: T
  ) => {
    if (target.dealer) return OrgType.dealer;
    if (target.customer) return OrgType.customer;
    if (target.distributor) return OrgType.distributor;
    return OrgType.nvoyy;
  };

  const getOrganisation = <
    T extends { dealer: Org; customer: Org; distributor: Org }
  >(
    target: T
  ) => {
    if (getOrgType(target) === OrgType.dealer) return target.dealer;
    if (getOrgType(target) === OrgType.customer) return target.customer;
    if (getOrgType(target) === OrgType.distributor) return target.distributor;
    return undefined;
  };

  // @ts-expect-error user is undefined, can't be if they are authenticated
  const metadata = isAuthenticated && user['http://hytalk.com/appMeta'];

  const getOnboardingStep = () => {
    if (userOnboarding === OnboardingStep.complete) {
      return OnboardingStep.complete;
    }
    // @ts-expect-error user is undefined, can't be if they are authenticated
    const getStep = metadata.onboardingStep;
    if (getStep === 'complete') {
      return OnboardingStep.complete;
    }
    if (getStep === 'terms') {
      return OnboardingStep.terms;
    }
    return OnboardingStep.terms;
  };
  const firstLogin = useStoreState((state) => state.user.firstLogin);

  const [getCurrentUser, { loading }] = useLazyQuery<CurrentUserRes>(
    CurrentUser,
    {
      onCompleted: ({ currentUser }) => {
        const accessToken: DecodedToken = jwtDecode(
          typedLocalStorage.get(LocalStorageKeys.access_token) || ''
        );

        const org = getOrganisation<typeof currentUser>(currentUser);
        if (typedLocalStorage.get(LocalStorageKeys.lang)) {
          // @ts-expect-error won't be null
          switchLocale(typedLocalStorage.get(LocalStorageKeys.lang));
        } else if (org?.locale && org?.locale.preferred_locales) {
          const orgLang = org?.locale.preferred_locales;
          const availbleLanguages = [
            'en-GB',
            'fr',
            'de',
            'el',
            'en',
            'cs',
            'sk',
            'hr',
          ];
          if (availbleLanguages.includes(lang)) {
            switch (lang) {
              case 'fr': {
                switchLocale('fr-FR');
                break;
              }
              case 'de': {
                switchLocale('de-DE');
                break;
              }
              case 'el': {
                switchLocale('el-GR');
                break;
              }
              case 'en': {
                switchLocale('en-GB');
                break;
              }
              case 'cs': {
                switchLocale('cs-CZ');
                break;
              }
              case 'sk': {
                switchLocale('sk-SK');
                break;
              }
              case 'hr': {
                switchLocale('hr-HR');
                break;
              }
              case 'en-US': {
                switchLocale('en-GB');
                break;
              }
              case 'en-GB': {
                switchLocale('en-GB');
                break;
              }
              default: {
                switchLocale(orgLang);

                break;
              }
            }
          } else {
            switchLocale(orgLang);
          }
        }
        const authRoleData = getRoleAndOrgType(accessToken.permissions);
        if (user) {
          handleSuccess({
            id: currentUser?.id,
            email: user.email,
            name: user.name,
            access_token:
              typedLocalStorage.get(LocalStorageKeys.access_token) || '',
            image: user.picture,
            role: authRoleData.role,
            onboarding: getOnboardingStep(),
            termsAgreement: currentUser?.termsAgreement,
            organisation: org,
            type: authRoleData.org,
            hytera: authRoleData.hytera,
            firstLogin,
          });
        }
      },
      onError: () => expired(),
    }
  );

  const rehydrateAuth = () => {
    if (user !== undefined) {
      if (new Date().getTime() < user.exp * 1000) {
        if (user.iss === 'https://hytalk.eu.auth0.com/') {
          getCurrentUser();
        } else if (isAuthenticated) {
          getCurrentUser();
        } else {
          expired();
        }
      } else {
        expired();
      }
    } else if (isAuthenticated) {
      getCurrentUser();
    } else {
      expired();
    }
  };

  const signOut = () => {
    clearUser();
    handleSignOut();
    typedLocalStorage.remove(LocalStorageKeys.access_token);
  };

  useEffect(() => {
    // authenticated from auth0
    if (isAuthenticated) {
      (async () => {
        try {
          // set token and get user details from api
          const token = await getAccessTokenSilently();
          authenticated(token);
          typedLocalStorage.set(LocalStorageKeys.access_token, token);
          getCurrentUser();
          typedLocalStorage.set(
            LocalStorageKeys.HYTALK_SYNC_TIMESTAMP,
            new Date(Date.now()).toISOString()
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      })();
    }
  }, [isAuthenticated]);

  return {
    rehydrateAuth,
    signOut,
    getCurrentUser,
    loading,
  };
};

export default useAuth;
