import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import Loading from 'components/Shared/Loading';
import { APP_PREFIX_PATH, DEMO_PREFIX_PATH } from 'configs/AppConfig';
import useAuth from 'hooks/useAuth';
import { useStoreState } from 'state';

export const DemoRouter = (): JSX.Element => {
  const { loading } = useAuth();
  const demo = useStoreState((state) => state.theme.demoMode);
  const history = useHistory();
  if (loading) return <Loading cover="content" />;
  if (!demo) {
    history.push(APP_PREFIX_PATH);
  }

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Redirect
          exact
          from={`${DEMO_PREFIX_PATH}`}
          to={`${DEMO_PREFIX_PATH}/dashboard`}
        />

        <Route
          path={DEMO_PREFIX_PATH}
          component={lazy(() => import('./Dealer/Router'))}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(DemoRouter);
