import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Row, Col, Typography, Button, Checkbox, Modal } from 'antd';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { useMutation } from '@apollo/client';
import Loading from 'components/Shared/Loading';
import {
  AgreeTerms,
  AgreeTermsArgs,
  AgreeTermsAuth0,
  AgreeTermsAuth0Args,
  AgreeTermsRes,
} from 'graphql/users/mutations';
import { useStoreActions, useStoreState } from 'state';
import { OnboardingStep } from 'graphql/organisations/enums';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDealerOnboarding } from '../Context';

const { Title } = Typography;

const Content = styled.div`
  height: calc(100% - 80px);
`;
const Terms = styled.div`
  overflow: auto;
`;

type Props = Omit<RouteComponentProps, 'location' | 'match'>;

const TermsConditions = ({ history }: Props): JSX.Element => {
  const demo = useStoreState((state) => state.theme.demoMode);
  const [modalState, setModalState] = useState<boolean>(false);
  const closeModal = () => {
    setModalState(!modalState);
  };
  const openModal = () => {
    setModalState(!modalState);
  };
  const intl = useIntl();
  const language = useStoreState((state) => state.theme.locale);
  const [agree, setAgree] = useState(false);
  const { state } = useDealerOnboarding();
  const id = demo ? ' ' : useStoreState((storeState) => storeState.user.id);
  const onboarded = demo
    ? OnboardingStep.details
    : useStoreState((storeState) => storeState.user.onboarding);
  const orgOnboarded = useStoreState(
    (storeState) => storeState.user.organisation?.onboarding
  );
  const setUserOnboarding = useStoreActions(
    (actions) => actions.user.setUserOnboarding
  );
  const [agreeTerms] = useMutation<AgreeTermsRes, AgreeTermsArgs>(AgreeTerms, {
    onCompleted: () => {
      if (orgOnboarded === OnboardingStep.complete) {
        setUserOnboarding({
          onboarding: OnboardingStep.complete,
        });
        history.push('/app');
      } else {
        history.push('business-details');
      }
    },
  });
  const [updateAuth0] = useMutation<AgreeTermsAuth0Args>(AgreeTermsAuth0);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  useEffect(() => {
    if (onboarded === OnboardingStep.complete)
      history.push('/onboarding/dealer/business-details');
    // eslint-disable-next-line
  }, [onboarded]);

  const setTermsContent = () => {
    if (state.userTerms) {
      if (language === 'en-GB') {
        return state.userTerms.content;
      }
      if (language === 'el-GR') {
        return state.userTerms.grContent || state.userTerms.content;
      }
      if (language === 'fr-FR') {
        return state.userTerms.frContent || state.userTerms.content;
      }
      if (language === 'de-DE') {
        return state.userTerms.deContent || state.userTerms.content;
      }
      if (language === 'cs-CZ') {
        return state.userTerms.czContent || state.userTerms.content;
      }
      if (language === 'sk_SK') {
        return state.userTerms.skContent || state.userTerms.content;
      }
      if (language === 'hr_HR') {
        return state.userTerms.hrContent || state.userTerms.content;
      }
      return state.userTerms.content;
    }
    return '';
  };

  const handleSubmit = () => {
    if (!demo) {
      if (state.userTerms) {
        updateAuth0({
          variables: {
            data: {
              id,
              termsVersion: state.userTerms.name,
              onboardingStep: 'complete',
            },
          },
        });
        agreeTerms({
          variables: {
            id,
            termsAgreement: {
              create: {
                terms: {
                  connect: {
                    id: state.userTerms.id,
                  },
                },
              },
            },
          },
          optimisticResponse: {
            user: {
              id,
              termsAgreement: {
                expired: false,
                id: '0',
              },
            },
          },
        });
      }
    } else {
      scrollToTop();
      history.push('business-details');
    }
  };
  if (!state.userTerms) return <Loading />;
  return (
    <Content>
      <Modal
        width={1000}
        title={intl.formatMessage({
          id: 'termsOfBusiness',
          defaultMessage: 'Terms of Business',
        })}
        onOk={closeModal}
        onCancel={closeModal}
        visible={modalState}
        footer={[
          <Button key="submit" type="primary" onClick={closeModal}>
            Ok
          </Button>,
        ]}
      >
        <Title level={3}>
          <FormattedMessage
            id="termsOfBusiness"
            defaultMessage="Terms of Business"
          />
        </Title>
        <ReactMarkdown>{setTermsContent()}</ReactMarkdown>
      </Modal>
      {language !== 'en-GB' && (
        <div>
          <Typography
            style={{
              color: 'red',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingBottom: '20px',
            }}
          >
            <FormattedMessage
              id="termsAgreementOther"
              defaultMessage="You are agreeing to these terms as they are presented in English. A localised version has been provided for reference only"
            />
            <Button
              type="primary"
              style={{ marginRight: '10px', marginLeft: '10px' }}
              onClick={openModal}
              htmlType="button"
              size="small"
            >
              <FormattedMessage
                id="viewIn"
                defaultMessage="View localised terms"
              />
            </Button>
          </Typography>
        </div>
      )}
      <Title level={3}>Hytalk User Terms &amp; Conditions</Title>
      <Terms>
        <ReactMarkdown>{state.userTerms.content || ''}</ReactMarkdown>
        <Row justify="end" className="mt-4">
          <Col>
            <Checkbox
              disabled={!state.userTerms}
              value={agree}
              onChange={() => setAgree(!agree)}
            >
              <FormattedMessage
                id="termsAgreement"
                defaultMessage="I agree to the terms"
              />{' '}
            </Checkbox>
          </Col>
        </Row>
        <Row justify="end" className="mt-2 pb-5">
          <Col>
            <Button disabled={!agree} type="primary" onClick={handleSubmit}>
              <FormattedMessage id="next" defaultMessage="Next" />
            </Button>
          </Col>
        </Row>
      </Terms>
    </Content>
  );
};
export default TermsConditions;
