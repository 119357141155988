import { gql } from '@apollo/client';
import { OrganisationType } from '../enums';

export const UpdateOrganisation = gql`
  mutation updateOneOrganisation(
    $id: String!
    $name: StringFieldUpdateOperationsInput
    $type: EnumOrganisationTypeFieldUpdateOperationsInput
    $parent: OrganisationUpdateOneWithoutChildrenInput
    $priceList: PriceListUpdateOneWithoutOrganisationsInput
    $currency: CurrencyUpdateOneWithoutOrganisationInput
  ) {
    updateOneOrganisation(
      where: { id: $id }
      data: {
        name: $name
        type: $type
        parent: $parent
        priceList: $priceList
        currency: $currency
      }
    ) {
      id
      name
      type
      parent {
        id
        name
      }
      priceList {
        id
        name
      }
    }
  }
`;

export interface UpdateOrganisationArgs {
  id: string;
  name?: { set: string };
  type?: { set: OrganisationType };
  parent?: { connect: { id: string } };
  priceList?: { connect: { id: string } };
  currency?: { connect: { id: string } };
}

export interface UpdateOrganisationRes {
  updateOneOrganisation: {
    id: string;
    name: string;
    type: OrganisationType;
    parent?: {
      id: string;
      name: string;
    };
    priceList?: {
      id: string;
      name: string;
    };
    currency?: {
      id: string;
      name: string;
      symbol: string;
    };
  };
}
