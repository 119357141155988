import React from 'react';

import View from './GettingStarted.view';
import useGettingStarted from './useGettingStarted';

const GettingStarted = (): JSX.Element => {
  const { completeOnboarding } = useGettingStarted();

  return <View completeOnboarding={completeOnboarding} />;
};
export default GettingStarted;
