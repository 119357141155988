import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import View from './BusinessDetails.view';
import useBusinessDetails from './useBusinessDetails';

type Props = Omit<RouteComponentProps, 'location' | 'match'>;

const BusinessDetails = ({ history }: Props): JSX.Element => {
  const { details, handleSubmit, loading, saving, demo } = useBusinessDetails({
    history,
  });

  return (
    <View
      details={details}
      handleSubmit={handleSubmit}
      loading={loading}
      saving={saving}
      demo={demo}
    />
  );
};
export default BusinessDetails;
