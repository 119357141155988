/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
type Return<T extends string | number | symbol> = Record<
  T | string,
  string | number
>;
type CounterType = { [key: string]: number };

let counter: CounterType = {};
const startCount = <T extends Record<string | number, any>, K extends keyof T>(
  objectArr: T[],
  count: CounterType,
  property: K
): Return<K>[] => {
  // exit condition
  if (objectArr.length === 0) {
    counter = {};
    return Object.keys(count).map((el) => ({
      [property]: el,
      quantity: count[el],
    })) as Return<typeof property>[];
  }
  const target = objectArr[0][property];

  if (count[target]) count[target] += 1;
  if (!count[target]) count[target] = 1;

  const newDevices = objectArr.slice(1);
  return startCount(newDevices, count, property);
};

/**
 *
 * @param data - Array of objects
 * @param property - The property of the objects to count by (e.g. 'name' would return the number of objects which share each unique name value)
 * @returns {object} { property, quantity }[ ]
 *
 * @description Recursively counts objects in the input array by property, and returns an array with objects containing the distinct name, and quantity of that object.
 */
const countObjectsInArrayByProperty = <
  T extends Record<string, any>,
  K extends keyof T
>(
  data: T[],
  property: K
): Return<K>[] => startCount<T, K>(data, counter, property);

export default countObjectsInArrayByProperty;
