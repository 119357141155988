import { gql } from '@apollo/client';
import TermsType from '../enums';

export const Terms = gql`
  query Terms($where: TermWhereInput) {
    terms(where: $where) {
      id
      name
      description
      defaultTerms
      type
    }
  }
`;

export interface TermsArgs {
  where?: {
    OR: {
      defaultTerms?: {
        equals: boolean;
      };
    }[];
  };
}

export interface TermsRes {
  terms: {
    id: string;
    name: string;
    description: string;
    defaultTerms: boolean;
    type: TermsType;
  }[];
}
