/* eslint-disable no-param-reassign */

import { action, Action } from 'easy-peasy';
import { typedLocalStorage, LocalStorageKeys } from 'utils';

export enum NavType {
  SIDE = 'SIDE',
  TOP = 'TOP',
}

export enum SideNavTheme {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export const ThemeConfig = {
  sideNavTheme: typedLocalStorage.get(LocalStorageKeys.theme)
    ? (typedLocalStorage
        .get(LocalStorageKeys.theme)
        ?.toLocaleUpperCase() as SideNavTheme)
    : SideNavTheme.LIGHT,
  locale: 'en-GB',
  headerNavColor: '',
  currentTheme: typedLocalStorage.get(LocalStorageKeys.theme) || 'light',
  demoMode: false,
};

export interface ThemeModel {
  sideNavTheme: SideNavTheme;
  locale: string;
  headerNavColor: string;
  currentTheme: string;
  demoMode: boolean;

  setDemoMode: Action<ThemeModel, boolean>;
  sideNavStyleChange: Action<ThemeModel, SideNavTheme>;
  changeLocale: Action<ThemeModel, string>;
  headerNavColorChange: Action<ThemeModel, string>;
  switchTheme: Action<ThemeModel, string>;
}

const themeModel: ThemeModel = {
  ...ThemeConfig,

  sideNavStyleChange: action((state, payload) => {
    state.sideNavTheme = payload;
  }),
  changeLocale: action((state, payload) => {
    state.locale = payload;
  }),
  headerNavColorChange: action((state, payload) => {
    state.headerNavColor = payload;
  }),
  setDemoMode: action((state, payload) => {
    state.demoMode = payload;
  }),
  switchTheme: action((state, payload) => {
    state.currentTheme = payload;
    state.sideNavTheme = payload.toLocaleUpperCase() as SideNavTheme;
  }),
};

export default themeModel;
