import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  DateTime: any;
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Onboarding = 'ONBOARDING',
}

export type AddBundleInput = {
  bundleId: Scalars['String'];
  id: Scalars['String'];
  quantity: Scalars['Int'];
  type: Scalars['String'];
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  county: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  dealer?: Maybe<Dealer>;
  dealerId?: Maybe<Scalars['String']>;
  distributor?: Maybe<Distributor>;
  distributorId?: Maybe<Scalars['String']>;
  fullAddress?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  postcode: Scalars['String'];
  recycled: Scalars['Boolean'];
  street1: Scalars['String'];
  street2?: Maybe<Scalars['String']>;
  type: AddressTypeEnum;
  updatedAt: Scalars['DateTime'];
};

export type AddressCreateManyCustomerInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  county: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  type: AddressTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddressCreateManyCustomerInputEnvelope = {
  data?: InputMaybe<Array<AddressCreateManyCustomerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AddressCreateManyDealerInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  county: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  type: AddressTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddressCreateManyDealerInputEnvelope = {
  data?: InputMaybe<Array<AddressCreateManyDealerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AddressCreateManyDistributorInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  county: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  type: AddressTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddressCreateManyDistributorInputEnvelope = {
  data?: InputMaybe<Array<AddressCreateManyDistributorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AddressCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<AddressCreateManyCustomerInputEnvelope>;
};

export type AddressCreateNestedManyWithoutDealerInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutDealerInput>>;
  createMany?: InputMaybe<AddressCreateManyDealerInputEnvelope>;
};

export type AddressCreateNestedManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<AddressCreateManyDistributorInputEnvelope>;
};

export type AddressCreateOrConnectWithoutCustomerInput = {
  create: AddressCreateWithoutCustomerInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutDealerInput = {
  create: AddressCreateWithoutDealerInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateOrConnectWithoutDistributorInput = {
  create: AddressCreateWithoutDistributorInput;
  where: AddressWhereUniqueInput;
};

export type AddressCreateWithoutCustomerInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  county: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutAddressesInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutAddressesInput>;
  id?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  type: AddressTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddressCreateWithoutDealerInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  county: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutAddressesInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutAddressesInput>;
  id?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  type: AddressTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddressCreateWithoutDistributorInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  county: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutAddressesInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutAddressesInput>;
  id?: InputMaybe<Scalars['String']>;
  postcode: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  street1: Scalars['String'];
  street2?: InputMaybe<Scalars['String']>;
  type: AddressTypeEnum;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AddressListRelationFilter = {
  every?: InputMaybe<AddressWhereInput>;
  none?: InputMaybe<AddressWhereInput>;
  some?: InputMaybe<AddressWhereInput>;
};

export type AddressScalarWhereInput = {
  AND?: InputMaybe<Array<AddressScalarWhereInput>>;
  NOT?: InputMaybe<Array<AddressScalarWhereInput>>;
  OR?: InputMaybe<Array<AddressScalarWhereInput>>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  county?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributorId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  postcode?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  street1?: InputMaybe<StringFilter>;
  street2?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumAddressTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum AddressTypeEnum {
  Billing = 'BILLING',
  Shipping = 'SHIPPING',
}

export type AddressUpdate = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  county?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
};

export type AddressUpdateInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  county?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutAddressesInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutAddressesInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutAddressesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  street1?: InputMaybe<StringFieldUpdateOperationsInput>;
  street2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateManyMutationInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  county?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  street1?: InputMaybe<StringFieldUpdateOperationsInput>;
  street2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateManyWithWhereWithoutCustomerInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithWhereWithoutDealerInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithWhereWithoutDistributorInput = {
  data: AddressUpdateManyMutationInput;
  where: AddressScalarWhereInput;
};

export type AddressUpdateManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<AddressCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<Array<AddressUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<
    Array<AddressUpdateManyWithWhereWithoutCustomerInput>
  >;
  upsert?: InputMaybe<Array<AddressUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type AddressUpdateManyWithoutDealerInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AddressCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<AddressCreateWithoutDealerInput>>;
  createMany?: InputMaybe<AddressCreateManyDealerInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<Array<AddressUpdateWithWhereUniqueWithoutDealerInput>>;
  updateMany?: InputMaybe<Array<AddressUpdateManyWithWhereWithoutDealerInput>>;
  upsert?: InputMaybe<Array<AddressUpsertWithWhereUniqueWithoutDealerInput>>;
};

export type AddressUpdateManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<AddressCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<AddressCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<AddressCreateManyDistributorInputEnvelope>;
  delete?: InputMaybe<Array<AddressWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AddressScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AddressWhereUniqueInput>>;
  set?: InputMaybe<Array<AddressWhereUniqueInput>>;
  update?: InputMaybe<
    Array<AddressUpdateWithWhereUniqueWithoutDistributorInput>
  >;
  updateMany?: InputMaybe<
    Array<AddressUpdateManyWithWhereWithoutDistributorInput>
  >;
  upsert?: InputMaybe<
    Array<AddressUpsertWithWhereUniqueWithoutDistributorInput>
  >;
};

export type AddressUpdateWithWhereUniqueWithoutCustomerInput = {
  data: AddressUpdateWithoutCustomerInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithWhereUniqueWithoutDealerInput = {
  data: AddressUpdateWithoutDealerInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithWhereUniqueWithoutDistributorInput = {
  data: AddressUpdateWithoutDistributorInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpdateWithoutCustomerInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  county?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutAddressesInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutAddressesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  street1?: InputMaybe<StringFieldUpdateOperationsInput>;
  street2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutDealerInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  county?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutAddressesInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutAddressesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  street1?: InputMaybe<StringFieldUpdateOperationsInput>;
  street2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpdateWithoutDistributorInput = {
  city?: InputMaybe<StringFieldUpdateOperationsInput>;
  country?: InputMaybe<StringFieldUpdateOperationsInput>;
  county?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutAddressesInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutAddressesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  postcode?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  street1?: InputMaybe<StringFieldUpdateOperationsInput>;
  street2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumAddressTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AddressUpsertWithWhereUniqueWithoutCustomerInput = {
  create: AddressCreateWithoutCustomerInput;
  update: AddressUpdateWithoutCustomerInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithWhereUniqueWithoutDealerInput = {
  create: AddressCreateWithoutDealerInput;
  update: AddressUpdateWithoutDealerInput;
  where: AddressWhereUniqueInput;
};

export type AddressUpsertWithWhereUniqueWithoutDistributorInput = {
  create: AddressCreateWithoutDistributorInput;
  update: AddressUpdateWithoutDistributorInput;
  where: AddressWhereUniqueInput;
};

export type AddressWhereInput = {
  AND?: InputMaybe<Array<AddressWhereInput>>;
  NOT?: InputMaybe<Array<AddressWhereInput>>;
  OR?: InputMaybe<Array<AddressWhereInput>>;
  city?: InputMaybe<StringFilter>;
  country?: InputMaybe<StringFilter>;
  county?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributor?: InputMaybe<DistributorWhereInput>;
  distributorId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  postcode?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  street1?: InputMaybe<StringFilter>;
  street2?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumAddressTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AddressWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum AnswerModeEnum {
  Foacsu = 'FOACSU',
  Oacsu = 'OACSU',
}

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BundleOnUser = {
  __typename?: 'BundleOnUser';
  assignedTo: Array<CustomerAssigned>;
  bundle?: Maybe<DemBundle>;
  bundleId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  dealer?: Maybe<Dealer>;
  dealerId?: Maybe<Scalars['String']>;
  distributor?: Maybe<Distributor>;
  distributorId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type BundleOnUserAssignedToArgs = {
  after?: InputMaybe<CustomerAssignedWhereUniqueInput>;
  before?: InputMaybe<CustomerAssignedWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type BundleOnUserCreateManyBundleInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BundleOnUserCreateManyBundleInputEnvelope = {
  data?: InputMaybe<Array<BundleOnUserCreateManyBundleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BundleOnUserCreateManyCustomerInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BundleOnUserCreateManyCustomerInputEnvelope = {
  data?: InputMaybe<Array<BundleOnUserCreateManyCustomerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BundleOnUserCreateManyDealerInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BundleOnUserCreateManyDealerInputEnvelope = {
  data?: InputMaybe<Array<BundleOnUserCreateManyDealerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BundleOnUserCreateManyDistributorInput = {
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BundleOnUserCreateManyDistributorInputEnvelope = {
  data?: InputMaybe<Array<BundleOnUserCreateManyDistributorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BundleOnUserCreateNestedManyWithoutBundleInput = {
  connect?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BundleOnUserCreateOrConnectWithoutBundleInput>
  >;
  create?: InputMaybe<Array<BundleOnUserCreateWithoutBundleInput>>;
  createMany?: InputMaybe<BundleOnUserCreateManyBundleInputEnvelope>;
};

export type BundleOnUserCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BundleOnUserCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<BundleOnUserCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<BundleOnUserCreateManyCustomerInputEnvelope>;
};

export type BundleOnUserCreateNestedManyWithoutDealerInput = {
  connect?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BundleOnUserCreateOrConnectWithoutDealerInput>
  >;
  create?: InputMaybe<Array<BundleOnUserCreateWithoutDealerInput>>;
  createMany?: InputMaybe<BundleOnUserCreateManyDealerInputEnvelope>;
};

export type BundleOnUserCreateNestedManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BundleOnUserCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<BundleOnUserCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<BundleOnUserCreateManyDistributorInputEnvelope>;
};

export type BundleOnUserCreateOrConnectWithoutBundleInput = {
  create: BundleOnUserCreateWithoutBundleInput;
  where: BundleOnUserWhereUniqueInput;
};

export type BundleOnUserCreateOrConnectWithoutCustomerInput = {
  create: BundleOnUserCreateWithoutCustomerInput;
  where: BundleOnUserWhereUniqueInput;
};

export type BundleOnUserCreateOrConnectWithoutDealerInput = {
  create: BundleOnUserCreateWithoutDealerInput;
  where: BundleOnUserWhereUniqueInput;
};

export type BundleOnUserCreateOrConnectWithoutDistributorInput = {
  create: BundleOnUserCreateWithoutDistributorInput;
  where: BundleOnUserWhereUniqueInput;
};

export type BundleOnUserCreateWithoutBundleInput = {
  assignedTo?: InputMaybe<CustomerAssignedCreateNestedManyWithoutBundleOnUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDemBundlesInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDemBundlesInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDemBundlesInput>;
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BundleOnUserCreateWithoutCustomerInput = {
  assignedTo?: InputMaybe<CustomerAssignedCreateNestedManyWithoutBundleOnUserInput>;
  bundle?: InputMaybe<DemBundleCreateNestedOneWithoutBundlesOnUsersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDemBundlesInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDemBundlesInput>;
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BundleOnUserCreateWithoutDealerInput = {
  assignedTo?: InputMaybe<CustomerAssignedCreateNestedManyWithoutBundleOnUserInput>;
  bundle?: InputMaybe<DemBundleCreateNestedOneWithoutBundlesOnUsersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDemBundlesInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDemBundlesInput>;
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BundleOnUserCreateWithoutDistributorInput = {
  assignedTo?: InputMaybe<CustomerAssignedCreateNestedManyWithoutBundleOnUserInput>;
  bundle?: InputMaybe<DemBundleCreateNestedOneWithoutBundlesOnUsersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDemBundlesInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDemBundlesInput>;
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BundleOnUserListRelationFilter = {
  every?: InputMaybe<BundleOnUserWhereInput>;
  none?: InputMaybe<BundleOnUserWhereInput>;
  some?: InputMaybe<BundleOnUserWhereInput>;
};

export type BundleOnUserScalarWhereInput = {
  AND?: InputMaybe<Array<BundleOnUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<BundleOnUserScalarWhereInput>>;
  OR?: InputMaybe<Array<BundleOnUserScalarWhereInput>>;
  bundleId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributorId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BundleOnUserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BundleOnUserUpdateManyWithWhereWithoutCustomerInput = {
  data: BundleOnUserUpdateManyMutationInput;
  where: BundleOnUserScalarWhereInput;
};

export type BundleOnUserUpdateManyWithWhereWithoutDealerInput = {
  data: BundleOnUserUpdateManyMutationInput;
  where: BundleOnUserScalarWhereInput;
};

export type BundleOnUserUpdateManyWithWhereWithoutDistributorInput = {
  data: BundleOnUserUpdateManyMutationInput;
  where: BundleOnUserScalarWhereInput;
};

export type BundleOnUserUpdateManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BundleOnUserCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<BundleOnUserCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<BundleOnUserCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BundleOnUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  set?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BundleOnUserUpdateWithWhereUniqueWithoutCustomerInput>
  >;
  updateMany?: InputMaybe<
    Array<BundleOnUserUpdateManyWithWhereWithoutCustomerInput>
  >;
  upsert?: InputMaybe<
    Array<BundleOnUserUpsertWithWhereUniqueWithoutCustomerInput>
  >;
};

export type BundleOnUserUpdateManyWithoutDealerInput = {
  connect?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BundleOnUserCreateOrConnectWithoutDealerInput>
  >;
  create?: InputMaybe<Array<BundleOnUserCreateWithoutDealerInput>>;
  createMany?: InputMaybe<BundleOnUserCreateManyDealerInputEnvelope>;
  delete?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BundleOnUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  set?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BundleOnUserUpdateWithWhereUniqueWithoutDealerInput>
  >;
  updateMany?: InputMaybe<
    Array<BundleOnUserUpdateManyWithWhereWithoutDealerInput>
  >;
  upsert?: InputMaybe<
    Array<BundleOnUserUpsertWithWhereUniqueWithoutDealerInput>
  >;
};

export type BundleOnUserUpdateManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<BundleOnUserCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<BundleOnUserCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<BundleOnUserCreateManyDistributorInputEnvelope>;
  delete?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<BundleOnUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  set?: InputMaybe<Array<BundleOnUserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<BundleOnUserUpdateWithWhereUniqueWithoutDistributorInput>
  >;
  updateMany?: InputMaybe<
    Array<BundleOnUserUpdateManyWithWhereWithoutDistributorInput>
  >;
  upsert?: InputMaybe<
    Array<BundleOnUserUpsertWithWhereUniqueWithoutDistributorInput>
  >;
};

export type BundleOnUserUpdateWithWhereUniqueWithoutCustomerInput = {
  data: BundleOnUserUpdateWithoutCustomerInput;
  where: BundleOnUserWhereUniqueInput;
};

export type BundleOnUserUpdateWithWhereUniqueWithoutDealerInput = {
  data: BundleOnUserUpdateWithoutDealerInput;
  where: BundleOnUserWhereUniqueInput;
};

export type BundleOnUserUpdateWithWhereUniqueWithoutDistributorInput = {
  data: BundleOnUserUpdateWithoutDistributorInput;
  where: BundleOnUserWhereUniqueInput;
};

export type BundleOnUserUpdateWithoutCustomerInput = {
  assignedTo?: InputMaybe<CustomerAssignedUpdateManyWithoutBundleOnUserInput>;
  bundle?: InputMaybe<DemBundleUpdateOneWithoutBundlesOnUsersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDemBundlesInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDemBundlesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BundleOnUserUpdateWithoutDealerInput = {
  assignedTo?: InputMaybe<CustomerAssignedUpdateManyWithoutBundleOnUserInput>;
  bundle?: InputMaybe<DemBundleUpdateOneWithoutBundlesOnUsersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDemBundlesInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDemBundlesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BundleOnUserUpdateWithoutDistributorInput = {
  assignedTo?: InputMaybe<CustomerAssignedUpdateManyWithoutBundleOnUserInput>;
  bundle?: InputMaybe<DemBundleUpdateOneWithoutBundlesOnUsersInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDemBundlesInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDemBundlesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type BundleOnUserUpsertWithWhereUniqueWithoutCustomerInput = {
  create: BundleOnUserCreateWithoutCustomerInput;
  update: BundleOnUserUpdateWithoutCustomerInput;
  where: BundleOnUserWhereUniqueInput;
};

export type BundleOnUserUpsertWithWhereUniqueWithoutDealerInput = {
  create: BundleOnUserCreateWithoutDealerInput;
  update: BundleOnUserUpdateWithoutDealerInput;
  where: BundleOnUserWhereUniqueInput;
};

export type BundleOnUserUpsertWithWhereUniqueWithoutDistributorInput = {
  create: BundleOnUserCreateWithoutDistributorInput;
  update: BundleOnUserUpdateWithoutDistributorInput;
  where: BundleOnUserWhereUniqueInput;
};

export type BundleOnUserWhereInput = {
  AND?: InputMaybe<Array<BundleOnUserWhereInput>>;
  NOT?: InputMaybe<Array<BundleOnUserWhereInput>>;
  OR?: InputMaybe<Array<BundleOnUserWhereInput>>;
  assignedTo?: InputMaybe<CustomerAssignedListRelationFilter>;
  bundle?: InputMaybe<DemBundleWhereInput>;
  bundleId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributor?: InputMaybe<DistributorWhereInput>;
  distributorId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type BundleOnUserWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CardUpdate = {
  expMonth?: InputMaybe<Scalars['Int']>;
  expYear?: InputMaybe<Scalars['Int']>;
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  id: Scalars['String'];
};

export type CompanyRoles = {
  __typename?: 'CompanyRoles';
  company?: Maybe<DemCompany>;
  id: Scalars['String'];
  roleName: Scalars['String'];
};

export type Contact = {
  __typename?: 'Contact';
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  dealer?: Maybe<Dealer>;
  dealerId?: Maybe<Scalars['String']>;
  distributor?: Maybe<Distributor>;
  distributorId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  recycled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type ContactCreateNestedOneWithoutCustomerInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<ContactCreateWithoutCustomerInput>;
};

export type ContactCreateNestedOneWithoutDealerInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutDealerInput>;
  create?: InputMaybe<ContactCreateWithoutDealerInput>;
};

export type ContactCreateNestedOneWithoutDistributorInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutDistributorInput>;
  create?: InputMaybe<ContactCreateWithoutDistributorInput>;
};

export type ContactCreateOrConnectWithoutCustomerInput = {
  create: ContactCreateWithoutCustomerInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutDealerInput = {
  create: ContactCreateWithoutDealerInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateOrConnectWithoutDistributorInput = {
  create: ContactCreateWithoutDistributorInput;
  where: ContactWhereUniqueInput;
};

export type ContactCreateWithoutCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutContactInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutContactInput>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ContactCreateWithoutDealerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutContactInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutContactInput>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ContactCreateWithoutDistributorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutContactInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutContactInput>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ContactUpdateOneWithoutCustomerInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<ContactCreateWithoutCustomerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ContactUpdateWithoutCustomerInput>;
  upsert?: InputMaybe<ContactUpsertWithoutCustomerInput>;
};

export type ContactUpdateOneWithoutDealerInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutDealerInput>;
  create?: InputMaybe<ContactCreateWithoutDealerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ContactUpdateWithoutDealerInput>;
  upsert?: InputMaybe<ContactUpsertWithoutDealerInput>;
};

export type ContactUpdateOneWithoutDistributorInput = {
  connect?: InputMaybe<ContactWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ContactCreateOrConnectWithoutDistributorInput>;
  create?: InputMaybe<ContactCreateWithoutDistributorInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ContactUpdateWithoutDistributorInput>;
  upsert?: InputMaybe<ContactUpsertWithoutDistributorInput>;
};

export type ContactUpdateWithoutCustomerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutContactInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutContactInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContactUpdateWithoutDealerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutContactInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutContactInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContactUpdateWithoutDistributorInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutContactInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutContactInput>;
  email?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  phone?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ContactUpsertWithoutCustomerInput = {
  create: ContactCreateWithoutCustomerInput;
  update: ContactUpdateWithoutCustomerInput;
};

export type ContactUpsertWithoutDealerInput = {
  create: ContactCreateWithoutDealerInput;
  update: ContactUpdateWithoutDealerInput;
};

export type ContactUpsertWithoutDistributorInput = {
  create: ContactCreateWithoutDistributorInput;
  update: ContactUpdateWithoutDistributorInput;
};

export type ContactWhereInput = {
  AND?: InputMaybe<Array<ContactWhereInput>>;
  NOT?: InputMaybe<Array<ContactWhereInput>>;
  OR?: InputMaybe<Array<ContactWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributor?: InputMaybe<DistributorWhereInput>;
  distributorId?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ContactWhereUniqueInput = {
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type CreateDemCompanyInput = {
  groupId: Scalars['String'];
  groupName: Scalars['String'];
  hytalkId: Scalars['String'];
  name: Scalars['String'];
  retentionDays1: Scalars['Int'];
  retentionDays2: Scalars['Int'];
  retentionName1: Scalars['String'];
  retentionName2: Scalars['String'];
  tier: Scalars['String'];
};

export type CreateDemDeviceInput = {
  companyId: Scalars['String'];
  demo: Scalars['Boolean'];
  groupIds: Array<InputMaybe<Scalars['String']>>;
  hytalkId: Scalars['String'];
  modelId: Scalars['String'];
  serialNumber: Scalars['String'];
};

export type CreateDemGroupInput = {
  groupId: Scalars['String'];
  hytalkId: Scalars['String'];
  name: Scalars['String'];
};

export type CreateDemUserInput = {
  companyId: Scalars['String'];
  email: Scalars['String'];
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hytalkId: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  lang: Scalars['String'];
  name: Scalars['String'];
  orgId?: InputMaybe<Scalars['String']>;
  orgType: Scalars['String'];
  role: Scalars['String'];
};

export type Currency = {
  currency?: InputMaybe<Scalars['String']>;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  customer?: Maybe<Customer>;
  dealer?: Maybe<Dealer>;
  distributor?: Maybe<Distributor>;
  id: Scalars['String'];
  onboarding?: Maybe<OnboardingStepEnum>;
  termsAgreement?: Maybe<TermsAgreement>;
};

export type Customer = {
  __typename?: 'Customer';
  SOPIdentifier: Array<Scalars['String']>;
  addresses: Array<Address>;
  billingAddress?: Maybe<StripeAddress>;
  contact?: Maybe<Contact>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  dealer?: Maybe<Dealer>;
  dealerId?: Maybe<Scalars['String']>;
  demBundles: Array<BundleOnUser>;
  deviceGroups: Array<DeviceGroup>;
  deviceUsers: Array<DeviceUser>;
  devices: Array<Device>;
  distributor?: Maybe<Distributor>;
  distributorId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoices: Array<Invoice>;
  locale?: Maybe<StripeLocale>;
  name: Scalars['String'];
  nextInvoice?: Maybe<Invoice>;
  onboarding: OnboardingStepEnum;
  payments: Array<Payment>;
  recycled: Scalars['Boolean'];
  services: Array<Service>;
  settings?: Maybe<CustomerSetting>;
  shipping?: Maybe<StripeShipping>;
  simCards: Array<SimCard>;
  sopLogin?: Maybe<SopLogin>;
  status?: Maybe<AccountStatus>;
  stripeId?: Maybe<Scalars['String']>;
  teams: Array<Team>;
  totalDemoDeviceUsers?: Maybe<Scalars['Int']>;
  totalDeviceUsers?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
  vatId?: Maybe<VatId>;
};

export type CustomerAddressesArgs = {
  after?: InputMaybe<AddressWhereUniqueInput>;
  before?: InputMaybe<AddressWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerDemBundlesArgs = {
  after?: InputMaybe<BundleOnUserWhereUniqueInput>;
  before?: InputMaybe<BundleOnUserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerDeviceGroupsArgs = {
  after?: InputMaybe<DeviceGroupWhereUniqueInput>;
  before?: InputMaybe<DeviceGroupWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerDeviceUsersArgs = {
  after?: InputMaybe<DeviceUserWhereUniqueInput>;
  before?: InputMaybe<DeviceUserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerDevicesArgs = {
  after?: InputMaybe<DeviceWhereUniqueInput>;
  before?: InputMaybe<DeviceWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerServicesArgs = {
  after?: InputMaybe<ServiceWhereUniqueInput>;
  before?: InputMaybe<ServiceWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerSimCardsArgs = {
  after?: InputMaybe<SimCardWhereUniqueInput>;
  before?: InputMaybe<SimCardWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerTeamsArgs = {
  after?: InputMaybe<TeamWhereUniqueInput>;
  before?: InputMaybe<TeamWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerUsersArgs = {
  after?: InputMaybe<UserWhereUniqueInput>;
  before?: InputMaybe<UserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type CustomerAssigned = {
  __typename?: 'CustomerAssigned';
  bundleOnUser: BundleOnUser;
  bundleOnUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customerId: Scalars['String'];
  quantity: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type CustomerAssignedBundleOnUserIdCustomerIdCompoundUniqueInput = {
  bundleOnUserId: Scalars['String'];
  customerId: Scalars['String'];
};

export type CustomerAssignedCreateManyBundleOnUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerAssignedCreateManyBundleOnUserInputEnvelope = {
  data?: InputMaybe<Array<CustomerAssignedCreateManyBundleOnUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerAssignedCreateNestedManyWithoutBundleOnUserInput = {
  connect?: InputMaybe<Array<CustomerAssignedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CustomerAssignedCreateOrConnectWithoutBundleOnUserInput>
  >;
  create?: InputMaybe<Array<CustomerAssignedCreateWithoutBundleOnUserInput>>;
  createMany?: InputMaybe<CustomerAssignedCreateManyBundleOnUserInputEnvelope>;
};

export type CustomerAssignedCreateOrConnectWithoutBundleOnUserInput = {
  create: CustomerAssignedCreateWithoutBundleOnUserInput;
  where: CustomerAssignedWhereUniqueInput;
};

export type CustomerAssignedCreateWithoutBundleOnUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerAssignedListRelationFilter = {
  every?: InputMaybe<CustomerAssignedWhereInput>;
  none?: InputMaybe<CustomerAssignedWhereInput>;
  some?: InputMaybe<CustomerAssignedWhereInput>;
};

export type CustomerAssignedScalarWhereInput = {
  AND?: InputMaybe<Array<CustomerAssignedScalarWhereInput>>;
  NOT?: InputMaybe<Array<CustomerAssignedScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomerAssignedScalarWhereInput>>;
  bundleOnUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerAssignedUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerAssignedUpdateManyWithWhereWithoutBundleOnUserInput = {
  data: CustomerAssignedUpdateManyMutationInput;
  where: CustomerAssignedScalarWhereInput;
};

export type CustomerAssignedUpdateManyWithoutBundleOnUserInput = {
  connect?: InputMaybe<Array<CustomerAssignedWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CustomerAssignedCreateOrConnectWithoutBundleOnUserInput>
  >;
  create?: InputMaybe<Array<CustomerAssignedCreateWithoutBundleOnUserInput>>;
  createMany?: InputMaybe<CustomerAssignedCreateManyBundleOnUserInputEnvelope>;
  delete?: InputMaybe<Array<CustomerAssignedWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomerAssignedScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomerAssignedWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomerAssignedWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CustomerAssignedUpdateWithWhereUniqueWithoutBundleOnUserInput>
  >;
  updateMany?: InputMaybe<
    Array<CustomerAssignedUpdateManyWithWhereWithoutBundleOnUserInput>
  >;
  upsert?: InputMaybe<
    Array<CustomerAssignedUpsertWithWhereUniqueWithoutBundleOnUserInput>
  >;
};

export type CustomerAssignedUpdateWithWhereUniqueWithoutBundleOnUserInput = {
  data: CustomerAssignedUpdateWithoutBundleOnUserInput;
  where: CustomerAssignedWhereUniqueInput;
};

export type CustomerAssignedUpdateWithoutBundleOnUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customerId?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerAssignedUpsertWithWhereUniqueWithoutBundleOnUserInput = {
  create: CustomerAssignedCreateWithoutBundleOnUserInput;
  update: CustomerAssignedUpdateWithoutBundleOnUserInput;
  where: CustomerAssignedWhereUniqueInput;
};

export type CustomerAssignedWhereInput = {
  AND?: InputMaybe<Array<CustomerAssignedWhereInput>>;
  NOT?: InputMaybe<Array<CustomerAssignedWhereInput>>;
  OR?: InputMaybe<Array<CustomerAssignedWhereInput>>;
  bundleOnUser?: InputMaybe<BundleOnUserWhereInput>;
  bundleOnUserId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  quantity?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerAssignedWhereUniqueInput = {
  bundleOnUserId_customerId?: InputMaybe<CustomerAssignedBundleOnUserIdCustomerIdCompoundUniqueInput>;
  id?: InputMaybe<Scalars['String']>;
};

/** hytalkModel: "dealer" or "customer" */
export type CustomerCreateInput = {
  address: AddressUpdate;
  currency: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  hytalkId: Scalars['String'];
  hytalkModel: Scalars['String'];
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
};

export type CustomerCreateManyDealerInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerCreateManyDealerInputEnvelope = {
  data?: InputMaybe<Array<CustomerCreateManyDealerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerCreateManyDistributorInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerCreateManyDistributorInputEnvelope = {
  data?: InputMaybe<Array<CustomerCreateManyDistributorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CustomerCreateNestedManyWithoutDealerInput = {
  connect?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CustomerCreateOrConnectWithoutDealerInput>
  >;
  create?: InputMaybe<Array<CustomerCreateWithoutDealerInput>>;
  createMany?: InputMaybe<CustomerCreateManyDealerInputEnvelope>;
};

export type CustomerCreateNestedManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CustomerCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<CustomerCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<CustomerCreateManyDistributorInputEnvelope>;
};

export type CustomerCreateNestedOneWithoutAddressesInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<CustomerCreateWithoutAddressesInput>;
};

export type CustomerCreateNestedOneWithoutContactInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<CustomerCreateWithoutContactInput>;
};

export type CustomerCreateNestedOneWithoutDemBundlesInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDemBundlesInput>;
  create?: InputMaybe<CustomerCreateWithoutDemBundlesInput>;
};

export type CustomerCreateNestedOneWithoutDeviceGroupsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDeviceGroupsInput>;
  create?: InputMaybe<CustomerCreateWithoutDeviceGroupsInput>;
};

export type CustomerCreateNestedOneWithoutDeviceUsersInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDeviceUsersInput>;
  create?: InputMaybe<CustomerCreateWithoutDeviceUsersInput>;
};

export type CustomerCreateNestedOneWithoutDevicesInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDevicesInput>;
  create?: InputMaybe<CustomerCreateWithoutDevicesInput>;
};

export type CustomerCreateNestedOneWithoutServicesInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutServicesInput>;
  create?: InputMaybe<CustomerCreateWithoutServicesInput>;
};

export type CustomerCreateNestedOneWithoutSimCardsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutSimCardsInput>;
  create?: InputMaybe<CustomerCreateWithoutSimCardsInput>;
};

export type CustomerCreateNestedOneWithoutSopLoginInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutSopLoginInput>;
  create?: InputMaybe<CustomerCreateWithoutSopLoginInput>;
};

export type CustomerCreateNestedOneWithoutSubscriptionsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutSubscriptionsInput>;
  create?: InputMaybe<CustomerCreateWithoutSubscriptionsInput>;
};

export type CustomerCreateNestedOneWithoutTeamsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutTeamsInput>;
  create?: InputMaybe<CustomerCreateWithoutTeamsInput>;
};

export type CustomerCreateNestedOneWithoutTermsAgreementInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutTermsAgreementInput>;
  create?: InputMaybe<CustomerCreateWithoutTermsAgreementInput>;
};

export type CustomerCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<CustomerCreateWithoutUsersInput>;
};

export type CustomerCreateOrConnectWithoutAddressesInput = {
  create: CustomerCreateWithoutAddressesInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutContactInput = {
  create: CustomerCreateWithoutContactInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutDealerInput = {
  create: CustomerCreateWithoutDealerInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutDemBundlesInput = {
  create: CustomerCreateWithoutDemBundlesInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutDeviceGroupsInput = {
  create: CustomerCreateWithoutDeviceGroupsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutDeviceUsersInput = {
  create: CustomerCreateWithoutDeviceUsersInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutDevicesInput = {
  create: CustomerCreateWithoutDevicesInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutDistributorInput = {
  create: CustomerCreateWithoutDistributorInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutServicesInput = {
  create: CustomerCreateWithoutServicesInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutSimCardsInput = {
  create: CustomerCreateWithoutSimCardsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutSopLoginInput = {
  create: CustomerCreateWithoutSopLoginInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutSubscriptionsInput = {
  create: CustomerCreateWithoutSubscriptionsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutTeamsInput = {
  create: CustomerCreateWithoutTeamsInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutTermsAgreementInput = {
  create: CustomerCreateWithoutTermsAgreementInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateOrConnectWithoutUsersInput = {
  create: CustomerCreateWithoutUsersInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerCreateSopIdentifierInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomerCreateWithoutAddressesInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutContactInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutDealerInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutDemBundlesInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutDeviceGroupsInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutDeviceUsersInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutDevicesInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutDistributorInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutServicesInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutSimCardsInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutSopLoginInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutSubscriptionsInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutTeamsInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutTermsAgreementInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutCustomerInput>;
};

export type CustomerCreateWithoutUsersInput = {
  SOPIdentifier?: InputMaybe<CustomerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutCustomerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupCreateNestedManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutCustomersInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingCreateNestedOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutCustomerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<CustomerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CustomerCreatestripeSubscriptionIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomerListRelationFilter = {
  every?: InputMaybe<CustomerWhereInput>;
  none?: InputMaybe<CustomerWhereInput>;
  some?: InputMaybe<CustomerWhereInput>;
};

export type CustomerObjectUpdateInput = {
  address?: InputMaybe<AddressUpdate>;
  defaultPaymentMethodId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  shipping?: InputMaybe<ShippingAddressInput>;
};

export type CustomerScalarWhereInput = {
  AND?: InputMaybe<Array<CustomerScalarWhereInput>>;
  NOT?: InputMaybe<Array<CustomerScalarWhereInput>>;
  OR?: InputMaybe<Array<CustomerScalarWhereInput>>;
  SOPIdentifier?: InputMaybe<StringNullableListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributorId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeId?: InputMaybe<StringNullableFilter>;
  stripeSubscriptionIds?: InputMaybe<StringNullableListFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CustomerSetting = {
  __typename?: 'CustomerSetting';
  PTTItems?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  dateFormat?: Maybe<Scalars['String']>;
  groupVideoDuration?: Maybe<Scalars['Int']>;
  groupVoiceDuration?: Maybe<Scalars['Int']>;
  hangTime?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  privateVideoDuration?: Maybe<Scalars['Int']>;
  privateVoiceDuration?: Maybe<Scalars['Int']>;
  recycled: Scalars['Boolean'];
  timeFormat?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  voiceQuality: VoiceQualityEnum;
};

export type CustomerSettingCreateNestedOneWithoutCustomerInput = {
  connect?: InputMaybe<CustomerSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerSettingCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<CustomerSettingCreateWithoutCustomerInput>;
};

export type CustomerSettingCreateOrConnectWithoutCustomerInput = {
  create: CustomerSettingCreateWithoutCustomerInput;
  where: CustomerSettingWhereUniqueInput;
};

export type CustomerSettingCreateWithoutCustomerInput = {
  PTTItems?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateFormat?: InputMaybe<Scalars['String']>;
  groupVideoDuration?: InputMaybe<Scalars['Int']>;
  groupVoiceDuration?: InputMaybe<Scalars['Int']>;
  hangTime?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  privateVideoDuration?: InputMaybe<Scalars['Int']>;
  privateVoiceDuration?: InputMaybe<Scalars['Int']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  timeFormat?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  voiceQuality: VoiceQualityEnum;
};

export type CustomerSettingUpdateOneWithoutCustomerInput = {
  connect?: InputMaybe<CustomerSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerSettingCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<CustomerSettingCreateWithoutCustomerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerSettingUpdateWithoutCustomerInput>;
  upsert?: InputMaybe<CustomerSettingUpsertWithoutCustomerInput>;
};

export type CustomerSettingUpdateWithoutCustomerInput = {
  PTTItems?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateFormat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  groupVideoDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  groupVoiceDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  hangTime?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  privateVideoDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  privateVoiceDuration?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  timeFormat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeZone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  voiceQuality?: InputMaybe<EnumVoiceQualityEnumFieldUpdateOperationsInput>;
};

export type CustomerSettingUpsertWithoutCustomerInput = {
  create: CustomerSettingCreateWithoutCustomerInput;
  update: CustomerSettingUpdateWithoutCustomerInput;
};

export type CustomerSettingWhereInput = {
  AND?: InputMaybe<Array<CustomerSettingWhereInput>>;
  NOT?: InputMaybe<Array<CustomerSettingWhereInput>>;
  OR?: InputMaybe<Array<CustomerSettingWhereInput>>;
  PTTItems?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringFilter>;
  dateFormat?: InputMaybe<StringNullableFilter>;
  groupVideoDuration?: InputMaybe<IntNullableFilter>;
  groupVoiceDuration?: InputMaybe<IntNullableFilter>;
  hangTime?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  privateVideoDuration?: InputMaybe<IntNullableFilter>;
  privateVoiceDuration?: InputMaybe<IntNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  timeFormat?: InputMaybe<StringNullableFilter>;
  timeZone?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  voiceQuality?: InputMaybe<EnumVoiceQualityEnumFilter>;
};

export type CustomerSettingWhereUniqueInput = {
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type CustomerUpdateInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateManyMutationInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUpdateManyWithWhereWithoutDealerInput = {
  data: CustomerUpdateManyMutationInput;
  where: CustomerScalarWhereInput;
};

export type CustomerUpdateManyWithWhereWithoutDistributorInput = {
  data: CustomerUpdateManyMutationInput;
  where: CustomerScalarWhereInput;
};

export type CustomerUpdateManyWithoutDealerInput = {
  connect?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CustomerCreateOrConnectWithoutDealerInput>
  >;
  create?: InputMaybe<Array<CustomerCreateWithoutDealerInput>>;
  createMany?: InputMaybe<CustomerCreateManyDealerInputEnvelope>;
  delete?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  update?: InputMaybe<Array<CustomerUpdateWithWhereUniqueWithoutDealerInput>>;
  updateMany?: InputMaybe<Array<CustomerUpdateManyWithWhereWithoutDealerInput>>;
  upsert?: InputMaybe<Array<CustomerUpsertWithWhereUniqueWithoutDealerInput>>;
};

export type CustomerUpdateManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<CustomerCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<CustomerCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<CustomerCreateManyDistributorInputEnvelope>;
  delete?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CustomerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  set?: InputMaybe<Array<CustomerWhereUniqueInput>>;
  update?: InputMaybe<
    Array<CustomerUpdateWithWhereUniqueWithoutDistributorInput>
  >;
  updateMany?: InputMaybe<
    Array<CustomerUpdateManyWithWhereWithoutDistributorInput>
  >;
  upsert?: InputMaybe<
    Array<CustomerUpsertWithWhereUniqueWithoutDistributorInput>
  >;
};

export type CustomerUpdateOneRequiredWithoutTeamsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutTeamsInput>;
  create?: InputMaybe<CustomerCreateWithoutTeamsInput>;
  update?: InputMaybe<CustomerUpdateWithoutTeamsInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutTeamsInput>;
};

export type CustomerUpdateOneWithoutAddressesInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<CustomerCreateWithoutAddressesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutAddressesInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutAddressesInput>;
};

export type CustomerUpdateOneWithoutContactInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<CustomerCreateWithoutContactInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutContactInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutContactInput>;
};

export type CustomerUpdateOneWithoutDemBundlesInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDemBundlesInput>;
  create?: InputMaybe<CustomerCreateWithoutDemBundlesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutDemBundlesInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutDemBundlesInput>;
};

export type CustomerUpdateOneWithoutDeviceGroupsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDeviceGroupsInput>;
  create?: InputMaybe<CustomerCreateWithoutDeviceGroupsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutDeviceGroupsInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutDeviceGroupsInput>;
};

export type CustomerUpdateOneWithoutDeviceUsersInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDeviceUsersInput>;
  create?: InputMaybe<CustomerCreateWithoutDeviceUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutDeviceUsersInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutDeviceUsersInput>;
};

export type CustomerUpdateOneWithoutDevicesInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutDevicesInput>;
  create?: InputMaybe<CustomerCreateWithoutDevicesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutDevicesInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutDevicesInput>;
};

export type CustomerUpdateOneWithoutServicesInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutServicesInput>;
  create?: InputMaybe<CustomerCreateWithoutServicesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutServicesInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutServicesInput>;
};

export type CustomerUpdateOneWithoutSimCardsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutSimCardsInput>;
  create?: InputMaybe<CustomerCreateWithoutSimCardsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutSimCardsInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutSimCardsInput>;
};

export type CustomerUpdateOneWithoutSopLoginInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutSopLoginInput>;
  create?: InputMaybe<CustomerCreateWithoutSopLoginInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutSopLoginInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutSopLoginInput>;
};

export type CustomerUpdateOneWithoutSubscriptionsInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutSubscriptionsInput>;
  create?: InputMaybe<CustomerCreateWithoutSubscriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutSubscriptionsInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutSubscriptionsInput>;
};

export type CustomerUpdateOneWithoutTermsAgreementInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutTermsAgreementInput>;
  create?: InputMaybe<CustomerCreateWithoutTermsAgreementInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutTermsAgreementInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutTermsAgreementInput>;
};

export type CustomerUpdateOneWithoutUsersInput = {
  connect?: InputMaybe<CustomerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CustomerCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<CustomerCreateWithoutUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CustomerUpdateWithoutUsersInput>;
  upsert?: InputMaybe<CustomerUpsertWithoutUsersInput>;
};

export type CustomerUpdateSopIdentifierInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomerUpdateWithWhereUniqueWithoutDealerInput = {
  data: CustomerUpdateWithoutDealerInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerUpdateWithWhereUniqueWithoutDistributorInput = {
  data: CustomerUpdateWithoutDistributorInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerUpdateWithoutAddressesInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutContactInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutDealerInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutDemBundlesInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutDeviceGroupsInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutDeviceUsersInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutDevicesInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutDistributorInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutServicesInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutSimCardsInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutSopLoginInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutSubscriptionsInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutTeamsInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutTermsAgreementInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutCustomerInput>;
};

export type CustomerUpdateWithoutUsersInput = {
  SOPIdentifier?: InputMaybe<CustomerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutCustomerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutCustomerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutCustomersInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutCustomerInput>;
  deviceGroups?: InputMaybe<DeviceGroupUpdateManyWithoutCustomerInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutCustomerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutCustomerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutCustomersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutCustomerInput>;
  settings?: InputMaybe<CustomerSettingUpdateOneWithoutCustomerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutCustomerInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutCustomerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<CustomerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutCustomerInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutCustomerInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutCustomerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CustomerUpdatestripeSubscriptionIdsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CustomerUpsertWithWhereUniqueWithoutDealerInput = {
  create: CustomerCreateWithoutDealerInput;
  update: CustomerUpdateWithoutDealerInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerUpsertWithWhereUniqueWithoutDistributorInput = {
  create: CustomerCreateWithoutDistributorInput;
  update: CustomerUpdateWithoutDistributorInput;
  where: CustomerWhereUniqueInput;
};

export type CustomerUpsertWithoutAddressesInput = {
  create: CustomerCreateWithoutAddressesInput;
  update: CustomerUpdateWithoutAddressesInput;
};

export type CustomerUpsertWithoutContactInput = {
  create: CustomerCreateWithoutContactInput;
  update: CustomerUpdateWithoutContactInput;
};

export type CustomerUpsertWithoutDemBundlesInput = {
  create: CustomerCreateWithoutDemBundlesInput;
  update: CustomerUpdateWithoutDemBundlesInput;
};

export type CustomerUpsertWithoutDeviceGroupsInput = {
  create: CustomerCreateWithoutDeviceGroupsInput;
  update: CustomerUpdateWithoutDeviceGroupsInput;
};

export type CustomerUpsertWithoutDeviceUsersInput = {
  create: CustomerCreateWithoutDeviceUsersInput;
  update: CustomerUpdateWithoutDeviceUsersInput;
};

export type CustomerUpsertWithoutDevicesInput = {
  create: CustomerCreateWithoutDevicesInput;
  update: CustomerUpdateWithoutDevicesInput;
};

export type CustomerUpsertWithoutServicesInput = {
  create: CustomerCreateWithoutServicesInput;
  update: CustomerUpdateWithoutServicesInput;
};

export type CustomerUpsertWithoutSimCardsInput = {
  create: CustomerCreateWithoutSimCardsInput;
  update: CustomerUpdateWithoutSimCardsInput;
};

export type CustomerUpsertWithoutSopLoginInput = {
  create: CustomerCreateWithoutSopLoginInput;
  update: CustomerUpdateWithoutSopLoginInput;
};

export type CustomerUpsertWithoutSubscriptionsInput = {
  create: CustomerCreateWithoutSubscriptionsInput;
  update: CustomerUpdateWithoutSubscriptionsInput;
};

export type CustomerUpsertWithoutTeamsInput = {
  create: CustomerCreateWithoutTeamsInput;
  update: CustomerUpdateWithoutTeamsInput;
};

export type CustomerUpsertWithoutTermsAgreementInput = {
  create: CustomerCreateWithoutTermsAgreementInput;
  update: CustomerUpdateWithoutTermsAgreementInput;
};

export type CustomerUpsertWithoutUsersInput = {
  create: CustomerCreateWithoutUsersInput;
  update: CustomerUpdateWithoutUsersInput;
};

export type CustomerWhereInput = {
  AND?: InputMaybe<Array<CustomerWhereInput>>;
  NOT?: InputMaybe<Array<CustomerWhereInput>>;
  OR?: InputMaybe<Array<CustomerWhereInput>>;
  SOPIdentifier?: InputMaybe<StringNullableListFilter>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  contact?: InputMaybe<ContactWhereInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  demBundles?: InputMaybe<BundleOnUserListRelationFilter>;
  deviceGroups?: InputMaybe<DeviceGroupListRelationFilter>;
  deviceUsers?: InputMaybe<DeviceUserListRelationFilter>;
  devices?: InputMaybe<DeviceListRelationFilter>;
  distributor?: InputMaybe<DistributorWhereInput>;
  distributorId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFilter>;
  recycled?: InputMaybe<BoolFilter>;
  services?: InputMaybe<ServiceListRelationFilter>;
  settings?: InputMaybe<CustomerSettingWhereInput>;
  simCards?: InputMaybe<SimCardListRelationFilter>;
  sopLogin?: InputMaybe<SopLoginWhereInput>;
  stripeId?: InputMaybe<StringNullableFilter>;
  stripeSubscriptionIds?: InputMaybe<StringNullableListFilter>;
  subscriptions?: InputMaybe<SubscriptionListRelationFilter>;
  teams?: InputMaybe<TeamListRelationFilter>;
  termsAgreement?: InputMaybe<TermsAgreementWhereInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type CustomerWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type Dealer = {
  __typename?: 'Dealer';
  SOPIdentifier: Array<Scalars['String']>;
  availablePaymentMethods?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingAddress?: Maybe<StripeAddress>;
  contact?: Maybe<Contact>;
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  customers: Array<Customer>;
  dealer?: Maybe<Dealer>;
  dealerId?: Maybe<Scalars['String']>;
  dealerTier: Scalars['Int'];
  dealers: Array<Dealer>;
  demBundles: Array<BundleOnUser>;
  demCompanies?: Maybe<Array<Maybe<DemCompanyBasic>>>;
  devices: Array<Device>;
  distributor?: Maybe<Distributor>;
  distributorId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invoiceOnly: Scalars['Boolean'];
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  locale?: Maybe<StripeLocale>;
  name: Scalars['String'];
  nextInvoice?: Maybe<Invoice>;
  onboarding: OnboardingStepEnum;
  payments?: Maybe<Array<Maybe<Payment>>>;
  recycled: Scalars['Boolean'];
  services: Array<Service>;
  settings?: Maybe<DealerSetting>;
  shipping?: Maybe<StripeShipping>;
  simCards: Array<SimCard>;
  sopLogin?: Maybe<SopLogin>;
  status?: Maybe<AccountStatus>;
  stripeId?: Maybe<Scalars['String']>;
  termAgreement?: Maybe<TermsAgreement>;
  totalCustomers?: Maybe<Scalars['Int']>;
  totalDemoDeviceUsers?: Maybe<Scalars['Int']>;
  totalDemoDispatchUsers?: Maybe<Scalars['Int']>;
  totalDeviceUsers?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
  vatId?: Maybe<VatId>;
};

export type DealerCustomersArgs = {
  after?: InputMaybe<CustomerWhereUniqueInput>;
  before?: InputMaybe<CustomerWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DealerDealersArgs = {
  after?: InputMaybe<DealerWhereUniqueInput>;
  before?: InputMaybe<DealerWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DealerDemBundlesArgs = {
  after?: InputMaybe<BundleOnUserWhereUniqueInput>;
  before?: InputMaybe<BundleOnUserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DealerDevicesArgs = {
  after?: InputMaybe<DeviceWhereUniqueInput>;
  before?: InputMaybe<DeviceWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DealerServicesArgs = {
  after?: InputMaybe<ServiceWhereUniqueInput>;
  before?: InputMaybe<ServiceWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DealerSimCardsArgs = {
  after?: InputMaybe<SimCardWhereUniqueInput>;
  before?: InputMaybe<SimCardWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DealerUsersArgs = {
  after?: InputMaybe<UserWhereUniqueInput>;
  before?: InputMaybe<UserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type DealerCreateInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateManyDealerInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DealerCreateManyDealerInputEnvelope = {
  data?: InputMaybe<Array<DealerCreateManyDealerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DealerCreateManyDistributorInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DealerCreateManyDistributorInputEnvelope = {
  data?: InputMaybe<Array<DealerCreateManyDistributorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DealerCreateNestedManyWithoutDealerInput = {
  connect?: InputMaybe<Array<DealerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DealerCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<DealerCreateWithoutDealerInput>>;
  createMany?: InputMaybe<DealerCreateManyDealerInputEnvelope>;
};

export type DealerCreateNestedManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<DealerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DealerCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<DealerCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<DealerCreateManyDistributorInputEnvelope>;
};

export type DealerCreateNestedOneWithoutAddressesInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<DealerCreateWithoutAddressesInput>;
};

export type DealerCreateNestedOneWithoutContactInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<DealerCreateWithoutContactInput>;
};

export type DealerCreateNestedOneWithoutCustomersInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutCustomersInput>;
  create?: InputMaybe<DealerCreateWithoutCustomersInput>;
};

export type DealerCreateNestedOneWithoutDealersInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutDealersInput>;
  create?: InputMaybe<DealerCreateWithoutDealersInput>;
};

export type DealerCreateNestedOneWithoutDemBundlesInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutDemBundlesInput>;
  create?: InputMaybe<DealerCreateWithoutDemBundlesInput>;
};

export type DealerCreateNestedOneWithoutDevicesInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutDevicesInput>;
  create?: InputMaybe<DealerCreateWithoutDevicesInput>;
};

export type DealerCreateNestedOneWithoutServicesInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutServicesInput>;
  create?: InputMaybe<DealerCreateWithoutServicesInput>;
};

export type DealerCreateNestedOneWithoutSimCardsInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutSimCardsInput>;
  create?: InputMaybe<DealerCreateWithoutSimCardsInput>;
};

export type DealerCreateNestedOneWithoutSopLoginInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutSopLoginInput>;
  create?: InputMaybe<DealerCreateWithoutSopLoginInput>;
};

export type DealerCreateNestedOneWithoutSubscriptionsInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutSubscriptionsInput>;
  create?: InputMaybe<DealerCreateWithoutSubscriptionsInput>;
};

export type DealerCreateNestedOneWithoutTermAgreementInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutTermAgreementInput>;
  create?: InputMaybe<DealerCreateWithoutTermAgreementInput>;
};

export type DealerCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<DealerCreateWithoutUsersInput>;
};

export type DealerCreateOrConnectWithoutAddressesInput = {
  create: DealerCreateWithoutAddressesInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutContactInput = {
  create: DealerCreateWithoutContactInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutCustomersInput = {
  create: DealerCreateWithoutCustomersInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutDealerInput = {
  create: DealerCreateWithoutDealerInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutDealersInput = {
  create: DealerCreateWithoutDealersInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutDemBundlesInput = {
  create: DealerCreateWithoutDemBundlesInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutDevicesInput = {
  create: DealerCreateWithoutDevicesInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutDistributorInput = {
  create: DealerCreateWithoutDistributorInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutServicesInput = {
  create: DealerCreateWithoutServicesInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutSimCardsInput = {
  create: DealerCreateWithoutSimCardsInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutSopLoginInput = {
  create: DealerCreateWithoutSopLoginInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutSubscriptionsInput = {
  create: DealerCreateWithoutSubscriptionsInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutTermAgreementInput = {
  create: DealerCreateWithoutTermAgreementInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateOrConnectWithoutUsersInput = {
  create: DealerCreateWithoutUsersInput;
  where: DealerWhereUniqueInput;
};

export type DealerCreateSopIdentifierInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type DealerCreateWithoutAddressesInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutContactInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutCustomersInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutDealerInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutDealersInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutDemBundlesInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutDevicesInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutDistributorInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutServicesInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutSimCardsInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutSopLoginInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutSubscriptionsInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutTermAgreementInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDealerInput>;
};

export type DealerCreateWithoutUsersInput = {
  SOPIdentifier?: InputMaybe<DealerCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDealerInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDealerInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDealersInput>;
  dealerTier?: InputMaybe<Scalars['Int']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutDealersInput>;
  id?: InputMaybe<Scalars['String']>;
  invoiceOnly?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  notifiyOnSimStatus?: InputMaybe<Scalars['Boolean']>;
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  services?: InputMaybe<ServiceCreateNestedManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingCreateNestedOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDealerInput>;
  skipSims?: InputMaybe<Scalars['Boolean']>;
  sopLogin?: InputMaybe<SopLoginCreateNestedOneWithoutDealerInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DealerCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDealerInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DealerCreatestripeSubscriptionIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

/** Data to be submitted for dealerDetailsOnboarding mutation. */
export type DealerDetailsOnboardingInput = {
  billingAddress: StripeAddressInput;
  contact: ContactCreateNestedOneWithoutDealerInput;
  currency: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
};

export type DealerListRelationFilter = {
  every?: InputMaybe<DealerWhereInput>;
  none?: InputMaybe<DealerWhereInput>;
  some?: InputMaybe<DealerWhereInput>;
};

export type DealerScalarWhereInput = {
  AND?: InputMaybe<Array<DealerScalarWhereInput>>;
  NOT?: InputMaybe<Array<DealerScalarWhereInput>>;
  OR?: InputMaybe<Array<DealerScalarWhereInput>>;
  SOPIdentifier?: InputMaybe<StringNullableListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringNullableFilter>;
  dealerId?: InputMaybe<StringNullableFilter>;
  dealerTier?: InputMaybe<IntFilter>;
  distributorId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoiceOnly?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  notifiyOnSimStatus?: InputMaybe<BoolFilter>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFilter>;
  recycled?: InputMaybe<BoolFilter>;
  skipSims?: InputMaybe<BoolFilter>;
  stripeId?: InputMaybe<StringNullableFilter>;
  stripeSubscriptionIds?: InputMaybe<StringNullableListFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DealerSetting = {
  __typename?: 'DealerSetting';
  createdAt: Scalars['DateTime'];
  dateFormat?: Maybe<Scalars['String']>;
  dealer: Dealer;
  dealerId: Scalars['String'];
  id: Scalars['String'];
  recycled: Scalars['Boolean'];
  timeFormat?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  voiceQuality: VoiceQualityEnum;
};

export type DealerSettingCreateNestedOneWithoutDealerInput = {
  connect?: InputMaybe<DealerSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerSettingCreateOrConnectWithoutDealerInput>;
  create?: InputMaybe<DealerSettingCreateWithoutDealerInput>;
};

export type DealerSettingCreateOrConnectWithoutDealerInput = {
  create: DealerSettingCreateWithoutDealerInput;
  where: DealerSettingWhereUniqueInput;
};

export type DealerSettingCreateWithoutDealerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateFormat?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  timeFormat?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  voiceQuality: VoiceQualityEnum;
};

export type DealerSettingUpdateOneWithoutDealerInput = {
  connect?: InputMaybe<DealerSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerSettingCreateOrConnectWithoutDealerInput>;
  create?: InputMaybe<DealerSettingCreateWithoutDealerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerSettingUpdateWithoutDealerInput>;
  upsert?: InputMaybe<DealerSettingUpsertWithoutDealerInput>;
};

export type DealerSettingUpdateWithoutDealerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateFormat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  timeFormat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeZone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  voiceQuality?: InputMaybe<EnumVoiceQualityEnumFieldUpdateOperationsInput>;
};

export type DealerSettingUpsertWithoutDealerInput = {
  create: DealerSettingCreateWithoutDealerInput;
  update: DealerSettingUpdateWithoutDealerInput;
};

export type DealerSettingWhereInput = {
  AND?: InputMaybe<Array<DealerSettingWhereInput>>;
  NOT?: InputMaybe<Array<DealerSettingWhereInput>>;
  OR?: InputMaybe<Array<DealerSettingWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateFormat?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  timeFormat?: InputMaybe<StringNullableFilter>;
  timeZone?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  voiceQuality?: InputMaybe<EnumVoiceQualityEnumFilter>;
};

export type DealerSettingWhereUniqueInput = {
  dealerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type DealerUpdateInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateManyMutationInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DealerUpdateManyWithWhereWithoutDealerInput = {
  data: DealerUpdateManyMutationInput;
  where: DealerScalarWhereInput;
};

export type DealerUpdateManyWithWhereWithoutDistributorInput = {
  data: DealerUpdateManyMutationInput;
  where: DealerScalarWhereInput;
};

export type DealerUpdateManyWithoutDealerInput = {
  connect?: InputMaybe<Array<DealerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DealerCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<DealerCreateWithoutDealerInput>>;
  createMany?: InputMaybe<DealerCreateManyDealerInputEnvelope>;
  delete?: InputMaybe<Array<DealerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DealerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DealerWhereUniqueInput>>;
  set?: InputMaybe<Array<DealerWhereUniqueInput>>;
  update?: InputMaybe<Array<DealerUpdateWithWhereUniqueWithoutDealerInput>>;
  updateMany?: InputMaybe<Array<DealerUpdateManyWithWhereWithoutDealerInput>>;
  upsert?: InputMaybe<Array<DealerUpsertWithWhereUniqueWithoutDealerInput>>;
};

export type DealerUpdateManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<DealerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DealerCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<DealerCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<DealerCreateManyDistributorInputEnvelope>;
  delete?: InputMaybe<Array<DealerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DealerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DealerWhereUniqueInput>>;
  set?: InputMaybe<Array<DealerWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DealerUpdateWithWhereUniqueWithoutDistributorInput>
  >;
  updateMany?: InputMaybe<
    Array<DealerUpdateManyWithWhereWithoutDistributorInput>
  >;
  upsert?: InputMaybe<
    Array<DealerUpsertWithWhereUniqueWithoutDistributorInput>
  >;
};

export type DealerUpdateOneWithoutAddressesInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<DealerCreateWithoutAddressesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutAddressesInput>;
  upsert?: InputMaybe<DealerUpsertWithoutAddressesInput>;
};

export type DealerUpdateOneWithoutContactInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<DealerCreateWithoutContactInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutContactInput>;
  upsert?: InputMaybe<DealerUpsertWithoutContactInput>;
};

export type DealerUpdateOneWithoutCustomersInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutCustomersInput>;
  create?: InputMaybe<DealerCreateWithoutCustomersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutCustomersInput>;
  upsert?: InputMaybe<DealerUpsertWithoutCustomersInput>;
};

export type DealerUpdateOneWithoutDealersInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutDealersInput>;
  create?: InputMaybe<DealerCreateWithoutDealersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutDealersInput>;
  upsert?: InputMaybe<DealerUpsertWithoutDealersInput>;
};

export type DealerUpdateOneWithoutDemBundlesInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutDemBundlesInput>;
  create?: InputMaybe<DealerCreateWithoutDemBundlesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutDemBundlesInput>;
  upsert?: InputMaybe<DealerUpsertWithoutDemBundlesInput>;
};

export type DealerUpdateOneWithoutDevicesInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutDevicesInput>;
  create?: InputMaybe<DealerCreateWithoutDevicesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutDevicesInput>;
  upsert?: InputMaybe<DealerUpsertWithoutDevicesInput>;
};

export type DealerUpdateOneWithoutServicesInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutServicesInput>;
  create?: InputMaybe<DealerCreateWithoutServicesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutServicesInput>;
  upsert?: InputMaybe<DealerUpsertWithoutServicesInput>;
};

export type DealerUpdateOneWithoutSimCardsInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutSimCardsInput>;
  create?: InputMaybe<DealerCreateWithoutSimCardsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutSimCardsInput>;
  upsert?: InputMaybe<DealerUpsertWithoutSimCardsInput>;
};

export type DealerUpdateOneWithoutSopLoginInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutSopLoginInput>;
  create?: InputMaybe<DealerCreateWithoutSopLoginInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutSopLoginInput>;
  upsert?: InputMaybe<DealerUpsertWithoutSopLoginInput>;
};

export type DealerUpdateOneWithoutSubscriptionsInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutSubscriptionsInput>;
  create?: InputMaybe<DealerCreateWithoutSubscriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutSubscriptionsInput>;
  upsert?: InputMaybe<DealerUpsertWithoutSubscriptionsInput>;
};

export type DealerUpdateOneWithoutTermAgreementInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutTermAgreementInput>;
  create?: InputMaybe<DealerCreateWithoutTermAgreementInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutTermAgreementInput>;
  upsert?: InputMaybe<DealerUpsertWithoutTermAgreementInput>;
};

export type DealerUpdateOneWithoutUsersInput = {
  connect?: InputMaybe<DealerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DealerCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<DealerCreateWithoutUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DealerUpdateWithoutUsersInput>;
  upsert?: InputMaybe<DealerUpsertWithoutUsersInput>;
};

export type DealerUpdateSopIdentifierInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type DealerUpdateWithWhereUniqueWithoutDealerInput = {
  data: DealerUpdateWithoutDealerInput;
  where: DealerWhereUniqueInput;
};

export type DealerUpdateWithWhereUniqueWithoutDistributorInput = {
  data: DealerUpdateWithoutDistributorInput;
  where: DealerWhereUniqueInput;
};

export type DealerUpdateWithoutAddressesInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutContactInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutCustomersInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutDealerInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutDealersInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutDemBundlesInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutDevicesInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutDistributorInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutServicesInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutSimCardsInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutSopLoginInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutSubscriptionsInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutTermAgreementInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDealerInput>;
};

export type DealerUpdateWithoutUsersInput = {
  SOPIdentifier?: InputMaybe<DealerUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDealerInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDealerInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDealerInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDealersInput>;
  dealerTier?: InputMaybe<IntFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDealerInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDealerInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDealerInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutDealersInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invoiceOnly?: InputMaybe<BoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  notifiyOnSimStatus?: InputMaybe<BoolFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  services?: InputMaybe<ServiceUpdateManyWithoutDealerInput>;
  settings?: InputMaybe<DealerSettingUpdateOneWithoutDealerInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDealerInput>;
  skipSims?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sopLogin?: InputMaybe<SopLoginUpdateOneWithoutDealerInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DealerUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDealerInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDealerInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DealerUpdatestripeSubscriptionIdsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type DealerUpsertWithWhereUniqueWithoutDealerInput = {
  create: DealerCreateWithoutDealerInput;
  update: DealerUpdateWithoutDealerInput;
  where: DealerWhereUniqueInput;
};

export type DealerUpsertWithWhereUniqueWithoutDistributorInput = {
  create: DealerCreateWithoutDistributorInput;
  update: DealerUpdateWithoutDistributorInput;
  where: DealerWhereUniqueInput;
};

export type DealerUpsertWithoutAddressesInput = {
  create: DealerCreateWithoutAddressesInput;
  update: DealerUpdateWithoutAddressesInput;
};

export type DealerUpsertWithoutContactInput = {
  create: DealerCreateWithoutContactInput;
  update: DealerUpdateWithoutContactInput;
};

export type DealerUpsertWithoutCustomersInput = {
  create: DealerCreateWithoutCustomersInput;
  update: DealerUpdateWithoutCustomersInput;
};

export type DealerUpsertWithoutDealersInput = {
  create: DealerCreateWithoutDealersInput;
  update: DealerUpdateWithoutDealersInput;
};

export type DealerUpsertWithoutDemBundlesInput = {
  create: DealerCreateWithoutDemBundlesInput;
  update: DealerUpdateWithoutDemBundlesInput;
};

export type DealerUpsertWithoutDevicesInput = {
  create: DealerCreateWithoutDevicesInput;
  update: DealerUpdateWithoutDevicesInput;
};

export type DealerUpsertWithoutServicesInput = {
  create: DealerCreateWithoutServicesInput;
  update: DealerUpdateWithoutServicesInput;
};

export type DealerUpsertWithoutSimCardsInput = {
  create: DealerCreateWithoutSimCardsInput;
  update: DealerUpdateWithoutSimCardsInput;
};

export type DealerUpsertWithoutSopLoginInput = {
  create: DealerCreateWithoutSopLoginInput;
  update: DealerUpdateWithoutSopLoginInput;
};

export type DealerUpsertWithoutSubscriptionsInput = {
  create: DealerCreateWithoutSubscriptionsInput;
  update: DealerUpdateWithoutSubscriptionsInput;
};

export type DealerUpsertWithoutTermAgreementInput = {
  create: DealerCreateWithoutTermAgreementInput;
  update: DealerUpdateWithoutTermAgreementInput;
};

export type DealerUpsertWithoutUsersInput = {
  create: DealerCreateWithoutUsersInput;
  update: DealerUpdateWithoutUsersInput;
};

export type DealerVatOnboardingInput = {
  vatId: Scalars['String'];
};

export type DealerWhereInput = {
  AND?: InputMaybe<Array<DealerWhereInput>>;
  NOT?: InputMaybe<Array<DealerWhereInput>>;
  OR?: InputMaybe<Array<DealerWhereInput>>;
  SOPIdentifier?: InputMaybe<StringNullableListFilter>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  contact?: InputMaybe<ContactWhereInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringNullableFilter>;
  customers?: InputMaybe<CustomerListRelationFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  dealerTier?: InputMaybe<IntFilter>;
  dealers?: InputMaybe<DealerListRelationFilter>;
  demBundles?: InputMaybe<BundleOnUserListRelationFilter>;
  devices?: InputMaybe<DeviceListRelationFilter>;
  distributor?: InputMaybe<DistributorWhereInput>;
  distributorId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoiceOnly?: InputMaybe<BoolFilter>;
  name?: InputMaybe<StringFilter>;
  notifiyOnSimStatus?: InputMaybe<BoolFilter>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFilter>;
  recycled?: InputMaybe<BoolFilter>;
  services?: InputMaybe<ServiceListRelationFilter>;
  settings?: InputMaybe<DealerSettingWhereInput>;
  simCards?: InputMaybe<SimCardListRelationFilter>;
  skipSims?: InputMaybe<BoolFilter>;
  sopLogin?: InputMaybe<SopLoginWhereInput>;
  stripeId?: InputMaybe<StringNullableFilter>;
  stripeSubscriptionIds?: InputMaybe<StringNullableListFilter>;
  subscriptions?: InputMaybe<SubscriptionListRelationFilter>;
  termAgreement?: InputMaybe<TermsAgreementWhereInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type DealerWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeleteUserInput = {
  id: Scalars['String'];
};

export type DemBundle = {
  __typename?: 'DemBundle';
  createdAt: Scalars['DateTime'];
  data: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Int'];
  stripeProductId: Scalars['String'];
};

export type DemBundleCreateInput = {
  bundlesOnUsers?: InputMaybe<BundleOnUserCreateNestedManyWithoutBundleInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  stripeProductId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DemBundleCreateNestedOneWithoutBundlesOnUsersInput = {
  connect?: InputMaybe<DemBundleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DemBundleCreateOrConnectWithoutBundlesOnUsersInput>;
  create?: InputMaybe<DemBundleCreateWithoutBundlesOnUsersInput>;
};

export type DemBundleCreateOrConnectWithoutBundlesOnUsersInput = {
  create: DemBundleCreateWithoutBundlesOnUsersInput;
  where: DemBundleWhereUniqueInput;
};

export type DemBundleCreateWithoutBundlesOnUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  stripeProductId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DemBundleUpdateOneWithoutBundlesOnUsersInput = {
  connect?: InputMaybe<DemBundleWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DemBundleCreateOrConnectWithoutBundlesOnUsersInput>;
  create?: InputMaybe<DemBundleCreateWithoutBundlesOnUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DemBundleUpdateWithoutBundlesOnUsersInput>;
  upsert?: InputMaybe<DemBundleUpsertWithoutBundlesOnUsersInput>;
};

export type DemBundleUpdateWithoutBundlesOnUsersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<IntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DemBundleUpsertWithoutBundlesOnUsersInput = {
  create: DemBundleCreateWithoutBundlesOnUsersInput;
  update: DemBundleUpdateWithoutBundlesOnUsersInput;
};

export type DemBundleWhereInput = {
  AND?: InputMaybe<Array<DemBundleWhereInput>>;
  NOT?: InputMaybe<Array<DemBundleWhereInput>>;
  OR?: InputMaybe<Array<DemBundleWhereInput>>;
  bundlesOnUsers?: InputMaybe<BundleOnUserListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  stripeProductId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DemBundleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DemCompanies = {
  __typename?: 'DemCompanies';
  company?: Maybe<Array<Maybe<DemCompany>>>;
  demUsers?: Maybe<Array<Maybe<DemUsers>>>;
  demoGbAvailable: Scalars['Float'];
  devices?: Maybe<Array<Maybe<DemDevices>>>;
  id: Scalars['String'];
  numberOfDemo: Scalars['Int'];
  totalGbUsed: Scalars['Int'];
};

export type DemCompany = {
  __typename?: 'DemCompany';
  demGroups?: Maybe<Array<Maybe<DemGroup>>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
};

export type DemCompanyBasic = {
  __typename?: 'DemCompanyBasic';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type DemCompanyDetails = {
  __typename?: 'DemCompanyDetails';
  demGroups?: Maybe<Array<Maybe<DemGroup>>>;
  device?: Maybe<Array<Maybe<DemDevices>>>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<DemUsers>>>;
};

export type DemDevices = {
  __typename?: 'DemDevices';
  company?: Maybe<DemCompany>;
  createdAt?: Maybe<Scalars['String']>;
  dataUsed?: Maybe<Scalars['Float']>;
  demGroups?: Maybe<Array<Maybe<DemGroup>>>;
  demo?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  model?: Maybe<DemModel>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type DemGroup = {
  __typename?: 'DemGroup';
  groupId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type DemGroupsWhereInput = {
  companies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['String']>;
};

export type DemModel = {
  __typename?: 'DemModel';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type DemUserInput = {
  companyId: Array<InputMaybe<Scalars['String']>>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DemUserWhereInput = {
  companies?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  currentCompany?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type DemUsers = {
  __typename?: 'DemUsers';
  companies?: Maybe<Array<Maybe<DemCompany>>>;
  companyRoles?: Maybe<Array<Maybe<CompanyRoles>>>;
  demGroupOnUser?: Maybe<Array<Maybe<DemGroup>>>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};

export type DemoDeviceInput = {
  id: Scalars['String'];
  isDemo: Scalars['Boolean'];
};

export type Device = {
  __typename?: 'Device';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  recycled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type DeviceCreateManyCustomerInput = {
  assetTag?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerId?: InputMaybe<Scalars['String']>;
  deviceUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imeiNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  stripeInvoiceIds?: InputMaybe<DeviceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceCreateManyCustomerInputEnvelope = {
  data?: InputMaybe<Array<DeviceCreateManyCustomerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceCreateManyDealerInput = {
  assetTag?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  deviceUserId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imeiNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  stripeInvoiceIds?: InputMaybe<DeviceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceCreateManyDealerInputEnvelope = {
  data?: InputMaybe<Array<DeviceCreateManyDealerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceCreateManyDeviceUserInput = {
  assetTag?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  imeiNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  stripeInvoiceIds?: InputMaybe<DeviceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceCreateManyDeviceUserInputEnvelope = {
  data?: InputMaybe<Array<DeviceCreateManyDeviceUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<DeviceCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<DeviceCreateManyCustomerInputEnvelope>;
};

export type DeviceCreateNestedManyWithoutDealerInput = {
  connect?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DeviceCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<DeviceCreateWithoutDealerInput>>;
  createMany?: InputMaybe<DeviceCreateManyDealerInputEnvelope>;
};

export type DeviceCreateNestedManyWithoutDeviceUserInput = {
  connect?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceCreateOrConnectWithoutDeviceUserInput>
  >;
  create?: InputMaybe<Array<DeviceCreateWithoutDeviceUserInput>>;
  createMany?: InputMaybe<DeviceCreateManyDeviceUserInputEnvelope>;
};

export type DeviceCreateNestedOneWithoutSimCardsInput = {
  connect?: InputMaybe<DeviceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceCreateOrConnectWithoutSimCardsInput>;
  create?: InputMaybe<DeviceCreateWithoutSimCardsInput>;
};

export type DeviceCreateOrConnectWithoutCustomerInput = {
  create: DeviceCreateWithoutCustomerInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceCreateOrConnectWithoutDealerInput = {
  create: DeviceCreateWithoutDealerInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceCreateOrConnectWithoutDeviceUserInput = {
  create: DeviceCreateWithoutDeviceUserInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceCreateOrConnectWithoutSimCardsInput = {
  create: DeviceCreateWithoutSimCardsInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceCreateWithoutCustomerInput = {
  assetTag?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDevicesInput>;
  deviceUser?: InputMaybe<DeviceUserCreateNestedOneWithoutDevicesInput>;
  id?: InputMaybe<Scalars['String']>;
  imeiNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceInput>;
  stripeInvoiceIds?: InputMaybe<DeviceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceCreateWithoutDealerInput = {
  assetTag?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDevicesInput>;
  deviceUser?: InputMaybe<DeviceUserCreateNestedOneWithoutDevicesInput>;
  id?: InputMaybe<Scalars['String']>;
  imeiNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceInput>;
  stripeInvoiceIds?: InputMaybe<DeviceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceCreateWithoutDeviceUserInput = {
  assetTag?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDevicesInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDevicesInput>;
  id?: InputMaybe<Scalars['String']>;
  imeiNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceInput>;
  stripeInvoiceIds?: InputMaybe<DeviceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceCreateWithoutSimCardsInput = {
  assetTag?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDevicesInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutDevicesInput>;
  deviceUser?: InputMaybe<DeviceUserCreateNestedOneWithoutDevicesInput>;
  id?: InputMaybe<Scalars['String']>;
  imeiNumber?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  serialNumber?: InputMaybe<Scalars['String']>;
  stripeInvoiceIds?: InputMaybe<DeviceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceCreatestripeInvoiceIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type DeviceGroup = {
  __typename?: 'DeviceGroup';
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  deviceUsers: Array<DeviceUserGroup>;
  floorQueue: Scalars['Boolean'];
  groupId: Scalars['String'];
  groupType: DeviceGroupTypeEnum;
  id: Scalars['String'];
  name: Scalars['String'];
  recycled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type DeviceGroupDeviceUsersArgs = {
  after?: InputMaybe<DeviceUserGroupWhereUniqueInput>;
  before?: InputMaybe<DeviceUserGroupWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeviceGroupCreateManyCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  floorQueue: Scalars['Boolean'];
  groupId: Scalars['String'];
  groupType: DeviceGroupTypeEnum;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceGroupCreateManyCustomerInputEnvelope = {
  data?: InputMaybe<Array<DeviceGroupCreateManyCustomerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceGroupCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<DeviceGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceGroupCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<DeviceGroupCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<DeviceGroupCreateManyCustomerInputEnvelope>;
};

export type DeviceGroupCreateNestedOneWithoutDeviceUsersInput = {
  connect?: InputMaybe<DeviceGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceGroupCreateOrConnectWithoutDeviceUsersInput>;
  create?: InputMaybe<DeviceGroupCreateWithoutDeviceUsersInput>;
};

export type DeviceGroupCreateOrConnectWithoutCustomerInput = {
  create: DeviceGroupCreateWithoutCustomerInput;
  where: DeviceGroupWhereUniqueInput;
};

export type DeviceGroupCreateOrConnectWithoutDeviceUsersInput = {
  create: DeviceGroupCreateWithoutDeviceUsersInput;
  where: DeviceGroupWhereUniqueInput;
};

export type DeviceGroupCreateWithoutCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceUsers?: InputMaybe<DeviceUserGroupCreateNestedManyWithoutDeviceGroupInput>;
  floorQueue: Scalars['Boolean'];
  groupId: Scalars['String'];
  groupType: DeviceGroupTypeEnum;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceGroupCreateWithoutDeviceUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDeviceGroupsInput>;
  floorQueue: Scalars['Boolean'];
  groupId: Scalars['String'];
  groupType: DeviceGroupTypeEnum;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceGroupListRelationFilter = {
  every?: InputMaybe<DeviceGroupWhereInput>;
  none?: InputMaybe<DeviceGroupWhereInput>;
  some?: InputMaybe<DeviceGroupWhereInput>;
};

export type DeviceGroupScalarWhereInput = {
  AND?: InputMaybe<Array<DeviceGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeviceGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<DeviceGroupScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  floorQueue?: InputMaybe<BoolFilter>;
  groupId?: InputMaybe<StringFilter>;
  groupType?: InputMaybe<EnumDeviceGroupTypeEnumFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum DeviceGroupTypeEnum {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC',
}

export type DeviceGroupUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  floorQueue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  groupId?: InputMaybe<StringFieldUpdateOperationsInput>;
  groupType?: InputMaybe<EnumDeviceGroupTypeEnumFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceGroupUpdateManyWithWhereWithoutCustomerInput = {
  data: DeviceGroupUpdateManyMutationInput;
  where: DeviceGroupScalarWhereInput;
};

export type DeviceGroupUpdateManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<DeviceGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceGroupCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<DeviceGroupCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<DeviceGroupCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<DeviceGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceGroupWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeviceGroupUpdateWithWhereUniqueWithoutCustomerInput>
  >;
  updateMany?: InputMaybe<
    Array<DeviceGroupUpdateManyWithWhereWithoutCustomerInput>
  >;
  upsert?: InputMaybe<
    Array<DeviceGroupUpsertWithWhereUniqueWithoutCustomerInput>
  >;
};

export type DeviceGroupUpdateOneRequiredWithoutDeviceUsersInput = {
  connect?: InputMaybe<DeviceGroupWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceGroupCreateOrConnectWithoutDeviceUsersInput>;
  create?: InputMaybe<DeviceGroupCreateWithoutDeviceUsersInput>;
  update?: InputMaybe<DeviceGroupUpdateWithoutDeviceUsersInput>;
  upsert?: InputMaybe<DeviceGroupUpsertWithoutDeviceUsersInput>;
};

export type DeviceGroupUpdateWithWhereUniqueWithoutCustomerInput = {
  data: DeviceGroupUpdateWithoutCustomerInput;
  where: DeviceGroupWhereUniqueInput;
};

export type DeviceGroupUpdateWithoutCustomerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deviceUsers?: InputMaybe<DeviceUserGroupUpdateManyWithoutDeviceGroupInput>;
  floorQueue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  groupId?: InputMaybe<StringFieldUpdateOperationsInput>;
  groupType?: InputMaybe<EnumDeviceGroupTypeEnumFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceGroupUpdateWithoutDeviceUsersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDeviceGroupsInput>;
  floorQueue?: InputMaybe<BoolFieldUpdateOperationsInput>;
  groupId?: InputMaybe<StringFieldUpdateOperationsInput>;
  groupType?: InputMaybe<EnumDeviceGroupTypeEnumFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceGroupUpsertWithWhereUniqueWithoutCustomerInput = {
  create: DeviceGroupCreateWithoutCustomerInput;
  update: DeviceGroupUpdateWithoutCustomerInput;
  where: DeviceGroupWhereUniqueInput;
};

export type DeviceGroupUpsertWithoutDeviceUsersInput = {
  create: DeviceGroupCreateWithoutDeviceUsersInput;
  update: DeviceGroupUpdateWithoutDeviceUsersInput;
};

export type DeviceGroupWhereInput = {
  AND?: InputMaybe<Array<DeviceGroupWhereInput>>;
  NOT?: InputMaybe<Array<DeviceGroupWhereInput>>;
  OR?: InputMaybe<Array<DeviceGroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  deviceUsers?: InputMaybe<DeviceUserGroupListRelationFilter>;
  floorQueue?: InputMaybe<BoolFilter>;
  groupId?: InputMaybe<StringFilter>;
  groupType?: InputMaybe<EnumDeviceGroupTypeEnumFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeviceGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeviceListRelationFilter = {
  every?: InputMaybe<DeviceWhereInput>;
  none?: InputMaybe<DeviceWhereInput>;
  some?: InputMaybe<DeviceWhereInput>;
};

export type DeviceModel = {
  __typename?: 'DeviceModel';
  createdAt: Scalars['DateTime'];
  downloads: Array<Download>;
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DeviceModelDownloadsArgs = {
  after?: InputMaybe<DownloadWhereUniqueInput>;
  before?: InputMaybe<DownloadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeviceModelCreateNestedManyWithoutDownloadsInput = {
  connect?: InputMaybe<Array<DeviceModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceModelCreateOrConnectWithoutDownloadsInput>
  >;
  create?: InputMaybe<Array<DeviceModelCreateWithoutDownloadsInput>>;
};

export type DeviceModelCreateOrConnectWithoutDownloadsInput = {
  create: DeviceModelCreateWithoutDownloadsInput;
  where: DeviceModelWhereUniqueInput;
};

export type DeviceModelCreateWithoutDownloadsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceModelScalarWhereInput = {
  AND?: InputMaybe<Array<DeviceModelScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeviceModelScalarWhereInput>>;
  OR?: InputMaybe<Array<DeviceModelScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeviceModelUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceModelUpdateManyWithWhereWithoutDownloadsInput = {
  data: DeviceModelUpdateManyMutationInput;
  where: DeviceModelScalarWhereInput;
};

export type DeviceModelUpdateManyWithoutDownloadsInput = {
  connect?: InputMaybe<Array<DeviceModelWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceModelCreateOrConnectWithoutDownloadsInput>
  >;
  create?: InputMaybe<Array<DeviceModelCreateWithoutDownloadsInput>>;
  delete?: InputMaybe<Array<DeviceModelWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceModelScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceModelWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceModelWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeviceModelUpdateWithWhereUniqueWithoutDownloadsInput>
  >;
  updateMany?: InputMaybe<
    Array<DeviceModelUpdateManyWithWhereWithoutDownloadsInput>
  >;
  upsert?: InputMaybe<
    Array<DeviceModelUpsertWithWhereUniqueWithoutDownloadsInput>
  >;
};

export type DeviceModelUpdateWithWhereUniqueWithoutDownloadsInput = {
  data: DeviceModelUpdateWithoutDownloadsInput;
  where: DeviceModelWhereUniqueInput;
};

export type DeviceModelUpdateWithoutDownloadsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceModelUpsertWithWhereUniqueWithoutDownloadsInput = {
  create: DeviceModelCreateWithoutDownloadsInput;
  update: DeviceModelUpdateWithoutDownloadsInput;
  where: DeviceModelWhereUniqueInput;
};

export type DeviceModelWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeviceScalarWhereInput = {
  AND?: InputMaybe<Array<DeviceScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeviceScalarWhereInput>>;
  OR?: InputMaybe<Array<DeviceScalarWhereInput>>;
  assetTag?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealerId?: InputMaybe<StringNullableFilter>;
  deviceUserId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  imeiNumber?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  serialNumber?: InputMaybe<StringNullableFilter>;
  stripeInvoiceIds?: InputMaybe<StringNullableListFilter>;
  stripeProductId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeviceTemplate = {
  __typename?: 'DeviceTemplate';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  deviceUsers: Array<DeviceUser>;
  dynamicGrouping?: Maybe<Scalars['Boolean']>;
  emergencyCall?: Maybe<Scalars['Boolean']>;
  floorQueuing?: Maybe<Scalars['Boolean']>;
  fullDuplexPrivate?: Maybe<Scalars['Boolean']>;
  groupCall?: Maybe<Scalars['Boolean']>;
  halfDuplexPrivate?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  interruptFloor?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['Boolean']>;
  multicastCall?: Maybe<Scalars['Boolean']>;
  multimediaData?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  passwordAuth?: Maybe<Scalars['Boolean']>;
  permissionType: PermissionTypeEnum;
  products: Array<Product>;
  realTimeShort?: Maybe<Scalars['Boolean']>;
  recycled: Scalars['Boolean'];
  requestFloor?: Maybe<Scalars['Boolean']>;
  serialNumAuth?: Maybe<Scalars['Boolean']>;
  statusMessage?: Maybe<Scalars['Boolean']>;
  stripeProductId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  videoGroup?: Maybe<Scalars['Boolean']>;
  videoPrivate?: Maybe<Scalars['Boolean']>;
  videoPull?: Maybe<Scalars['Boolean']>;
  videoUpload?: Maybe<Scalars['Boolean']>;
};

export type DeviceTemplateDeviceUsersArgs = {
  after?: InputMaybe<DeviceUserWhereUniqueInput>;
  before?: InputMaybe<DeviceUserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeviceTemplateProductsArgs = {
  after?: InputMaybe<ProductWhereUniqueInput>;
  before?: InputMaybe<ProductWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeviceTemplateCreateInput = {
  SOPIdentifier: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutDeviceTemplatesInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutDeviceTemplateInput>;
  dynamicGrouping?: InputMaybe<Scalars['Boolean']>;
  emergencyCall?: InputMaybe<Scalars['Boolean']>;
  floorQueuing?: InputMaybe<Scalars['Boolean']>;
  fullDuplexPrivate?: InputMaybe<Scalars['Boolean']>;
  groupCall?: InputMaybe<Scalars['Boolean']>;
  halfDuplexPrivate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  interruptFloor?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  multicastCall?: InputMaybe<Scalars['Boolean']>;
  multicastShort?: InputMaybe<Scalars['Boolean']>;
  multimediaData?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  passwordAuth?: InputMaybe<Scalars['Boolean']>;
  permissionType: PermissionTypeEnum;
  products?: InputMaybe<ProductCreateNestedManyWithoutDeviceTemplateInput>;
  realTimeShort?: InputMaybe<Scalars['Boolean']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  requestFloor?: InputMaybe<Scalars['Boolean']>;
  serialNumAuth?: InputMaybe<Scalars['Boolean']>;
  statusMessage?: InputMaybe<Scalars['Boolean']>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoGroup?: InputMaybe<Scalars['Boolean']>;
  videoPrivate?: InputMaybe<Scalars['Boolean']>;
  videoPull?: InputMaybe<Scalars['Boolean']>;
  videoUpload?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTemplateCreateManyCreatedByInput = {
  SOPIdentifier: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dynamicGrouping?: InputMaybe<Scalars['Boolean']>;
  emergencyCall?: InputMaybe<Scalars['Boolean']>;
  floorQueuing?: InputMaybe<Scalars['Boolean']>;
  fullDuplexPrivate?: InputMaybe<Scalars['Boolean']>;
  groupCall?: InputMaybe<Scalars['Boolean']>;
  halfDuplexPrivate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  interruptFloor?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  multicastCall?: InputMaybe<Scalars['Boolean']>;
  multicastShort?: InputMaybe<Scalars['Boolean']>;
  multimediaData?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  passwordAuth?: InputMaybe<Scalars['Boolean']>;
  permissionType: PermissionTypeEnum;
  realTimeShort?: InputMaybe<Scalars['Boolean']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  requestFloor?: InputMaybe<Scalars['Boolean']>;
  serialNumAuth?: InputMaybe<Scalars['Boolean']>;
  statusMessage?: InputMaybe<Scalars['Boolean']>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoGroup?: InputMaybe<Scalars['Boolean']>;
  videoPrivate?: InputMaybe<Scalars['Boolean']>;
  videoPull?: InputMaybe<Scalars['Boolean']>;
  videoUpload?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTemplateCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<DeviceTemplateCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTemplateCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<DeviceTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceTemplateCreateOrConnectWithoutCreatedByInput>
  >;
  create?: InputMaybe<Array<DeviceTemplateCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<DeviceTemplateCreateManyCreatedByInputEnvelope>;
};

export type DeviceTemplateCreateNestedOneWithoutDeviceUsersInput = {
  connect?: InputMaybe<DeviceTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceTemplateCreateOrConnectWithoutDeviceUsersInput>;
  create?: InputMaybe<DeviceTemplateCreateWithoutDeviceUsersInput>;
};

export type DeviceTemplateCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<DeviceTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceTemplateCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<DeviceTemplateCreateWithoutProductsInput>;
};

export type DeviceTemplateCreateOrConnectWithoutCreatedByInput = {
  create: DeviceTemplateCreateWithoutCreatedByInput;
  where: DeviceTemplateWhereUniqueInput;
};

export type DeviceTemplateCreateOrConnectWithoutDeviceUsersInput = {
  create: DeviceTemplateCreateWithoutDeviceUsersInput;
  where: DeviceTemplateWhereUniqueInput;
};

export type DeviceTemplateCreateOrConnectWithoutProductsInput = {
  create: DeviceTemplateCreateWithoutProductsInput;
  where: DeviceTemplateWhereUniqueInput;
};

export type DeviceTemplateCreateWithoutCreatedByInput = {
  SOPIdentifier: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutDeviceTemplateInput>;
  dynamicGrouping?: InputMaybe<Scalars['Boolean']>;
  emergencyCall?: InputMaybe<Scalars['Boolean']>;
  floorQueuing?: InputMaybe<Scalars['Boolean']>;
  fullDuplexPrivate?: InputMaybe<Scalars['Boolean']>;
  groupCall?: InputMaybe<Scalars['Boolean']>;
  halfDuplexPrivate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  interruptFloor?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  multicastCall?: InputMaybe<Scalars['Boolean']>;
  multicastShort?: InputMaybe<Scalars['Boolean']>;
  multimediaData?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  passwordAuth?: InputMaybe<Scalars['Boolean']>;
  permissionType: PermissionTypeEnum;
  products?: InputMaybe<ProductCreateNestedManyWithoutDeviceTemplateInput>;
  realTimeShort?: InputMaybe<Scalars['Boolean']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  requestFloor?: InputMaybe<Scalars['Boolean']>;
  serialNumAuth?: InputMaybe<Scalars['Boolean']>;
  statusMessage?: InputMaybe<Scalars['Boolean']>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoGroup?: InputMaybe<Scalars['Boolean']>;
  videoPrivate?: InputMaybe<Scalars['Boolean']>;
  videoPull?: InputMaybe<Scalars['Boolean']>;
  videoUpload?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTemplateCreateWithoutDeviceUsersInput = {
  SOPIdentifier: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutDeviceTemplatesInput>;
  dynamicGrouping?: InputMaybe<Scalars['Boolean']>;
  emergencyCall?: InputMaybe<Scalars['Boolean']>;
  floorQueuing?: InputMaybe<Scalars['Boolean']>;
  fullDuplexPrivate?: InputMaybe<Scalars['Boolean']>;
  groupCall?: InputMaybe<Scalars['Boolean']>;
  halfDuplexPrivate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  interruptFloor?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  multicastCall?: InputMaybe<Scalars['Boolean']>;
  multicastShort?: InputMaybe<Scalars['Boolean']>;
  multimediaData?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  passwordAuth?: InputMaybe<Scalars['Boolean']>;
  permissionType: PermissionTypeEnum;
  products?: InputMaybe<ProductCreateNestedManyWithoutDeviceTemplateInput>;
  realTimeShort?: InputMaybe<Scalars['Boolean']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  requestFloor?: InputMaybe<Scalars['Boolean']>;
  serialNumAuth?: InputMaybe<Scalars['Boolean']>;
  statusMessage?: InputMaybe<Scalars['Boolean']>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoGroup?: InputMaybe<Scalars['Boolean']>;
  videoPrivate?: InputMaybe<Scalars['Boolean']>;
  videoPull?: InputMaybe<Scalars['Boolean']>;
  videoUpload?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTemplateCreateWithoutProductsInput = {
  SOPIdentifier: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutDeviceTemplatesInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutDeviceTemplateInput>;
  dynamicGrouping?: InputMaybe<Scalars['Boolean']>;
  emergencyCall?: InputMaybe<Scalars['Boolean']>;
  floorQueuing?: InputMaybe<Scalars['Boolean']>;
  fullDuplexPrivate?: InputMaybe<Scalars['Boolean']>;
  groupCall?: InputMaybe<Scalars['Boolean']>;
  halfDuplexPrivate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  interruptFloor?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<Scalars['Boolean']>;
  multicastCall?: InputMaybe<Scalars['Boolean']>;
  multicastShort?: InputMaybe<Scalars['Boolean']>;
  multimediaData?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  passwordAuth?: InputMaybe<Scalars['Boolean']>;
  permissionType: PermissionTypeEnum;
  realTimeShort?: InputMaybe<Scalars['Boolean']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  requestFloor?: InputMaybe<Scalars['Boolean']>;
  serialNumAuth?: InputMaybe<Scalars['Boolean']>;
  statusMessage?: InputMaybe<Scalars['Boolean']>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoGroup?: InputMaybe<Scalars['Boolean']>;
  videoPrivate?: InputMaybe<Scalars['Boolean']>;
  videoPull?: InputMaybe<Scalars['Boolean']>;
  videoUpload?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceTemplateListRelationFilter = {
  every?: InputMaybe<DeviceTemplateWhereInput>;
  none?: InputMaybe<DeviceTemplateWhereInput>;
  some?: InputMaybe<DeviceTemplateWhereInput>;
};

export type DeviceTemplateScalarWhereInput = {
  AND?: InputMaybe<Array<DeviceTemplateScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeviceTemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<DeviceTemplateScalarWhereInput>>;
  SOPIdentifier?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  dynamicGrouping?: InputMaybe<BoolNullableFilter>;
  emergencyCall?: InputMaybe<BoolNullableFilter>;
  floorQueuing?: InputMaybe<BoolNullableFilter>;
  fullDuplexPrivate?: InputMaybe<BoolNullableFilter>;
  groupCall?: InputMaybe<BoolNullableFilter>;
  halfDuplexPrivate?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  interruptFloor?: InputMaybe<BoolNullableFilter>;
  location?: InputMaybe<BoolNullableFilter>;
  multicastCall?: InputMaybe<BoolNullableFilter>;
  multicastShort?: InputMaybe<BoolNullableFilter>;
  multimediaData?: InputMaybe<BoolNullableFilter>;
  name?: InputMaybe<StringFilter>;
  passwordAuth?: InputMaybe<BoolNullableFilter>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFilter>;
  realTimeShort?: InputMaybe<BoolNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  requestFloor?: InputMaybe<BoolNullableFilter>;
  serialNumAuth?: InputMaybe<BoolNullableFilter>;
  statusMessage?: InputMaybe<BoolNullableFilter>;
  stripeProductId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoGroup?: InputMaybe<BoolNullableFilter>;
  videoPrivate?: InputMaybe<BoolNullableFilter>;
  videoPull?: InputMaybe<BoolNullableFilter>;
  videoUpload?: InputMaybe<BoolNullableFilter>;
};

export type DeviceTemplateUpdateInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutDeviceTemplatesInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutDeviceTemplateInput>;
  dynamicGrouping?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  emergencyCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  floorQueuing?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  fullDuplexPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  groupCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  halfDuplexPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  interruptFloor?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multicastCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multicastShort?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multimediaData?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  passwordAuth?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutDeviceTemplateInput>;
  realTimeShort?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestFloor?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  serialNumAuth?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoGroup?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoPull?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoUpload?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DeviceTemplateUpdateManyMutationInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dynamicGrouping?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  emergencyCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  floorQueuing?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  fullDuplexPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  groupCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  halfDuplexPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  interruptFloor?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multicastCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multicastShort?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multimediaData?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  passwordAuth?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFieldUpdateOperationsInput>;
  realTimeShort?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestFloor?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  serialNumAuth?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoGroup?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoPull?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoUpload?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DeviceTemplateUpdateManyWithWhereWithoutCreatedByInput = {
  data: DeviceTemplateUpdateManyMutationInput;
  where: DeviceTemplateScalarWhereInput;
};

export type DeviceTemplateUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<DeviceTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceTemplateCreateOrConnectWithoutCreatedByInput>
  >;
  create?: InputMaybe<Array<DeviceTemplateCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<DeviceTemplateCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<DeviceTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceTemplateWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeviceTemplateUpdateWithWhereUniqueWithoutCreatedByInput>
  >;
  updateMany?: InputMaybe<
    Array<DeviceTemplateUpdateManyWithWhereWithoutCreatedByInput>
  >;
  upsert?: InputMaybe<
    Array<DeviceTemplateUpsertWithWhereUniqueWithoutCreatedByInput>
  >;
};

export type DeviceTemplateUpdateOneWithoutDeviceUsersInput = {
  connect?: InputMaybe<DeviceTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceTemplateCreateOrConnectWithoutDeviceUsersInput>;
  create?: InputMaybe<DeviceTemplateCreateWithoutDeviceUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DeviceTemplateUpdateWithoutDeviceUsersInput>;
  upsert?: InputMaybe<DeviceTemplateUpsertWithoutDeviceUsersInput>;
};

export type DeviceTemplateUpdateOneWithoutProductsInput = {
  connect?: InputMaybe<DeviceTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceTemplateCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<DeviceTemplateCreateWithoutProductsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DeviceTemplateUpdateWithoutProductsInput>;
  upsert?: InputMaybe<DeviceTemplateUpsertWithoutProductsInput>;
};

export type DeviceTemplateUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: DeviceTemplateUpdateWithoutCreatedByInput;
  where: DeviceTemplateWhereUniqueInput;
};

export type DeviceTemplateUpdateWithoutCreatedByInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutDeviceTemplateInput>;
  dynamicGrouping?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  emergencyCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  floorQueuing?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  fullDuplexPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  groupCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  halfDuplexPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  interruptFloor?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multicastCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multicastShort?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multimediaData?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  passwordAuth?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutDeviceTemplateInput>;
  realTimeShort?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestFloor?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  serialNumAuth?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoGroup?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoPull?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoUpload?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DeviceTemplateUpdateWithoutDeviceUsersInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutDeviceTemplatesInput>;
  dynamicGrouping?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  emergencyCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  floorQueuing?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  fullDuplexPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  groupCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  halfDuplexPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  interruptFloor?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multicastCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multicastShort?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multimediaData?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  passwordAuth?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutDeviceTemplateInput>;
  realTimeShort?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestFloor?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  serialNumAuth?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoGroup?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoPull?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoUpload?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DeviceTemplateUpdateWithoutProductsInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutDeviceTemplatesInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutDeviceTemplateInput>;
  dynamicGrouping?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  emergencyCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  floorQueuing?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  fullDuplexPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  groupCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  halfDuplexPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  interruptFloor?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multicastCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multicastShort?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  multimediaData?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  passwordAuth?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFieldUpdateOperationsInput>;
  realTimeShort?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestFloor?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  serialNumAuth?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoGroup?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoPrivate?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoPull?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  videoUpload?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DeviceTemplateUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: DeviceTemplateCreateWithoutCreatedByInput;
  update: DeviceTemplateUpdateWithoutCreatedByInput;
  where: DeviceTemplateWhereUniqueInput;
};

export type DeviceTemplateUpsertWithoutDeviceUsersInput = {
  create: DeviceTemplateCreateWithoutDeviceUsersInput;
  update: DeviceTemplateUpdateWithoutDeviceUsersInput;
};

export type DeviceTemplateUpsertWithoutProductsInput = {
  create: DeviceTemplateCreateWithoutProductsInput;
  update: DeviceTemplateUpdateWithoutProductsInput;
};

export type DeviceTemplateWhereInput = {
  AND?: InputMaybe<Array<DeviceTemplateWhereInput>>;
  NOT?: InputMaybe<Array<DeviceTemplateWhereInput>>;
  OR?: InputMaybe<Array<DeviceTemplateWhereInput>>;
  SOPIdentifier?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<StringNullableFilter>;
  deviceUsers?: InputMaybe<DeviceUserListRelationFilter>;
  dynamicGrouping?: InputMaybe<BoolNullableFilter>;
  emergencyCall?: InputMaybe<BoolNullableFilter>;
  floorQueuing?: InputMaybe<BoolNullableFilter>;
  fullDuplexPrivate?: InputMaybe<BoolNullableFilter>;
  groupCall?: InputMaybe<BoolNullableFilter>;
  halfDuplexPrivate?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  interruptFloor?: InputMaybe<BoolNullableFilter>;
  location?: InputMaybe<BoolNullableFilter>;
  multicastCall?: InputMaybe<BoolNullableFilter>;
  multicastShort?: InputMaybe<BoolNullableFilter>;
  multimediaData?: InputMaybe<BoolNullableFilter>;
  name?: InputMaybe<StringFilter>;
  passwordAuth?: InputMaybe<BoolNullableFilter>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  realTimeShort?: InputMaybe<BoolNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  requestFloor?: InputMaybe<BoolNullableFilter>;
  serialNumAuth?: InputMaybe<BoolNullableFilter>;
  statusMessage?: InputMaybe<BoolNullableFilter>;
  stripeProductId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoGroup?: InputMaybe<BoolNullableFilter>;
  videoPrivate?: InputMaybe<BoolNullableFilter>;
  videoPull?: InputMaybe<BoolNullableFilter>;
  videoUpload?: InputMaybe<BoolNullableFilter>;
};

export type DeviceTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeviceUpdateManyMutationInput = {
  assetTag?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imeiNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  serialNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<DeviceUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUpdateManyWithWhereWithoutCustomerInput = {
  data: DeviceUpdateManyMutationInput;
  where: DeviceScalarWhereInput;
};

export type DeviceUpdateManyWithWhereWithoutDealerInput = {
  data: DeviceUpdateManyMutationInput;
  where: DeviceScalarWhereInput;
};

export type DeviceUpdateManyWithWhereWithoutDeviceUserInput = {
  data: DeviceUpdateManyMutationInput;
  where: DeviceScalarWhereInput;
};

export type DeviceUpdateManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<DeviceCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<DeviceCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  update?: InputMaybe<Array<DeviceUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<DeviceUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<DeviceUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type DeviceUpdateManyWithoutDealerInput = {
  connect?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<DeviceCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<DeviceCreateWithoutDealerInput>>;
  createMany?: InputMaybe<DeviceCreateManyDealerInputEnvelope>;
  delete?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  update?: InputMaybe<Array<DeviceUpdateWithWhereUniqueWithoutDealerInput>>;
  updateMany?: InputMaybe<Array<DeviceUpdateManyWithWhereWithoutDealerInput>>;
  upsert?: InputMaybe<Array<DeviceUpsertWithWhereUniqueWithoutDealerInput>>;
};

export type DeviceUpdateManyWithoutDeviceUserInput = {
  connect?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceCreateOrConnectWithoutDeviceUserInput>
  >;
  create?: InputMaybe<Array<DeviceCreateWithoutDeviceUserInput>>;
  createMany?: InputMaybe<DeviceCreateManyDeviceUserInputEnvelope>;
  delete?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceWhereUniqueInput>>;
  update?: InputMaybe<Array<DeviceUpdateWithWhereUniqueWithoutDeviceUserInput>>;
  updateMany?: InputMaybe<
    Array<DeviceUpdateManyWithWhereWithoutDeviceUserInput>
  >;
  upsert?: InputMaybe<Array<DeviceUpsertWithWhereUniqueWithoutDeviceUserInput>>;
};

export type DeviceUpdateOneWithoutSimCardsInput = {
  connect?: InputMaybe<DeviceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceCreateOrConnectWithoutSimCardsInput>;
  create?: InputMaybe<DeviceCreateWithoutSimCardsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DeviceUpdateWithoutSimCardsInput>;
  upsert?: InputMaybe<DeviceUpsertWithoutSimCardsInput>;
};

export type DeviceUpdateWithWhereUniqueWithoutCustomerInput = {
  data: DeviceUpdateWithoutCustomerInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceUpdateWithWhereUniqueWithoutDealerInput = {
  data: DeviceUpdateWithoutDealerInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceUpdateWithWhereUniqueWithoutDeviceUserInput = {
  data: DeviceUpdateWithoutDeviceUserInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceUpdateWithoutCustomerInput = {
  assetTag?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDevicesInput>;
  deviceUser?: InputMaybe<DeviceUserUpdateOneWithoutDevicesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imeiNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  serialNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceInput>;
  stripeInvoiceIds?: InputMaybe<DeviceUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUpdateWithoutDealerInput = {
  assetTag?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDevicesInput>;
  deviceUser?: InputMaybe<DeviceUserUpdateOneWithoutDevicesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imeiNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  serialNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceInput>;
  stripeInvoiceIds?: InputMaybe<DeviceUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUpdateWithoutDeviceUserInput = {
  assetTag?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDevicesInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDevicesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imeiNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  serialNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceInput>;
  stripeInvoiceIds?: InputMaybe<DeviceUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUpdateWithoutSimCardsInput = {
  assetTag?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDevicesInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutDevicesInput>;
  deviceUser?: InputMaybe<DeviceUserUpdateOneWithoutDevicesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  imeiNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  serialNumber?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<DeviceUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUpdatestripeInvoiceIdsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type DeviceUpsertWithWhereUniqueWithoutCustomerInput = {
  create: DeviceCreateWithoutCustomerInput;
  update: DeviceUpdateWithoutCustomerInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceUpsertWithWhereUniqueWithoutDealerInput = {
  create: DeviceCreateWithoutDealerInput;
  update: DeviceUpdateWithoutDealerInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceUpsertWithWhereUniqueWithoutDeviceUserInput = {
  create: DeviceCreateWithoutDeviceUserInput;
  update: DeviceUpdateWithoutDeviceUserInput;
  where: DeviceWhereUniqueInput;
};

export type DeviceUpsertWithoutSimCardsInput = {
  create: DeviceCreateWithoutSimCardsInput;
  update: DeviceUpdateWithoutSimCardsInput;
};

export type DeviceUser = {
  __typename?: 'DeviceUser';
  answerMode?: Maybe<AnswerModeEnum>;
  callPriority?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  demo: Scalars['Boolean'];
  deviceGroups: Array<DeviceUserGroup>;
  deviceTemplate?: Maybe<DeviceTemplate>;
  deviceTemplateId?: Maybe<Scalars['String']>;
  devices: Array<Device>;
  dispatchTemplate?: Maybe<DispatchTemplate>;
  dispatchTemplateId?: Maybe<Scalars['String']>;
  emergencyAction?: Maybe<EmergencyActionEnum>;
  emergencyAlarm?: Maybe<Scalars['String']>;
  emergencyGroup?: Maybe<Scalars['String']>;
  emergencyPrivate?: Maybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id: Scalars['String'];
  license?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  recycled: Scalars['Boolean'];
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['String']>;
  simCards: Array<SimCard>;
  teams: Array<Team>;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export type DeviceUserDeviceGroupsArgs = {
  after?: InputMaybe<DeviceUserGroupWhereUniqueInput>;
  before?: InputMaybe<DeviceUserGroupWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeviceUserDevicesArgs = {
  after?: InputMaybe<DeviceWhereUniqueInput>;
  before?: InputMaybe<DeviceWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeviceUserSimCardsArgs = {
  after?: InputMaybe<SimCardWhereUniqueInput>;
  before?: InputMaybe<SimCardWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeviceUserTeamsArgs = {
  after?: InputMaybe<TeamWhereUniqueInput>;
  before?: InputMaybe<TeamWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DeviceUserCreateInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceGroups?: InputMaybe<DeviceUserGroupCreateNestedManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateCreateNestedOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateCreateNestedOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMembersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateManyCustomerInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceTemplateId?: InputMaybe<Scalars['String']>;
  dispatchTemplateId?: InputMaybe<Scalars['String']>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  serviceId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateManyCustomerInputEnvelope = {
  data?: InputMaybe<Array<DeviceUserCreateManyCustomerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceUserCreateManyDeviceTemplateInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  demo?: InputMaybe<Scalars['Boolean']>;
  dispatchTemplateId?: InputMaybe<Scalars['String']>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  serviceId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateManyDeviceTemplateInputEnvelope = {
  data?: InputMaybe<Array<DeviceUserCreateManyDeviceTemplateInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceUserCreateManyDispatchTemplateInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceTemplateId?: InputMaybe<Scalars['String']>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  serviceId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateManyDispatchTemplateInputEnvelope = {
  data?: InputMaybe<Array<DeviceUserCreateManyDispatchTemplateInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceUserCreateManyServiceInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceTemplateId?: InputMaybe<Scalars['String']>;
  dispatchTemplateId?: InputMaybe<Scalars['String']>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateManyServiceInputEnvelope = {
  data?: InputMaybe<Array<DeviceUserCreateManyServiceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceUserCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<DeviceUserCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<DeviceUserCreateManyCustomerInputEnvelope>;
};

export type DeviceUserCreateNestedManyWithoutDeviceTemplateInput = {
  connect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserCreateOrConnectWithoutDeviceTemplateInput>
  >;
  create?: InputMaybe<Array<DeviceUserCreateWithoutDeviceTemplateInput>>;
  createMany?: InputMaybe<DeviceUserCreateManyDeviceTemplateInputEnvelope>;
};

export type DeviceUserCreateNestedManyWithoutDispatchTemplateInput = {
  connect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserCreateOrConnectWithoutDispatchTemplateInput>
  >;
  create?: InputMaybe<Array<DeviceUserCreateWithoutDispatchTemplateInput>>;
  createMany?: InputMaybe<DeviceUserCreateManyDispatchTemplateInputEnvelope>;
};

export type DeviceUserCreateNestedManyWithoutServiceInput = {
  connect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<DeviceUserCreateWithoutServiceInput>>;
  createMany?: InputMaybe<DeviceUserCreateManyServiceInputEnvelope>;
};

export type DeviceUserCreateNestedManyWithoutTeamsInput = {
  connect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserCreateOrConnectWithoutTeamsInput>
  >;
  create?: InputMaybe<Array<DeviceUserCreateWithoutTeamsInput>>;
};

export type DeviceUserCreateNestedOneWithoutDeviceGroupsInput = {
  connect?: InputMaybe<DeviceUserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceUserCreateOrConnectWithoutDeviceGroupsInput>;
  create?: InputMaybe<DeviceUserCreateWithoutDeviceGroupsInput>;
};

export type DeviceUserCreateNestedOneWithoutDevicesInput = {
  connect?: InputMaybe<DeviceUserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceUserCreateOrConnectWithoutDevicesInput>;
  create?: InputMaybe<DeviceUserCreateWithoutDevicesInput>;
};

export type DeviceUserCreateNestedOneWithoutSimCardsInput = {
  connect?: InputMaybe<DeviceUserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceUserCreateOrConnectWithoutSimCardsInput>;
  create?: InputMaybe<DeviceUserCreateWithoutSimCardsInput>;
};

export type DeviceUserCreateOrConnectWithoutCustomerInput = {
  create: DeviceUserCreateWithoutCustomerInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserCreateOrConnectWithoutDeviceGroupsInput = {
  create: DeviceUserCreateWithoutDeviceGroupsInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserCreateOrConnectWithoutDeviceTemplateInput = {
  create: DeviceUserCreateWithoutDeviceTemplateInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserCreateOrConnectWithoutDevicesInput = {
  create: DeviceUserCreateWithoutDevicesInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserCreateOrConnectWithoutDispatchTemplateInput = {
  create: DeviceUserCreateWithoutDispatchTemplateInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserCreateOrConnectWithoutServiceInput = {
  create: DeviceUserCreateWithoutServiceInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserCreateOrConnectWithoutSimCardsInput = {
  create: DeviceUserCreateWithoutSimCardsInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserCreateOrConnectWithoutTeamsInput = {
  create: DeviceUserCreateWithoutTeamsInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserCreateWithoutCustomerInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceGroups?: InputMaybe<DeviceUserGroupCreateNestedManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateCreateNestedOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateCreateNestedOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMembersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateWithoutDeviceGroupsInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceTemplate?: InputMaybe<DeviceTemplateCreateNestedOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateCreateNestedOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMembersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateWithoutDeviceTemplateInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceGroups?: InputMaybe<DeviceUserGroupCreateNestedManyWithoutDeviceUserInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateCreateNestedOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMembersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateWithoutDevicesInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceGroups?: InputMaybe<DeviceUserGroupCreateNestedManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateCreateNestedOneWithoutDeviceUsersInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateCreateNestedOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMembersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateWithoutDispatchTemplateInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceGroups?: InputMaybe<DeviceUserGroupCreateNestedManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateCreateNestedOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDeviceUserInput>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMembersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateWithoutServiceInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceGroups?: InputMaybe<DeviceUserGroupCreateNestedManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateCreateNestedOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateCreateNestedOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMembersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateWithoutSimCardsInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceGroups?: InputMaybe<DeviceUserGroupCreateNestedManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateCreateNestedOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateCreateNestedOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutDeviceUsersInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutMembersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserCreateWithoutTeamsInput = {
  SOPIdentifier: Scalars['String'];
  answerMode?: InputMaybe<AnswerModeEnum>;
  callPriority?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<Scalars['Boolean']>;
  deviceGroups?: InputMaybe<DeviceUserGroupCreateNestedManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateCreateNestedOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceCreateNestedManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateCreateNestedOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<EmergencyActionEnum>;
  emergencyAlarm?: InputMaybe<Scalars['String']>;
  emergencyGroup?: InputMaybe<Scalars['String']>;
  emergencyPrivate?: InputMaybe<Scalars['String']>;
  expireDate: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  service?: InputMaybe<ServiceCreateNestedOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardCreateNestedManyWithoutDeviceUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DeviceUserGroup = {
  __typename?: 'DeviceUserGroup';
  createdAt: Scalars['DateTime'];
  deviceGroup: DeviceGroup;
  deviceGroupId: Scalars['String'];
  deviceUser: DeviceUser;
  deviceUserId: Scalars['String'];
  groupProperty: GroupPropertyEnum;
  id: Scalars['String'];
  permissions: Array<FloorPermissionsEnum>;
  priority: Scalars['Int'];
  recycled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type DeviceUserGroupCreateManyDeviceGroupInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceUserId: Scalars['String'];
  groupProperty: GroupPropertyEnum;
  id?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<DeviceUserGroupCreatepermissionsInput>;
  priority: Scalars['Int'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceUserGroupCreateManyDeviceGroupInputEnvelope = {
  data?: InputMaybe<Array<DeviceUserGroupCreateManyDeviceGroupInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceUserGroupCreateManyDeviceUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceGroupId: Scalars['String'];
  groupProperty: GroupPropertyEnum;
  id?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<DeviceUserGroupCreatepermissionsInput>;
  priority: Scalars['Int'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceUserGroupCreateManyDeviceUserInputEnvelope = {
  data?: InputMaybe<Array<DeviceUserGroupCreateManyDeviceUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DeviceUserGroupCreateNestedManyWithoutDeviceGroupInput = {
  connect?: InputMaybe<Array<DeviceUserGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserGroupCreateOrConnectWithoutDeviceGroupInput>
  >;
  create?: InputMaybe<Array<DeviceUserGroupCreateWithoutDeviceGroupInput>>;
  createMany?: InputMaybe<DeviceUserGroupCreateManyDeviceGroupInputEnvelope>;
};

export type DeviceUserGroupCreateNestedManyWithoutDeviceUserInput = {
  connect?: InputMaybe<Array<DeviceUserGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserGroupCreateOrConnectWithoutDeviceUserInput>
  >;
  create?: InputMaybe<Array<DeviceUserGroupCreateWithoutDeviceUserInput>>;
  createMany?: InputMaybe<DeviceUserGroupCreateManyDeviceUserInputEnvelope>;
};

export type DeviceUserGroupCreateOrConnectWithoutDeviceGroupInput = {
  create: DeviceUserGroupCreateWithoutDeviceGroupInput;
  where: DeviceUserGroupWhereUniqueInput;
};

export type DeviceUserGroupCreateOrConnectWithoutDeviceUserInput = {
  create: DeviceUserGroupCreateWithoutDeviceUserInput;
  where: DeviceUserGroupWhereUniqueInput;
};

export type DeviceUserGroupCreateWithoutDeviceGroupInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceUser: DeviceUserCreateNestedOneWithoutDeviceGroupsInput;
  groupProperty: GroupPropertyEnum;
  id?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<DeviceUserGroupCreatepermissionsInput>;
  priority: Scalars['Int'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceUserGroupCreateWithoutDeviceUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceGroup: DeviceGroupCreateNestedOneWithoutDeviceUsersInput;
  groupProperty: GroupPropertyEnum;
  id?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<DeviceUserGroupCreatepermissionsInput>;
  priority: Scalars['Int'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DeviceUserGroupCreatepermissionsInput = {
  set?: InputMaybe<Array<FloorPermissionsEnum>>;
};

export type DeviceUserGroupListRelationFilter = {
  every?: InputMaybe<DeviceUserGroupWhereInput>;
  none?: InputMaybe<DeviceUserGroupWhereInput>;
  some?: InputMaybe<DeviceUserGroupWhereInput>;
};

export type DeviceUserGroupScalarWhereInput = {
  AND?: InputMaybe<Array<DeviceUserGroupScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeviceUserGroupScalarWhereInput>>;
  OR?: InputMaybe<Array<DeviceUserGroupScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceGroupId?: InputMaybe<StringFilter>;
  deviceUserId?: InputMaybe<StringFilter>;
  groupProperty?: InputMaybe<EnumGroupPropertyEnumFilter>;
  id?: InputMaybe<StringFilter>;
  permissions?: InputMaybe<EnumFloorPermissionsEnumNullableListFilter>;
  priority?: InputMaybe<IntFilter>;
  recycled?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeviceUserGroupUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  groupProperty?: InputMaybe<EnumGroupPropertyEnumFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<DeviceUserGroupUpdatepermissionsInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUserGroupUpdateManyWithWhereWithoutDeviceGroupInput = {
  data: DeviceUserGroupUpdateManyMutationInput;
  where: DeviceUserGroupScalarWhereInput;
};

export type DeviceUserGroupUpdateManyWithWhereWithoutDeviceUserInput = {
  data: DeviceUserGroupUpdateManyMutationInput;
  where: DeviceUserGroupScalarWhereInput;
};

export type DeviceUserGroupUpdateManyWithoutDeviceGroupInput = {
  connect?: InputMaybe<Array<DeviceUserGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserGroupCreateOrConnectWithoutDeviceGroupInput>
  >;
  create?: InputMaybe<Array<DeviceUserGroupCreateWithoutDeviceGroupInput>>;
  createMany?: InputMaybe<DeviceUserGroupCreateManyDeviceGroupInputEnvelope>;
  delete?: InputMaybe<Array<DeviceUserGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceUserGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceUserGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceUserGroupWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeviceUserGroupUpdateWithWhereUniqueWithoutDeviceGroupInput>
  >;
  updateMany?: InputMaybe<
    Array<DeviceUserGroupUpdateManyWithWhereWithoutDeviceGroupInput>
  >;
  upsert?: InputMaybe<
    Array<DeviceUserGroupUpsertWithWhereUniqueWithoutDeviceGroupInput>
  >;
};

export type DeviceUserGroupUpdateManyWithoutDeviceUserInput = {
  connect?: InputMaybe<Array<DeviceUserGroupWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserGroupCreateOrConnectWithoutDeviceUserInput>
  >;
  create?: InputMaybe<Array<DeviceUserGroupCreateWithoutDeviceUserInput>>;
  createMany?: InputMaybe<DeviceUserGroupCreateManyDeviceUserInputEnvelope>;
  delete?: InputMaybe<Array<DeviceUserGroupWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceUserGroupScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceUserGroupWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceUserGroupWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeviceUserGroupUpdateWithWhereUniqueWithoutDeviceUserInput>
  >;
  updateMany?: InputMaybe<
    Array<DeviceUserGroupUpdateManyWithWhereWithoutDeviceUserInput>
  >;
  upsert?: InputMaybe<
    Array<DeviceUserGroupUpsertWithWhereUniqueWithoutDeviceUserInput>
  >;
};

export type DeviceUserGroupUpdateWithWhereUniqueWithoutDeviceGroupInput = {
  data: DeviceUserGroupUpdateWithoutDeviceGroupInput;
  where: DeviceUserGroupWhereUniqueInput;
};

export type DeviceUserGroupUpdateWithWhereUniqueWithoutDeviceUserInput = {
  data: DeviceUserGroupUpdateWithoutDeviceUserInput;
  where: DeviceUserGroupWhereUniqueInput;
};

export type DeviceUserGroupUpdateWithoutDeviceGroupInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deviceUser?: InputMaybe<DeviceUserUpdateOneRequiredWithoutDeviceGroupsInput>;
  groupProperty?: InputMaybe<EnumGroupPropertyEnumFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<DeviceUserGroupUpdatepermissionsInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUserGroupUpdateWithoutDeviceUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deviceGroup?: InputMaybe<DeviceGroupUpdateOneRequiredWithoutDeviceUsersInput>;
  groupProperty?: InputMaybe<EnumGroupPropertyEnumFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissions?: InputMaybe<DeviceUserGroupUpdatepermissionsInput>;
  priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DeviceUserGroupUpdatepermissionsInput = {
  push?: InputMaybe<FloorPermissionsEnum>;
  set?: InputMaybe<Array<FloorPermissionsEnum>>;
};

export type DeviceUserGroupUpsertWithWhereUniqueWithoutDeviceGroupInput = {
  create: DeviceUserGroupCreateWithoutDeviceGroupInput;
  update: DeviceUserGroupUpdateWithoutDeviceGroupInput;
  where: DeviceUserGroupWhereUniqueInput;
};

export type DeviceUserGroupUpsertWithWhereUniqueWithoutDeviceUserInput = {
  create: DeviceUserGroupCreateWithoutDeviceUserInput;
  update: DeviceUserGroupUpdateWithoutDeviceUserInput;
  where: DeviceUserGroupWhereUniqueInput;
};

export type DeviceUserGroupWhereInput = {
  AND?: InputMaybe<Array<DeviceUserGroupWhereInput>>;
  NOT?: InputMaybe<Array<DeviceUserGroupWhereInput>>;
  OR?: InputMaybe<Array<DeviceUserGroupWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deviceGroup?: InputMaybe<DeviceGroupWhereInput>;
  deviceGroupId?: InputMaybe<StringFilter>;
  deviceUser?: InputMaybe<DeviceUserWhereInput>;
  deviceUserId?: InputMaybe<StringFilter>;
  groupProperty?: InputMaybe<EnumGroupPropertyEnumFilter>;
  id?: InputMaybe<StringFilter>;
  permissions?: InputMaybe<EnumFloorPermissionsEnumNullableListFilter>;
  priority?: InputMaybe<IntFilter>;
  recycled?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeviceUserGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeviceUserListRelationFilter = {
  every?: InputMaybe<DeviceUserWhereInput>;
  none?: InputMaybe<DeviceUserWhereInput>;
  some?: InputMaybe<DeviceUserWhereInput>;
};

export type DeviceUserScalarWhereInput = {
  AND?: InputMaybe<Array<DeviceUserScalarWhereInput>>;
  NOT?: InputMaybe<Array<DeviceUserScalarWhereInput>>;
  OR?: InputMaybe<Array<DeviceUserScalarWhereInput>>;
  SOPIdentifier?: InputMaybe<StringFilter>;
  answerMode?: InputMaybe<EnumAnswerModeEnumNullableFilter>;
  callPriority?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  demo?: InputMaybe<BoolFilter>;
  deviceTemplateId?: InputMaybe<StringNullableFilter>;
  dispatchTemplateId?: InputMaybe<StringNullableFilter>;
  emergencyAction?: InputMaybe<EnumEmergencyActionEnumNullableFilter>;
  emergencyAlarm?: InputMaybe<StringNullableFilter>;
  emergencyGroup?: InputMaybe<StringNullableFilter>;
  emergencyPrivate?: InputMaybe<StringNullableFilter>;
  expireDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  serviceId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type DeviceUserUpdateInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  answerMode?: InputMaybe<NullableEnumAnswerModeEnumFieldUpdateOperationsInput>;
  callPriority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deviceGroups?: InputMaybe<DeviceUserGroupUpdateManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateUpdateOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateUpdateOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<NullableEnumEmergencyActionEnumFieldUpdateOperationsInput>;
  emergencyAlarm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyPrivate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expireDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMembersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeviceUserUpdateManyMutationInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  answerMode?: InputMaybe<NullableEnumAnswerModeEnumFieldUpdateOperationsInput>;
  callPriority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  demo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  emergencyAction?: InputMaybe<NullableEnumEmergencyActionEnumFieldUpdateOperationsInput>;
  emergencyAlarm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyPrivate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expireDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeviceUserUpdateManyWithWhereWithoutCustomerInput = {
  data: DeviceUserUpdateManyMutationInput;
  where: DeviceUserScalarWhereInput;
};

export type DeviceUserUpdateManyWithWhereWithoutDeviceTemplateInput = {
  data: DeviceUserUpdateManyMutationInput;
  where: DeviceUserScalarWhereInput;
};

export type DeviceUserUpdateManyWithWhereWithoutDispatchTemplateInput = {
  data: DeviceUserUpdateManyMutationInput;
  where: DeviceUserScalarWhereInput;
};

export type DeviceUserUpdateManyWithWhereWithoutServiceInput = {
  data: DeviceUserUpdateManyMutationInput;
  where: DeviceUserScalarWhereInput;
};

export type DeviceUserUpdateManyWithWhereWithoutTeamsInput = {
  data: DeviceUserUpdateManyMutationInput;
  where: DeviceUserScalarWhereInput;
};

export type DeviceUserUpdateManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<DeviceUserCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<DeviceUserCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeviceUserUpdateWithWhereUniqueWithoutCustomerInput>
  >;
  updateMany?: InputMaybe<
    Array<DeviceUserUpdateManyWithWhereWithoutCustomerInput>
  >;
  upsert?: InputMaybe<
    Array<DeviceUserUpsertWithWhereUniqueWithoutCustomerInput>
  >;
};

export type DeviceUserUpdateManyWithoutDeviceTemplateInput = {
  connect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserCreateOrConnectWithoutDeviceTemplateInput>
  >;
  create?: InputMaybe<Array<DeviceUserCreateWithoutDeviceTemplateInput>>;
  createMany?: InputMaybe<DeviceUserCreateManyDeviceTemplateInputEnvelope>;
  delete?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeviceUserUpdateWithWhereUniqueWithoutDeviceTemplateInput>
  >;
  updateMany?: InputMaybe<
    Array<DeviceUserUpdateManyWithWhereWithoutDeviceTemplateInput>
  >;
  upsert?: InputMaybe<
    Array<DeviceUserUpsertWithWhereUniqueWithoutDeviceTemplateInput>
  >;
};

export type DeviceUserUpdateManyWithoutDispatchTemplateInput = {
  connect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserCreateOrConnectWithoutDispatchTemplateInput>
  >;
  create?: InputMaybe<Array<DeviceUserCreateWithoutDispatchTemplateInput>>;
  createMany?: InputMaybe<DeviceUserCreateManyDispatchTemplateInputEnvelope>;
  delete?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeviceUserUpdateWithWhereUniqueWithoutDispatchTemplateInput>
  >;
  updateMany?: InputMaybe<
    Array<DeviceUserUpdateManyWithWhereWithoutDispatchTemplateInput>
  >;
  upsert?: InputMaybe<
    Array<DeviceUserUpsertWithWhereUniqueWithoutDispatchTemplateInput>
  >;
};

export type DeviceUserUpdateManyWithoutServiceInput = {
  connect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserCreateOrConnectWithoutServiceInput>
  >;
  create?: InputMaybe<Array<DeviceUserCreateWithoutServiceInput>>;
  createMany?: InputMaybe<DeviceUserCreateManyServiceInputEnvelope>;
  delete?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DeviceUserUpdateWithWhereUniqueWithoutServiceInput>
  >;
  updateMany?: InputMaybe<
    Array<DeviceUserUpdateManyWithWhereWithoutServiceInput>
  >;
  upsert?: InputMaybe<
    Array<DeviceUserUpsertWithWhereUniqueWithoutServiceInput>
  >;
};

export type DeviceUserUpdateManyWithoutTeamsInput = {
  connect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DeviceUserCreateOrConnectWithoutTeamsInput>
  >;
  create?: InputMaybe<Array<DeviceUserCreateWithoutTeamsInput>>;
  delete?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DeviceUserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  set?: InputMaybe<Array<DeviceUserWhereUniqueInput>>;
  update?: InputMaybe<Array<DeviceUserUpdateWithWhereUniqueWithoutTeamsInput>>;
  updateMany?: InputMaybe<
    Array<DeviceUserUpdateManyWithWhereWithoutTeamsInput>
  >;
  upsert?: InputMaybe<Array<DeviceUserUpsertWithWhereUniqueWithoutTeamsInput>>;
};

export type DeviceUserUpdateOneRequiredWithoutDeviceGroupsInput = {
  connect?: InputMaybe<DeviceUserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceUserCreateOrConnectWithoutDeviceGroupsInput>;
  create?: InputMaybe<DeviceUserCreateWithoutDeviceGroupsInput>;
  update?: InputMaybe<DeviceUserUpdateWithoutDeviceGroupsInput>;
  upsert?: InputMaybe<DeviceUserUpsertWithoutDeviceGroupsInput>;
};

export type DeviceUserUpdateOneWithoutDevicesInput = {
  connect?: InputMaybe<DeviceUserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceUserCreateOrConnectWithoutDevicesInput>;
  create?: InputMaybe<DeviceUserCreateWithoutDevicesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DeviceUserUpdateWithoutDevicesInput>;
  upsert?: InputMaybe<DeviceUserUpsertWithoutDevicesInput>;
};

export type DeviceUserUpdateOneWithoutSimCardsInput = {
  connect?: InputMaybe<DeviceUserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DeviceUserCreateOrConnectWithoutSimCardsInput>;
  create?: InputMaybe<DeviceUserCreateWithoutSimCardsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DeviceUserUpdateWithoutSimCardsInput>;
  upsert?: InputMaybe<DeviceUserUpsertWithoutSimCardsInput>;
};

export type DeviceUserUpdateWithWhereUniqueWithoutCustomerInput = {
  data: DeviceUserUpdateWithoutCustomerInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserUpdateWithWhereUniqueWithoutDeviceTemplateInput = {
  data: DeviceUserUpdateWithoutDeviceTemplateInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserUpdateWithWhereUniqueWithoutDispatchTemplateInput = {
  data: DeviceUserUpdateWithoutDispatchTemplateInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserUpdateWithWhereUniqueWithoutServiceInput = {
  data: DeviceUserUpdateWithoutServiceInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserUpdateWithWhereUniqueWithoutTeamsInput = {
  data: DeviceUserUpdateWithoutTeamsInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserUpdateWithoutCustomerInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  answerMode?: InputMaybe<NullableEnumAnswerModeEnumFieldUpdateOperationsInput>;
  callPriority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  demo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deviceGroups?: InputMaybe<DeviceUserGroupUpdateManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateUpdateOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateUpdateOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<NullableEnumEmergencyActionEnumFieldUpdateOperationsInput>;
  emergencyAlarm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyPrivate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expireDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMembersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeviceUserUpdateWithoutDeviceGroupsInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  answerMode?: InputMaybe<NullableEnumAnswerModeEnumFieldUpdateOperationsInput>;
  callPriority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateUpdateOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateUpdateOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<NullableEnumEmergencyActionEnumFieldUpdateOperationsInput>;
  emergencyAlarm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyPrivate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expireDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMembersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeviceUserUpdateWithoutDeviceTemplateInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  answerMode?: InputMaybe<NullableEnumAnswerModeEnumFieldUpdateOperationsInput>;
  callPriority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deviceGroups?: InputMaybe<DeviceUserGroupUpdateManyWithoutDeviceUserInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateUpdateOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<NullableEnumEmergencyActionEnumFieldUpdateOperationsInput>;
  emergencyAlarm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyPrivate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expireDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMembersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeviceUserUpdateWithoutDevicesInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  answerMode?: InputMaybe<NullableEnumAnswerModeEnumFieldUpdateOperationsInput>;
  callPriority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deviceGroups?: InputMaybe<DeviceUserGroupUpdateManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateUpdateOneWithoutDeviceUsersInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateUpdateOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<NullableEnumEmergencyActionEnumFieldUpdateOperationsInput>;
  emergencyAlarm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyPrivate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expireDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMembersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeviceUserUpdateWithoutDispatchTemplateInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  answerMode?: InputMaybe<NullableEnumAnswerModeEnumFieldUpdateOperationsInput>;
  callPriority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deviceGroups?: InputMaybe<DeviceUserGroupUpdateManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateUpdateOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDeviceUserInput>;
  emergencyAction?: InputMaybe<NullableEnumEmergencyActionEnumFieldUpdateOperationsInput>;
  emergencyAlarm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyPrivate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expireDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMembersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeviceUserUpdateWithoutServiceInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  answerMode?: InputMaybe<NullableEnumAnswerModeEnumFieldUpdateOperationsInput>;
  callPriority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deviceGroups?: InputMaybe<DeviceUserGroupUpdateManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateUpdateOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateUpdateOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<NullableEnumEmergencyActionEnumFieldUpdateOperationsInput>;
  emergencyAlarm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyPrivate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expireDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceUserInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMembersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeviceUserUpdateWithoutSimCardsInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  answerMode?: InputMaybe<NullableEnumAnswerModeEnumFieldUpdateOperationsInput>;
  callPriority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deviceGroups?: InputMaybe<DeviceUserGroupUpdateManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateUpdateOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateUpdateOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<NullableEnumEmergencyActionEnumFieldUpdateOperationsInput>;
  emergencyAlarm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyPrivate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expireDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutDeviceUsersInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutMembersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeviceUserUpdateWithoutTeamsInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  answerMode?: InputMaybe<NullableEnumAnswerModeEnumFieldUpdateOperationsInput>;
  callPriority?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutDeviceUsersInput>;
  demo?: InputMaybe<BoolFieldUpdateOperationsInput>;
  deviceGroups?: InputMaybe<DeviceUserGroupUpdateManyWithoutDeviceUserInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateUpdateOneWithoutDeviceUsersInput>;
  devices?: InputMaybe<DeviceUpdateManyWithoutDeviceUserInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateUpdateOneWithoutDeviceUsersInput>;
  emergencyAction?: InputMaybe<NullableEnumEmergencyActionEnumFieldUpdateOperationsInput>;
  emergencyAlarm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyGroup?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emergencyPrivate?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  expireDate?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  service?: InputMaybe<ServiceUpdateOneWithoutDeviceUsersInput>;
  simCards?: InputMaybe<SimCardUpdateManyWithoutDeviceUserInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type DeviceUserUpsertWithWhereUniqueWithoutCustomerInput = {
  create: DeviceUserCreateWithoutCustomerInput;
  update: DeviceUserUpdateWithoutCustomerInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserUpsertWithWhereUniqueWithoutDeviceTemplateInput = {
  create: DeviceUserCreateWithoutDeviceTemplateInput;
  update: DeviceUserUpdateWithoutDeviceTemplateInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserUpsertWithWhereUniqueWithoutDispatchTemplateInput = {
  create: DeviceUserCreateWithoutDispatchTemplateInput;
  update: DeviceUserUpdateWithoutDispatchTemplateInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserUpsertWithWhereUniqueWithoutServiceInput = {
  create: DeviceUserCreateWithoutServiceInput;
  update: DeviceUserUpdateWithoutServiceInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserUpsertWithWhereUniqueWithoutTeamsInput = {
  create: DeviceUserCreateWithoutTeamsInput;
  update: DeviceUserUpdateWithoutTeamsInput;
  where: DeviceUserWhereUniqueInput;
};

export type DeviceUserUpsertWithoutDeviceGroupsInput = {
  create: DeviceUserCreateWithoutDeviceGroupsInput;
  update: DeviceUserUpdateWithoutDeviceGroupsInput;
};

export type DeviceUserUpsertWithoutDevicesInput = {
  create: DeviceUserCreateWithoutDevicesInput;
  update: DeviceUserUpdateWithoutDevicesInput;
};

export type DeviceUserUpsertWithoutSimCardsInput = {
  create: DeviceUserCreateWithoutSimCardsInput;
  update: DeviceUserUpdateWithoutSimCardsInput;
};

export type DeviceUserWhereInput = {
  AND?: InputMaybe<Array<DeviceUserWhereInput>>;
  NOT?: InputMaybe<Array<DeviceUserWhereInput>>;
  OR?: InputMaybe<Array<DeviceUserWhereInput>>;
  SOPIdentifier?: InputMaybe<StringFilter>;
  answerMode?: InputMaybe<EnumAnswerModeEnumNullableFilter>;
  callPriority?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  demo?: InputMaybe<BoolFilter>;
  deviceGroups?: InputMaybe<DeviceUserGroupListRelationFilter>;
  deviceTemplate?: InputMaybe<DeviceTemplateWhereInput>;
  deviceTemplateId?: InputMaybe<StringNullableFilter>;
  devices?: InputMaybe<DeviceListRelationFilter>;
  dispatchTemplate?: InputMaybe<DispatchTemplateWhereInput>;
  dispatchTemplateId?: InputMaybe<StringNullableFilter>;
  emergencyAction?: InputMaybe<EnumEmergencyActionEnumNullableFilter>;
  emergencyAlarm?: InputMaybe<StringNullableFilter>;
  emergencyGroup?: InputMaybe<StringNullableFilter>;
  emergencyPrivate?: InputMaybe<StringNullableFilter>;
  expireDate?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  service?: InputMaybe<ServiceWhereInput>;
  serviceId?: InputMaybe<StringNullableFilter>;
  simCards?: InputMaybe<SimCardListRelationFilter>;
  teams?: InputMaybe<TeamListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type DeviceUserWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DeviceWhereInput = {
  AND?: InputMaybe<Array<DeviceWhereInput>>;
  NOT?: InputMaybe<Array<DeviceWhereInput>>;
  OR?: InputMaybe<Array<DeviceWhereInput>>;
  assetTag?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  deviceUser?: InputMaybe<DeviceUserWhereInput>;
  deviceUserId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  imeiNumber?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  serialNumber?: InputMaybe<StringNullableFilter>;
  simCards?: InputMaybe<SimCardListRelationFilter>;
  stripeInvoiceIds?: InputMaybe<StringNullableListFilter>;
  stripeProductId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DeviceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  imeiNumber?: InputMaybe<Scalars['String']>;
};

export type DispatchTemplate = {
  __typename?: 'DispatchTemplate';
  advancedMap?: Maybe<Scalars['Boolean']>;
  audioCall?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  deviceUsers: Array<DeviceUser>;
  envDetection?: Maybe<Scalars['Boolean']>;
  envListen?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  listen?: Maybe<Scalars['Boolean']>;
  map?: Maybe<Scalars['Boolean']>;
  messaging?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  permissionType: PermissionTypeEnum;
  products: Array<Product>;
  realTimeLocation?: Maybe<Scalars['Boolean']>;
  recordPlayback?: Maybe<Scalars['Boolean']>;
  recycled: Scalars['Boolean'];
  stripeProductId?: Maybe<Scalars['String']>;
  trackPlayback?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  videoCall?: Maybe<Scalars['Boolean']>;
};

export type DispatchTemplateDeviceUsersArgs = {
  after?: InputMaybe<DeviceUserWhereUniqueInput>;
  before?: InputMaybe<DeviceUserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DispatchTemplateProductsArgs = {
  after?: InputMaybe<ProductWhereUniqueInput>;
  before?: InputMaybe<ProductWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DispatchTemplateCreateInput = {
  SOPIdentifier: Scalars['String'];
  advancedMap?: InputMaybe<Scalars['Boolean']>;
  audioCall?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutDispatchTemplatesInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutDispatchTemplateInput>;
  envDetection?: InputMaybe<Scalars['Boolean']>;
  envListen?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  listen?: InputMaybe<Scalars['Boolean']>;
  map?: InputMaybe<Scalars['Boolean']>;
  messaging?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  permissionType: PermissionTypeEnum;
  products?: InputMaybe<ProductCreateNestedManyWithoutDispatchTemplateInput>;
  realTimeLocation?: InputMaybe<Scalars['Boolean']>;
  recordPlayback?: InputMaybe<Scalars['Boolean']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  trackPlayback?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCall?: InputMaybe<Scalars['Boolean']>;
};

export type DispatchTemplateCreateManyCreatedByInput = {
  SOPIdentifier: Scalars['String'];
  advancedMap?: InputMaybe<Scalars['Boolean']>;
  audioCall?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  envDetection?: InputMaybe<Scalars['Boolean']>;
  envListen?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  listen?: InputMaybe<Scalars['Boolean']>;
  map?: InputMaybe<Scalars['Boolean']>;
  messaging?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  permissionType: PermissionTypeEnum;
  realTimeLocation?: InputMaybe<Scalars['Boolean']>;
  recordPlayback?: InputMaybe<Scalars['Boolean']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  trackPlayback?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCall?: InputMaybe<Scalars['Boolean']>;
};

export type DispatchTemplateCreateManyCreatedByInputEnvelope = {
  data?: InputMaybe<Array<DispatchTemplateCreateManyCreatedByInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type DispatchTemplateCreateNestedManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<DispatchTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DispatchTemplateCreateOrConnectWithoutCreatedByInput>
  >;
  create?: InputMaybe<Array<DispatchTemplateCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<DispatchTemplateCreateManyCreatedByInputEnvelope>;
};

export type DispatchTemplateCreateNestedOneWithoutDeviceUsersInput = {
  connect?: InputMaybe<DispatchTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DispatchTemplateCreateOrConnectWithoutDeviceUsersInput>;
  create?: InputMaybe<DispatchTemplateCreateWithoutDeviceUsersInput>;
};

export type DispatchTemplateCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<DispatchTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DispatchTemplateCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<DispatchTemplateCreateWithoutProductsInput>;
};

export type DispatchTemplateCreateOrConnectWithoutCreatedByInput = {
  create: DispatchTemplateCreateWithoutCreatedByInput;
  where: DispatchTemplateWhereUniqueInput;
};

export type DispatchTemplateCreateOrConnectWithoutDeviceUsersInput = {
  create: DispatchTemplateCreateWithoutDeviceUsersInput;
  where: DispatchTemplateWhereUniqueInput;
};

export type DispatchTemplateCreateOrConnectWithoutProductsInput = {
  create: DispatchTemplateCreateWithoutProductsInput;
  where: DispatchTemplateWhereUniqueInput;
};

export type DispatchTemplateCreateWithoutCreatedByInput = {
  SOPIdentifier: Scalars['String'];
  advancedMap?: InputMaybe<Scalars['Boolean']>;
  audioCall?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutDispatchTemplateInput>;
  envDetection?: InputMaybe<Scalars['Boolean']>;
  envListen?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  listen?: InputMaybe<Scalars['Boolean']>;
  map?: InputMaybe<Scalars['Boolean']>;
  messaging?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  permissionType: PermissionTypeEnum;
  products?: InputMaybe<ProductCreateNestedManyWithoutDispatchTemplateInput>;
  realTimeLocation?: InputMaybe<Scalars['Boolean']>;
  recordPlayback?: InputMaybe<Scalars['Boolean']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  trackPlayback?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCall?: InputMaybe<Scalars['Boolean']>;
};

export type DispatchTemplateCreateWithoutDeviceUsersInput = {
  SOPIdentifier: Scalars['String'];
  advancedMap?: InputMaybe<Scalars['Boolean']>;
  audioCall?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutDispatchTemplatesInput>;
  envDetection?: InputMaybe<Scalars['Boolean']>;
  envListen?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  listen?: InputMaybe<Scalars['Boolean']>;
  map?: InputMaybe<Scalars['Boolean']>;
  messaging?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  permissionType: PermissionTypeEnum;
  products?: InputMaybe<ProductCreateNestedManyWithoutDispatchTemplateInput>;
  realTimeLocation?: InputMaybe<Scalars['Boolean']>;
  recordPlayback?: InputMaybe<Scalars['Boolean']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  trackPlayback?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCall?: InputMaybe<Scalars['Boolean']>;
};

export type DispatchTemplateCreateWithoutProductsInput = {
  SOPIdentifier: Scalars['String'];
  advancedMap?: InputMaybe<Scalars['Boolean']>;
  audioCall?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<UserCreateNestedOneWithoutDispatchTemplatesInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutDispatchTemplateInput>;
  envDetection?: InputMaybe<Scalars['Boolean']>;
  envListen?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  listen?: InputMaybe<Scalars['Boolean']>;
  map?: InputMaybe<Scalars['Boolean']>;
  messaging?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  permissionType: PermissionTypeEnum;
  realTimeLocation?: InputMaybe<Scalars['Boolean']>;
  recordPlayback?: InputMaybe<Scalars['Boolean']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  trackPlayback?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoCall?: InputMaybe<Scalars['Boolean']>;
};

export type DispatchTemplateListRelationFilter = {
  every?: InputMaybe<DispatchTemplateWhereInput>;
  none?: InputMaybe<DispatchTemplateWhereInput>;
  some?: InputMaybe<DispatchTemplateWhereInput>;
};

export type DispatchTemplateScalarWhereInput = {
  AND?: InputMaybe<Array<DispatchTemplateScalarWhereInput>>;
  NOT?: InputMaybe<Array<DispatchTemplateScalarWhereInput>>;
  OR?: InputMaybe<Array<DispatchTemplateScalarWhereInput>>;
  SOPIdentifier?: InputMaybe<StringFilter>;
  advancedMap?: InputMaybe<BoolNullableFilter>;
  audioCall?: InputMaybe<BoolNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdById?: InputMaybe<StringNullableFilter>;
  envDetection?: InputMaybe<BoolNullableFilter>;
  envListen?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  listen?: InputMaybe<BoolNullableFilter>;
  map?: InputMaybe<BoolNullableFilter>;
  messaging?: InputMaybe<BoolNullableFilter>;
  name?: InputMaybe<StringFilter>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFilter>;
  realTimeLocation?: InputMaybe<BoolNullableFilter>;
  recordPlayback?: InputMaybe<BoolNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeProductId?: InputMaybe<StringNullableFilter>;
  trackPlayback?: InputMaybe<BoolNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoCall?: InputMaybe<BoolNullableFilter>;
};

export type DispatchTemplateUpdateInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  advancedMap?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  audioCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutDispatchTemplatesInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutDispatchTemplateInput>;
  envDetection?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  envListen?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  listen?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  map?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  messaging?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutDispatchTemplateInput>;
  realTimeLocation?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recordPlayback?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trackPlayback?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DispatchTemplateUpdateManyMutationInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  advancedMap?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  audioCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  envDetection?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  envListen?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  listen?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  map?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  messaging?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFieldUpdateOperationsInput>;
  realTimeLocation?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recordPlayback?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trackPlayback?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DispatchTemplateUpdateManyWithWhereWithoutCreatedByInput = {
  data: DispatchTemplateUpdateManyMutationInput;
  where: DispatchTemplateScalarWhereInput;
};

export type DispatchTemplateUpdateManyWithoutCreatedByInput = {
  connect?: InputMaybe<Array<DispatchTemplateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<DispatchTemplateCreateOrConnectWithoutCreatedByInput>
  >;
  create?: InputMaybe<Array<DispatchTemplateCreateWithoutCreatedByInput>>;
  createMany?: InputMaybe<DispatchTemplateCreateManyCreatedByInputEnvelope>;
  delete?: InputMaybe<Array<DispatchTemplateWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<DispatchTemplateScalarWhereInput>>;
  disconnect?: InputMaybe<Array<DispatchTemplateWhereUniqueInput>>;
  set?: InputMaybe<Array<DispatchTemplateWhereUniqueInput>>;
  update?: InputMaybe<
    Array<DispatchTemplateUpdateWithWhereUniqueWithoutCreatedByInput>
  >;
  updateMany?: InputMaybe<
    Array<DispatchTemplateUpdateManyWithWhereWithoutCreatedByInput>
  >;
  upsert?: InputMaybe<
    Array<DispatchTemplateUpsertWithWhereUniqueWithoutCreatedByInput>
  >;
};

export type DispatchTemplateUpdateOneWithoutDeviceUsersInput = {
  connect?: InputMaybe<DispatchTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DispatchTemplateCreateOrConnectWithoutDeviceUsersInput>;
  create?: InputMaybe<DispatchTemplateCreateWithoutDeviceUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DispatchTemplateUpdateWithoutDeviceUsersInput>;
  upsert?: InputMaybe<DispatchTemplateUpsertWithoutDeviceUsersInput>;
};

export type DispatchTemplateUpdateOneWithoutProductsInput = {
  connect?: InputMaybe<DispatchTemplateWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DispatchTemplateCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<DispatchTemplateCreateWithoutProductsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DispatchTemplateUpdateWithoutProductsInput>;
  upsert?: InputMaybe<DispatchTemplateUpsertWithoutProductsInput>;
};

export type DispatchTemplateUpdateWithWhereUniqueWithoutCreatedByInput = {
  data: DispatchTemplateUpdateWithoutCreatedByInput;
  where: DispatchTemplateWhereUniqueInput;
};

export type DispatchTemplateUpdateWithoutCreatedByInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  advancedMap?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  audioCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutDispatchTemplateInput>;
  envDetection?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  envListen?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  listen?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  map?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  messaging?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutDispatchTemplateInput>;
  realTimeLocation?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recordPlayback?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trackPlayback?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DispatchTemplateUpdateWithoutDeviceUsersInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  advancedMap?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  audioCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutDispatchTemplatesInput>;
  envDetection?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  envListen?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  listen?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  map?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  messaging?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutDispatchTemplateInput>;
  realTimeLocation?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recordPlayback?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trackPlayback?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DispatchTemplateUpdateWithoutProductsInput = {
  SOPIdentifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  advancedMap?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  audioCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<UserUpdateOneWithoutDispatchTemplatesInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutDispatchTemplateInput>;
  envDetection?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  envListen?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  listen?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  map?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  messaging?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFieldUpdateOperationsInput>;
  realTimeLocation?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recordPlayback?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  trackPlayback?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoCall?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
};

export type DispatchTemplateUpsertWithWhereUniqueWithoutCreatedByInput = {
  create: DispatchTemplateCreateWithoutCreatedByInput;
  update: DispatchTemplateUpdateWithoutCreatedByInput;
  where: DispatchTemplateWhereUniqueInput;
};

export type DispatchTemplateUpsertWithoutDeviceUsersInput = {
  create: DispatchTemplateCreateWithoutDeviceUsersInput;
  update: DispatchTemplateUpdateWithoutDeviceUsersInput;
};

export type DispatchTemplateUpsertWithoutProductsInput = {
  create: DispatchTemplateCreateWithoutProductsInput;
  update: DispatchTemplateUpdateWithoutProductsInput;
};

export type DispatchTemplateWhereInput = {
  AND?: InputMaybe<Array<DispatchTemplateWhereInput>>;
  NOT?: InputMaybe<Array<DispatchTemplateWhereInput>>;
  OR?: InputMaybe<Array<DispatchTemplateWhereInput>>;
  SOPIdentifier?: InputMaybe<StringFilter>;
  advancedMap?: InputMaybe<BoolNullableFilter>;
  audioCall?: InputMaybe<BoolNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<UserWhereInput>;
  createdById?: InputMaybe<StringNullableFilter>;
  deviceUsers?: InputMaybe<DeviceUserListRelationFilter>;
  envDetection?: InputMaybe<BoolNullableFilter>;
  envListen?: InputMaybe<BoolNullableFilter>;
  id?: InputMaybe<StringFilter>;
  listen?: InputMaybe<BoolNullableFilter>;
  map?: InputMaybe<BoolNullableFilter>;
  messaging?: InputMaybe<BoolNullableFilter>;
  name?: InputMaybe<StringFilter>;
  permissionType?: InputMaybe<EnumPermissionTypeEnumFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  realTimeLocation?: InputMaybe<BoolNullableFilter>;
  recordPlayback?: InputMaybe<BoolNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeProductId?: InputMaybe<StringNullableFilter>;
  trackPlayback?: InputMaybe<BoolNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoCall?: InputMaybe<BoolNullableFilter>;
};

export type DispatchTemplateWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Distributor = {
  __typename?: 'Distributor';
  SOPIdentifier: Array<Scalars['String']>;
  addresses: Array<Address>;
  availablePaymentMethods?: Maybe<Array<Maybe<Scalars['String']>>>;
  billingAddress?: Maybe<StripeAddress>;
  contact?: Maybe<Contact>;
  createdAt: Scalars['DateTime'];
  currency?: Maybe<Scalars['String']>;
  customers: Array<Customer>;
  dealers: Array<Dealer>;
  demBundles: Array<BundleOnUser>;
  id: Scalars['String'];
  invoices?: Maybe<Array<Maybe<Invoice>>>;
  locale?: Maybe<StripeLocale>;
  name: Scalars['String'];
  nextInvoice?: Maybe<Invoice>;
  onboarding: OnboardingStepEnum;
  payments?: Maybe<Array<Maybe<Payment>>>;
  recycled: Scalars['Boolean'];
  settings?: Maybe<DistributorSetting>;
  shipping?: Maybe<StripeShipping>;
  stripeId?: Maybe<Scalars['String']>;
  termAgreement?: Maybe<TermsAgreement>;
  totalDemoDeviceUsers?: Maybe<Scalars['Int']>;
  totalDemoDispatchUsers?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
  vatId?: Maybe<VatId>;
};

export type DistributorAddressesArgs = {
  after?: InputMaybe<AddressWhereUniqueInput>;
  before?: InputMaybe<AddressWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DistributorCustomersArgs = {
  after?: InputMaybe<CustomerWhereUniqueInput>;
  before?: InputMaybe<CustomerWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DistributorDealersArgs = {
  after?: InputMaybe<DealerWhereUniqueInput>;
  before?: InputMaybe<DealerWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DistributorDemBundlesArgs = {
  after?: InputMaybe<BundleOnUserWhereUniqueInput>;
  before?: InputMaybe<BundleOnUserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DistributorUsersArgs = {
  after?: InputMaybe<UserWhereUniqueInput>;
  before?: InputMaybe<UserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type DistributorCreateInput = {
  SOPIdentifier?: InputMaybe<DistributorCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDistributorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDistributorInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<DistributorSettingCreateNestedOneWithoutDistributorInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DistributorCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDistributorInput>;
};

export type DistributorCreateNestedOneWithoutAddressesInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<DistributorCreateWithoutAddressesInput>;
};

export type DistributorCreateNestedOneWithoutContactInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<DistributorCreateWithoutContactInput>;
};

export type DistributorCreateNestedOneWithoutCustomersInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutCustomersInput>;
  create?: InputMaybe<DistributorCreateWithoutCustomersInput>;
};

export type DistributorCreateNestedOneWithoutDealersInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutDealersInput>;
  create?: InputMaybe<DistributorCreateWithoutDealersInput>;
};

export type DistributorCreateNestedOneWithoutDemBundlesInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutDemBundlesInput>;
  create?: InputMaybe<DistributorCreateWithoutDemBundlesInput>;
};

export type DistributorCreateNestedOneWithoutSubscriptionsInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutSubscriptionsInput>;
  create?: InputMaybe<DistributorCreateWithoutSubscriptionsInput>;
};

export type DistributorCreateNestedOneWithoutTermAgreementInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutTermAgreementInput>;
  create?: InputMaybe<DistributorCreateWithoutTermAgreementInput>;
};

export type DistributorCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<DistributorCreateWithoutUsersInput>;
};

export type DistributorCreateOrConnectWithoutAddressesInput = {
  create: DistributorCreateWithoutAddressesInput;
  where: DistributorWhereUniqueInput;
};

export type DistributorCreateOrConnectWithoutContactInput = {
  create: DistributorCreateWithoutContactInput;
  where: DistributorWhereUniqueInput;
};

export type DistributorCreateOrConnectWithoutCustomersInput = {
  create: DistributorCreateWithoutCustomersInput;
  where: DistributorWhereUniqueInput;
};

export type DistributorCreateOrConnectWithoutDealersInput = {
  create: DistributorCreateWithoutDealersInput;
  where: DistributorWhereUniqueInput;
};

export type DistributorCreateOrConnectWithoutDemBundlesInput = {
  create: DistributorCreateWithoutDemBundlesInput;
  where: DistributorWhereUniqueInput;
};

export type DistributorCreateOrConnectWithoutSubscriptionsInput = {
  create: DistributorCreateWithoutSubscriptionsInput;
  where: DistributorWhereUniqueInput;
};

export type DistributorCreateOrConnectWithoutTermAgreementInput = {
  create: DistributorCreateWithoutTermAgreementInput;
  where: DistributorWhereUniqueInput;
};

export type DistributorCreateOrConnectWithoutUsersInput = {
  create: DistributorCreateWithoutUsersInput;
  where: DistributorWhereUniqueInput;
};

export type DistributorCreateSopIdentifierInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type DistributorCreateWithoutAddressesInput = {
  SOPIdentifier?: InputMaybe<DistributorCreateSopIdentifierInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDistributorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDistributorInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<DistributorSettingCreateNestedOneWithoutDistributorInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DistributorCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDistributorInput>;
};

export type DistributorCreateWithoutContactInput = {
  SOPIdentifier?: InputMaybe<DistributorCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDistributorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDistributorInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<DistributorSettingCreateNestedOneWithoutDistributorInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DistributorCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDistributorInput>;
};

export type DistributorCreateWithoutCustomersInput = {
  SOPIdentifier?: InputMaybe<DistributorCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDistributorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDistributorInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<DistributorSettingCreateNestedOneWithoutDistributorInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DistributorCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDistributorInput>;
};

export type DistributorCreateWithoutDealersInput = {
  SOPIdentifier?: InputMaybe<DistributorCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDistributorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDistributorInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<DistributorSettingCreateNestedOneWithoutDistributorInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DistributorCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDistributorInput>;
};

export type DistributorCreateWithoutDemBundlesInput = {
  SOPIdentifier?: InputMaybe<DistributorCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDistributorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDistributorInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<DistributorSettingCreateNestedOneWithoutDistributorInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DistributorCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDistributorInput>;
};

export type DistributorCreateWithoutSubscriptionsInput = {
  SOPIdentifier?: InputMaybe<DistributorCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDistributorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDistributorInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<DistributorSettingCreateNestedOneWithoutDistributorInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DistributorCreatestripeSubscriptionIdsInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDistributorInput>;
};

export type DistributorCreateWithoutTermAgreementInput = {
  SOPIdentifier?: InputMaybe<DistributorCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDistributorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDistributorInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<DistributorSettingCreateNestedOneWithoutDistributorInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DistributorCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDistributorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutDistributorInput>;
};

export type DistributorCreateWithoutUsersInput = {
  SOPIdentifier?: InputMaybe<DistributorCreateSopIdentifierInput>;
  addresses?: InputMaybe<AddressCreateNestedManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactCreateNestedOneWithoutDistributorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<CustomerCreateNestedManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerCreateNestedManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserCreateNestedManyWithoutDistributorInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  onboarding?: InputMaybe<OnboardingStepEnum>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<DistributorSettingCreateNestedOneWithoutDistributorInput>;
  stripeId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionIds?: InputMaybe<DistributorCreatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionCreateNestedManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DistributorCreatestripeSubscriptionIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

/** Data to be submitted for distributorDetailsOnboarding mutation. */
export type DistributorDetailsOnboardingInput = {
  billingAddress: StripeAddressInput;
  contact: ContactCreateNestedOneWithoutDistributorInput;
  currency: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  vatId: Scalars['String'];
};

export type DistributorSetting = {
  __typename?: 'DistributorSetting';
  createdAt: Scalars['DateTime'];
  dateFormat?: Maybe<Scalars['String']>;
  distributor: Distributor;
  distributorId: Scalars['String'];
  id: Scalars['String'];
  recycled: Scalars['Boolean'];
  timeFormat?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type DistributorSettingCreateNestedOneWithoutDistributorInput = {
  connect?: InputMaybe<DistributorSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorSettingCreateOrConnectWithoutDistributorInput>;
  create?: InputMaybe<DistributorSettingCreateWithoutDistributorInput>;
};

export type DistributorSettingCreateOrConnectWithoutDistributorInput = {
  create: DistributorSettingCreateWithoutDistributorInput;
  where: DistributorSettingWhereUniqueInput;
};

export type DistributorSettingCreateWithoutDistributorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dateFormat?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  timeFormat?: InputMaybe<Scalars['String']>;
  timeZone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DistributorSettingUpdateOneWithoutDistributorInput = {
  connect?: InputMaybe<DistributorSettingWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorSettingCreateOrConnectWithoutDistributorInput>;
  create?: InputMaybe<DistributorSettingCreateWithoutDistributorInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DistributorSettingUpdateWithoutDistributorInput>;
  upsert?: InputMaybe<DistributorSettingUpsertWithoutDistributorInput>;
};

export type DistributorSettingUpdateWithoutDistributorInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dateFormat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  timeFormat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  timeZone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DistributorSettingUpsertWithoutDistributorInput = {
  create: DistributorSettingCreateWithoutDistributorInput;
  update: DistributorSettingUpdateWithoutDistributorInput;
};

export type DistributorSettingWhereInput = {
  AND?: InputMaybe<Array<DistributorSettingWhereInput>>;
  NOT?: InputMaybe<Array<DistributorSettingWhereInput>>;
  OR?: InputMaybe<Array<DistributorSettingWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dateFormat?: InputMaybe<StringNullableFilter>;
  distributor?: InputMaybe<DistributorWhereInput>;
  distributorId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  timeFormat?: InputMaybe<StringNullableFilter>;
  timeZone?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type DistributorSettingWhereUniqueInput = {
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type DistributorUpdateInput = {
  SOPIdentifier?: InputMaybe<DistributorUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDistributorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDistributorInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<DistributorSettingUpdateOneWithoutDistributorInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DistributorUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDistributorInput>;
};

export type DistributorUpdateOneWithoutAddressesInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutAddressesInput>;
  create?: InputMaybe<DistributorCreateWithoutAddressesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DistributorUpdateWithoutAddressesInput>;
  upsert?: InputMaybe<DistributorUpsertWithoutAddressesInput>;
};

export type DistributorUpdateOneWithoutContactInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutContactInput>;
  create?: InputMaybe<DistributorCreateWithoutContactInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DistributorUpdateWithoutContactInput>;
  upsert?: InputMaybe<DistributorUpsertWithoutContactInput>;
};

export type DistributorUpdateOneWithoutCustomersInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutCustomersInput>;
  create?: InputMaybe<DistributorCreateWithoutCustomersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DistributorUpdateWithoutCustomersInput>;
  upsert?: InputMaybe<DistributorUpsertWithoutCustomersInput>;
};

export type DistributorUpdateOneWithoutDealersInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutDealersInput>;
  create?: InputMaybe<DistributorCreateWithoutDealersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DistributorUpdateWithoutDealersInput>;
  upsert?: InputMaybe<DistributorUpsertWithoutDealersInput>;
};

export type DistributorUpdateOneWithoutDemBundlesInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutDemBundlesInput>;
  create?: InputMaybe<DistributorCreateWithoutDemBundlesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DistributorUpdateWithoutDemBundlesInput>;
  upsert?: InputMaybe<DistributorUpsertWithoutDemBundlesInput>;
};

export type DistributorUpdateOneWithoutSubscriptionsInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutSubscriptionsInput>;
  create?: InputMaybe<DistributorCreateWithoutSubscriptionsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DistributorUpdateWithoutSubscriptionsInput>;
  upsert?: InputMaybe<DistributorUpsertWithoutSubscriptionsInput>;
};

export type DistributorUpdateOneWithoutTermAgreementInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutTermAgreementInput>;
  create?: InputMaybe<DistributorCreateWithoutTermAgreementInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DistributorUpdateWithoutTermAgreementInput>;
  upsert?: InputMaybe<DistributorUpsertWithoutTermAgreementInput>;
};

export type DistributorUpdateOneWithoutUsersInput = {
  connect?: InputMaybe<DistributorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DistributorCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<DistributorCreateWithoutUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DistributorUpdateWithoutUsersInput>;
  upsert?: InputMaybe<DistributorUpsertWithoutUsersInput>;
};

export type DistributorUpdateSopIdentifierInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type DistributorUpdateWithoutAddressesInput = {
  SOPIdentifier?: InputMaybe<DistributorUpdateSopIdentifierInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDistributorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDistributorInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<DistributorSettingUpdateOneWithoutDistributorInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DistributorUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDistributorInput>;
};

export type DistributorUpdateWithoutContactInput = {
  SOPIdentifier?: InputMaybe<DistributorUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDistributorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDistributorInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<DistributorSettingUpdateOneWithoutDistributorInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DistributorUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDistributorInput>;
};

export type DistributorUpdateWithoutCustomersInput = {
  SOPIdentifier?: InputMaybe<DistributorUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDistributorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDistributorInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<DistributorSettingUpdateOneWithoutDistributorInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DistributorUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDistributorInput>;
};

export type DistributorUpdateWithoutDealersInput = {
  SOPIdentifier?: InputMaybe<DistributorUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDistributorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDistributorInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<DistributorSettingUpdateOneWithoutDistributorInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DistributorUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDistributorInput>;
};

export type DistributorUpdateWithoutDemBundlesInput = {
  SOPIdentifier?: InputMaybe<DistributorUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDistributorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDistributorInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<DistributorSettingUpdateOneWithoutDistributorInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DistributorUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDistributorInput>;
};

export type DistributorUpdateWithoutSubscriptionsInput = {
  SOPIdentifier?: InputMaybe<DistributorUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDistributorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDistributorInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<DistributorSettingUpdateOneWithoutDistributorInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DistributorUpdatestripeSubscriptionIdsInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDistributorInput>;
};

export type DistributorUpdateWithoutTermAgreementInput = {
  SOPIdentifier?: InputMaybe<DistributorUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDistributorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDistributorInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<DistributorSettingUpdateOneWithoutDistributorInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DistributorUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDistributorInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutDistributorInput>;
};

export type DistributorUpdateWithoutUsersInput = {
  SOPIdentifier?: InputMaybe<DistributorUpdateSopIdentifierInput>;
  addresses?: InputMaybe<AddressUpdateManyWithoutDistributorInput>;
  contact?: InputMaybe<ContactUpdateOneWithoutDistributorInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  customers?: InputMaybe<CustomerUpdateManyWithoutDistributorInput>;
  dealers?: InputMaybe<DealerUpdateManyWithoutDistributorInput>;
  demBundles?: InputMaybe<BundleOnUserUpdateManyWithoutDistributorInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  settings?: InputMaybe<DistributorSettingUpdateOneWithoutDistributorInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  stripeSubscriptionIds?: InputMaybe<DistributorUpdatestripeSubscriptionIdsInput>;
  subscriptions?: InputMaybe<SubscriptionUpdateManyWithoutDistributorInput>;
  termAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutDistributorInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type DistributorUpdatestripeSubscriptionIdsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type DistributorUpsertWithoutAddressesInput = {
  create: DistributorCreateWithoutAddressesInput;
  update: DistributorUpdateWithoutAddressesInput;
};

export type DistributorUpsertWithoutContactInput = {
  create: DistributorCreateWithoutContactInput;
  update: DistributorUpdateWithoutContactInput;
};

export type DistributorUpsertWithoutCustomersInput = {
  create: DistributorCreateWithoutCustomersInput;
  update: DistributorUpdateWithoutCustomersInput;
};

export type DistributorUpsertWithoutDealersInput = {
  create: DistributorCreateWithoutDealersInput;
  update: DistributorUpdateWithoutDealersInput;
};

export type DistributorUpsertWithoutDemBundlesInput = {
  create: DistributorCreateWithoutDemBundlesInput;
  update: DistributorUpdateWithoutDemBundlesInput;
};

export type DistributorUpsertWithoutSubscriptionsInput = {
  create: DistributorCreateWithoutSubscriptionsInput;
  update: DistributorUpdateWithoutSubscriptionsInput;
};

export type DistributorUpsertWithoutTermAgreementInput = {
  create: DistributorCreateWithoutTermAgreementInput;
  update: DistributorUpdateWithoutTermAgreementInput;
};

export type DistributorUpsertWithoutUsersInput = {
  create: DistributorCreateWithoutUsersInput;
  update: DistributorUpdateWithoutUsersInput;
};

export type DistributorWhereInput = {
  AND?: InputMaybe<Array<DistributorWhereInput>>;
  NOT?: InputMaybe<Array<DistributorWhereInput>>;
  OR?: InputMaybe<Array<DistributorWhereInput>>;
  SOPIdentifier?: InputMaybe<StringNullableListFilter>;
  addresses?: InputMaybe<AddressListRelationFilter>;
  contact?: InputMaybe<ContactWhereInput>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringNullableFilter>;
  customers?: InputMaybe<CustomerListRelationFilter>;
  dealers?: InputMaybe<DealerListRelationFilter>;
  demBundles?: InputMaybe<BundleOnUserListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  onboarding?: InputMaybe<EnumOnboardingStepEnumFilter>;
  recycled?: InputMaybe<BoolFilter>;
  settings?: InputMaybe<DistributorSettingWhereInput>;
  stripeId?: InputMaybe<StringNullableFilter>;
  stripeSubscriptionIds?: InputMaybe<StringNullableListFilter>;
  subscriptions?: InputMaybe<SubscriptionListRelationFilter>;
  termAgreement?: InputMaybe<TermsAgreementWhereInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type DistributorWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Download = {
  __typename?: 'Download';
  createdAt: Scalars['DateTime'];
  descTranslations: Array<TranslationContent>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  modelList?: Maybe<Scalars['String']>;
  models: Array<DeviceModel>;
  name?: Maybe<Scalars['String']>;
  nameTranslations: Array<TranslationContent>;
  type: DownloadType;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type DownloadDescTranslationsArgs = {
  after?: InputMaybe<TranslationContentWhereUniqueInput>;
  before?: InputMaybe<TranslationContentWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DownloadDescriptionArgs = {
  where: TranslationContentWhere;
};

export type DownloadModelsArgs = {
  after?: InputMaybe<DeviceModelWhereUniqueInput>;
  before?: InputMaybe<DeviceModelWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DownloadNameArgs = {
  where: TranslationContentWhere;
};

export type DownloadNameTranslationsArgs = {
  after?: InputMaybe<TranslationContentWhereUniqueInput>;
  before?: InputMaybe<TranslationContentWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type DownloadCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutDownloadDescInput>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  models?: InputMaybe<DeviceModelCreateNestedManyWithoutDownloadsInput>;
  name?: InputMaybe<Scalars['String']>;
  nameTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutDownloadNameInput>;
  type: DownloadType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type DownloadCreateNestedOneWithoutDescTranslationsInput = {
  connect?: InputMaybe<DownloadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DownloadCreateOrConnectWithoutDescTranslationsInput>;
  create?: InputMaybe<DownloadCreateWithoutDescTranslationsInput>;
};

export type DownloadCreateNestedOneWithoutNameTranslationsInput = {
  connect?: InputMaybe<DownloadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DownloadCreateOrConnectWithoutNameTranslationsInput>;
  create?: InputMaybe<DownloadCreateWithoutNameTranslationsInput>;
};

export type DownloadCreateOrConnectWithoutDescTranslationsInput = {
  create: DownloadCreateWithoutDescTranslationsInput;
  where: DownloadWhereUniqueInput;
};

export type DownloadCreateOrConnectWithoutNameTranslationsInput = {
  create: DownloadCreateWithoutNameTranslationsInput;
  where: DownloadWhereUniqueInput;
};

export type DownloadCreateWithoutDescTranslationsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  models?: InputMaybe<DeviceModelCreateNestedManyWithoutDownloadsInput>;
  nameTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutDownloadNameInput>;
  type: DownloadType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type DownloadCreateWithoutNameTranslationsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutDownloadDescInput>;
  id?: InputMaybe<Scalars['String']>;
  models?: InputMaybe<DeviceModelCreateNestedManyWithoutDownloadsInput>;
  type: DownloadType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export enum DownloadType {
  App = 'APP',
  Firmware = 'FIRMWARE',
  Tool = 'TOOL',
}

export type DownloadUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descTranslations?: InputMaybe<TranslationContentUpdateManyWithoutDownloadDescInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  models?: InputMaybe<DeviceModelUpdateManyWithoutDownloadsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  nameTranslations?: InputMaybe<TranslationContentUpdateManyWithoutDownloadNameInput>;
  type?: InputMaybe<EnumDownloadTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DownloadUpdateOneWithoutDescTranslationsInput = {
  connect?: InputMaybe<DownloadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DownloadCreateOrConnectWithoutDescTranslationsInput>;
  create?: InputMaybe<DownloadCreateWithoutDescTranslationsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DownloadUpdateWithoutDescTranslationsInput>;
  upsert?: InputMaybe<DownloadUpsertWithoutDescTranslationsInput>;
};

export type DownloadUpdateOneWithoutNameTranslationsInput = {
  connect?: InputMaybe<DownloadWhereUniqueInput>;
  connectOrCreate?: InputMaybe<DownloadCreateOrConnectWithoutNameTranslationsInput>;
  create?: InputMaybe<DownloadCreateWithoutNameTranslationsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<DownloadUpdateWithoutNameTranslationsInput>;
  upsert?: InputMaybe<DownloadUpsertWithoutNameTranslationsInput>;
};

export type DownloadUpdateWithoutDescTranslationsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  models?: InputMaybe<DeviceModelUpdateManyWithoutDownloadsInput>;
  nameTranslations?: InputMaybe<TranslationContentUpdateManyWithoutDownloadNameInput>;
  type?: InputMaybe<EnumDownloadTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DownloadUpdateWithoutNameTranslationsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descTranslations?: InputMaybe<TranslationContentUpdateManyWithoutDownloadDescInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  models?: InputMaybe<DeviceModelUpdateManyWithoutDownloadsInput>;
  type?: InputMaybe<EnumDownloadTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type DownloadUpsertWithoutDescTranslationsInput = {
  create: DownloadCreateWithoutDescTranslationsInput;
  update: DownloadUpdateWithoutDescTranslationsInput;
};

export type DownloadUpsertWithoutNameTranslationsInput = {
  create: DownloadCreateWithoutNameTranslationsInput;
  update: DownloadUpdateWithoutNameTranslationsInput;
};

export type DownloadWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type EditDemDeviceInput = {
  deviceId: Scalars['String'];
  groupIdsToAdd: Array<InputMaybe<Scalars['String']>>;
  groupIdsToRemove: Array<InputMaybe<Scalars['String']>>;
  modelId: Scalars['String'];
  serialNumber: Scalars['String'];
};

export type EditManySimInput = {
  customerId?: InputMaybe<Scalars['String']>;
  groupCode: Scalars['String'];
  iccid: Array<InputMaybe<Scalars['String']>>;
  limit?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  warning?: InputMaybe<Scalars['Int']>;
};

export type EditSimInput = {
  customerId?: InputMaybe<Scalars['String']>;
  groupCode?: InputMaybe<Scalars['String']>;
  iccid: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  warning?: InputMaybe<Scalars['Int']>;
};

export type EditUserInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export type EditUserMetaInput = {
  id: Scalars['String'];
  onboardingStep: Scalars['String'];
  termsVersion: Scalars['String'];
};

export enum EmergencyActionEnum {
  Alarm = 'ALARM',
  Group = 'GROUP',
  Private = 'PRIVATE',
}

export type EnumAddressTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<AddressTypeEnum>;
};

export type EnumAddressTypeEnumFilter = {
  equals?: InputMaybe<AddressTypeEnum>;
  in?: InputMaybe<Array<AddressTypeEnum>>;
  not?: InputMaybe<NestedEnumAddressTypeEnumFilter>;
  notIn?: InputMaybe<Array<AddressTypeEnum>>;
};

export type EnumAnswerModeEnumNullableFilter = {
  equals?: InputMaybe<AnswerModeEnum>;
  in?: InputMaybe<Array<AnswerModeEnum>>;
  not?: InputMaybe<NestedEnumAnswerModeEnumNullableFilter>;
  notIn?: InputMaybe<Array<AnswerModeEnum>>;
};

export type EnumDeviceGroupTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<DeviceGroupTypeEnum>;
};

export type EnumDeviceGroupTypeEnumFilter = {
  equals?: InputMaybe<DeviceGroupTypeEnum>;
  in?: InputMaybe<Array<DeviceGroupTypeEnum>>;
  not?: InputMaybe<NestedEnumDeviceGroupTypeEnumFilter>;
  notIn?: InputMaybe<Array<DeviceGroupTypeEnum>>;
};

export type EnumDownloadTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<DownloadType>;
};

export type EnumEmergencyActionEnumNullableFilter = {
  equals?: InputMaybe<EmergencyActionEnum>;
  in?: InputMaybe<Array<EmergencyActionEnum>>;
  not?: InputMaybe<NestedEnumEmergencyActionEnumNullableFilter>;
  notIn?: InputMaybe<Array<EmergencyActionEnum>>;
};

export type EnumFloorPermissionsEnumNullableListFilter = {
  equals?: InputMaybe<Array<FloorPermissionsEnum>>;
  has?: InputMaybe<FloorPermissionsEnum>;
  hasEvery?: InputMaybe<Array<FloorPermissionsEnum>>;
  hasSome?: InputMaybe<Array<FloorPermissionsEnum>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type EnumGroupPropertyEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<GroupPropertyEnum>;
};

export type EnumGroupPropertyEnumFilter = {
  equals?: InputMaybe<GroupPropertyEnum>;
  in?: InputMaybe<Array<GroupPropertyEnum>>;
  not?: InputMaybe<NestedEnumGroupPropertyEnumFilter>;
  notIn?: InputMaybe<Array<GroupPropertyEnum>>;
};

export type EnumOnboardingStepEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<OnboardingStepEnum>;
};

export type EnumOnboardingStepEnumFilter = {
  equals?: InputMaybe<OnboardingStepEnum>;
  in?: InputMaybe<Array<OnboardingStepEnum>>;
  not?: InputMaybe<NestedEnumOnboardingStepEnumFilter>;
  notIn?: InputMaybe<Array<OnboardingStepEnum>>;
};

export type EnumPermissionTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<PermissionTypeEnum>;
};

export type EnumPermissionTypeEnumFilter = {
  equals?: InputMaybe<PermissionTypeEnum>;
  in?: InputMaybe<Array<PermissionTypeEnum>>;
  not?: InputMaybe<NestedEnumPermissionTypeEnumFilter>;
  notIn?: InputMaybe<Array<PermissionTypeEnum>>;
};

export type EnumProductTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ProductType>;
};

export type EnumProductTypeFilter = {
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type EnumSimStatusEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<SimStatusEnum>;
};

export type EnumSimStatusEnumFilter = {
  equals?: InputMaybe<SimStatusEnum>;
  in?: InputMaybe<Array<SimStatusEnum>>;
  not?: InputMaybe<NestedEnumSimStatusEnumFilter>;
  notIn?: InputMaybe<Array<SimStatusEnum>>;
};

export type EnumSubscriptionTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<SubscriptionType>;
};

export type EnumSubscriptionTypeFilter = {
  equals?: InputMaybe<SubscriptionType>;
  in?: InputMaybe<Array<SubscriptionType>>;
  not?: InputMaybe<NestedEnumSubscriptionTypeFilter>;
  notIn?: InputMaybe<Array<SubscriptionType>>;
};

export type EnumTermTypeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<TermTypeEnum>;
};

export type EnumTermTypeEnumFilter = {
  equals?: InputMaybe<TermTypeEnum>;
  in?: InputMaybe<Array<TermTypeEnum>>;
  not?: InputMaybe<NestedEnumTermTypeEnumFilter>;
  notIn?: InputMaybe<Array<TermTypeEnum>>;
};

export type EnumVoiceQualityEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<VoiceQualityEnum>;
};

export type EnumVoiceQualityEnumFilter = {
  equals?: InputMaybe<VoiceQualityEnum>;
  in?: InputMaybe<Array<VoiceQualityEnum>>;
  not?: InputMaybe<NestedEnumVoiceQualityEnumFilter>;
  notIn?: InputMaybe<Array<VoiceQualityEnum>>;
};

/** The importance of evidence for retention. */
export enum EvidenceImportance {
  Important = 'Important',
  Normal = 'Normal',
}

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export enum FloorPermissionsEnum {
  Interrupt = 'INTERRUPT',
  Queue = 'QUEUE',
  Request = 'REQUEST',
}

/** The code of a group. */
export enum GroupCode {
  Msceo2Gf = 'MSCEO2GF',
  Msceo30Gf = 'MSCEO30GF',
  Mseo100F = 'MSEO100F',
  Mseu01Gb = 'MSEU01GB',
  Mseu010G = 'MSEU010G',
  Mskceuf = 'MSKCEUF',
  Mskceuo = 'MSKCEUO',
  Mskcp = 'MSKCP',
  Mskcs = 'MSKCS',
}

export enum GroupPropertyEnum {
  Default = 'DEFAULT',
  Hidden = 'HIDDEN',
  Response = 'RESPONSE',
}

/** The type of a group. */
export enum GroupType {
  Live = 'Live',
  Pending = 'Pending',
  Suspended = 'Suspended',
}

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type Invoice = {
  __typename?: 'Invoice';
  created: Scalars['String'];
  dueDate: Scalars['String'];
  invoiceNumber: Scalars['String'];
  items: Array<InvoiceItem>;
  pdf: Scalars['String'];
  periodEnd: Scalars['String'];
  periodStart: Scalars['String'];
  status: Scalars['String'];
  total: Scalars['Float'];
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  description: Scalars['String'];
  quantity: Scalars['Int'];
  tax: Scalars['Float'];
  total: Scalars['Float'];
};

export type ListDealerSims = {
  __typename?: 'ListDealerSims';
  sims: Array<SimCard>;
  total?: Maybe<Scalars['Int']>;
};

export type ListSimCards = {
  __typename?: 'ListSimCards';
  simCards: Array<SimCard>;
  total: Scalars['Int'];
};

export type Manual = {
  __typename?: 'Manual';
  chapters: Array<ManualChapter>;
  createdAt: Scalars['DateTime'];
  descTranslations: Array<TranslationContent>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  pages: Array<ManualPage>;
  title?: Maybe<Scalars['String']>;
  titleTranslations: Array<TranslationContent>;
  totalPages: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type ManualChaptersArgs = {
  after?: InputMaybe<ManualChapterWhereUniqueInput>;
  before?: InputMaybe<ManualChapterWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ManualDescTranslationsArgs = {
  after?: InputMaybe<TranslationContentWhereUniqueInput>;
  before?: InputMaybe<TranslationContentWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ManualDescriptionArgs = {
  where: TranslationContentWhere;
};

export type ManualPagesArgs = {
  after?: InputMaybe<ManualPageWhereUniqueInput>;
  before?: InputMaybe<ManualPageWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ManualTitleArgs = {
  where: TranslationContentWhere;
};

export type ManualTitleTranslationsArgs = {
  after?: InputMaybe<TranslationContentWhereUniqueInput>;
  before?: InputMaybe<TranslationContentWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ManualChapter = {
  __typename?: 'ManualChapter';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  manual: Manual;
  manualId: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  pages: Array<ManualPage>;
  title?: Maybe<Scalars['String']>;
  titleTranslations: Array<TranslationContent>;
  updatedAt: Scalars['DateTime'];
};

export type ManualChapterPagesArgs = {
  after?: InputMaybe<ManualPageWhereUniqueInput>;
  before?: InputMaybe<ManualPageWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ManualChapterTitleArgs = {
  where: TranslationContentWhere;
};

export type ManualChapterTitleTranslationsArgs = {
  after?: InputMaybe<TranslationContentWhereUniqueInput>;
  before?: InputMaybe<TranslationContentWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ManualChapterCreateInput = {
  manual: ManualOnManualChapter;
  title: Scalars['String'];
};

export type ManualChapterCreateManyManualInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualChapterCreateManyManualInputEnvelope = {
  data?: InputMaybe<Array<ManualChapterCreateManyManualInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ManualChapterCreateNestedManyWithoutManualInput = {
  connect?: InputMaybe<Array<ManualChapterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ManualChapterCreateOrConnectWithoutManualInput>
  >;
  create?: InputMaybe<Array<ManualChapterCreateWithoutManualInput>>;
  createMany?: InputMaybe<ManualChapterCreateManyManualInputEnvelope>;
};

export type ManualChapterCreateNestedOneWithoutPagesInput = {
  connect?: InputMaybe<ManualChapterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualChapterCreateOrConnectWithoutPagesInput>;
  create?: InputMaybe<ManualChapterCreateWithoutPagesInput>;
};

export type ManualChapterCreateNestedOneWithoutTitleTranslationsInput = {
  connect?: InputMaybe<ManualChapterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualChapterCreateOrConnectWithoutTitleTranslationsInput>;
  create?: InputMaybe<ManualChapterCreateWithoutTitleTranslationsInput>;
};

export type ManualChapterCreateOrConnectWithoutManualInput = {
  create: ManualChapterCreateWithoutManualInput;
  where: ManualChapterWhereUniqueInput;
};

export type ManualChapterCreateOrConnectWithoutPagesInput = {
  create: ManualChapterCreateWithoutPagesInput;
  where: ManualChapterWhereUniqueInput;
};

export type ManualChapterCreateOrConnectWithoutTitleTranslationsInput = {
  create: ManualChapterCreateWithoutTitleTranslationsInput;
  where: ManualChapterWhereUniqueInput;
};

export type ManualChapterCreateWithoutManualInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pages?: InputMaybe<ManualPageCreateNestedManyWithoutChapterInput>;
  titleTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutChapterTitleInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualChapterCreateWithoutPagesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  manual: ManualCreateNestedOneWithoutChaptersInput;
  order?: InputMaybe<Scalars['Int']>;
  titleTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutChapterTitleInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualChapterCreateWithoutTitleTranslationsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  manual: ManualCreateNestedOneWithoutChaptersInput;
  order?: InputMaybe<Scalars['Int']>;
  pages?: InputMaybe<ManualPageCreateNestedManyWithoutChapterInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualChapterOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ManualChapterScalarWhereInput = {
  AND?: InputMaybe<Array<ManualChapterScalarWhereInput>>;
  NOT?: InputMaybe<Array<ManualChapterScalarWhereInput>>;
  OR?: InputMaybe<Array<ManualChapterScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  manualId?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ManualChapterUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualChapterUpdateManyWithWhereWithoutManualInput = {
  data: ManualChapterUpdateManyMutationInput;
  where: ManualChapterScalarWhereInput;
};

export type ManualChapterUpdateManyWithoutManualInput = {
  connect?: InputMaybe<Array<ManualChapterWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ManualChapterCreateOrConnectWithoutManualInput>
  >;
  create?: InputMaybe<Array<ManualChapterCreateWithoutManualInput>>;
  createMany?: InputMaybe<ManualChapterCreateManyManualInputEnvelope>;
  delete?: InputMaybe<Array<ManualChapterWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ManualChapterScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ManualChapterWhereUniqueInput>>;
  set?: InputMaybe<Array<ManualChapterWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ManualChapterUpdateWithWhereUniqueWithoutManualInput>
  >;
  updateMany?: InputMaybe<
    Array<ManualChapterUpdateManyWithWhereWithoutManualInput>
  >;
  upsert?: InputMaybe<
    Array<ManualChapterUpsertWithWhereUniqueWithoutManualInput>
  >;
};

export type ManualChapterUpdateOneRequiredWithoutPagesInput = {
  connect?: InputMaybe<ManualChapterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualChapterCreateOrConnectWithoutPagesInput>;
  create?: InputMaybe<ManualChapterCreateWithoutPagesInput>;
  update?: InputMaybe<ManualChapterUpdateWithoutPagesInput>;
  upsert?: InputMaybe<ManualChapterUpsertWithoutPagesInput>;
};

export type ManualChapterUpdateOneWithoutTitleTranslationsInput = {
  connect?: InputMaybe<ManualChapterWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualChapterCreateOrConnectWithoutTitleTranslationsInput>;
  create?: InputMaybe<ManualChapterCreateWithoutTitleTranslationsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ManualChapterUpdateWithoutTitleTranslationsInput>;
  upsert?: InputMaybe<ManualChapterUpsertWithoutTitleTranslationsInput>;
};

export type ManualChapterUpdateWithWhereUniqueWithoutManualInput = {
  data: ManualChapterUpdateWithoutManualInput;
  where: ManualChapterWhereUniqueInput;
};

export type ManualChapterUpdateWithoutManualInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pages?: InputMaybe<ManualPageUpdateManyWithoutChapterInput>;
  titleTranslations?: InputMaybe<TranslationContentUpdateManyWithoutChapterTitleInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualChapterUpdateWithoutPagesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manual?: InputMaybe<ManualUpdateOneRequiredWithoutChaptersInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  titleTranslations?: InputMaybe<TranslationContentUpdateManyWithoutChapterTitleInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualChapterUpdateWithoutTitleTranslationsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manual?: InputMaybe<ManualUpdateOneRequiredWithoutChaptersInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pages?: InputMaybe<ManualPageUpdateManyWithoutChapterInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualChapterUpsertWithWhereUniqueWithoutManualInput = {
  create: ManualChapterCreateWithoutManualInput;
  update: ManualChapterUpdateWithoutManualInput;
  where: ManualChapterWhereUniqueInput;
};

export type ManualChapterUpsertWithoutPagesInput = {
  create: ManualChapterCreateWithoutPagesInput;
  update: ManualChapterUpdateWithoutPagesInput;
};

export type ManualChapterUpsertWithoutTitleTranslationsInput = {
  create: ManualChapterCreateWithoutTitleTranslationsInput;
  update: ManualChapterUpdateWithoutTitleTranslationsInput;
};

export type ManualChapterWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ManualCreateInput = {
  description: Scalars['String'];
  title: Scalars['String'];
};

export type ManualCreateNestedOneWithoutChaptersInput = {
  connect?: InputMaybe<ManualWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualCreateOrConnectWithoutChaptersInput>;
  create?: InputMaybe<ManualCreateWithoutChaptersInput>;
};

export type ManualCreateNestedOneWithoutDescTranslationsInput = {
  connect?: InputMaybe<ManualWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualCreateOrConnectWithoutDescTranslationsInput>;
  create?: InputMaybe<ManualCreateWithoutDescTranslationsInput>;
};

export type ManualCreateNestedOneWithoutPagesInput = {
  connect?: InputMaybe<ManualWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualCreateOrConnectWithoutPagesInput>;
  create?: InputMaybe<ManualCreateWithoutPagesInput>;
};

export type ManualCreateNestedOneWithoutTitleTranslationsInput = {
  connect?: InputMaybe<ManualWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualCreateOrConnectWithoutTitleTranslationsInput>;
  create?: InputMaybe<ManualCreateWithoutTitleTranslationsInput>;
};

export type ManualCreateOrConnectWithoutChaptersInput = {
  create: ManualCreateWithoutChaptersInput;
  where: ManualWhereUniqueInput;
};

export type ManualCreateOrConnectWithoutDescTranslationsInput = {
  create: ManualCreateWithoutDescTranslationsInput;
  where: ManualWhereUniqueInput;
};

export type ManualCreateOrConnectWithoutPagesInput = {
  create: ManualCreateWithoutPagesInput;
  where: ManualWhereUniqueInput;
};

export type ManualCreateOrConnectWithoutTitleTranslationsInput = {
  create: ManualCreateWithoutTitleTranslationsInput;
  where: ManualWhereUniqueInput;
};

export type ManualCreateWithoutChaptersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutManualDescInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pages?: InputMaybe<ManualPageCreateNestedManyWithoutManualInput>;
  titleTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutManualTitleInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualCreateWithoutDescTranslationsInput = {
  chapters?: InputMaybe<ManualChapterCreateNestedManyWithoutManualInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pages?: InputMaybe<ManualPageCreateNestedManyWithoutManualInput>;
  titleTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutManualTitleInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualCreateWithoutPagesInput = {
  chapters?: InputMaybe<ManualChapterCreateNestedManyWithoutManualInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutManualDescInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  titleTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutManualTitleInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualCreateWithoutTitleTranslationsInput = {
  chapters?: InputMaybe<ManualChapterCreateNestedManyWithoutManualInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  descTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutManualDescInput>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  pages?: InputMaybe<ManualPageCreateNestedManyWithoutManualInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualOnManualChapter = {
  connect?: InputMaybe<ManualWhereUniqueInput>;
};

export type ManualOrderByWithRelationInput = {
  chapters?: InputMaybe<ManualChapterOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  descTranslations?: InputMaybe<TranslationContentOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  pages?: InputMaybe<ManualPageOrderByRelationAggregateInput>;
  titleTranslations?: InputMaybe<TranslationContentOrderByRelationAggregateInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ManualPage = {
  __typename?: 'ManualPage';
  chapter: ManualChapter;
  chapterId: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  contentTranslations: Array<TranslationContent>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  manual: Manual;
  manualId: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  titleTranslations: Array<TranslationContent>;
  updatedAt: Scalars['DateTime'];
};

export type ManualPageContentArgs = {
  where: TranslationContentWhere;
};

export type ManualPageContentTranslationsArgs = {
  after?: InputMaybe<TranslationContentWhereUniqueInput>;
  before?: InputMaybe<TranslationContentWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ManualPageTitleArgs = {
  where: TranslationContentWhere;
};

export type ManualPageTitleTranslationsArgs = {
  after?: InputMaybe<TranslationContentWhereUniqueInput>;
  before?: InputMaybe<TranslationContentWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type ManualPageCreateInput = {
  chapter: ManualPageOnManualChapter;
  manual: ManualPageOnManual;
  title: Scalars['String'];
};

export type ManualPageCreateManyChapterInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  manualId: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualPageCreateManyChapterInputEnvelope = {
  data?: InputMaybe<Array<ManualPageCreateManyChapterInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ManualPageCreateManyManualInput = {
  chapterId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualPageCreateManyManualInputEnvelope = {
  data?: InputMaybe<Array<ManualPageCreateManyManualInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ManualPageCreateNestedManyWithoutChapterInput = {
  connect?: InputMaybe<Array<ManualPageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ManualPageCreateOrConnectWithoutChapterInput>
  >;
  create?: InputMaybe<Array<ManualPageCreateWithoutChapterInput>>;
  createMany?: InputMaybe<ManualPageCreateManyChapterInputEnvelope>;
};

export type ManualPageCreateNestedManyWithoutManualInput = {
  connect?: InputMaybe<Array<ManualPageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ManualPageCreateOrConnectWithoutManualInput>
  >;
  create?: InputMaybe<Array<ManualPageCreateWithoutManualInput>>;
  createMany?: InputMaybe<ManualPageCreateManyManualInputEnvelope>;
};

export type ManualPageCreateNestedOneWithoutContentTranslationsInput = {
  connect?: InputMaybe<ManualPageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualPageCreateOrConnectWithoutContentTranslationsInput>;
  create?: InputMaybe<ManualPageCreateWithoutContentTranslationsInput>;
};

export type ManualPageCreateNestedOneWithoutTitleTranslationsInput = {
  connect?: InputMaybe<ManualPageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualPageCreateOrConnectWithoutTitleTranslationsInput>;
  create?: InputMaybe<ManualPageCreateWithoutTitleTranslationsInput>;
};

export type ManualPageCreateOrConnectWithoutChapterInput = {
  create: ManualPageCreateWithoutChapterInput;
  where: ManualPageWhereUniqueInput;
};

export type ManualPageCreateOrConnectWithoutContentTranslationsInput = {
  create: ManualPageCreateWithoutContentTranslationsInput;
  where: ManualPageWhereUniqueInput;
};

export type ManualPageCreateOrConnectWithoutManualInput = {
  create: ManualPageCreateWithoutManualInput;
  where: ManualPageWhereUniqueInput;
};

export type ManualPageCreateOrConnectWithoutTitleTranslationsInput = {
  create: ManualPageCreateWithoutTitleTranslationsInput;
  where: ManualPageWhereUniqueInput;
};

export type ManualPageCreateWithoutChapterInput = {
  contentTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutPageContentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  manual: ManualCreateNestedOneWithoutPagesInput;
  order?: InputMaybe<Scalars['Int']>;
  titleTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutPageTitleInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualPageCreateWithoutContentTranslationsInput = {
  chapter: ManualChapterCreateNestedOneWithoutPagesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  manual: ManualCreateNestedOneWithoutPagesInput;
  order?: InputMaybe<Scalars['Int']>;
  titleTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutPageTitleInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualPageCreateWithoutManualInput = {
  chapter: ManualChapterCreateNestedOneWithoutPagesInput;
  contentTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutPageContentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  titleTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutPageTitleInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualPageCreateWithoutTitleTranslationsInput = {
  chapter: ManualChapterCreateNestedOneWithoutPagesInput;
  contentTranslations?: InputMaybe<TranslationContentCreateNestedManyWithoutPageContentInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  manual: ManualCreateNestedOneWithoutPagesInput;
  order?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManualPageOnManual = {
  connect?: InputMaybe<ManualWhereUniqueInput>;
};

export type ManualPageOnManualChapter = {
  connect?: InputMaybe<ManualChapterWhereUniqueInput>;
};

export type ManualPageOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ManualPageScalarWhereInput = {
  AND?: InputMaybe<Array<ManualPageScalarWhereInput>>;
  NOT?: InputMaybe<Array<ManualPageScalarWhereInput>>;
  OR?: InputMaybe<Array<ManualPageScalarWhereInput>>;
  chapterId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  manualId?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ManualPageUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualPageUpdateManyWithWhereWithoutChapterInput = {
  data: ManualPageUpdateManyMutationInput;
  where: ManualPageScalarWhereInput;
};

export type ManualPageUpdateManyWithWhereWithoutManualInput = {
  data: ManualPageUpdateManyMutationInput;
  where: ManualPageScalarWhereInput;
};

export type ManualPageUpdateManyWithoutChapterInput = {
  connect?: InputMaybe<Array<ManualPageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ManualPageCreateOrConnectWithoutChapterInput>
  >;
  create?: InputMaybe<Array<ManualPageCreateWithoutChapterInput>>;
  createMany?: InputMaybe<ManualPageCreateManyChapterInputEnvelope>;
  delete?: InputMaybe<Array<ManualPageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ManualPageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ManualPageWhereUniqueInput>>;
  set?: InputMaybe<Array<ManualPageWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ManualPageUpdateWithWhereUniqueWithoutChapterInput>
  >;
  updateMany?: InputMaybe<
    Array<ManualPageUpdateManyWithWhereWithoutChapterInput>
  >;
  upsert?: InputMaybe<
    Array<ManualPageUpsertWithWhereUniqueWithoutChapterInput>
  >;
};

export type ManualPageUpdateManyWithoutManualInput = {
  connect?: InputMaybe<Array<ManualPageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ManualPageCreateOrConnectWithoutManualInput>
  >;
  create?: InputMaybe<Array<ManualPageCreateWithoutManualInput>>;
  createMany?: InputMaybe<ManualPageCreateManyManualInputEnvelope>;
  delete?: InputMaybe<Array<ManualPageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ManualPageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ManualPageWhereUniqueInput>>;
  set?: InputMaybe<Array<ManualPageWhereUniqueInput>>;
  update?: InputMaybe<Array<ManualPageUpdateWithWhereUniqueWithoutManualInput>>;
  updateMany?: InputMaybe<
    Array<ManualPageUpdateManyWithWhereWithoutManualInput>
  >;
  upsert?: InputMaybe<Array<ManualPageUpsertWithWhereUniqueWithoutManualInput>>;
};

export type ManualPageUpdateOneWithoutContentTranslationsInput = {
  connect?: InputMaybe<ManualPageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualPageCreateOrConnectWithoutContentTranslationsInput>;
  create?: InputMaybe<ManualPageCreateWithoutContentTranslationsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ManualPageUpdateWithoutContentTranslationsInput>;
  upsert?: InputMaybe<ManualPageUpsertWithoutContentTranslationsInput>;
};

export type ManualPageUpdateOneWithoutTitleTranslationsInput = {
  connect?: InputMaybe<ManualPageWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualPageCreateOrConnectWithoutTitleTranslationsInput>;
  create?: InputMaybe<ManualPageCreateWithoutTitleTranslationsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ManualPageUpdateWithoutTitleTranslationsInput>;
  upsert?: InputMaybe<ManualPageUpsertWithoutTitleTranslationsInput>;
};

export type ManualPageUpdateWithWhereUniqueWithoutChapterInput = {
  data: ManualPageUpdateWithoutChapterInput;
  where: ManualPageWhereUniqueInput;
};

export type ManualPageUpdateWithWhereUniqueWithoutManualInput = {
  data: ManualPageUpdateWithoutManualInput;
  where: ManualPageWhereUniqueInput;
};

export type ManualPageUpdateWithoutChapterInput = {
  contentTranslations?: InputMaybe<TranslationContentUpdateManyWithoutPageContentInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manual?: InputMaybe<ManualUpdateOneRequiredWithoutPagesInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  titleTranslations?: InputMaybe<TranslationContentUpdateManyWithoutPageTitleInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualPageUpdateWithoutContentTranslationsInput = {
  chapter?: InputMaybe<ManualChapterUpdateOneRequiredWithoutPagesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manual?: InputMaybe<ManualUpdateOneRequiredWithoutPagesInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  titleTranslations?: InputMaybe<TranslationContentUpdateManyWithoutPageTitleInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualPageUpdateWithoutManualInput = {
  chapter?: InputMaybe<ManualChapterUpdateOneRequiredWithoutPagesInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  contentTranslations?: InputMaybe<TranslationContentUpdateManyWithoutPageContentInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  titleTranslations?: InputMaybe<TranslationContentUpdateManyWithoutPageTitleInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualPageUpdateWithoutTitleTranslationsInput = {
  chapter?: InputMaybe<ManualChapterUpdateOneRequiredWithoutPagesInput>;
  contentTranslations?: InputMaybe<TranslationContentUpdateManyWithoutPageContentInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  manual?: InputMaybe<ManualUpdateOneRequiredWithoutPagesInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualPageUpsertWithWhereUniqueWithoutChapterInput = {
  create: ManualPageCreateWithoutChapterInput;
  update: ManualPageUpdateWithoutChapterInput;
  where: ManualPageWhereUniqueInput;
};

export type ManualPageUpsertWithWhereUniqueWithoutManualInput = {
  create: ManualPageCreateWithoutManualInput;
  update: ManualPageUpdateWithoutManualInput;
  where: ManualPageWhereUniqueInput;
};

export type ManualPageUpsertWithoutContentTranslationsInput = {
  create: ManualPageCreateWithoutContentTranslationsInput;
  update: ManualPageUpdateWithoutContentTranslationsInput;
};

export type ManualPageUpsertWithoutTitleTranslationsInput = {
  create: ManualPageCreateWithoutTitleTranslationsInput;
  update: ManualPageUpdateWithoutTitleTranslationsInput;
};

export type ManualPageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ManualUpdateInput = {
  chapters?: InputMaybe<ManualChapterUpdateManyWithoutManualInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descTranslations?: InputMaybe<TranslationContentUpdateManyWithoutManualDescInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pages?: InputMaybe<ManualPageUpdateManyWithoutManualInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  titleTranslations?: InputMaybe<TranslationContentUpdateManyWithoutManualTitleInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualUpdateOneRequiredWithoutChaptersInput = {
  connect?: InputMaybe<ManualWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualCreateOrConnectWithoutChaptersInput>;
  create?: InputMaybe<ManualCreateWithoutChaptersInput>;
  update?: InputMaybe<ManualUpdateWithoutChaptersInput>;
  upsert?: InputMaybe<ManualUpsertWithoutChaptersInput>;
};

export type ManualUpdateOneRequiredWithoutPagesInput = {
  connect?: InputMaybe<ManualWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualCreateOrConnectWithoutPagesInput>;
  create?: InputMaybe<ManualCreateWithoutPagesInput>;
  update?: InputMaybe<ManualUpdateWithoutPagesInput>;
  upsert?: InputMaybe<ManualUpsertWithoutPagesInput>;
};

export type ManualUpdateOneWithoutDescTranslationsInput = {
  connect?: InputMaybe<ManualWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualCreateOrConnectWithoutDescTranslationsInput>;
  create?: InputMaybe<ManualCreateWithoutDescTranslationsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ManualUpdateWithoutDescTranslationsInput>;
  upsert?: InputMaybe<ManualUpsertWithoutDescTranslationsInput>;
};

export type ManualUpdateOneWithoutTitleTranslationsInput = {
  connect?: InputMaybe<ManualWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ManualCreateOrConnectWithoutTitleTranslationsInput>;
  create?: InputMaybe<ManualCreateWithoutTitleTranslationsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ManualUpdateWithoutTitleTranslationsInput>;
  upsert?: InputMaybe<ManualUpsertWithoutTitleTranslationsInput>;
};

export type ManualUpdateWithoutChaptersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descTranslations?: InputMaybe<TranslationContentUpdateManyWithoutManualDescInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pages?: InputMaybe<ManualPageUpdateManyWithoutManualInput>;
  titleTranslations?: InputMaybe<TranslationContentUpdateManyWithoutManualTitleInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualUpdateWithoutDescTranslationsInput = {
  chapters?: InputMaybe<ManualChapterUpdateManyWithoutManualInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pages?: InputMaybe<ManualPageUpdateManyWithoutManualInput>;
  titleTranslations?: InputMaybe<TranslationContentUpdateManyWithoutManualTitleInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualUpdateWithoutPagesInput = {
  chapters?: InputMaybe<ManualChapterUpdateManyWithoutManualInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descTranslations?: InputMaybe<TranslationContentUpdateManyWithoutManualDescInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  titleTranslations?: InputMaybe<TranslationContentUpdateManyWithoutManualTitleInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualUpdateWithoutTitleTranslationsInput = {
  chapters?: InputMaybe<ManualChapterUpdateManyWithoutManualInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  descTranslations?: InputMaybe<TranslationContentUpdateManyWithoutManualDescInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  pages?: InputMaybe<ManualPageUpdateManyWithoutManualInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ManualUpsertWithoutChaptersInput = {
  create: ManualCreateWithoutChaptersInput;
  update: ManualUpdateWithoutChaptersInput;
};

export type ManualUpsertWithoutDescTranslationsInput = {
  create: ManualCreateWithoutDescTranslationsInput;
  update: ManualUpdateWithoutDescTranslationsInput;
};

export type ManualUpsertWithoutPagesInput = {
  create: ManualCreateWithoutPagesInput;
  update: ManualUpdateWithoutPagesInput;
};

export type ManualUpsertWithoutTitleTranslationsInput = {
  create: ManualCreateWithoutTitleTranslationsInput;
  update: ManualUpdateWithoutTitleTranslationsInput;
};

export type ManualWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

/** If the last mobile traffic was data, SMS or voice. */
export enum MobileTrafficType {
  Data = 'Data',
  Sms = 'SMS',
  Voice = 'Voice',
}

export type Mutation = {
  __typename?: 'Mutation';
  SOP_SYNC?: Maybe<SopSync>;
  SOP_SYNC_TARGETED?: Maybe<SopSync>;
  /** Attempts to create or update stripe subscriptions based on items present in the hytalk database. This mutation should become redundant and be removed as soon as the billing system is not dependent on items being created in SOP and syncd into the hytalk database (ie. when clients can purchase items directly through the hytalk frontend). */
  STRIPE_SOP_SUBSCRIPTION_SYNC?: Maybe<RecordUpdated>;
  activateDemoUsers?: Maybe<Array<Maybe<DeviceUser>>>;
  addBundle?: Maybe<BundleOnUser>;
  createCompanyMutation?: Maybe<DemCompany>;
  createDemGroup?: Maybe<DemGroup>;
  createDemUser?: Maybe<DemUsers>;
  createOneCustomer: Customer;
  createOneDealer: Dealer;
  createOneDemBundle: DemBundle;
  createOneDeviceTemplate: DeviceTemplate;
  createOneDeviceUser: DeviceUser;
  createOneDispatchTemplate: DispatchTemplate;
  createOneDistributor: Distributor;
  createOneDownload: Download;
  createOneManual?: Maybe<Manual>;
  createOneManualChapter?: Maybe<ManualChapter>;
  createOneManualPage?: Maybe<ManualPage>;
  createOneService: Service;
  createOneTerm: Term;
  /** Create a customer in stripe. A "contact" will also be created in Zoho for accounting purposes, and the relevant hytalk model with the provided id will be updated to include the new stripe customer id as the stripeId on the model. */
  createStripeCustomer?: Maybe<Scalars['String']>;
  /** Create a stripe payment method using stripe checkout sessions in setup mode (https://stripe.com/docs/api/checkout/sessions/create). */
  createStripePaymentMethod?: Maybe<CheckoutSession>;
  /** Create a stripe price linked to an existing stripe product. A Zoho item (a combination of a stripe product and a stripe price) will also be created for accounting purposes, and the id of this item will be set as "zoho_item" on the metadata of the stripe price. Stripe docs: https://stripe.com/docs/api/prices/create */
  createStripePriceOnProduct?: Maybe<StripePrice>;
  /** Create a stripe product with a price. A Zoho item will also be created for accounting purposes, and the id of that item set as "zoho_item" on the metadata of both the stripe price and stripe product. Stripe docs: https://stripe.com/docs/api/products/create, https://stripe.com/docs/api/prices/create */
  createStripeProductWithPrice?: Maybe<StripeProduct>;
  createUser: OrgUsers;
  deactivateDemoUsers?: Maybe<Array<Maybe<DeviceUser>>>;
  /** Takes the details for a dealer business onboarding page and updates the database and stripe. */
  dealerDetailsOnboarding?: Maybe<Dealer>;
  dealerVATOnboarding?: Maybe<Dealer>;
  deleteOneService?: Maybe<Service>;
  /** Remove a payment method from a stripe customer. Stripe does not allow deletion of payment methods, but once detatched, they cannot be reused. */
  deleteStripePaymentMethod?: Maybe<Id>;
  /** Updates a stripe price to active=false. Stripe does not allow the deletion of prices, but an inactive price cannot be used. */
  deleteStripePrice?: Maybe<Scalars['String']>;
  /** Delete a stripe product and any prices associated with it. Stripe does not allow deletion of prices, or products that have an associated price, so this mutation updates the product and its prices to active=false, to hide them (https://stripe.com/docs/api/products/delete). */
  deleteStripeProductWithPrice?: Maybe<Scalars['String']>;
  deleteUser?: Maybe<Scalars['String']>;
  demoStatusMutation?: Maybe<DemDevices>;
  /** Takes the details for a distributor business onboarding page and updates the database and stripe. */
  distributorDetailsOnboarding?: Maybe<Distributor>;
  editBundles?: Maybe<BundleOnUser>;
  editManySim?: Maybe<Array<Maybe<SimCard>>>;
  editSim?: Maybe<SimCard>;
  editUser?: Maybe<Scalars['String']>;
  editUserMeta?: Maybe<Scalars['String']>;
  onboardDealerRequest?: Maybe<OnboardDealerRequest>;
  orderSims?: Maybe<Scalars['String']>;
  registerDevice?: Maybe<DemDevices>;
  registerManySimCards?: Maybe<Array<Maybe<SimCard>>>;
  setSimCustomer?: Maybe<Scalars['Boolean']>;
  setSimGroup?: Maybe<SimCard>;
  updateBundleOnCompany?: Maybe<CustomerAssigned>;
  updateDealerDistributors?: Maybe<Array<Maybe<Dealer>>>;
  updateOneAddress?: Maybe<Address>;
  updateOneCustomer?: Maybe<Customer>;
  updateOneDealer?: Maybe<Dealer>;
  updateOneDemUser?: Maybe<DemUsers>;
  updateOneDeviceTemplate?: Maybe<DeviceTemplate>;
  updateOneDeviceUser?: Maybe<DeviceUser>;
  updateOneDispatchTemplate?: Maybe<DispatchTemplate>;
  updateOneDistributor?: Maybe<Distributor>;
  updateOneDownload?: Maybe<Download>;
  updateOneManual?: Maybe<Manual>;
  updateOneManualPage?: Maybe<ManualPage>;
  updateOneService?: Maybe<Service>;
  updateOneTerm?: Maybe<Term>;
  updateOneUser?: Maybe<User>;
  /** Update the contact details on a stripe customer. */
  updateStripeCustomer?: Maybe<Scalars['String']>;
  /** Update a stripe payment method (https://stripe.com/docs/api/payment_methods/update). */
  updateStripePaymentMethod?: Maybe<PaymentMethod>;
  /** Update a stripe price (https://stripe.com/docs/api/prices/update). */
  updateStripePrice?: Maybe<StripePrice>;
  /** Update a stripe product (https://stripe.com/docs/api/products/update). Using the zoho_item ids in the metadata of prices on the target product, the name of the relevant Zoho items will be updated. */
  updateStripeProduct?: Maybe<StripeProduct>;
  /** Update a stripe product (https://stripe.com/docs/api/products/update). */
  updateStripeProductMetadata?: Maybe<StripeProduct>;
  updateUserPassword?: Maybe<Scalars['String']>;
  updatedDemDevice?: Maybe<DemDevices>;
};

export type MutationSop_Sync_TargetedArgs = {
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
};

export type MutationActivateDemoUsersArgs = {
  dealer?: InputMaybe<DealerWhereUniqueInput>;
  devices?: InputMaybe<Array<InputMaybe<DeviceUserWhereUniqueInput>>>;
};

export type MutationAddBundleArgs = {
  data: Array<InputMaybe<AddBundleInput>>;
};

export type MutationCreateCompanyMutationArgs = {
  data: CreateDemCompanyInput;
};

export type MutationCreateDemGroupArgs = {
  data: CreateDemGroupInput;
};

export type MutationCreateDemUserArgs = {
  data: CreateDemUserInput;
};

export type MutationCreateOneCustomerArgs = {
  data: CustomerCreateInput;
};

export type MutationCreateOneDealerArgs = {
  data: DealerCreateInput;
};

export type MutationCreateOneDemBundleArgs = {
  data: DemBundleCreateInput;
};

export type MutationCreateOneDeviceTemplateArgs = {
  data: DeviceTemplateCreateInput;
};

export type MutationCreateOneDeviceUserArgs = {
  data: DeviceUserCreateInput;
};

export type MutationCreateOneDispatchTemplateArgs = {
  data: DispatchTemplateCreateInput;
};

export type MutationCreateOneDistributorArgs = {
  data: DistributorCreateInput;
};

export type MutationCreateOneDownloadArgs = {
  data: DownloadCreateInput;
};

export type MutationCreateOneManualArgs = {
  data: ManualCreateInput;
};

export type MutationCreateOneManualChapterArgs = {
  data: ManualChapterCreateInput;
};

export type MutationCreateOneManualPageArgs = {
  data: ManualPageCreateInput;
};

export type MutationCreateOneServiceArgs = {
  data: ServiceCreateInput;
};

export type MutationCreateOneTermArgs = {
  data: TermCreateInput;
};

export type MutationCreateStripeCustomerArgs = {
  data: CustomerCreateInput;
};

export type MutationCreateStripePaymentMethodArgs = {
  data: PaymentMethodCreateInput;
  where: WhereUniqueCustomerOrDealerInput;
};

export type MutationCreateStripePriceOnProductArgs = {
  data: PriceCreateInput;
  where: PriceCreateOnProductWhere;
};

export type MutationCreateStripeProductWithPriceArgs = {
  data: ProductWithPriceCreateInput;
};

export type MutationCreateUserArgs = {
  data?: InputMaybe<CreateUserInput>;
};

export type MutationDeactivateDemoUsersArgs = {
  devices?: InputMaybe<Array<InputMaybe<DeviceUserWhereUniqueInput>>>;
};

export type MutationDealerDetailsOnboardingArgs = {
  data: DealerDetailsOnboardingInput;
  where: DealerWhereUniqueInput;
};

export type MutationDealerVatOnboardingArgs = {
  data: DealerVatOnboardingInput;
  where: DealerWhereUniqueInput;
};

export type MutationDeleteOneServiceArgs = {
  where: ServiceWhereUniqueInput;
};

export type MutationDeleteStripePaymentMethodArgs = {
  where: UniqueId;
};

export type MutationDeleteStripePriceArgs = {
  where: UniqueId;
};

export type MutationDeleteStripeProductWithPriceArgs = {
  where: UniqueId;
};

export type MutationDeleteUserArgs = {
  data?: InputMaybe<DeleteUserInput>;
};

export type MutationDemoStatusMutationArgs = {
  data: DemoDeviceInput;
};

export type MutationDistributorDetailsOnboardingArgs = {
  data: DistributorDetailsOnboardingInput;
  where: DistributorWhereUniqueInput;
};

export type MutationEditBundlesArgs = {
  data: Array<InputMaybe<AddBundleInput>>;
};

export type MutationEditManySimArgs = {
  data: EditManySimInput;
};

export type MutationEditSimArgs = {
  data: EditSimInput;
};

export type MutationEditUserArgs = {
  data?: InputMaybe<EditUserInput>;
};

export type MutationEditUserMetaArgs = {
  data?: InputMaybe<EditUserMetaInput>;
};

export type MutationOnboardDealerRequestArgs = {
  data: OnboardDealerRequestInput;
};

export type MutationOrderSimsArgs = {
  data: OrderSimsInput;
};

export type MutationRegisterDeviceArgs = {
  data: CreateDemDeviceInput;
};

export type MutationRegisterManySimCardsArgs = {
  data: Array<InputMaybe<RegisterSimInput>>;
};

export type MutationSetSimCustomerArgs = {
  where: DealerWhereUniqueInput;
};

export type MutationSetSimGroupArgs = {
  data: RegisterSimGroupInput;
};

export type MutationUpdateBundleOnCompanyArgs = {
  bundleOnUser: Scalars['String'];
  customerId: Scalars['String'];
  quantity: Scalars['Int'];
};

export type MutationUpdateDealerDistributorsArgs = {
  data: Scalars['String'];
  where: Array<Scalars['String']>;
};

export type MutationUpdateOneAddressArgs = {
  data: AddressUpdateInput;
  where: AddressWhereUniqueInput;
};

export type MutationUpdateOneCustomerArgs = {
  data: CustomerUpdateInput;
  where: CustomerWhereUniqueInput;
};

export type MutationUpdateOneDealerArgs = {
  data: DealerUpdateInput;
  where: DealerWhereUniqueInput;
};

export type MutationUpdateOneDemUserArgs = {
  data: DemUserInput;
  where: UserWhereUniqueInput;
};

export type MutationUpdateOneDeviceTemplateArgs = {
  data: DeviceTemplateUpdateInput;
  where: DeviceTemplateWhereUniqueInput;
};

export type MutationUpdateOneDeviceUserArgs = {
  data: DeviceUserUpdateInput;
  where: DeviceUserWhereUniqueInput;
};

export type MutationUpdateOneDispatchTemplateArgs = {
  data: DispatchTemplateUpdateInput;
  where: DispatchTemplateWhereUniqueInput;
};

export type MutationUpdateOneDistributorArgs = {
  data: DistributorUpdateInput;
  where: DistributorWhereUniqueInput;
};

export type MutationUpdateOneDownloadArgs = {
  data: DownloadUpdateInput;
  where: DownloadWhereUniqueInput;
};

export type MutationUpdateOneManualArgs = {
  data: ManualUpdateInput;
  where: ManualWhereUniqueInput;
};

export type MutationUpdateOneManualPageArgs = {
  data: ManualPageUpdateWithoutManualInput;
  where: UpdateOneManualPageWhereInput;
};

export type MutationUpdateOneServiceArgs = {
  data: ServiceUpdateInput;
  where: ServiceWhereUniqueInput;
};

export type MutationUpdateOneTermArgs = {
  data: TermUpdateInput;
  where: TermWhereUniqueInput;
};

export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};

export type MutationUpdateStripeCustomerArgs = {
  data: CustomerObjectUpdateInput;
  where: UniqueId;
};

export type MutationUpdateStripePaymentMethodArgs = {
  data: PaymentMethodUpdateInput;
  where: UniqueId;
};

export type MutationUpdateStripePriceArgs = {
  data: PriceUpdateInput;
  where: UniqueId;
};

export type MutationUpdateStripeProductArgs = {
  data: ProductUpdateInput;
  where: UniqueId;
};

export type MutationUpdateStripeProductMetadataArgs = {
  data: ProductUpdateInput;
  where: UniqueId;
};

export type MutationUpdateUserPasswordArgs = {
  data: UserPasswordInput;
  where: UniqueId;
};

export type MutationUpdatedDemDeviceArgs = {
  data: EditDemDeviceInput;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumAddressTypeEnumFilter = {
  equals?: InputMaybe<AddressTypeEnum>;
  in?: InputMaybe<Array<AddressTypeEnum>>;
  not?: InputMaybe<NestedEnumAddressTypeEnumFilter>;
  notIn?: InputMaybe<Array<AddressTypeEnum>>;
};

export type NestedEnumAnswerModeEnumNullableFilter = {
  equals?: InputMaybe<AnswerModeEnum>;
  in?: InputMaybe<Array<AnswerModeEnum>>;
  not?: InputMaybe<NestedEnumAnswerModeEnumNullableFilter>;
  notIn?: InputMaybe<Array<AnswerModeEnum>>;
};

export type NestedEnumDeviceGroupTypeEnumFilter = {
  equals?: InputMaybe<DeviceGroupTypeEnum>;
  in?: InputMaybe<Array<DeviceGroupTypeEnum>>;
  not?: InputMaybe<NestedEnumDeviceGroupTypeEnumFilter>;
  notIn?: InputMaybe<Array<DeviceGroupTypeEnum>>;
};

export type NestedEnumEmergencyActionEnumNullableFilter = {
  equals?: InputMaybe<EmergencyActionEnum>;
  in?: InputMaybe<Array<EmergencyActionEnum>>;
  not?: InputMaybe<NestedEnumEmergencyActionEnumNullableFilter>;
  notIn?: InputMaybe<Array<EmergencyActionEnum>>;
};

export type NestedEnumGroupPropertyEnumFilter = {
  equals?: InputMaybe<GroupPropertyEnum>;
  in?: InputMaybe<Array<GroupPropertyEnum>>;
  not?: InputMaybe<NestedEnumGroupPropertyEnumFilter>;
  notIn?: InputMaybe<Array<GroupPropertyEnum>>;
};

export type NestedEnumOnboardingStepEnumFilter = {
  equals?: InputMaybe<OnboardingStepEnum>;
  in?: InputMaybe<Array<OnboardingStepEnum>>;
  not?: InputMaybe<NestedEnumOnboardingStepEnumFilter>;
  notIn?: InputMaybe<Array<OnboardingStepEnum>>;
};

export type NestedEnumPermissionTypeEnumFilter = {
  equals?: InputMaybe<PermissionTypeEnum>;
  in?: InputMaybe<Array<PermissionTypeEnum>>;
  not?: InputMaybe<NestedEnumPermissionTypeEnumFilter>;
  notIn?: InputMaybe<Array<PermissionTypeEnum>>;
};

export type NestedEnumProductTypeFilter = {
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type NestedEnumSimStatusEnumFilter = {
  equals?: InputMaybe<SimStatusEnum>;
  in?: InputMaybe<Array<SimStatusEnum>>;
  not?: InputMaybe<NestedEnumSimStatusEnumFilter>;
  notIn?: InputMaybe<Array<SimStatusEnum>>;
};

export type NestedEnumSubscriptionTypeFilter = {
  equals?: InputMaybe<SubscriptionType>;
  in?: InputMaybe<Array<SubscriptionType>>;
  not?: InputMaybe<NestedEnumSubscriptionTypeFilter>;
  notIn?: InputMaybe<Array<SubscriptionType>>;
};

export type NestedEnumTermTypeEnumFilter = {
  equals?: InputMaybe<TermTypeEnum>;
  in?: InputMaybe<Array<TermTypeEnum>>;
  not?: InputMaybe<NestedEnumTermTypeEnumFilter>;
  notIn?: InputMaybe<Array<TermTypeEnum>>;
};

export type NestedEnumVoiceQualityEnumFilter = {
  equals?: InputMaybe<VoiceQualityEnum>;
  in?: InputMaybe<Array<VoiceQualityEnum>>;
  not?: InputMaybe<NestedEnumVoiceQualityEnumFilter>;
  notIn?: InputMaybe<Array<VoiceQualityEnum>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableEnumAnswerModeEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<AnswerModeEnum>;
};

export type NullableEnumEmergencyActionEnumFieldUpdateOperationsInput = {
  set?: InputMaybe<EmergencyActionEnum>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type OnboardDealerRequest = {
  __typename?: 'OnboardDealerRequest';
  name: Scalars['String'];
  sopEmail: Scalars['String'];
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

export type OnboardDealerRequestInput = {
  name: Scalars['String'];
  sopEmail: Scalars['String'];
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};

export enum OnboardingStepEnum {
  Complete = 'COMPLETE',
  Details = 'DETAILS',
  Payment = 'PAYMENT',
  Terms = 'TERMS',
  Vat = 'VAT',
}

export type OrderSimsInput = {
  currency: Scalars['String'];
  dealerId: Scalars['String'];
  numberOrdered: Scalars['String'];
  shippingCost: Scalars['Int'];
};

export type OrgUser = {
  __typename?: 'OrgUser';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type OrgUsers = {
  __typename?: 'OrgUsers';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type PastUsage = {
  __typename?: 'PastUsage';
  createdAt: Scalars['Float'];
  day: Scalars['Float'];
  month: Scalars['String'];
  value: Scalars['Float'];
};

export type PastUsageMonthly = {
  __typename?: 'PastUsageMonthly';
  createdAt: Scalars['String'];
  month: Scalars['String'];
  value: Scalars['Float'];
  year: Scalars['String'];
};

export type Payment = {
  __typename?: 'Payment';
  date: Scalars['String'];
  invoiceNumber: Scalars['String'];
  paid: Scalars['Boolean'];
  status: Scalars['String'];
  total: Scalars['Float'];
  totalCollected: Scalars['Float'];
  type: Scalars['String'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  default?: Maybe<Scalars['Boolean']>;
  details?: Maybe<PaymentMethodDetails>;
  id: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

/** successUrl: a page on your website to redirect your customer after they complete the payment. cancelUrl: a page on your website to redirect your customer if they click on your logo in Checkout. type: string[ ] where strings are values of the stripe type enum (https://stripe.com/docs/api/payment_methods/object) */
export type PaymentMethodCreateInput = {
  cancelUrl: Scalars['String'];
  successUrl: Scalars['String'];
  type?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PaymentMethodDetails = {
  __typename?: 'PaymentMethodDetails';
  brand?: Maybe<Scalars['String']>;
  expMonth?: Maybe<Scalars['Int']>;
  expYear?: Maybe<Scalars['Int']>;
  last4?: Maybe<Scalars['String']>;
  sortCode?: Maybe<Scalars['String']>;
};

export type PaymentMethodUpdateInput = {
  address?: InputMaybe<AddressUpdate>;
  card?: InputMaybe<CardUpdate>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

/** stripePaymentMethodType: value from the stripe type enum (https://stripe.com/docs/api/payment_methods/object) */
export type PaymentMethodWhereCustomerIdAndPaymentType = {
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripePaymentMethodType?: InputMaybe<Scalars['String']>;
};

export enum PermissionTypeEnum {
  Device = 'DEVICE',
  Dispatch = 'DISPATCH',
}

/** https://stripe.com/docs/api/prices/create */
export type PriceCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  currency: Scalars['String'];
  nickname?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<Scalars['String']>;
  recurring?: InputMaybe<PriceRecurringInput>;
  unit_amount: Scalars['Int'];
};

export type PriceCreateOnProductWhere = {
  productId: Scalars['String'];
  productName: Scalars['String'];
};

/** https://stripe.com/docs/api/prices/object */
export type PriceRecurringInput = {
  aggregate_usage?: InputMaybe<Scalars['String']>;
  interval: Scalars['String'];
  interval_count?: InputMaybe<Scalars['Int']>;
  usage_type?: InputMaybe<Scalars['String']>;
};

export type PriceUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  nickname?: InputMaybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  deviceTemplate?: Maybe<DeviceTemplate>;
  dispatchTemplate?: Maybe<DispatchTemplate>;
  id: Scalars['String'];
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  recycled: Scalars['Boolean'];
  stripeId: Scalars['String'];
  type: ProductType;
  updatedAt: Scalars['DateTime'];
};

/** type should be "device","service" or "simcard". taxCode should be a stripe tax code (https://stripe.com/docs/tax/tax-codes). (https://stripe.com/docs/api/products/create) */
export type ProductCreateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  taxCode: Scalars['String'];
  type: Scalars['String'];
};

export type ProductCreateManyDeviceTemplateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  dispatchTemplateId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  type: ProductType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductCreateManyDeviceTemplateInputEnvelope = {
  data?: InputMaybe<Array<ProductCreateManyDeviceTemplateInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateManyDispatchTemplateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  deviceTemplateId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  type: ProductType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductCreateManyDispatchTemplateInputEnvelope = {
  data?: InputMaybe<Array<ProductCreateManyDispatchTemplateInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateNestedManyWithoutDeviceTemplateInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ProductCreateOrConnectWithoutDeviceTemplateInput>
  >;
  create?: InputMaybe<Array<ProductCreateWithoutDeviceTemplateInput>>;
  createMany?: InputMaybe<ProductCreateManyDeviceTemplateInputEnvelope>;
};

export type ProductCreateNestedManyWithoutDispatchTemplateInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ProductCreateOrConnectWithoutDispatchTemplateInput>
  >;
  create?: InputMaybe<Array<ProductCreateWithoutDispatchTemplateInput>>;
  createMany?: InputMaybe<ProductCreateManyDispatchTemplateInputEnvelope>;
};

export type ProductCreateOrConnectWithoutDeviceTemplateInput = {
  create: ProductCreateWithoutDeviceTemplateInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutDispatchTemplateInput = {
  create: ProductCreateWithoutDispatchTemplateInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutDeviceTemplateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  dispatchTemplate?: InputMaybe<DispatchTemplateCreateNestedOneWithoutProductsInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  type: ProductType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductCreateWithoutDispatchTemplateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  deviceTemplate?: InputMaybe<DeviceTemplateCreateNestedOneWithoutProductsInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  type: ProductType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ProductListRelationFilter = {
  every?: InputMaybe<ProductWhereInput>;
  none?: InputMaybe<ProductWhereInput>;
  some?: InputMaybe<ProductWhereInput>;
};

export type ProductScalarWhereInput = {
  AND?: InputMaybe<Array<ProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  deviceTemplateId?: InputMaybe<StringNullableFilter>;
  dispatchTemplateId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumProductTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ProductType {
  Dem = 'DEM',
  License = 'LICENSE',
  Sim = 'SIM',
}

/** https://stripe.com/docs/api/products/update */
export type ProductUpdateInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  metadataDescription?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductUpdateManyWithWhereWithoutDeviceTemplateInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutDispatchTemplateInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithoutDeviceTemplateInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ProductCreateOrConnectWithoutDeviceTemplateInput>
  >;
  create?: InputMaybe<Array<ProductCreateWithoutDeviceTemplateInput>>;
  createMany?: InputMaybe<ProductCreateManyDeviceTemplateInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ProductUpdateWithWhereUniqueWithoutDeviceTemplateInput>
  >;
  updateMany?: InputMaybe<
    Array<ProductUpdateManyWithWhereWithoutDeviceTemplateInput>
  >;
  upsert?: InputMaybe<
    Array<ProductUpsertWithWhereUniqueWithoutDeviceTemplateInput>
  >;
};

export type ProductUpdateManyWithoutDispatchTemplateInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ProductCreateOrConnectWithoutDispatchTemplateInput>
  >;
  create?: InputMaybe<Array<ProductCreateWithoutDispatchTemplateInput>>;
  createMany?: InputMaybe<ProductCreateManyDispatchTemplateInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<
    Array<ProductUpdateWithWhereUniqueWithoutDispatchTemplateInput>
  >;
  updateMany?: InputMaybe<
    Array<ProductUpdateManyWithWhereWithoutDispatchTemplateInput>
  >;
  upsert?: InputMaybe<
    Array<ProductUpsertWithWhereUniqueWithoutDispatchTemplateInput>
  >;
};

export type ProductUpdateWithWhereUniqueWithoutDeviceTemplateInput = {
  data: ProductUpdateWithoutDeviceTemplateInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutDispatchTemplateInput = {
  data: ProductUpdateWithoutDispatchTemplateInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithoutDeviceTemplateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  dispatchTemplate?: InputMaybe<DispatchTemplateUpdateOneWithoutProductsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutDispatchTemplateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  deviceTemplate?: InputMaybe<DeviceTemplateUpdateOneWithoutProductsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ProductUpsertWithWhereUniqueWithoutDeviceTemplateInput = {
  create: ProductCreateWithoutDeviceTemplateInput;
  update: ProductUpdateWithoutDeviceTemplateInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutDispatchTemplateInput = {
  create: ProductCreateWithoutDispatchTemplateInput;
  update: ProductUpdateWithoutDispatchTemplateInput;
  where: ProductWhereUniqueInput;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  deviceTemplate?: InputMaybe<DeviceTemplateWhereInput>;
  deviceTemplateId?: InputMaybe<StringNullableFilter>;
  dispatchTemplate?: InputMaybe<DispatchTemplateWhereInput>;
  dispatchTemplateId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeId?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumProductTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ProductWithPriceCreateInput = {
  croatianName?: InputMaybe<Scalars['String']>;
  czechName?: InputMaybe<Scalars['String']>;
  eurPrice?: InputMaybe<PriceCreateInput>;
  frenchName?: InputMaybe<Scalars['String']>;
  germanName?: InputMaybe<Scalars['String']>;
  greekName?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<PriceCreateInput>;
  product?: InputMaybe<ProductCreateInput>;
  slovakName?: InputMaybe<Scalars['String']>;
};

export type PurchasedService = {
  name: Scalars['String'];
  quantity: Scalars['Int'];
  stripeInvoiceId: Scalars['String'];
  stripeProductId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Returns a list of bundles and the quantity that a user is currently using in their subscription */
  activeBundles?: Maybe<Array<Maybe<BundleOnUser>>>;
  auth0User?: Maybe<OrgUser>;
  auth0Users?: Maybe<Array<OrgUsers>>;
  /** Returns a list of bundles that the current user can purchase. Excludes bundles where they have already added to subscription as those will have their quantity changed */
  availableBundles?: Maybe<Array<Maybe<DemBundle>>>;
  availableUsers?: Maybe<Array<Maybe<DemUsers>>>;
  currentUser?: Maybe<CurrentUser>;
  customer?: Maybe<Customer>;
  /** Counts products on a customer to determine the total cost of a customer per month. In future, this functionality should be directly available by querying for "services" on the relevant model, but at the time of creation (product launch), reliably calculating services to create and update based on the direction of data flow (not having write access to sop), was significantly more complex than counting the items in the database when required. This query is a temporary fix to an architectural problem */
  customerMonthlyCost?: Maybe<Scalars['Int']>;
  customers: Array<Customer>;
  dealer?: Maybe<Dealer>;
  dealers: Array<Dealer>;
  demCompaniesListBasic?: Maybe<Array<Maybe<DemCompanyBasic>>>;
  demDeviceModels?: Maybe<Array<Maybe<DemModel>>>;
  deviceModels: Array<DeviceModel>;
  deviceTemplate?: Maybe<DeviceTemplate>;
  deviceTemplates: Array<DeviceTemplate>;
  deviceUser?: Maybe<DeviceUser>;
  deviceUsers: Array<DeviceUser>;
  dispatchTemplate?: Maybe<DispatchTemplate>;
  dispatchTemplates: Array<DispatchTemplate>;
  distributor?: Maybe<Distributor>;
  distributors: Array<Distributor>;
  download?: Maybe<Download>;
  downloads: Array<Download>;
  groupsList?: Maybe<Array<Maybe<DemGroup>>>;
  listDealerSims?: Maybe<ListDealerSims>;
  manual?: Maybe<Manual>;
  manuals: Array<Manual>;
  service?: Maybe<Service>;
  services: Array<Service>;
  /** This query is a temp replacement for querying "Services".In future, a service should be created when a user orders and pays for a product of type, "service", at which point "Services" can be queried as normal. At the time of writing, billing was calculated based on the number of SOP items a user had created, rather than items ordered directly through the Hytalk portal (due to read-only access to the SOP DB). */
  servicesOnDealer?: Maybe<Array<Maybe<ServicesOnDealer>>>;
  simCard?: Maybe<SimCard>;
  simCards: Array<SimCard>;
  /** Lists payment methods for the provided stripe customer id and payment type (https://stripe.com/docs/api/payment_methods/list). */
  stripePaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  /** Lists all stripe products with their prices. Intended as an admin-level operation. */
  stripeProductsWithPrices?: Maybe<Array<Maybe<StripeProduct>>>;
  term?: Maybe<Term>;
  terms: Array<Term>;
  user?: Maybe<User>;
  users: Array<User>;
  viewDemCompanies?: Maybe<DemCompanies>;
  viewDemCompany?: Maybe<DemCompanyDetails>;
  viewDemDevice?: Maybe<DemDevices>;
};

export type QueryActiveBundlesArgs = {
  where?: InputMaybe<UniqueId>;
};

export type QueryAuth0UserArgs = {
  data?: InputMaybe<UniqueId>;
};

export type QueryAuth0UsersArgs = {
  data?: InputMaybe<UniqueId>;
};

export type QueryAvailableBundlesArgs = {
  where?: InputMaybe<UniqueId>;
};

export type QueryAvailableUsersArgs = {
  where: DemUserWhereInput;
};

export type QueryCustomerArgs = {
  where: CustomerWhereUniqueInput;
};

export type QueryCustomerMonthlyCostArgs = {
  where?: InputMaybe<UniqueId>;
};

export type QueryCustomersArgs = {
  after?: InputMaybe<CustomerWhereUniqueInput>;
  before?: InputMaybe<CustomerWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CustomerWhereInput>;
};

export type QueryDealerArgs = {
  where: DealerWhereUniqueInput;
};

export type QueryDealersArgs = {
  after?: InputMaybe<DealerWhereUniqueInput>;
  before?: InputMaybe<DealerWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DealerWhereInput>;
};

export type QueryDemCompaniesListBasicArgs = {
  where?: InputMaybe<UniqueId>;
};

export type QueryDeviceModelsArgs = {
  after?: InputMaybe<DeviceModelWhereUniqueInput>;
  before?: InputMaybe<DeviceModelWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryDeviceTemplateArgs = {
  where: DeviceTemplateWhereUniqueInput;
};

export type QueryDeviceTemplatesArgs = {
  after?: InputMaybe<DeviceTemplateWhereUniqueInput>;
  before?: InputMaybe<DeviceTemplateWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceTemplateWhereInput>;
};

export type QueryDeviceUserArgs = {
  where: DeviceUserWhereUniqueInput;
};

export type QueryDeviceUsersArgs = {
  after?: InputMaybe<DeviceUserWhereUniqueInput>;
  before?: InputMaybe<DeviceUserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DeviceUserWhereInput>;
};

export type QueryDispatchTemplateArgs = {
  where: DispatchTemplateWhereUniqueInput;
};

export type QueryDispatchTemplatesArgs = {
  after?: InputMaybe<DispatchTemplateWhereUniqueInput>;
  before?: InputMaybe<DispatchTemplateWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DispatchTemplateWhereInput>;
};

export type QueryDistributorArgs = {
  where: DistributorWhereUniqueInput;
};

export type QueryDistributorsArgs = {
  after?: InputMaybe<DistributorWhereUniqueInput>;
  before?: InputMaybe<DistributorWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DistributorWhereInput>;
};

export type QueryDownloadArgs = {
  where: DownloadWhereUniqueInput;
};

export type QueryDownloadsArgs = {
  after?: InputMaybe<DownloadWhereUniqueInput>;
  before?: InputMaybe<DownloadWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryGroupsListArgs = {
  where: DemGroupsWhereInput;
};

export type QueryListDealerSimsArgs = {
  simWhere?: InputMaybe<SimCardWhereInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UniqueId>;
};

export type QueryManualArgs = {
  where: ManualWhereUniqueInput;
};

export type QueryManualsArgs = {
  after?: InputMaybe<ManualWhereUniqueInput>;
  before?: InputMaybe<ManualWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ManualOrderByWithRelationInput>>;
};

export type QueryServiceArgs = {
  where: ServiceWhereUniqueInput;
};

export type QueryServicesArgs = {
  after?: InputMaybe<ServiceWhereUniqueInput>;
  before?: InputMaybe<ServiceWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryServicesOnDealerArgs = {
  where?: InputMaybe<UniqueId>;
};

export type QuerySimCardArgs = {
  where: SimCardWhereUniqueInput;
};

export type QuerySimCardsArgs = {
  after?: InputMaybe<SimCardWhereUniqueInput>;
  before?: InputMaybe<SimCardWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryStripePaymentMethodsArgs = {
  where?: InputMaybe<PaymentMethodWhereCustomerIdAndPaymentType>;
};

export type QueryStripeProductsWithPricesArgs = {
  data?: InputMaybe<Currency>;
};

export type QueryTermArgs = {
  where: TermWhereUniqueInput;
};

export type QueryTermsArgs = {
  after?: InputMaybe<TermWhereUniqueInput>;
  before?: InputMaybe<TermWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TermWhereInput>;
};

export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
  after?: InputMaybe<UserWhereUniqueInput>;
  before?: InputMaybe<UserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryViewDemCompaniesArgs = {
  where?: InputMaybe<UniqueId>;
};

export type QueryViewDemCompanyArgs = {
  where?: InputMaybe<UniqueId>;
};

export type QueryViewDemDeviceArgs = {
  where?: InputMaybe<UniqueId>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive',
}

export type RecordDeleted = {
  __typename?: 'RecordDeleted';
  deleted?: Maybe<Array<Maybe<Scalars['String']>>>;
  removedIdentifier?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RecordUpdated = {
  __typename?: 'RecordUpdated';
  created?: Maybe<Array<Maybe<Scalars['String']>>>;
  updated?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RegisterManySimsInput = {
  data: Array<InputMaybe<RegisterSimInput>>;
};

export type RegisterSimGroupInput = {
  groupCode: Scalars['String'];
  iccid: Scalars['String'];
};

export type RegisterSimInput = {
  customer?: InputMaybe<CustomerWhereUniqueInput>;
  dealerId: Scalars['String'];
  iccid: Scalars['String'];
};

/** The status of a SIM card returned on the sim object. */
export enum SimStatus {
  Active = 'Active',
  Closed = 'Closed',
  Deactivated = 'Deactivated',
}

export type SopSync = {
  __typename?: 'SOPSync';
  deleteCustomersRecord?: Maybe<RecordDeleted>;
  deleteDealersRecord?: Maybe<RecordDeleted>;
  deleteDeviceTemplatesRecord?: Maybe<RecordDeleted>;
  deleteDeviceUsersRecord?: Maybe<RecordDeleted>;
  deleteDispatchTemplatesRecord?: Maybe<RecordDeleted>;
  deleteDistributorsRecord?: Maybe<RecordDeleted>;
  metrics?: Maybe<SopSyncMetrics>;
  upsertCustomersRecord?: Maybe<RecordUpdated>;
  upsertDealersRecord?: Maybe<RecordUpdated>;
  upsertDeviceTemplatesRecord?: Maybe<RecordUpdated>;
  upsertDeviceUsersRecord?: Maybe<RecordUpdated>;
  upsertDispatchTemplatesRecord?: Maybe<RecordUpdated>;
  upsertDistributorsRecord?: Maybe<RecordUpdated>;
};

export type SopSyncDurations = {
  __typename?: 'SOPSyncDurations';
  delete?: Maybe<Scalars['Int']>;
  hytalkDataFetch?: Maybe<Scalars['Int']>;
  sopDataFetch?: Maybe<Scalars['Int']>;
  totalTime?: Maybe<Scalars['Int']>;
  upsert?: Maybe<Scalars['Int']>;
};

export type SopSyncMetrics = {
  __typename?: 'SOPSyncMetrics';
  deletes?: Maybe<SopSyncUpsertsAndDeletes>;
  duration?: Maybe<SopSyncDurations>;
  upserts?: Maybe<SopSyncUpsertsAndDeletes>;
};

export type SopSyncUpsertsAndDeletes = {
  __typename?: 'SOPSyncUpsertsAndDeletes';
  customers?: Maybe<Scalars['Int']>;
  dealers?: Maybe<Scalars['Int']>;
  deviceTemplates?: Maybe<Scalars['Int']>;
  deviceUsers?: Maybe<Scalars['Int']>;
  dispatchTemplates?: Maybe<Scalars['Int']>;
  distributors?: Maybe<Scalars['Int']>;
};

export type Service = {
  __typename?: 'Service';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  recycled: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type ServiceCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutServicesInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutServicesInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars['String']>;
  licenceTotal?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeInvoiceIds?: InputMaybe<ServiceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ServiceCreateManyCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  licenceTotal?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeInvoiceIds?: InputMaybe<ServiceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ServiceCreateManyCustomerInputEnvelope = {
  data?: InputMaybe<Array<ServiceCreateManyCustomerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceCreateManyDealerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  licenceTotal?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeInvoiceIds?: InputMaybe<ServiceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ServiceCreateManyDealerInputEnvelope = {
  data?: InputMaybe<Array<ServiceCreateManyDealerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ServiceCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<ServiceCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<ServiceCreateManyCustomerInputEnvelope>;
};

export type ServiceCreateNestedManyWithoutDealerInput = {
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ServiceCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<ServiceCreateWithoutDealerInput>>;
  createMany?: InputMaybe<ServiceCreateManyDealerInputEnvelope>;
};

export type ServiceCreateNestedOneWithoutDeviceUsersInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutDeviceUsersInput>;
  create?: InputMaybe<ServiceCreateWithoutDeviceUsersInput>;
};

export type ServiceCreateOrConnectWithoutCustomerInput = {
  create: ServiceCreateWithoutCustomerInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutDealerInput = {
  create: ServiceCreateWithoutDealerInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateOrConnectWithoutDeviceUsersInput = {
  create: ServiceCreateWithoutDeviceUsersInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceCreateWithoutCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutServicesInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars['String']>;
  licenceTotal?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeInvoiceIds?: InputMaybe<ServiceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ServiceCreateWithoutDealerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutServicesInput>;
  deviceUsers?: InputMaybe<DeviceUserCreateNestedManyWithoutServiceInput>;
  id?: InputMaybe<Scalars['String']>;
  licenceTotal?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeInvoiceIds?: InputMaybe<ServiceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ServiceCreateWithoutDeviceUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutServicesInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutServicesInput>;
  id?: InputMaybe<Scalars['String']>;
  licenceTotal?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeInvoiceIds?: InputMaybe<ServiceCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ServiceCreatestripeInvoiceIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ServiceListRelationFilter = {
  every?: InputMaybe<ServiceWhereInput>;
  none?: InputMaybe<ServiceWhereInput>;
  some?: InputMaybe<ServiceWhereInput>;
};

export type ServiceScalarWhereInput = {
  AND?: InputMaybe<Array<ServiceScalarWhereInput>>;
  NOT?: InputMaybe<Array<ServiceScalarWhereInput>>;
  OR?: InputMaybe<Array<ServiceScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealerId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  licenceTotal?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeInvoiceIds?: InputMaybe<StringNullableListFilter>;
  stripeProductId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutServicesInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutServicesInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutServiceInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  licenceTotal?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<ServiceUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  licenceTotal?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<ServiceUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateManyWithWhereWithoutCustomerInput = {
  data: ServiceUpdateManyMutationInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithWhereWithoutDealerInput = {
  data: ServiceUpdateManyMutationInput;
  where: ServiceScalarWhereInput;
};

export type ServiceUpdateManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<ServiceCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<ServiceCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<ServiceCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  update?: InputMaybe<Array<ServiceUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<
    Array<ServiceUpdateManyWithWhereWithoutCustomerInput>
  >;
  upsert?: InputMaybe<Array<ServiceUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type ServiceUpdateManyWithoutDealerInput = {
  connect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ServiceCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<ServiceCreateWithoutDealerInput>>;
  createMany?: InputMaybe<ServiceCreateManyDealerInputEnvelope>;
  delete?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ServiceScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  set?: InputMaybe<Array<ServiceWhereUniqueInput>>;
  update?: InputMaybe<Array<ServiceUpdateWithWhereUniqueWithoutDealerInput>>;
  updateMany?: InputMaybe<Array<ServiceUpdateManyWithWhereWithoutDealerInput>>;
  upsert?: InputMaybe<Array<ServiceUpsertWithWhereUniqueWithoutDealerInput>>;
};

export type ServiceUpdateOneWithoutDeviceUsersInput = {
  connect?: InputMaybe<ServiceWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ServiceCreateOrConnectWithoutDeviceUsersInput>;
  create?: InputMaybe<ServiceCreateWithoutDeviceUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ServiceUpdateWithoutDeviceUsersInput>;
  upsert?: InputMaybe<ServiceUpsertWithoutDeviceUsersInput>;
};

export type ServiceUpdateWithWhereUniqueWithoutCustomerInput = {
  data: ServiceUpdateWithoutCustomerInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithWhereUniqueWithoutDealerInput = {
  data: ServiceUpdateWithoutDealerInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpdateWithoutCustomerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutServicesInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutServiceInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  licenceTotal?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<ServiceUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutDealerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutServicesInput>;
  deviceUsers?: InputMaybe<DeviceUserUpdateManyWithoutServiceInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  licenceTotal?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<ServiceUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdateWithoutDeviceUsersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutServicesInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutServicesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  licenceTotal?: InputMaybe<IntFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<ServiceUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ServiceUpdatestripeInvoiceIdsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ServiceUpsertWithWhereUniqueWithoutCustomerInput = {
  create: ServiceCreateWithoutCustomerInput;
  update: ServiceUpdateWithoutCustomerInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithWhereUniqueWithoutDealerInput = {
  create: ServiceCreateWithoutDealerInput;
  update: ServiceUpdateWithoutDealerInput;
  where: ServiceWhereUniqueInput;
};

export type ServiceUpsertWithoutDeviceUsersInput = {
  create: ServiceCreateWithoutDeviceUsersInput;
  update: ServiceUpdateWithoutDeviceUsersInput;
};

export type ServiceWhereInput = {
  AND?: InputMaybe<Array<ServiceWhereInput>>;
  NOT?: InputMaybe<Array<ServiceWhereInput>>;
  OR?: InputMaybe<Array<ServiceWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  deviceUsers?: InputMaybe<DeviceUserListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  licenceTotal?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeInvoiceIds?: InputMaybe<StringNullableListFilter>;
  stripeProductId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ServiceWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ServicesOnDealer = {
  __typename?: 'ServicesOnDealer';
  demoQuantity?: Maybe<Scalars['Int']>;
  itemPrice?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Translations>;
  monthlyCost?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  stripeProductId?: Maybe<Scalars['String']>;
};

export type ShippingAddressInput = {
  address: AddressUpdate;
  name: Scalars['String'];
};

export type SimCard = {
  __typename?: 'SimCard';
  canChangeGroup?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  dealer?: Maybe<Dealer>;
  dealerId?: Maybe<Scalars['String']>;
  groupChanged: Scalars['DateTime'];
  groupCode?: Maybe<Scalars['String']>;
  iccid: Scalars['String'];
  id: Scalars['String'];
  imei?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  locked?: Maybe<Scalars['Boolean']>;
  msidn?: Maybe<Scalars['String']>;
  pastUsage?: Maybe<Array<Maybe<PastUsage>>>;
  pastUsageMonthly?: Maybe<Array<Maybe<PastUsageMonthly>>>;
  prevGroupCode?: Maybe<Scalars['String']>;
  recycled: Scalars['Boolean'];
  status: SimStatusEnum;
  tariff?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  usage?: Maybe<UsageComparison>;
  warning: Scalars['Int'];
};

export type SimCardCreateManyCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  deviceUserId?: InputMaybe<Scalars['String']>;
  exceeded?: InputMaybe<Scalars['Boolean']>;
  groupChanged?: InputMaybe<Scalars['DateTime']>;
  groupCode?: InputMaybe<Scalars['String']>;
  iccid: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  prevGroupCode?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<SimStatusEnum>;
  stripeInvoiceIds?: InputMaybe<SimCardCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  warned?: InputMaybe<Scalars['Boolean']>;
  warning?: InputMaybe<Scalars['Int']>;
};

export type SimCardCreateManyCustomerInputEnvelope = {
  data?: InputMaybe<Array<SimCardCreateManyCustomerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SimCardCreateManyDealerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  deviceUserId?: InputMaybe<Scalars['String']>;
  exceeded?: InputMaybe<Scalars['Boolean']>;
  groupChanged?: InputMaybe<Scalars['DateTime']>;
  groupCode?: InputMaybe<Scalars['String']>;
  iccid: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  prevGroupCode?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<SimStatusEnum>;
  stripeInvoiceIds?: InputMaybe<SimCardCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  warned?: InputMaybe<Scalars['Boolean']>;
  warning?: InputMaybe<Scalars['Int']>;
};

export type SimCardCreateManyDealerInputEnvelope = {
  data?: InputMaybe<Array<SimCardCreateManyDealerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SimCardCreateManyDeviceInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  deviceUserId?: InputMaybe<Scalars['String']>;
  exceeded?: InputMaybe<Scalars['Boolean']>;
  groupChanged?: InputMaybe<Scalars['DateTime']>;
  groupCode?: InputMaybe<Scalars['String']>;
  iccid: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  prevGroupCode?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<SimStatusEnum>;
  stripeInvoiceIds?: InputMaybe<SimCardCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  warned?: InputMaybe<Scalars['Boolean']>;
  warning?: InputMaybe<Scalars['Int']>;
};

export type SimCardCreateManyDeviceInputEnvelope = {
  data?: InputMaybe<Array<SimCardCreateManyDeviceInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SimCardCreateManyDeviceUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  deviceId?: InputMaybe<Scalars['String']>;
  exceeded?: InputMaybe<Scalars['Boolean']>;
  groupChanged?: InputMaybe<Scalars['DateTime']>;
  groupCode?: InputMaybe<Scalars['String']>;
  iccid: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  prevGroupCode?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<SimStatusEnum>;
  stripeInvoiceIds?: InputMaybe<SimCardCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  warned?: InputMaybe<Scalars['Boolean']>;
  warning?: InputMaybe<Scalars['Int']>;
};

export type SimCardCreateManyDeviceUserInputEnvelope = {
  data?: InputMaybe<Array<SimCardCreateManyDeviceUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SimCardCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SimCardCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<SimCardCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<SimCardCreateManyCustomerInputEnvelope>;
};

export type SimCardCreateNestedManyWithoutDealerInput = {
  connect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SimCardCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<SimCardCreateWithoutDealerInput>>;
  createMany?: InputMaybe<SimCardCreateManyDealerInputEnvelope>;
};

export type SimCardCreateNestedManyWithoutDeviceInput = {
  connect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SimCardCreateOrConnectWithoutDeviceInput>>;
  create?: InputMaybe<Array<SimCardCreateWithoutDeviceInput>>;
  createMany?: InputMaybe<SimCardCreateManyDeviceInputEnvelope>;
};

export type SimCardCreateNestedManyWithoutDeviceUserInput = {
  connect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SimCardCreateOrConnectWithoutDeviceUserInput>
  >;
  create?: InputMaybe<Array<SimCardCreateWithoutDeviceUserInput>>;
  createMany?: InputMaybe<SimCardCreateManyDeviceUserInputEnvelope>;
};

export type SimCardCreateOrConnectWithoutCustomerInput = {
  create: SimCardCreateWithoutCustomerInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardCreateOrConnectWithoutDealerInput = {
  create: SimCardCreateWithoutDealerInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardCreateOrConnectWithoutDeviceInput = {
  create: SimCardCreateWithoutDeviceInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardCreateOrConnectWithoutDeviceUserInput = {
  create: SimCardCreateWithoutDeviceUserInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardCreateWithoutCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutSimCardsInput>;
  device?: InputMaybe<DeviceCreateNestedOneWithoutSimCardsInput>;
  deviceUser?: InputMaybe<DeviceUserCreateNestedOneWithoutSimCardsInput>;
  exceeded?: InputMaybe<Scalars['Boolean']>;
  groupChanged?: InputMaybe<Scalars['DateTime']>;
  groupCode?: InputMaybe<Scalars['String']>;
  iccid: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  prevGroupCode?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<SimStatusEnum>;
  stripeInvoiceIds?: InputMaybe<SimCardCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  warned?: InputMaybe<Scalars['Boolean']>;
  warning?: InputMaybe<Scalars['Int']>;
};

export type SimCardCreateWithoutDealerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutSimCardsInput>;
  device?: InputMaybe<DeviceCreateNestedOneWithoutSimCardsInput>;
  deviceUser?: InputMaybe<DeviceUserCreateNestedOneWithoutSimCardsInput>;
  exceeded?: InputMaybe<Scalars['Boolean']>;
  groupChanged?: InputMaybe<Scalars['DateTime']>;
  groupCode?: InputMaybe<Scalars['String']>;
  iccid: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  prevGroupCode?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<SimStatusEnum>;
  stripeInvoiceIds?: InputMaybe<SimCardCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  warned?: InputMaybe<Scalars['Boolean']>;
  warning?: InputMaybe<Scalars['Int']>;
};

export type SimCardCreateWithoutDeviceInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutSimCardsInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutSimCardsInput>;
  deviceUser?: InputMaybe<DeviceUserCreateNestedOneWithoutSimCardsInput>;
  exceeded?: InputMaybe<Scalars['Boolean']>;
  groupChanged?: InputMaybe<Scalars['DateTime']>;
  groupCode?: InputMaybe<Scalars['String']>;
  iccid: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  prevGroupCode?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<SimStatusEnum>;
  stripeInvoiceIds?: InputMaybe<SimCardCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  warned?: InputMaybe<Scalars['Boolean']>;
  warning?: InputMaybe<Scalars['Int']>;
};

export type SimCardCreateWithoutDeviceUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutSimCardsInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutSimCardsInput>;
  device?: InputMaybe<DeviceCreateNestedOneWithoutSimCardsInput>;
  exceeded?: InputMaybe<Scalars['Boolean']>;
  groupChanged?: InputMaybe<Scalars['DateTime']>;
  groupCode?: InputMaybe<Scalars['String']>;
  iccid: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  locked?: InputMaybe<Scalars['Boolean']>;
  prevGroupCode?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<SimStatusEnum>;
  stripeInvoiceIds?: InputMaybe<SimCardCreatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  warned?: InputMaybe<Scalars['Boolean']>;
  warning?: InputMaybe<Scalars['Int']>;
};

export type SimCardCreatestripeInvoiceIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type SimCardListRelationFilter = {
  every?: InputMaybe<SimCardWhereInput>;
  none?: InputMaybe<SimCardWhereInput>;
  some?: InputMaybe<SimCardWhereInput>;
};

export type SimCardScalarWhereInput = {
  AND?: InputMaybe<Array<SimCardScalarWhereInput>>;
  NOT?: InputMaybe<Array<SimCardScalarWhereInput>>;
  OR?: InputMaybe<Array<SimCardScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealerId?: InputMaybe<StringNullableFilter>;
  deviceId?: InputMaybe<StringNullableFilter>;
  deviceUserId?: InputMaybe<StringNullableFilter>;
  exceeded?: InputMaybe<BoolNullableFilter>;
  groupChanged?: InputMaybe<DateTimeFilter>;
  groupCode?: InputMaybe<StringNullableFilter>;
  iccid?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  limit?: InputMaybe<IntFilter>;
  locked?: InputMaybe<BoolNullableFilter>;
  prevGroupCode?: InputMaybe<StringNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumSimStatusEnumFilter>;
  stripeInvoiceIds?: InputMaybe<StringNullableListFilter>;
  stripeProductId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  warned?: InputMaybe<BoolNullableFilter>;
  warning?: InputMaybe<IntFilter>;
};

export type SimCardUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  exceeded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  groupChanged?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  groupCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iccid?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  locked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  prevGroupCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumSimStatusEnumFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<SimCardUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  warned?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  warning?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type SimCardUpdateManyWithWhereWithoutCustomerInput = {
  data: SimCardUpdateManyMutationInput;
  where: SimCardScalarWhereInput;
};

export type SimCardUpdateManyWithWhereWithoutDealerInput = {
  data: SimCardUpdateManyMutationInput;
  where: SimCardScalarWhereInput;
};

export type SimCardUpdateManyWithWhereWithoutDeviceInput = {
  data: SimCardUpdateManyMutationInput;
  where: SimCardScalarWhereInput;
};

export type SimCardUpdateManyWithWhereWithoutDeviceUserInput = {
  data: SimCardUpdateManyMutationInput;
  where: SimCardScalarWhereInput;
};

export type SimCardUpdateManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SimCardCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<SimCardCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<SimCardCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SimCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  set?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  update?: InputMaybe<Array<SimCardUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<
    Array<SimCardUpdateManyWithWhereWithoutCustomerInput>
  >;
  upsert?: InputMaybe<Array<SimCardUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type SimCardUpdateManyWithoutDealerInput = {
  connect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SimCardCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<SimCardCreateWithoutDealerInput>>;
  createMany?: InputMaybe<SimCardCreateManyDealerInputEnvelope>;
  delete?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SimCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  set?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  update?: InputMaybe<Array<SimCardUpdateWithWhereUniqueWithoutDealerInput>>;
  updateMany?: InputMaybe<Array<SimCardUpdateManyWithWhereWithoutDealerInput>>;
  upsert?: InputMaybe<Array<SimCardUpsertWithWhereUniqueWithoutDealerInput>>;
};

export type SimCardUpdateManyWithoutDeviceInput = {
  connect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SimCardCreateOrConnectWithoutDeviceInput>>;
  create?: InputMaybe<Array<SimCardCreateWithoutDeviceInput>>;
  createMany?: InputMaybe<SimCardCreateManyDeviceInputEnvelope>;
  delete?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SimCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  set?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  update?: InputMaybe<Array<SimCardUpdateWithWhereUniqueWithoutDeviceInput>>;
  updateMany?: InputMaybe<Array<SimCardUpdateManyWithWhereWithoutDeviceInput>>;
  upsert?: InputMaybe<Array<SimCardUpsertWithWhereUniqueWithoutDeviceInput>>;
};

export type SimCardUpdateManyWithoutDeviceUserInput = {
  connect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SimCardCreateOrConnectWithoutDeviceUserInput>
  >;
  create?: InputMaybe<Array<SimCardCreateWithoutDeviceUserInput>>;
  createMany?: InputMaybe<SimCardCreateManyDeviceUserInputEnvelope>;
  delete?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SimCardScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  set?: InputMaybe<Array<SimCardWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SimCardUpdateWithWhereUniqueWithoutDeviceUserInput>
  >;
  updateMany?: InputMaybe<
    Array<SimCardUpdateManyWithWhereWithoutDeviceUserInput>
  >;
  upsert?: InputMaybe<
    Array<SimCardUpsertWithWhereUniqueWithoutDeviceUserInput>
  >;
};

export type SimCardUpdateWithWhereUniqueWithoutCustomerInput = {
  data: SimCardUpdateWithoutCustomerInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardUpdateWithWhereUniqueWithoutDealerInput = {
  data: SimCardUpdateWithoutDealerInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardUpdateWithWhereUniqueWithoutDeviceInput = {
  data: SimCardUpdateWithoutDeviceInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardUpdateWithWhereUniqueWithoutDeviceUserInput = {
  data: SimCardUpdateWithoutDeviceUserInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardUpdateWithoutCustomerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutSimCardsInput>;
  device?: InputMaybe<DeviceUpdateOneWithoutSimCardsInput>;
  deviceUser?: InputMaybe<DeviceUserUpdateOneWithoutSimCardsInput>;
  exceeded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  groupChanged?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  groupCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iccid?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  locked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  prevGroupCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumSimStatusEnumFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<SimCardUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  warned?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  warning?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type SimCardUpdateWithoutDealerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutSimCardsInput>;
  device?: InputMaybe<DeviceUpdateOneWithoutSimCardsInput>;
  deviceUser?: InputMaybe<DeviceUserUpdateOneWithoutSimCardsInput>;
  exceeded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  groupChanged?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  groupCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iccid?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  locked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  prevGroupCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumSimStatusEnumFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<SimCardUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  warned?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  warning?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type SimCardUpdateWithoutDeviceInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutSimCardsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutSimCardsInput>;
  deviceUser?: InputMaybe<DeviceUserUpdateOneWithoutSimCardsInput>;
  exceeded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  groupChanged?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  groupCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iccid?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  locked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  prevGroupCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumSimStatusEnumFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<SimCardUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  warned?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  warning?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type SimCardUpdateWithoutDeviceUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutSimCardsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutSimCardsInput>;
  device?: InputMaybe<DeviceUpdateOneWithoutSimCardsInput>;
  exceeded?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  groupChanged?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  groupCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iccid?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  locked?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  prevGroupCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumSimStatusEnumFieldUpdateOperationsInput>;
  stripeInvoiceIds?: InputMaybe<SimCardUpdatestripeInvoiceIdsInput>;
  stripeProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  warned?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  warning?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type SimCardUpdatestripeInvoiceIdsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type SimCardUpsertWithWhereUniqueWithoutCustomerInput = {
  create: SimCardCreateWithoutCustomerInput;
  update: SimCardUpdateWithoutCustomerInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardUpsertWithWhereUniqueWithoutDealerInput = {
  create: SimCardCreateWithoutDealerInput;
  update: SimCardUpdateWithoutDealerInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardUpsertWithWhereUniqueWithoutDeviceInput = {
  create: SimCardCreateWithoutDeviceInput;
  update: SimCardUpdateWithoutDeviceInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardUpsertWithWhereUniqueWithoutDeviceUserInput = {
  create: SimCardCreateWithoutDeviceUserInput;
  update: SimCardUpdateWithoutDeviceUserInput;
  where: SimCardWhereUniqueInput;
};

export type SimCardWhereInput = {
  AND?: InputMaybe<Array<SimCardWhereInput>>;
  NOT?: InputMaybe<Array<SimCardWhereInput>>;
  OR?: InputMaybe<Array<SimCardWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  device?: InputMaybe<DeviceWhereInput>;
  deviceId?: InputMaybe<StringNullableFilter>;
  deviceUser?: InputMaybe<DeviceUserWhereInput>;
  deviceUserId?: InputMaybe<StringNullableFilter>;
  exceeded?: InputMaybe<BoolNullableFilter>;
  groupChanged?: InputMaybe<DateTimeFilter>;
  groupCode?: InputMaybe<StringNullableFilter>;
  iccid?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  limit?: InputMaybe<IntFilter>;
  locked?: InputMaybe<BoolNullableFilter>;
  prevGroupCode?: InputMaybe<StringNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumSimStatusEnumFilter>;
  stripeInvoiceIds?: InputMaybe<StringNullableListFilter>;
  stripeProductId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  warned?: InputMaybe<BoolNullableFilter>;
  warning?: InputMaybe<IntFilter>;
};

export type SimCardWhereUniqueInput = {
  iccid?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export enum SimStatusEnum {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Deactivated = 'DEACTIVATED',
  Unregistered = 'UNREGISTERED',
}

/** The type of a SIM card returned on the sim object. */
export enum SimType {
  Ee = 'EE',
  MultinetKpnC = 'MULTINET_KPN_C',
  MultinetKpnLbs = 'MULTINET_KPN_LBS',
  MultinetKpnO = 'MULTINET_KPN_O',
  MultinetM2M = 'MULTINET_M2M',
  MultinetRogC = 'MULTINET_ROG_C',
  MultinetVoda = 'MULTINET_VODA',
  O2Uk = 'O2_UK',
  OneNet = 'ONE_NET',
  SmartO2Voda = 'SMART_O2_VODA',
  ThreeGlobal = 'THREE_GLOBAL',
  Verizon = 'VERIZON',
  VerizonM1 = 'VERIZON_M1',
}

export type SopLogin = {
  __typename?: 'SopLogin';
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  dealer?: Maybe<Dealer>;
  dealerId?: Maybe<Scalars['String']>;
  decryptedPassword?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  username: Scalars['String'];
};

export type SopLoginCreateNestedOneWithoutCustomerInput = {
  connect?: InputMaybe<SopLoginWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SopLoginCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<SopLoginCreateWithoutCustomerInput>;
};

export type SopLoginCreateNestedOneWithoutDealerInput = {
  connect?: InputMaybe<SopLoginWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SopLoginCreateOrConnectWithoutDealerInput>;
  create?: InputMaybe<SopLoginCreateWithoutDealerInput>;
};

export type SopLoginCreateOrConnectWithoutCustomerInput = {
  create: SopLoginCreateWithoutCustomerInput;
  where: SopLoginWhereUniqueInput;
};

export type SopLoginCreateOrConnectWithoutDealerInput = {
  create: SopLoginCreateWithoutDealerInput;
  where: SopLoginWhereUniqueInput;
};

export type SopLoginCreateWithoutCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutSopLoginInput>;
  id?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type SopLoginCreateWithoutDealerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutSopLoginInput>;
  id?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type SopLoginUpdateOneWithoutCustomerInput = {
  connect?: InputMaybe<SopLoginWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SopLoginCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<SopLoginCreateWithoutCustomerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SopLoginUpdateWithoutCustomerInput>;
  upsert?: InputMaybe<SopLoginUpsertWithoutCustomerInput>;
};

export type SopLoginUpdateOneWithoutDealerInput = {
  connect?: InputMaybe<SopLoginWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SopLoginCreateOrConnectWithoutDealerInput>;
  create?: InputMaybe<SopLoginCreateWithoutDealerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<SopLoginUpdateWithoutDealerInput>;
  upsert?: InputMaybe<SopLoginUpsertWithoutDealerInput>;
};

export type SopLoginUpdateWithoutCustomerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutSopLoginInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SopLoginUpdateWithoutDealerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutSopLoginInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  password?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type SopLoginUpsertWithoutCustomerInput = {
  create: SopLoginCreateWithoutCustomerInput;
  update: SopLoginUpdateWithoutCustomerInput;
};

export type SopLoginUpsertWithoutDealerInput = {
  create: SopLoginCreateWithoutDealerInput;
  update: SopLoginUpdateWithoutDealerInput;
};

export type SopLoginWhereInput = {
  AND?: InputMaybe<Array<SopLoginWhereInput>>;
  NOT?: InputMaybe<Array<SopLoginWhereInput>>;
  OR?: InputMaybe<Array<SopLoginWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringFilter>;
};

export type SopLoginWhereUniqueInput = {
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type StripeAddress = {
  __typename?: 'StripeAddress';
  city: Scalars['String'];
  country: Scalars['String'];
  county?: Maybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  postCode: Scalars['String'];
};

/** Data for a stripe address. */
export type StripeAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  county?: InputMaybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postCode: Scalars['String'];
};

export type StripeLocale = {
  __typename?: 'StripeLocale';
  currency?: Maybe<Scalars['String']>;
  preferred_locales?: Maybe<Scalars['String']>;
};

export type StripePrice = {
  __typename?: 'StripePrice';
  active?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  recurring?: Maybe<StripePriceRecurring>;
  unit_amount?: Maybe<Scalars['Int']>;
};

export type StripePriceRecurring = {
  __typename?: 'StripePriceRecurring';
  aggregate_usage?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['String']>;
  interval_count?: Maybe<Scalars['Int']>;
  usage_type?: Maybe<Scalars['String']>;
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  active?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  metadata?: Maybe<Translations>;
  name?: Maybe<Scalars['String']>;
  prices?: Maybe<Array<Maybe<StripePrice>>>;
  updated?: Maybe<Scalars['Int']>;
};

export type StripeShipping = {
  __typename?: 'StripeShipping';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
};

/** type: "sop" or "simcard". Subscription: https://stripe.com/docs/api/subscriptions/create */
export type SubscriptionCreateInput = {
  email: Scalars['String'];
  items?: InputMaybe<Array<InputMaybe<SubscriptionItem>>>;
  type: Scalars['String'];
};

export type SubscriptionCreateManyCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  type: SubscriptionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionCreateManyCustomerInputEnvelope = {
  data?: InputMaybe<Array<SubscriptionCreateManyCustomerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SubscriptionCreateManyDealerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  type: SubscriptionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionCreateManyDealerInputEnvelope = {
  data?: InputMaybe<Array<SubscriptionCreateManyDealerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SubscriptionCreateManyDistributorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  type: SubscriptionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionCreateManyDistributorInputEnvelope = {
  data?: InputMaybe<Array<SubscriptionCreateManyDistributorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SubscriptionCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SubscriptionCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<SubscriptionCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<SubscriptionCreateManyCustomerInputEnvelope>;
};

export type SubscriptionCreateNestedManyWithoutDealerInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SubscriptionCreateOrConnectWithoutDealerInput>
  >;
  create?: InputMaybe<Array<SubscriptionCreateWithoutDealerInput>>;
  createMany?: InputMaybe<SubscriptionCreateManyDealerInputEnvelope>;
};

export type SubscriptionCreateNestedManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SubscriptionCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<SubscriptionCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<SubscriptionCreateManyDistributorInputEnvelope>;
};

export type SubscriptionCreateOrConnectWithoutCustomerInput = {
  create: SubscriptionCreateWithoutCustomerInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionCreateOrConnectWithoutDealerInput = {
  create: SubscriptionCreateWithoutDealerInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionCreateOrConnectWithoutDistributorInput = {
  create: SubscriptionCreateWithoutDistributorInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionCreateWithoutCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutSubscriptionsInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutSubscriptionsInput>;
  id?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<SubscriptionProductCreateNestedManyWithoutSubscriptionInput>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  type: SubscriptionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionCreateWithoutDealerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutSubscriptionsInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutSubscriptionsInput>;
  id?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<SubscriptionProductCreateNestedManyWithoutSubscriptionInput>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  type: SubscriptionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionCreateWithoutDistributorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutSubscriptionsInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutSubscriptionsInput>;
  id?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<SubscriptionProductCreateNestedManyWithoutSubscriptionInput>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId?: InputMaybe<Scalars['String']>;
  type: SubscriptionType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

/** items in https://stripe.com/docs/api/subscriptions/create */
export type SubscriptionItem = {
  price: Scalars['String'];
  quantity?: InputMaybe<Scalars['Int']>;
};

export type SubscriptionListRelationFilter = {
  every?: InputMaybe<SubscriptionWhereInput>;
  none?: InputMaybe<SubscriptionWhereInput>;
  some?: InputMaybe<SubscriptionWhereInput>;
};

export type SubscriptionProductCreateManySubscriptionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  type: ProductType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionProductCreateManySubscriptionInputEnvelope = {
  data?: InputMaybe<Array<SubscriptionProductCreateManySubscriptionInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SubscriptionProductCreateNestedManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<SubscriptionProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SubscriptionProductCreateOrConnectWithoutSubscriptionInput>
  >;
  create?: InputMaybe<Array<SubscriptionProductCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<SubscriptionProductCreateManySubscriptionInputEnvelope>;
};

export type SubscriptionProductCreateOrConnectWithoutSubscriptionInput = {
  create: SubscriptionProductCreateWithoutSubscriptionInput;
  where: SubscriptionProductWhereUniqueInput;
};

export type SubscriptionProductCreateWithoutSubscriptionInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  stripeId: Scalars['String'];
  type: ProductType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SubscriptionProductListRelationFilter = {
  every?: InputMaybe<SubscriptionProductWhereInput>;
  none?: InputMaybe<SubscriptionProductWhereInput>;
  some?: InputMaybe<SubscriptionProductWhereInput>;
};

export type SubscriptionProductScalarWhereInput = {
  AND?: InputMaybe<Array<SubscriptionProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionProductScalarWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionProductScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  quantity?: InputMaybe<IntFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeId?: InputMaybe<StringFilter>;
  subscriptionId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumProductTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionProductUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriptionProductUpdateManyWithWhereWithoutSubscriptionInput = {
  data: SubscriptionProductUpdateManyMutationInput;
  where: SubscriptionProductScalarWhereInput;
};

export type SubscriptionProductUpdateManyWithoutSubscriptionInput = {
  connect?: InputMaybe<Array<SubscriptionProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SubscriptionProductCreateOrConnectWithoutSubscriptionInput>
  >;
  create?: InputMaybe<Array<SubscriptionProductCreateWithoutSubscriptionInput>>;
  createMany?: InputMaybe<SubscriptionProductCreateManySubscriptionInputEnvelope>;
  delete?: InputMaybe<Array<SubscriptionProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubscriptionProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubscriptionProductWhereUniqueInput>>;
  set?: InputMaybe<Array<SubscriptionProductWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SubscriptionProductUpdateWithWhereUniqueWithoutSubscriptionInput>
  >;
  updateMany?: InputMaybe<
    Array<SubscriptionProductUpdateManyWithWhereWithoutSubscriptionInput>
  >;
  upsert?: InputMaybe<
    Array<SubscriptionProductUpsertWithWhereUniqueWithoutSubscriptionInput>
  >;
};

export type SubscriptionProductUpdateWithWhereUniqueWithoutSubscriptionInput = {
  data: SubscriptionProductUpdateWithoutSubscriptionInput;
  where: SubscriptionProductWhereUniqueInput;
};

export type SubscriptionProductUpdateWithoutSubscriptionInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  price?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriptionProductUpsertWithWhereUniqueWithoutSubscriptionInput = {
  create: SubscriptionProductCreateWithoutSubscriptionInput;
  update: SubscriptionProductUpdateWithoutSubscriptionInput;
  where: SubscriptionProductWhereUniqueInput;
};

export type SubscriptionProductWhereInput = {
  AND?: InputMaybe<Array<SubscriptionProductWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionProductWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionProductWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  price?: InputMaybe<FloatNullableFilter>;
  quantity?: InputMaybe<IntFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeId?: InputMaybe<StringFilter>;
  subscription?: InputMaybe<SubscriptionWhereInput>;
  subscriptionId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumProductTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionProductWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type SubscriptionScalarWhereInput = {
  AND?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributorId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumSubscriptionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum SubscriptionType {
  Live = 'LIVE',
  Staging = 'STAGING',
}

export type SubscriptionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSubscriptionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriptionUpdateManyWithWhereWithoutCustomerInput = {
  data: SubscriptionUpdateManyMutationInput;
  where: SubscriptionScalarWhereInput;
};

export type SubscriptionUpdateManyWithWhereWithoutDealerInput = {
  data: SubscriptionUpdateManyMutationInput;
  where: SubscriptionScalarWhereInput;
};

export type SubscriptionUpdateManyWithWhereWithoutDistributorInput = {
  data: SubscriptionUpdateManyMutationInput;
  where: SubscriptionScalarWhereInput;
};

export type SubscriptionUpdateManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SubscriptionCreateOrConnectWithoutCustomerInput>
  >;
  create?: InputMaybe<Array<SubscriptionCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<SubscriptionCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SubscriptionUpdateWithWhereUniqueWithoutCustomerInput>
  >;
  updateMany?: InputMaybe<
    Array<SubscriptionUpdateManyWithWhereWithoutCustomerInput>
  >;
  upsert?: InputMaybe<
    Array<SubscriptionUpsertWithWhereUniqueWithoutCustomerInput>
  >;
};

export type SubscriptionUpdateManyWithoutDealerInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SubscriptionCreateOrConnectWithoutDealerInput>
  >;
  create?: InputMaybe<Array<SubscriptionCreateWithoutDealerInput>>;
  createMany?: InputMaybe<SubscriptionCreateManyDealerInputEnvelope>;
  delete?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SubscriptionUpdateWithWhereUniqueWithoutDealerInput>
  >;
  updateMany?: InputMaybe<
    Array<SubscriptionUpdateManyWithWhereWithoutDealerInput>
  >;
  upsert?: InputMaybe<
    Array<SubscriptionUpsertWithWhereUniqueWithoutDealerInput>
  >;
};

export type SubscriptionUpdateManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<SubscriptionCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<SubscriptionCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<SubscriptionCreateManyDistributorInputEnvelope>;
  delete?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SubscriptionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  set?: InputMaybe<Array<SubscriptionWhereUniqueInput>>;
  update?: InputMaybe<
    Array<SubscriptionUpdateWithWhereUniqueWithoutDistributorInput>
  >;
  updateMany?: InputMaybe<
    Array<SubscriptionUpdateManyWithWhereWithoutDistributorInput>
  >;
  upsert?: InputMaybe<
    Array<SubscriptionUpsertWithWhereUniqueWithoutDistributorInput>
  >;
};

export type SubscriptionUpdateWithWhereUniqueWithoutCustomerInput = {
  data: SubscriptionUpdateWithoutCustomerInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionUpdateWithWhereUniqueWithoutDealerInput = {
  data: SubscriptionUpdateWithoutDealerInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionUpdateWithWhereUniqueWithoutDistributorInput = {
  data: SubscriptionUpdateWithoutDistributorInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionUpdateWithoutCustomerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutSubscriptionsInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutSubscriptionsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<SubscriptionProductUpdateManyWithoutSubscriptionInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSubscriptionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriptionUpdateWithoutDealerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutSubscriptionsInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutSubscriptionsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<SubscriptionProductUpdateManyWithoutSubscriptionInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSubscriptionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriptionUpdateWithoutDistributorInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutSubscriptionsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutSubscriptionsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<SubscriptionProductUpdateManyWithoutSubscriptionInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  stripeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumSubscriptionTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SubscriptionUpsertWithWhereUniqueWithoutCustomerInput = {
  create: SubscriptionCreateWithoutCustomerInput;
  update: SubscriptionUpdateWithoutCustomerInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionUpsertWithWhereUniqueWithoutDealerInput = {
  create: SubscriptionCreateWithoutDealerInput;
  update: SubscriptionUpdateWithoutDealerInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionUpsertWithWhereUniqueWithoutDistributorInput = {
  create: SubscriptionCreateWithoutDistributorInput;
  update: SubscriptionUpdateWithoutDistributorInput;
  where: SubscriptionWhereUniqueInput;
};

export type SubscriptionWhereInput = {
  AND?: InputMaybe<Array<SubscriptionWhereInput>>;
  NOT?: InputMaybe<Array<SubscriptionWhereInput>>;
  OR?: InputMaybe<Array<SubscriptionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributor?: InputMaybe<DistributorWhereInput>;
  distributorId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  products?: InputMaybe<SubscriptionProductListRelationFilter>;
  recycled?: InputMaybe<BoolFilter>;
  stripeId?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumSubscriptionTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SubscriptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Sync = {
  __typename?: 'Sync';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  success: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type Team = {
  __typename?: 'Team';
  createdAt: Scalars['DateTime'];
  customer: Customer;
  customerId: Scalars['String'];
  id: Scalars['String'];
  members: Array<DeviceUser>;
  name: Scalars['String'];
  recycled: Scalars['Boolean'];
  rootTeam: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type TeamMembersArgs = {
  after?: InputMaybe<DeviceUserWhereUniqueInput>;
  before?: InputMaybe<DeviceUserWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TeamCreateManyCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  rootTeam: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TeamCreateManyCustomerInputEnvelope = {
  data?: InputMaybe<Array<TeamCreateManyCustomerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TeamCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<TeamCreateManyCustomerInputEnvelope>;
};

export type TeamCreateNestedManyWithoutMembersInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutMembersInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutMembersInput>>;
};

export type TeamCreateOrConnectWithoutCustomerInput = {
  create: TeamCreateWithoutCustomerInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutMembersInput = {
  create: TeamCreateWithoutMembersInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateWithoutCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<DeviceUserCreateNestedManyWithoutTeamsInput>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  rootTeam: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TeamCreateWithoutMembersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer: CustomerCreateNestedOneWithoutTeamsInput;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  rootTeam: Scalars['Boolean'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TeamListRelationFilter = {
  every?: InputMaybe<TeamWhereInput>;
  none?: InputMaybe<TeamWhereInput>;
  some?: InputMaybe<TeamWhereInput>;
};

export type TeamScalarWhereInput = {
  AND?: InputMaybe<Array<TeamScalarWhereInput>>;
  NOT?: InputMaybe<Array<TeamScalarWhereInput>>;
  OR?: InputMaybe<Array<TeamScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  rootTeam?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TeamUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  rootTeam?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateManyWithWhereWithoutCustomerInput = {
  data: TeamUpdateManyMutationInput;
  where: TeamScalarWhereInput;
};

export type TeamUpdateManyWithWhereWithoutMembersInput = {
  data: TeamUpdateManyMutationInput;
  where: TeamScalarWhereInput;
};

export type TeamUpdateManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<TeamCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<TeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<TeamUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<TeamUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type TeamUpdateManyWithoutMembersInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutMembersInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutMembersInput>>;
  delete?: InputMaybe<Array<TeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamUpdateWithWhereUniqueWithoutMembersInput>>;
  updateMany?: InputMaybe<Array<TeamUpdateManyWithWhereWithoutMembersInput>>;
  upsert?: InputMaybe<Array<TeamUpsertWithWhereUniqueWithoutMembersInput>>;
};

export type TeamUpdateWithWhereUniqueWithoutCustomerInput = {
  data: TeamUpdateWithoutCustomerInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpdateWithWhereUniqueWithoutMembersInput = {
  data: TeamUpdateWithoutMembersInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpdateWithoutCustomerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  members?: InputMaybe<DeviceUserUpdateManyWithoutTeamsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  rootTeam?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpdateWithoutMembersInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneRequiredWithoutTeamsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  rootTeam?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TeamUpsertWithWhereUniqueWithoutCustomerInput = {
  create: TeamCreateWithoutCustomerInput;
  update: TeamUpdateWithoutCustomerInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpsertWithWhereUniqueWithoutMembersInput = {
  create: TeamCreateWithoutMembersInput;
  update: TeamUpdateWithoutMembersInput;
  where: TeamWhereUniqueInput;
};

export type TeamWhereInput = {
  AND?: InputMaybe<Array<TeamWhereInput>>;
  NOT?: InputMaybe<Array<TeamWhereInput>>;
  OR?: InputMaybe<Array<TeamWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  members?: InputMaybe<DeviceUserListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  rootTeam?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TeamWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Term = {
  __typename?: 'Term';
  agreements: Array<TermsAgreement>;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  czContent?: Maybe<Scalars['String']>;
  deContent?: Maybe<Scalars['String']>;
  defaultTerms: Scalars['Boolean'];
  description: Scalars['String'];
  frContent?: Maybe<Scalars['String']>;
  grContent?: Maybe<Scalars['String']>;
  hrContent?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  recycled: Scalars['Boolean'];
  skContent?: Maybe<Scalars['String']>;
  type: TermTypeEnum;
  updatedAt: Scalars['DateTime'];
  version: Scalars['Float'];
};

export type TermAgreementsArgs = {
  after?: InputMaybe<TermsAgreementWhereUniqueInput>;
  before?: InputMaybe<TermsAgreementWhereUniqueInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type TermCreateInput = {
  agreements?: InputMaybe<TermsAgreementCreateNestedManyWithoutTermsInput>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  czContent?: InputMaybe<Scalars['String']>;
  deContent?: InputMaybe<Scalars['String']>;
  defaultTerms?: InputMaybe<Scalars['Boolean']>;
  description: Scalars['String'];
  frContent?: InputMaybe<Scalars['String']>;
  grContent?: InputMaybe<Scalars['String']>;
  hrContent?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  skContent?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TermTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  version: Scalars['Float'];
};

export type TermCreateNestedOneWithoutAgreementsInput = {
  connect?: InputMaybe<TermWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TermCreateOrConnectWithoutAgreementsInput>;
  create?: InputMaybe<TermCreateWithoutAgreementsInput>;
};

export type TermCreateOrConnectWithoutAgreementsInput = {
  create: TermCreateWithoutAgreementsInput;
  where: TermWhereUniqueInput;
};

export type TermCreateWithoutAgreementsInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  czContent?: InputMaybe<Scalars['String']>;
  deContent?: InputMaybe<Scalars['String']>;
  defaultTerms?: InputMaybe<Scalars['Boolean']>;
  description: Scalars['String'];
  frContent?: InputMaybe<Scalars['String']>;
  grContent?: InputMaybe<Scalars['String']>;
  hrContent?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  recycled?: InputMaybe<Scalars['Boolean']>;
  skContent?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TermTypeEnum>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  version: Scalars['Float'];
};

export enum TermTypeEnum {
  Customer = 'CUSTOMER',
  Dealer = 'DEALER',
  User = 'USER',
}

export type TermUpdateInput = {
  agreements?: InputMaybe<TermsAgreementUpdateManyWithoutTermsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  czContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  deContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  defaultTerms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  frContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  grContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hrContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  skContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTermTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  version?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type TermUpdateOneRequiredWithoutAgreementsInput = {
  connect?: InputMaybe<TermWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TermCreateOrConnectWithoutAgreementsInput>;
  create?: InputMaybe<TermCreateWithoutAgreementsInput>;
  update?: InputMaybe<TermUpdateWithoutAgreementsInput>;
  upsert?: InputMaybe<TermUpsertWithoutAgreementsInput>;
};

export type TermUpdateWithoutAgreementsInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  czContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  deContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  defaultTerms?: InputMaybe<BoolFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  frContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  grContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  hrContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  skContent?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTermTypeEnumFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  version?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type TermUpsertWithoutAgreementsInput = {
  create: TermCreateWithoutAgreementsInput;
  update: TermUpdateWithoutAgreementsInput;
};

export type TermWhereInput = {
  AND?: InputMaybe<Array<TermWhereInput>>;
  NOT?: InputMaybe<Array<TermWhereInput>>;
  OR?: InputMaybe<Array<TermWhereInput>>;
  agreements?: InputMaybe<TermsAgreementListRelationFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  czContent?: InputMaybe<StringNullableFilter>;
  deContent?: InputMaybe<StringNullableFilter>;
  defaultTerms?: InputMaybe<BoolFilter>;
  description?: InputMaybe<StringFilter>;
  frContent?: InputMaybe<StringNullableFilter>;
  grContent?: InputMaybe<StringNullableFilter>;
  hrContent?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  skContent?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumTermTypeEnumFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  version?: InputMaybe<FloatFilter>;
};

export type TermWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TermsAgreement = {
  __typename?: 'TermsAgreement';
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  dealer?: Maybe<Dealer>;
  dealerId?: Maybe<Scalars['String']>;
  expired: Scalars['Boolean'];
  id: Scalars['String'];
  jobTitle?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  recycled: Scalars['Boolean'];
  signature?: Maybe<Scalars['String']>;
  terms: Term;
  termsId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type TermsAgreementCreateManyTermsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  expired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  signature?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type TermsAgreementCreateManyTermsInputEnvelope = {
  data?: InputMaybe<Array<TermsAgreementCreateManyTermsInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TermsAgreementCreateNestedManyWithoutTermsInput = {
  connect?: InputMaybe<Array<TermsAgreementWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TermsAgreementCreateOrConnectWithoutTermsInput>
  >;
  create?: InputMaybe<Array<TermsAgreementCreateWithoutTermsInput>>;
  createMany?: InputMaybe<TermsAgreementCreateManyTermsInputEnvelope>;
};

export type TermsAgreementCreateNestedOneWithoutCustomerInput = {
  connect?: InputMaybe<TermsAgreementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TermsAgreementCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<TermsAgreementCreateWithoutCustomerInput>;
};

export type TermsAgreementCreateNestedOneWithoutDealerInput = {
  connect?: InputMaybe<TermsAgreementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TermsAgreementCreateOrConnectWithoutDealerInput>;
  create?: InputMaybe<TermsAgreementCreateWithoutDealerInput>;
};

export type TermsAgreementCreateNestedOneWithoutDistributorInput = {
  connect?: InputMaybe<TermsAgreementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TermsAgreementCreateOrConnectWithoutDistributorInput>;
  create?: InputMaybe<TermsAgreementCreateWithoutDistributorInput>;
};

export type TermsAgreementCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<TermsAgreementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TermsAgreementCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<TermsAgreementCreateWithoutUserInput>;
};

export type TermsAgreementCreateOrConnectWithoutCustomerInput = {
  create: TermsAgreementCreateWithoutCustomerInput;
  where: TermsAgreementWhereUniqueInput;
};

export type TermsAgreementCreateOrConnectWithoutDealerInput = {
  create: TermsAgreementCreateWithoutDealerInput;
  where: TermsAgreementWhereUniqueInput;
};

export type TermsAgreementCreateOrConnectWithoutDistributorInput = {
  create: TermsAgreementCreateWithoutDistributorInput;
  where: TermsAgreementWhereUniqueInput;
};

export type TermsAgreementCreateOrConnectWithoutTermsInput = {
  create: TermsAgreementCreateWithoutTermsInput;
  where: TermsAgreementWhereUniqueInput;
};

export type TermsAgreementCreateOrConnectWithoutUserInput = {
  create: TermsAgreementCreateWithoutUserInput;
  where: TermsAgreementWhereUniqueInput;
};

export type TermsAgreementCreateWithoutCustomerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutTermAgreementInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutTermAgreementInput>;
  expired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  signature?: InputMaybe<Scalars['String']>;
  terms: TermCreateNestedOneWithoutAgreementsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutTermsAgreementInput>;
};

export type TermsAgreementCreateWithoutDealerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutTermsAgreementInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutTermAgreementInput>;
  expired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  signature?: InputMaybe<Scalars['String']>;
  terms: TermCreateNestedOneWithoutAgreementsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutTermsAgreementInput>;
};

export type TermsAgreementCreateWithoutDistributorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutTermsAgreementInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutTermAgreementInput>;
  expired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  signature?: InputMaybe<Scalars['String']>;
  terms: TermCreateNestedOneWithoutAgreementsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutTermsAgreementInput>;
};

export type TermsAgreementCreateWithoutTermsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutTermsAgreementInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutTermAgreementInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutTermAgreementInput>;
  expired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  signature?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user?: InputMaybe<UserCreateNestedOneWithoutTermsAgreementInput>;
};

export type TermsAgreementCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutTermsAgreementInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutTermAgreementInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutTermAgreementInput>;
  expired?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  signature?: InputMaybe<Scalars['String']>;
  terms: TermCreateNestedOneWithoutAgreementsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TermsAgreementListRelationFilter = {
  every?: InputMaybe<TermsAgreementWhereInput>;
  none?: InputMaybe<TermsAgreementWhereInput>;
  some?: InputMaybe<TermsAgreementWhereInput>;
};

export type TermsAgreementScalarWhereInput = {
  AND?: InputMaybe<Array<TermsAgreementScalarWhereInput>>;
  NOT?: InputMaybe<Array<TermsAgreementScalarWhereInput>>;
  OR?: InputMaybe<Array<TermsAgreementScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributorId?: InputMaybe<StringNullableFilter>;
  expired?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  jobTitle?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  signature?: InputMaybe<StringNullableFilter>;
  termsId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type TermsAgreementUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  signature?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TermsAgreementUpdateManyWithWhereWithoutTermsInput = {
  data: TermsAgreementUpdateManyMutationInput;
  where: TermsAgreementScalarWhereInput;
};

export type TermsAgreementUpdateManyWithoutTermsInput = {
  connect?: InputMaybe<Array<TermsAgreementWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TermsAgreementCreateOrConnectWithoutTermsInput>
  >;
  create?: InputMaybe<Array<TermsAgreementCreateWithoutTermsInput>>;
  createMany?: InputMaybe<TermsAgreementCreateManyTermsInputEnvelope>;
  delete?: InputMaybe<Array<TermsAgreementWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TermsAgreementScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TermsAgreementWhereUniqueInput>>;
  set?: InputMaybe<Array<TermsAgreementWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TermsAgreementUpdateWithWhereUniqueWithoutTermsInput>
  >;
  updateMany?: InputMaybe<
    Array<TermsAgreementUpdateManyWithWhereWithoutTermsInput>
  >;
  upsert?: InputMaybe<
    Array<TermsAgreementUpsertWithWhereUniqueWithoutTermsInput>
  >;
};

export type TermsAgreementUpdateOneWithoutCustomerInput = {
  connect?: InputMaybe<TermsAgreementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TermsAgreementCreateOrConnectWithoutCustomerInput>;
  create?: InputMaybe<TermsAgreementCreateWithoutCustomerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TermsAgreementUpdateWithoutCustomerInput>;
  upsert?: InputMaybe<TermsAgreementUpsertWithoutCustomerInput>;
};

export type TermsAgreementUpdateOneWithoutDealerInput = {
  connect?: InputMaybe<TermsAgreementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TermsAgreementCreateOrConnectWithoutDealerInput>;
  create?: InputMaybe<TermsAgreementCreateWithoutDealerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TermsAgreementUpdateWithoutDealerInput>;
  upsert?: InputMaybe<TermsAgreementUpsertWithoutDealerInput>;
};

export type TermsAgreementUpdateOneWithoutDistributorInput = {
  connect?: InputMaybe<TermsAgreementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TermsAgreementCreateOrConnectWithoutDistributorInput>;
  create?: InputMaybe<TermsAgreementCreateWithoutDistributorInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TermsAgreementUpdateWithoutDistributorInput>;
  upsert?: InputMaybe<TermsAgreementUpsertWithoutDistributorInput>;
};

export type TermsAgreementUpdateOneWithoutUserInput = {
  connect?: InputMaybe<TermsAgreementWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TermsAgreementCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<TermsAgreementCreateWithoutUserInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TermsAgreementUpdateWithoutUserInput>;
  upsert?: InputMaybe<TermsAgreementUpsertWithoutUserInput>;
};

export type TermsAgreementUpdateWithWhereUniqueWithoutTermsInput = {
  data: TermsAgreementUpdateWithoutTermsInput;
  where: TermsAgreementWhereUniqueInput;
};

export type TermsAgreementUpdateWithoutCustomerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutTermAgreementInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutTermAgreementInput>;
  expired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  signature?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  terms?: InputMaybe<TermUpdateOneRequiredWithoutAgreementsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutTermsAgreementInput>;
};

export type TermsAgreementUpdateWithoutDealerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutTermsAgreementInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutTermAgreementInput>;
  expired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  signature?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  terms?: InputMaybe<TermUpdateOneRequiredWithoutAgreementsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutTermsAgreementInput>;
};

export type TermsAgreementUpdateWithoutDistributorInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutTermsAgreementInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutTermAgreementInput>;
  expired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  signature?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  terms?: InputMaybe<TermUpdateOneRequiredWithoutAgreementsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutTermsAgreementInput>;
};

export type TermsAgreementUpdateWithoutTermsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutTermsAgreementInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutTermAgreementInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutTermAgreementInput>;
  expired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  signature?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneWithoutTermsAgreementInput>;
};

export type TermsAgreementUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutTermsAgreementInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutTermAgreementInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutTermAgreementInput>;
  expired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  jobTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  signature?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  terms?: InputMaybe<TermUpdateOneRequiredWithoutAgreementsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TermsAgreementUpsertWithWhereUniqueWithoutTermsInput = {
  create: TermsAgreementCreateWithoutTermsInput;
  update: TermsAgreementUpdateWithoutTermsInput;
  where: TermsAgreementWhereUniqueInput;
};

export type TermsAgreementUpsertWithoutCustomerInput = {
  create: TermsAgreementCreateWithoutCustomerInput;
  update: TermsAgreementUpdateWithoutCustomerInput;
};

export type TermsAgreementUpsertWithoutDealerInput = {
  create: TermsAgreementCreateWithoutDealerInput;
  update: TermsAgreementUpdateWithoutDealerInput;
};

export type TermsAgreementUpsertWithoutDistributorInput = {
  create: TermsAgreementCreateWithoutDistributorInput;
  update: TermsAgreementUpdateWithoutDistributorInput;
};

export type TermsAgreementUpsertWithoutUserInput = {
  create: TermsAgreementCreateWithoutUserInput;
  update: TermsAgreementUpdateWithoutUserInput;
};

export type TermsAgreementWhereInput = {
  AND?: InputMaybe<Array<TermsAgreementWhereInput>>;
  NOT?: InputMaybe<Array<TermsAgreementWhereInput>>;
  OR?: InputMaybe<Array<TermsAgreementWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributor?: InputMaybe<DistributorWhereInput>;
  distributorId?: InputMaybe<StringNullableFilter>;
  expired?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  jobTitle?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  recycled?: InputMaybe<BoolFilter>;
  signature?: InputMaybe<StringNullableFilter>;
  terms?: InputMaybe<TermWhereInput>;
  termsId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringNullableFilter>;
};

export type TermsAgreementWhereUniqueInput = {
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type TranslationContent = {
  __typename?: 'TranslationContent';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  language: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TranslationContentCreateManyChapterTitleInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDescId?: InputMaybe<Scalars['String']>;
  downloadNameId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDescId?: InputMaybe<Scalars['String']>;
  manualTitleId?: InputMaybe<Scalars['String']>;
  pageTitleId?: InputMaybe<Scalars['String']>;
  pagecontentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateManyChapterTitleInputEnvelope = {
  data?: InputMaybe<Array<TranslationContentCreateManyChapterTitleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TranslationContentCreateManyDownloadDescInput = {
  chapterTitleId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadNameId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDescId?: InputMaybe<Scalars['String']>;
  manualTitleId?: InputMaybe<Scalars['String']>;
  pageTitleId?: InputMaybe<Scalars['String']>;
  pagecontentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateManyDownloadDescInputEnvelope = {
  data?: InputMaybe<Array<TranslationContentCreateManyDownloadDescInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TranslationContentCreateManyDownloadNameInput = {
  chapterTitleId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDescId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDescId?: InputMaybe<Scalars['String']>;
  manualTitleId?: InputMaybe<Scalars['String']>;
  pageTitleId?: InputMaybe<Scalars['String']>;
  pagecontentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateManyDownloadNameInputEnvelope = {
  data?: InputMaybe<Array<TranslationContentCreateManyDownloadNameInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TranslationContentCreateManyManualDescInput = {
  chapterTitleId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDescId?: InputMaybe<Scalars['String']>;
  downloadNameId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualTitleId?: InputMaybe<Scalars['String']>;
  pageTitleId?: InputMaybe<Scalars['String']>;
  pagecontentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateManyManualDescInputEnvelope = {
  data?: InputMaybe<Array<TranslationContentCreateManyManualDescInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TranslationContentCreateManyManualTitleInput = {
  chapterTitleId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDescId?: InputMaybe<Scalars['String']>;
  downloadNameId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDescId?: InputMaybe<Scalars['String']>;
  pageTitleId?: InputMaybe<Scalars['String']>;
  pagecontentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateManyManualTitleInputEnvelope = {
  data?: InputMaybe<Array<TranslationContentCreateManyManualTitleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TranslationContentCreateManyPageContentInput = {
  chapterTitleId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDescId?: InputMaybe<Scalars['String']>;
  downloadNameId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDescId?: InputMaybe<Scalars['String']>;
  manualTitleId?: InputMaybe<Scalars['String']>;
  pageTitleId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateManyPageContentInputEnvelope = {
  data?: InputMaybe<Array<TranslationContentCreateManyPageContentInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TranslationContentCreateManyPageTitleInput = {
  chapterTitleId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDescId?: InputMaybe<Scalars['String']>;
  downloadNameId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDescId?: InputMaybe<Scalars['String']>;
  manualTitleId?: InputMaybe<Scalars['String']>;
  pagecontentId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateManyPageTitleInputEnvelope = {
  data?: InputMaybe<Array<TranslationContentCreateManyPageTitleInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TranslationContentCreateNestedManyWithoutChapterTitleInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutChapterTitleInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutChapterTitleInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyChapterTitleInputEnvelope>;
};

export type TranslationContentCreateNestedManyWithoutDownloadDescInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutDownloadDescInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutDownloadDescInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyDownloadDescInputEnvelope>;
};

export type TranslationContentCreateNestedManyWithoutDownloadNameInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutDownloadNameInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutDownloadNameInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyDownloadNameInputEnvelope>;
};

export type TranslationContentCreateNestedManyWithoutManualDescInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutManualDescInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutManualDescInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyManualDescInputEnvelope>;
};

export type TranslationContentCreateNestedManyWithoutManualTitleInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutManualTitleInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutManualTitleInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyManualTitleInputEnvelope>;
};

export type TranslationContentCreateNestedManyWithoutPageContentInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutPageContentInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutPageContentInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyPageContentInputEnvelope>;
};

export type TranslationContentCreateNestedManyWithoutPageTitleInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutPageTitleInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutPageTitleInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyPageTitleInputEnvelope>;
};

export type TranslationContentCreateOrConnectWithoutChapterTitleInput = {
  create: TranslationContentCreateWithoutChapterTitleInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentCreateOrConnectWithoutDownloadDescInput = {
  create: TranslationContentCreateWithoutDownloadDescInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentCreateOrConnectWithoutDownloadNameInput = {
  create: TranslationContentCreateWithoutDownloadNameInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentCreateOrConnectWithoutManualDescInput = {
  create: TranslationContentCreateWithoutManualDescInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentCreateOrConnectWithoutManualTitleInput = {
  create: TranslationContentCreateWithoutManualTitleInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentCreateOrConnectWithoutPageContentInput = {
  create: TranslationContentCreateWithoutPageContentInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentCreateOrConnectWithoutPageTitleInput = {
  create: TranslationContentCreateWithoutPageTitleInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentCreateWithoutChapterTitleInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDesc?: InputMaybe<DownloadCreateNestedOneWithoutDescTranslationsInput>;
  downloadName?: InputMaybe<DownloadCreateNestedOneWithoutNameTranslationsInput>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDesc?: InputMaybe<ManualCreateNestedOneWithoutDescTranslationsInput>;
  manualTitle?: InputMaybe<ManualCreateNestedOneWithoutTitleTranslationsInput>;
  pageContent?: InputMaybe<ManualPageCreateNestedOneWithoutContentTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageCreateNestedOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateWithoutDownloadDescInput = {
  chapterTitle?: InputMaybe<ManualChapterCreateNestedOneWithoutTitleTranslationsInput>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadName?: InputMaybe<DownloadCreateNestedOneWithoutNameTranslationsInput>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDesc?: InputMaybe<ManualCreateNestedOneWithoutDescTranslationsInput>;
  manualTitle?: InputMaybe<ManualCreateNestedOneWithoutTitleTranslationsInput>;
  pageContent?: InputMaybe<ManualPageCreateNestedOneWithoutContentTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageCreateNestedOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateWithoutDownloadNameInput = {
  chapterTitle?: InputMaybe<ManualChapterCreateNestedOneWithoutTitleTranslationsInput>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDesc?: InputMaybe<DownloadCreateNestedOneWithoutDescTranslationsInput>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDesc?: InputMaybe<ManualCreateNestedOneWithoutDescTranslationsInput>;
  manualTitle?: InputMaybe<ManualCreateNestedOneWithoutTitleTranslationsInput>;
  pageContent?: InputMaybe<ManualPageCreateNestedOneWithoutContentTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageCreateNestedOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateWithoutManualDescInput = {
  chapterTitle?: InputMaybe<ManualChapterCreateNestedOneWithoutTitleTranslationsInput>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDesc?: InputMaybe<DownloadCreateNestedOneWithoutDescTranslationsInput>;
  downloadName?: InputMaybe<DownloadCreateNestedOneWithoutNameTranslationsInput>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualTitle?: InputMaybe<ManualCreateNestedOneWithoutTitleTranslationsInput>;
  pageContent?: InputMaybe<ManualPageCreateNestedOneWithoutContentTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageCreateNestedOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateWithoutManualTitleInput = {
  chapterTitle?: InputMaybe<ManualChapterCreateNestedOneWithoutTitleTranslationsInput>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDesc?: InputMaybe<DownloadCreateNestedOneWithoutDescTranslationsInput>;
  downloadName?: InputMaybe<DownloadCreateNestedOneWithoutNameTranslationsInput>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDesc?: InputMaybe<ManualCreateNestedOneWithoutDescTranslationsInput>;
  pageContent?: InputMaybe<ManualPageCreateNestedOneWithoutContentTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageCreateNestedOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateWithoutPageContentInput = {
  chapterTitle?: InputMaybe<ManualChapterCreateNestedOneWithoutTitleTranslationsInput>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDesc?: InputMaybe<DownloadCreateNestedOneWithoutDescTranslationsInput>;
  downloadName?: InputMaybe<DownloadCreateNestedOneWithoutNameTranslationsInput>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDesc?: InputMaybe<ManualCreateNestedOneWithoutDescTranslationsInput>;
  manualTitle?: InputMaybe<ManualCreateNestedOneWithoutTitleTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageCreateNestedOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentCreateWithoutPageTitleInput = {
  chapterTitle?: InputMaybe<ManualChapterCreateNestedOneWithoutTitleTranslationsInput>;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  downloadDesc?: InputMaybe<DownloadCreateNestedOneWithoutDescTranslationsInput>;
  downloadName?: InputMaybe<DownloadCreateNestedOneWithoutNameTranslationsInput>;
  id?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  manualDesc?: InputMaybe<ManualCreateNestedOneWithoutDescTranslationsInput>;
  manualTitle?: InputMaybe<ManualCreateNestedOneWithoutTitleTranslationsInput>;
  pageContent?: InputMaybe<ManualPageCreateNestedOneWithoutContentTranslationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TranslationContentOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TranslationContentScalarWhereInput = {
  AND?: InputMaybe<Array<TranslationContentScalarWhereInput>>;
  NOT?: InputMaybe<Array<TranslationContentScalarWhereInput>>;
  OR?: InputMaybe<Array<TranslationContentScalarWhereInput>>;
  chapterTitleId?: InputMaybe<StringNullableFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  downloadDescId?: InputMaybe<StringNullableFilter>;
  downloadNameId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  language?: InputMaybe<StringFilter>;
  manualDescId?: InputMaybe<StringNullableFilter>;
  manualTitleId?: InputMaybe<StringNullableFilter>;
  pageTitleId?: InputMaybe<StringNullableFilter>;
  pagecontentId?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TranslationContentUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TranslationContentUpdateManyWithWhereWithoutChapterTitleInput = {
  data: TranslationContentUpdateManyMutationInput;
  where: TranslationContentScalarWhereInput;
};

export type TranslationContentUpdateManyWithWhereWithoutDownloadDescInput = {
  data: TranslationContentUpdateManyMutationInput;
  where: TranslationContentScalarWhereInput;
};

export type TranslationContentUpdateManyWithWhereWithoutDownloadNameInput = {
  data: TranslationContentUpdateManyMutationInput;
  where: TranslationContentScalarWhereInput;
};

export type TranslationContentUpdateManyWithWhereWithoutManualDescInput = {
  data: TranslationContentUpdateManyMutationInput;
  where: TranslationContentScalarWhereInput;
};

export type TranslationContentUpdateManyWithWhereWithoutManualTitleInput = {
  data: TranslationContentUpdateManyMutationInput;
  where: TranslationContentScalarWhereInput;
};

export type TranslationContentUpdateManyWithWhereWithoutPageContentInput = {
  data: TranslationContentUpdateManyMutationInput;
  where: TranslationContentScalarWhereInput;
};

export type TranslationContentUpdateManyWithWhereWithoutPageTitleInput = {
  data: TranslationContentUpdateManyMutationInput;
  where: TranslationContentScalarWhereInput;
};

export type TranslationContentUpdateManyWithoutChapterTitleInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutChapterTitleInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutChapterTitleInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyChapterTitleInputEnvelope>;
  delete?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslationContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TranslationContentUpdateWithWhereUniqueWithoutChapterTitleInput>
  >;
  updateMany?: InputMaybe<
    Array<TranslationContentUpdateManyWithWhereWithoutChapterTitleInput>
  >;
  upsert?: InputMaybe<
    Array<TranslationContentUpsertWithWhereUniqueWithoutChapterTitleInput>
  >;
};

export type TranslationContentUpdateManyWithoutDownloadDescInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutDownloadDescInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutDownloadDescInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyDownloadDescInputEnvelope>;
  delete?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslationContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TranslationContentUpdateWithWhereUniqueWithoutDownloadDescInput>
  >;
  updateMany?: InputMaybe<
    Array<TranslationContentUpdateManyWithWhereWithoutDownloadDescInput>
  >;
  upsert?: InputMaybe<
    Array<TranslationContentUpsertWithWhereUniqueWithoutDownloadDescInput>
  >;
};

export type TranslationContentUpdateManyWithoutDownloadNameInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutDownloadNameInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutDownloadNameInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyDownloadNameInputEnvelope>;
  delete?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslationContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TranslationContentUpdateWithWhereUniqueWithoutDownloadNameInput>
  >;
  updateMany?: InputMaybe<
    Array<TranslationContentUpdateManyWithWhereWithoutDownloadNameInput>
  >;
  upsert?: InputMaybe<
    Array<TranslationContentUpsertWithWhereUniqueWithoutDownloadNameInput>
  >;
};

export type TranslationContentUpdateManyWithoutManualDescInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutManualDescInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutManualDescInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyManualDescInputEnvelope>;
  delete?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslationContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TranslationContentUpdateWithWhereUniqueWithoutManualDescInput>
  >;
  updateMany?: InputMaybe<
    Array<TranslationContentUpdateManyWithWhereWithoutManualDescInput>
  >;
  upsert?: InputMaybe<
    Array<TranslationContentUpsertWithWhereUniqueWithoutManualDescInput>
  >;
};

export type TranslationContentUpdateManyWithoutManualTitleInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutManualTitleInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutManualTitleInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyManualTitleInputEnvelope>;
  delete?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslationContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TranslationContentUpdateWithWhereUniqueWithoutManualTitleInput>
  >;
  updateMany?: InputMaybe<
    Array<TranslationContentUpdateManyWithWhereWithoutManualTitleInput>
  >;
  upsert?: InputMaybe<
    Array<TranslationContentUpsertWithWhereUniqueWithoutManualTitleInput>
  >;
};

export type TranslationContentUpdateManyWithoutPageContentInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutPageContentInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutPageContentInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyPageContentInputEnvelope>;
  delete?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslationContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TranslationContentUpdateWithWhereUniqueWithoutPageContentInput>
  >;
  updateMany?: InputMaybe<
    Array<TranslationContentUpdateManyWithWhereWithoutPageContentInput>
  >;
  upsert?: InputMaybe<
    Array<TranslationContentUpsertWithWhereUniqueWithoutPageContentInput>
  >;
};

export type TranslationContentUpdateManyWithoutPageTitleInput = {
  connect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<TranslationContentCreateOrConnectWithoutPageTitleInput>
  >;
  create?: InputMaybe<Array<TranslationContentCreateWithoutPageTitleInput>>;
  createMany?: InputMaybe<TranslationContentCreateManyPageTitleInputEnvelope>;
  delete?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TranslationContentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  set?: InputMaybe<Array<TranslationContentWhereUniqueInput>>;
  update?: InputMaybe<
    Array<TranslationContentUpdateWithWhereUniqueWithoutPageTitleInput>
  >;
  updateMany?: InputMaybe<
    Array<TranslationContentUpdateManyWithWhereWithoutPageTitleInput>
  >;
  upsert?: InputMaybe<
    Array<TranslationContentUpsertWithWhereUniqueWithoutPageTitleInput>
  >;
};

export type TranslationContentUpdateWithWhereUniqueWithoutChapterTitleInput = {
  data: TranslationContentUpdateWithoutChapterTitleInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpdateWithWhereUniqueWithoutDownloadDescInput = {
  data: TranslationContentUpdateWithoutDownloadDescInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpdateWithWhereUniqueWithoutDownloadNameInput = {
  data: TranslationContentUpdateWithoutDownloadNameInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpdateWithWhereUniqueWithoutManualDescInput = {
  data: TranslationContentUpdateWithoutManualDescInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpdateWithWhereUniqueWithoutManualTitleInput = {
  data: TranslationContentUpdateWithoutManualTitleInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpdateWithWhereUniqueWithoutPageContentInput = {
  data: TranslationContentUpdateWithoutPageContentInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpdateWithWhereUniqueWithoutPageTitleInput = {
  data: TranslationContentUpdateWithoutPageTitleInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpdateWithoutChapterTitleInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  downloadDesc?: InputMaybe<DownloadUpdateOneWithoutDescTranslationsInput>;
  downloadName?: InputMaybe<DownloadUpdateOneWithoutNameTranslationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualDesc?: InputMaybe<ManualUpdateOneWithoutDescTranslationsInput>;
  manualTitle?: InputMaybe<ManualUpdateOneWithoutTitleTranslationsInput>;
  pageContent?: InputMaybe<ManualPageUpdateOneWithoutContentTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageUpdateOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TranslationContentUpdateWithoutDownloadDescInput = {
  chapterTitle?: InputMaybe<ManualChapterUpdateOneWithoutTitleTranslationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  downloadName?: InputMaybe<DownloadUpdateOneWithoutNameTranslationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualDesc?: InputMaybe<ManualUpdateOneWithoutDescTranslationsInput>;
  manualTitle?: InputMaybe<ManualUpdateOneWithoutTitleTranslationsInput>;
  pageContent?: InputMaybe<ManualPageUpdateOneWithoutContentTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageUpdateOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TranslationContentUpdateWithoutDownloadNameInput = {
  chapterTitle?: InputMaybe<ManualChapterUpdateOneWithoutTitleTranslationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  downloadDesc?: InputMaybe<DownloadUpdateOneWithoutDescTranslationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualDesc?: InputMaybe<ManualUpdateOneWithoutDescTranslationsInput>;
  manualTitle?: InputMaybe<ManualUpdateOneWithoutTitleTranslationsInput>;
  pageContent?: InputMaybe<ManualPageUpdateOneWithoutContentTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageUpdateOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TranslationContentUpdateWithoutManualDescInput = {
  chapterTitle?: InputMaybe<ManualChapterUpdateOneWithoutTitleTranslationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  downloadDesc?: InputMaybe<DownloadUpdateOneWithoutDescTranslationsInput>;
  downloadName?: InputMaybe<DownloadUpdateOneWithoutNameTranslationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualTitle?: InputMaybe<ManualUpdateOneWithoutTitleTranslationsInput>;
  pageContent?: InputMaybe<ManualPageUpdateOneWithoutContentTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageUpdateOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TranslationContentUpdateWithoutManualTitleInput = {
  chapterTitle?: InputMaybe<ManualChapterUpdateOneWithoutTitleTranslationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  downloadDesc?: InputMaybe<DownloadUpdateOneWithoutDescTranslationsInput>;
  downloadName?: InputMaybe<DownloadUpdateOneWithoutNameTranslationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualDesc?: InputMaybe<ManualUpdateOneWithoutDescTranslationsInput>;
  pageContent?: InputMaybe<ManualPageUpdateOneWithoutContentTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageUpdateOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TranslationContentUpdateWithoutPageContentInput = {
  chapterTitle?: InputMaybe<ManualChapterUpdateOneWithoutTitleTranslationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  downloadDesc?: InputMaybe<DownloadUpdateOneWithoutDescTranslationsInput>;
  downloadName?: InputMaybe<DownloadUpdateOneWithoutNameTranslationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualDesc?: InputMaybe<ManualUpdateOneWithoutDescTranslationsInput>;
  manualTitle?: InputMaybe<ManualUpdateOneWithoutTitleTranslationsInput>;
  pageTitle?: InputMaybe<ManualPageUpdateOneWithoutTitleTranslationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TranslationContentUpdateWithoutPageTitleInput = {
  chapterTitle?: InputMaybe<ManualChapterUpdateOneWithoutTitleTranslationsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  downloadDesc?: InputMaybe<DownloadUpdateOneWithoutDescTranslationsInput>;
  downloadName?: InputMaybe<DownloadUpdateOneWithoutNameTranslationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  language?: InputMaybe<StringFieldUpdateOperationsInput>;
  manualDesc?: InputMaybe<ManualUpdateOneWithoutDescTranslationsInput>;
  manualTitle?: InputMaybe<ManualUpdateOneWithoutTitleTranslationsInput>;
  pageContent?: InputMaybe<ManualPageUpdateOneWithoutContentTranslationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TranslationContentUpsertWithWhereUniqueWithoutChapterTitleInput = {
  create: TranslationContentCreateWithoutChapterTitleInput;
  update: TranslationContentUpdateWithoutChapterTitleInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpsertWithWhereUniqueWithoutDownloadDescInput = {
  create: TranslationContentCreateWithoutDownloadDescInput;
  update: TranslationContentUpdateWithoutDownloadDescInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpsertWithWhereUniqueWithoutDownloadNameInput = {
  create: TranslationContentCreateWithoutDownloadNameInput;
  update: TranslationContentUpdateWithoutDownloadNameInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpsertWithWhereUniqueWithoutManualDescInput = {
  create: TranslationContentCreateWithoutManualDescInput;
  update: TranslationContentUpdateWithoutManualDescInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpsertWithWhereUniqueWithoutManualTitleInput = {
  create: TranslationContentCreateWithoutManualTitleInput;
  update: TranslationContentUpdateWithoutManualTitleInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpsertWithWhereUniqueWithoutPageContentInput = {
  create: TranslationContentCreateWithoutPageContentInput;
  update: TranslationContentUpdateWithoutPageContentInput;
  where: TranslationContentWhereUniqueInput;
};

export type TranslationContentUpsertWithWhereUniqueWithoutPageTitleInput = {
  create: TranslationContentCreateWithoutPageTitleInput;
  update: TranslationContentUpdateWithoutPageTitleInput;
  where: TranslationContentWhereUniqueInput;
};

/** The language code for the manual page content. */
export type TranslationContentWhere = {
  language: Scalars['String'];
};

export type TranslationContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Translations = {
  __typename?: 'Translations';
  cz?: Maybe<Scalars['String']>;
  cz_description?: Maybe<Scalars['String']>;
  de?: Maybe<Scalars['String']>;
  de_description?: Maybe<Scalars['String']>;
  el?: Maybe<Scalars['String']>;
  el_description?: Maybe<Scalars['String']>;
  en_description?: Maybe<Scalars['String']>;
  fr?: Maybe<Scalars['String']>;
  fr_description?: Maybe<Scalars['String']>;
  hr?: Maybe<Scalars['String']>;
  hr_description?: Maybe<Scalars['String']>;
  sk?: Maybe<Scalars['String']>;
  sk_description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type UniqueId = {
  id: Scalars['String'];
};

export type UpdateOneManualPageWhereInput = {
  id: Scalars['String'];
};

export type UpdateServiceOnOrganisationInput = {
  purchasedServices?: InputMaybe<Array<InputMaybe<PurchasedService>>>;
};

export type UsageComparison = {
  __typename?: 'UsageComparison';
  allowance?: Maybe<Scalars['Int']>;
  status?: Maybe<UsageComparisonStatus>;
  text?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

/** The status of a usage comparison. */
export enum UsageComparisonStatus {
  Exceeded = 'EXCEEDED',
  Good = 'GOOD',
  Warning = 'WARNING',
}

export type User = {
  __typename?: 'User';
  auth0Id?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']>;
  dealer?: Maybe<Dealer>;
  dealerId?: Maybe<Scalars['String']>;
  distributor?: Maybe<Distributor>;
  distributorId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  recycled: Scalars['Boolean'];
  termsAgreement?: Maybe<TermsAgreement>;
  updatedAt: Scalars['DateTime'];
};

export type UserCreateManyCustomerInput = {
  auth0Id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyCustomerInputEnvelope = {
  data?: InputMaybe<Array<UserCreateManyCustomerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateManyDealerInput = {
  auth0Id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyDealerInputEnvelope = {
  data?: InputMaybe<Array<UserCreateManyDealerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateManyDistributorInput = {
  auth0Id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyDistributorInputEnvelope = {
  data?: InputMaybe<Array<UserCreateManyDistributorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<UserCreateManyCustomerInputEnvelope>;
};

export type UserCreateNestedManyWithoutDealerInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<UserCreateWithoutDealerInput>>;
  createMany?: InputMaybe<UserCreateManyDealerInputEnvelope>;
};

export type UserCreateNestedManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<UserCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<UserCreateManyDistributorInputEnvelope>;
};

export type UserCreateNestedOneWithoutDeviceTemplatesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDeviceTemplatesInput>;
  create?: InputMaybe<UserCreateWithoutDeviceTemplatesInput>;
};

export type UserCreateNestedOneWithoutDispatchTemplatesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDispatchTemplatesInput>;
  create?: InputMaybe<UserCreateWithoutDispatchTemplatesInput>;
};

export type UserCreateNestedOneWithoutTermsAgreementInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTermsAgreementInput>;
  create?: InputMaybe<UserCreateWithoutTermsAgreementInput>;
};

export type UserCreateOrConnectWithoutCustomerInput = {
  create: UserCreateWithoutCustomerInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDealerInput = {
  create: UserCreateWithoutDealerInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDeviceTemplatesInput = {
  create: UserCreateWithoutDeviceTemplatesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDispatchTemplatesInput = {
  create: UserCreateWithoutDispatchTemplatesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutDistributorInput = {
  create: UserCreateWithoutDistributorInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTermsAgreementInput = {
  create: UserCreateWithoutTermsAgreementInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutCustomerInput = {
  auth0Id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateCreateNestedManyWithoutCreatedByInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateCreateNestedManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutUsersInput>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutDealerInput = {
  auth0Id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateCreateNestedManyWithoutCreatedByInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateCreateNestedManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutUsersInput>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutDeviceTemplatesInput = {
  auth0Id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutUsersInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutUsersInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateCreateNestedManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutUsersInput>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutDispatchTemplatesInput = {
  auth0Id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutUsersInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateCreateNestedManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutUsersInput>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutDistributorInput = {
  auth0Id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutUsersInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateCreateNestedManyWithoutCreatedByInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateCreateNestedManyWithoutCreatedByInput>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  termsAgreement?: InputMaybe<TermsAgreementCreateNestedOneWithoutUserInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutTermsAgreementInput = {
  auth0Id?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customer?: InputMaybe<CustomerCreateNestedOneWithoutUsersInput>;
  dealer?: InputMaybe<DealerCreateNestedOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateCreateNestedManyWithoutCreatedByInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateCreateNestedManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorCreateNestedOneWithoutUsersInput>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  recycled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserPasswordInput = {
  password?: InputMaybe<Scalars['String']>;
};

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  auth0Id?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealerId?: InputMaybe<StringNullableFilter>;
  distributorId?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserUpdateInput = {
  auth0Id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutUsersInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateUpdateManyWithoutCreatedByInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateUpdateManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutUsersInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutUserInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  auth0Id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutCustomerInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutDealerInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutDistributorInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutCustomerInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCustomerInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCustomerInput>>;
  createMany?: InputMaybe<UserCreateManyCustomerInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutCustomerInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutCustomerInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutCustomerInput>>;
};

export type UserUpdateManyWithoutDealerInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutDealerInput>>;
  create?: InputMaybe<Array<UserCreateWithoutDealerInput>>;
  createMany?: InputMaybe<UserCreateManyDealerInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutDealerInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutDealerInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutDealerInput>>;
};

export type UserUpdateManyWithoutDistributorInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<
    Array<UserCreateOrConnectWithoutDistributorInput>
  >;
  create?: InputMaybe<Array<UserCreateWithoutDistributorInput>>;
  createMany?: InputMaybe<UserCreateManyDistributorInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutDistributorInput>>;
  updateMany?: InputMaybe<
    Array<UserUpdateManyWithWhereWithoutDistributorInput>
  >;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutDistributorInput>>;
};

export type UserUpdateOneWithoutDeviceTemplatesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDeviceTemplatesInput>;
  create?: InputMaybe<UserCreateWithoutDeviceTemplatesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutDeviceTemplatesInput>;
  upsert?: InputMaybe<UserUpsertWithoutDeviceTemplatesInput>;
};

export type UserUpdateOneWithoutDispatchTemplatesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutDispatchTemplatesInput>;
  create?: InputMaybe<UserCreateWithoutDispatchTemplatesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutDispatchTemplatesInput>;
  upsert?: InputMaybe<UserUpsertWithoutDispatchTemplatesInput>;
};

export type UserUpdateOneWithoutTermsAgreementInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutTermsAgreementInput>;
  create?: InputMaybe<UserCreateWithoutTermsAgreementInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutTermsAgreementInput>;
  upsert?: InputMaybe<UserUpsertWithoutTermsAgreementInput>;
};

export type UserUpdateWithWhereUniqueWithoutCustomerInput = {
  data: UserUpdateWithoutCustomerInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutDealerInput = {
  data: UserUpdateWithoutDealerInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutDistributorInput = {
  data: UserUpdateWithoutDistributorInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutCustomerInput = {
  auth0Id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateUpdateManyWithoutCreatedByInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateUpdateManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutUsersInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutUserInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDealerInput = {
  auth0Id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateUpdateManyWithoutCreatedByInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateUpdateManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutUsersInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutUserInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDeviceTemplatesInput = {
  auth0Id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutUsersInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutUsersInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateUpdateManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutUsersInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutUserInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDispatchTemplatesInput = {
  auth0Id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutUsersInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateUpdateManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutUsersInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutUserInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutDistributorInput = {
  auth0Id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutUsersInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateUpdateManyWithoutCreatedByInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateUpdateManyWithoutCreatedByInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  termsAgreement?: InputMaybe<TermsAgreementUpdateOneWithoutUserInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTermsAgreementInput = {
  auth0Id?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customer?: InputMaybe<CustomerUpdateOneWithoutUsersInput>;
  dealer?: InputMaybe<DealerUpdateOneWithoutUsersInput>;
  deviceTemplates?: InputMaybe<DeviceTemplateUpdateManyWithoutCreatedByInput>;
  dispatchTemplates?: InputMaybe<DispatchTemplateUpdateManyWithoutCreatedByInput>;
  distributor?: InputMaybe<DistributorUpdateOneWithoutUsersInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recycled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpsertWithWhereUniqueWithoutCustomerInput = {
  create: UserCreateWithoutCustomerInput;
  update: UserUpdateWithoutCustomerInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutDealerInput = {
  create: UserCreateWithoutDealerInput;
  update: UserUpdateWithoutDealerInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutDistributorInput = {
  create: UserCreateWithoutDistributorInput;
  update: UserUpdateWithoutDistributorInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutDeviceTemplatesInput = {
  create: UserCreateWithoutDeviceTemplatesInput;
  update: UserUpdateWithoutDeviceTemplatesInput;
};

export type UserUpsertWithoutDispatchTemplatesInput = {
  create: UserCreateWithoutDispatchTemplatesInput;
  update: UserUpdateWithoutDispatchTemplatesInput;
};

export type UserUpsertWithoutTermsAgreementInput = {
  create: UserCreateWithoutTermsAgreementInput;
  update: UserUpdateWithoutTermsAgreementInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  auth0Id?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customer?: InputMaybe<CustomerWhereInput>;
  customerId?: InputMaybe<StringNullableFilter>;
  dealer?: InputMaybe<DealerWhereInput>;
  dealerId?: InputMaybe<StringNullableFilter>;
  deviceTemplates?: InputMaybe<DeviceTemplateListRelationFilter>;
  dispatchTemplates?: InputMaybe<DispatchTemplateListRelationFilter>;
  distributor?: InputMaybe<DistributorWhereInput>;
  distributorId?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  recycled?: InputMaybe<BoolFilter>;
  termsAgreement?: InputMaybe<TermsAgreementWhereInput>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  auth0Id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type VatId = {
  __typename?: 'VatId';
  country: Scalars['String'];
  created: Scalars['Int'];
  customer: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
  value: Scalars['String'];
  verification: VatVerification;
};

export type VatVerification = {
  __typename?: 'VatVerification';
  status: Scalars['String'];
  verified_address: Scalars['String'];
  verified_name: Scalars['String'];
};

export enum VoiceQualityEnum {
  High = 'HIGH',
  Normal = 'NORMAL',
}

/** Provide either a customerId or a dealerId */
export type WhereUniqueCustomerOrDealerInput = {
  customerId?: InputMaybe<Scalars['String']>;
  dealerId?: InputMaybe<Scalars['String']>;
  distributorId?: InputMaybe<Scalars['String']>;
};

export type Id = {
  __typename?: 'id';
  id: Scalars['String'];
};

export type CreateUserMutationVariables = Exact<{
  data?: InputMaybe<CreateUserInput>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser: {
    __typename?: 'OrgUsers';
    id: string;
    name?: string | null;
    email?: string | null;
  };
};

export type Auth0UsersQueryVariables = Exact<{
  data: UniqueId;
}>;

export type Auth0UsersQuery = {
  __typename?: 'Query';
  auth0Users?: Array<{
    __typename?: 'OrgUsers';
    id: string;
    name?: string | null;
    email?: string | null;
  }> | null;
};

export type UpdateOneDeviceUserMutationVariables = Exact<{
  data: DeviceUserUpdateInput;
  where: DeviceUserWhereUniqueInput;
}>;

export type UpdateOneDeviceUserMutation = {
  __typename?: 'Mutation';
  updateOneDeviceUser?: {
    __typename?: 'DeviceUser';
    demo: boolean;
    id: string;
  } | null;
};

export type ListCustomersQueryVariables = Exact<{
  where?: InputMaybe<CustomerWhereInput>;
}>;

export type ListCustomersQuery = {
  __typename?: 'Query';
  customers: Array<{
    __typename?: 'Customer';
    id: string;
    name: string;
    status?: AccountStatus | null;
  }>;
};

export type ViewCustomerDeviceUsersQueryVariables = Exact<{
  where: CustomerWhereUniqueInput;
}>;

export type ViewCustomerDeviceUsersQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    id: string;
    deviceUsers: Array<{
      __typename?: 'DeviceUser';
      id: string;
      name: string;
      userId?: string | null;
      license?: string | null;
      expireDate: any;
      devices: Array<{ __typename?: 'Device'; id: string; name: string }>;
    }>;
  } | null;
};

export type ViewCustomerInvoicesQueryVariables = Exact<{
  where: CustomerWhereUniqueInput;
}>;

export type ViewCustomerInvoicesQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    id: string;
    name: string;
    stripeId?: string | null;
    invoices: Array<{
      __typename?: 'Invoice';
      created: string;
      total: number;
      status: string;
      invoiceNumber: string;
      pdf: string;
      items: Array<{
        __typename?: 'InvoiceItem';
        tax: number;
        description: string;
        total: number;
        quantity: number;
      }>;
    }>;
    nextInvoice?: {
      __typename?: 'Invoice';
      total: number;
      dueDate: string;
    } | null;
    billingAddress?: {
      __typename?: 'StripeAddress';
      city: string;
      county?: string | null;
      country: string;
      postCode: string;
      line1: string;
      line2?: string | null;
    } | null;
    shipping?: {
      __typename?: 'StripeShipping';
      city?: string | null;
      county?: string | null;
      country?: string | null;
      postCode?: string | null;
      line1?: string | null;
      line2?: string | null;
    } | null;
  } | null;
};

export type ViewCustomerOverviewQueryVariables = Exact<{
  where: CustomerWhereUniqueInput;
}>;

export type ViewCustomerOverviewQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    id: string;
    name: string;
    totalDeviceUsers?: number | null;
    sopLogin?: {
      __typename?: 'SopLogin';
      username: string;
      decryptedPassword?: string | null;
    } | null;
    invoices: Array<{ __typename?: 'Invoice'; created: string; total: number }>;
    nextInvoice?: { __typename?: 'Invoice'; total: number } | null;
    distributor?: {
      __typename?: 'Distributor';
      id: string;
      name: string;
    } | null;
    billingAddress?: {
      __typename?: 'StripeAddress';
      postCode: string;
      line2?: string | null;
      line1: string;
      county?: string | null;
      country: string;
      city: string;
    } | null;
    shipping?: {
      __typename?: 'StripeShipping';
      city?: string | null;
      country?: string | null;
      line1?: string | null;
      county?: string | null;
      line2?: string | null;
      postCode?: string | null;
      name?: string | null;
      phone?: string | null;
    } | null;
    contact?: {
      __typename?: 'Contact';
      id: string;
      email: string;
      name: string;
      phone: string;
    } | null;
  } | null;
};

export type ViewCustomerPaymentsQueryVariables = Exact<{
  where: CustomerWhereUniqueInput;
}>;

export type ViewCustomerPaymentsQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    id: string;
    name: string;
    billingAddress?: {
      __typename?: 'StripeAddress';
      city: string;
      county?: string | null;
      country: string;
      postCode: string;
      line1: string;
      line2?: string | null;
    } | null;
    payments: Array<{
      __typename?: 'Payment';
      date: string;
      invoiceNumber: string;
      paid: boolean;
      status: string;
      total: number;
      totalCollected: number;
      type: string;
    }>;
  } | null;
};

export type ViewCustomerSimsQueryVariables = Exact<{
  where: CustomerWhereUniqueInput;
}>;

export type ViewCustomerSimsQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    id: string;
    simCards: Array<{
      __typename?: 'SimCard';
      id: string;
      iccid: string;
      tariff?: string | null;
      groupCode?: string | null;
      groupChanged: any;
      status: SimStatusEnum;
      usage?: {
        __typename?: 'UsageComparison';
        status?: UsageComparisonStatus | null;
        text?: string | null;
      } | null;
    }>;
  } | null;
};

export type DealerVatOnboardingMutationVariables = Exact<{
  where: DealerWhereUniqueInput;
  data: DealerVatOnboardingInput;
}>;

export type DealerVatOnboardingMutation = {
  __typename?: 'Mutation';
  dealerVATOnboarding?: {
    __typename?: 'Dealer';
    id: string;
    name: string;
    currency?: string | null;
    onboarding: OnboardingStepEnum;
    billingAddress?: {
      __typename?: 'StripeAddress';
      line1: string;
      line2?: string | null;
      city: string;
      county?: string | null;
      country: string;
      postCode: string;
    } | null;
    contact?: {
      __typename?: 'Contact';
      email: string;
      name: string;
      phone: string;
      id: string;
    } | null;
    vatId?: { __typename?: 'VatId'; id: string; value: string } | null;
  } | null;
};

export type EditSimMutationVariables = Exact<{
  data: EditSimInput;
}>;

export type EditSimMutation = {
  __typename?: 'Mutation';
  editSim?: {
    __typename?: 'SimCard';
    id: string;
    iccid: string;
    status: SimStatusEnum;
    groupChanged: any;
    groupCode?: string | null;
    tariff?: string | null;
    limit: number;
    warning: number;
    canChangeGroup?: boolean | null;
    usage?: {
      __typename?: 'UsageComparison';
      status?: UsageComparisonStatus | null;
      allowance?: number | null;
      value?: number | null;
      text?: string | null;
    } | null;
    customer?: { __typename?: 'Customer'; id: string; name: string } | null;
  } | null;
};

export type UpdateStripeCustomerMutationVariables = Exact<{
  data: CustomerObjectUpdateInput;
  where: UniqueId;
}>;

export type UpdateStripeCustomerMutation = {
  __typename?: 'Mutation';
  updateStripeCustomer?: string | null;
};

export type UpdateDealerDistributorsMutationVariables = Exact<{
  where: Array<Scalars['String']> | Scalars['String'];
  data: Scalars['String'];
}>;

export type UpdateDealerDistributorsMutation = {
  __typename?: 'Mutation';
  updateDealerDistributors?: Array<{
    __typename?: 'Dealer';
    id: string;
  } | null> | null;
};

export type UpdateSopLoginMutationVariables = Exact<{
  data: DealerUpdateInput;
  where: DealerWhereUniqueInput;
}>;

export type UpdateSopLoginMutation = {
  __typename?: 'Mutation';
  updateOneDealer?: { __typename?: 'Dealer'; id: string } | null;
};

export type DealerCurrencyQueryVariables = Exact<{
  where: DealerWhereUniqueInput;
}>;

export type DealerCurrencyQuery = {
  __typename?: 'Query';
  dealer?: {
    __typename?: 'Dealer';
    id: string;
    currency?: string | null;
  } | null;
};

export type DealerSopLoginQueryVariables = Exact<{
  where: DealerWhereUniqueInput;
}>;

export type DealerSopLoginQuery = {
  __typename?: 'Query';
  dealer?: {
    __typename?: 'Dealer';
    id: string;
    sopLogin?: {
      __typename?: 'SopLogin';
      id: string;
      username: string;
      decryptedPassword?: string | null;
    } | null;
  } | null;
};

export type DealerQueryVariables = Exact<{
  where: DealerWhereUniqueInput;
}>;

export type DealerQuery = {
  __typename?: 'Query';
  dealer?: {
    __typename?: 'Dealer';
    id: string;
    customers: Array<{ __typename?: 'Customer'; id: string; name: string }>;
    simCards: Array<{
      __typename?: 'SimCard';
      id: string;
      iccid: string;
      status: SimStatusEnum;
      usage?: {
        __typename?: 'UsageComparison';
        status?: UsageComparisonStatus | null;
      } | null;
      customer?: { __typename?: 'Customer'; name: string } | null;
    }>;
  } | null;
};

export type ViewDealerCustomersQueryVariables = Exact<{
  where: DealerWhereUniqueInput;
}>;

export type ViewDealerCustomersQuery = {
  __typename?: 'Query';
  dealer?: {
    __typename?: 'Dealer';
    id: string;
    totalDemoDeviceUsers?: number | null;
    customers: Array<{
      __typename?: 'Customer';
      id: string;
      name: string;
      deviceUsers: Array<{ __typename?: 'DeviceUser'; id: string }>;
    }>;
  } | null;
};

export type ViewDemoDeviceUsersDealerQueryVariables = Exact<{
  where: DealerWhereUniqueInput;
}>;

export type ViewDemoDeviceUsersDealerQuery = {
  __typename?: 'Query';
  dealer?: {
    __typename?: 'Dealer';
    id: string;
    totalDemoDeviceUsers?: number | null;
    totalDemoDispatchUsers?: number | null;
  } | null;
};

export type AddBundleMutationVariables = Exact<{
  data: Array<InputMaybe<AddBundleInput>> | InputMaybe<AddBundleInput>;
}>;

export type AddBundleMutation = {
  __typename?: 'Mutation';
  addBundle?: { __typename?: 'BundleOnUser'; id: string } | null;
};

export type CreateCompanyMutationVariables = Exact<{
  data: CreateDemCompanyInput;
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompanyMutation?: {
    __typename?: 'DemCompany';
    id: string;
    name?: string | null;
    tier?: string | null;
    demGroups?: Array<{
      __typename?: 'DemGroup';
      id: string;
      name?: string | null;
      groupId?: string | null;
    } | null> | null;
  } | null;
};

export type CreateDeviceMutationVariables = Exact<{
  data: CreateDemDeviceInput;
}>;

export type CreateDeviceMutation = {
  __typename?: 'Mutation';
  registerDevice?: {
    __typename?: 'DemDevices';
    serialNumber?: string | null;
  } | null;
};

export type CreateGroupMutationVariables = Exact<{
  data: CreateDemGroupInput;
}>;

export type CreateGroupMutation = {
  __typename?: 'Mutation';
  createDemGroup?: {
    __typename?: 'DemGroup';
    id: string;
    name?: string | null;
  } | null;
};

export type CreateDemUserMutationVariables = Exact<{
  data: CreateDemUserInput;
}>;

export type CreateDemUserMutation = {
  __typename?: 'Mutation';
  createDemUser?: {
    __typename?: 'DemUsers';
    name?: string | null;
    id: string;
  } | null;
};

export type EditBundlesMutationVariables = Exact<{
  data: Array<InputMaybe<AddBundleInput>> | InputMaybe<AddBundleInput>;
}>;

export type EditBundlesMutation = {
  __typename?: 'Mutation';
  editBundles?: { __typename?: 'BundleOnUser'; id: string } | null;
};

export type DemoStatusMutationMutationVariables = Exact<{
  data: DemoDeviceInput;
}>;

export type DemoStatusMutationMutation = {
  __typename?: 'Mutation';
  demoStatusMutation?: {
    __typename?: 'DemDevices';
    id: string;
    demo?: boolean | null;
    serialNumber?: string | null;
    model?: {
      __typename?: 'DemModel';
      name?: string | null;
      id: string;
    } | null;
    demGroups?: Array<{
      __typename?: 'DemGroup';
      id: string;
      name?: string | null;
      groupId?: string | null;
    } | null> | null;
  } | null;
};

export type UpdateDemDeviceMutationVariables = Exact<{
  data: EditDemDeviceInput;
}>;

export type UpdateDemDeviceMutation = {
  __typename?: 'Mutation';
  updatedDemDevice?: {
    __typename?: 'DemDevices';
    id: string;
    serialNumber?: string | null;
    model?: {
      __typename?: 'DemModel';
      name?: string | null;
      id: string;
    } | null;
    demGroups?: Array<{
      __typename?: 'DemGroup';
      groupId?: string | null;
      name?: string | null;
      id: string;
    } | null> | null;
  } | null;
};

export type UpdateUserGroupsMutationVariables = Exact<{
  data: DemUserInput;
  where: UserWhereUniqueInput;
}>;

export type UpdateUserGroupsMutation = {
  __typename?: 'Mutation';
  updateOneDemUser?: {
    __typename?: 'DemUsers';
    id: string;
    name?: string | null;
  } | null;
};

export type AvailableUsersQueryVariables = Exact<{
  where: DemUserWhereInput;
}>;

export type AvailableUsersQuery = {
  __typename?: 'Query';
  availableUsers?: Array<{
    __typename?: 'DemUsers';
    id: string;
    name?: string | null;
    role?: string | null;
    email?: string | null;
  } | null> | null;
};

export type AvailableBundlesQueryVariables = Exact<{
  where?: InputMaybe<UniqueId>;
}>;

export type AvailableBundlesQuery = {
  __typename?: 'Query';
  availableBundles?: Array<{
    __typename?: 'DemBundle';
    id: string;
    name: string;
    price: number;
    data: number;
  } | null> | null;
};

export type ActiveBundlesQueryVariables = Exact<{
  where?: InputMaybe<UniqueId>;
}>;

export type ActiveBundlesQuery = {
  __typename?: 'Query';
  activeBundles?: Array<{
    __typename?: 'BundleOnUser';
    id: string;
    quantity: number;
    assignedTo: Array<{
      __typename?: 'CustomerAssigned';
      quantity: number;
      customerId: string;
    }>;
    bundle?: {
      __typename?: 'DemBundle';
      data: number;
      id: string;
      name: string;
      price: number;
    } | null;
  } | null> | null;
};

export type GetDemCompaniesQueryVariables = Exact<{
  where?: InputMaybe<UniqueId>;
}>;

export type GetDemCompaniesQuery = {
  __typename?: 'Query';
  viewDemCompanies?: {
    __typename?: 'DemCompanies';
    id: string;
    demoGbAvailable: number;
    totalGbUsed: number;
    numberOfDemo: number;
    company?: Array<{
      __typename?: 'DemCompany';
      id: string;
      name?: string | null;
      tier?: string | null;
      demGroups?: Array<{
        __typename?: 'DemGroup';
        id: string;
        name?: string | null;
        groupId?: string | null;
      } | null> | null;
    } | null> | null;
    devices?: Array<{
      __typename?: 'DemDevices';
      id: string;
      dataUsed?: number | null;
      demo?: boolean | null;
      serialNumber?: string | null;
      model?: {
        __typename?: 'DemModel';
        id: string;
        name?: string | null;
      } | null;
      company?: {
        __typename?: 'DemCompany';
        name?: string | null;
        id: string;
        demGroups?: Array<{
          __typename?: 'DemGroup';
          id: string;
          name?: string | null;
          groupId?: string | null;
        } | null> | null;
      } | null;
      demGroups?: Array<{
        __typename?: 'DemGroup';
        id: string;
        name?: string | null;
        groupId?: string | null;
      } | null> | null;
    } | null> | null;
    demUsers?: Array<{
      __typename?: 'DemUsers';
      role?: string | null;
      id: string;
      name?: string | null;
      email?: string | null;
      companyRoles?: Array<{
        __typename?: 'CompanyRoles';
        id: string;
        roleName: string;
        company?: { __typename?: 'DemCompany'; id: string } | null;
      } | null> | null;
      companies?: Array<{
        __typename?: 'DemCompany';
        name?: string | null;
        id: string;
      } | null> | null;
      demGroupOnUser?: Array<{
        __typename?: 'DemGroup';
        id: string;
        name?: string | null;
        groupId?: string | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type ViewDemCompanyQueryVariables = Exact<{
  where?: InputMaybe<UniqueId>;
}>;

export type ViewDemCompanyQuery = {
  __typename?: 'Query';
  viewDemCompany?: {
    __typename?: 'DemCompanyDetails';
    id: string;
    name?: string | null;
    demGroups?: Array<{
      __typename?: 'DemGroup';
      groupId?: string | null;
      name?: string | null;
      id: string;
    } | null> | null;
    device?: Array<{
      __typename?: 'DemDevices';
      dataUsed?: number | null;
      serialNumber?: string | null;
      demo?: boolean | null;
      id: string;
      demGroups?: Array<{
        __typename?: 'DemGroup';
        groupId?: string | null;
        name?: string | null;
        id: string;
      } | null> | null;
      model?: {
        __typename?: 'DemModel';
        id: string;
        name?: string | null;
      } | null;
    } | null> | null;
    users?: Array<{
      __typename?: 'DemUsers';
      id: string;
      role?: string | null;
      email?: string | null;
      name?: string | null;
      companyRoles?: Array<{
        __typename?: 'CompanyRoles';
        id: string;
        roleName: string;
        company?: { __typename?: 'DemCompany'; id: string } | null;
      } | null> | null;
      demGroupOnUser?: Array<{
        __typename?: 'DemGroup';
        name?: string | null;
        groupId?: string | null;
        id: string;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type DemDeviceModelsQueryVariables = Exact<{ [key: string]: never }>;

export type DemDeviceModelsQuery = {
  __typename?: 'Query';
  demDeviceModels?: Array<{
    __typename?: 'DemModel';
    id: string;
    name?: string | null;
  } | null> | null;
};

export type GroupsListQueryVariables = Exact<{
  where: DemGroupsWhereInput;
}>;

export type GroupsListQuery = {
  __typename?: 'Query';
  groupsList?: Array<{
    __typename?: 'DemGroup';
    id: string;
    name?: string | null;
  } | null> | null;
};

export type ViewDemDeviceQueryVariables = Exact<{
  where?: InputMaybe<UniqueId>;
}>;

export type ViewDemDeviceQuery = {
  __typename?: 'Query';
  viewDemDevice?: {
    __typename?: 'DemDevices';
    id: string;
    serialNumber?: string | null;
    model?: {
      __typename?: 'DemModel';
      name?: string | null;
      id: string;
    } | null;
    demGroups?: Array<{
      __typename?: 'DemGroup';
      groupId?: string | null;
      name?: string | null;
      id: string;
    } | null> | null;
  } | null;
};

export type DeviceModelsQueryVariables = Exact<{ [key: string]: never }>;

export type DeviceModelsQuery = {
  __typename?: 'Query';
  deviceModels: Array<{ __typename?: 'DeviceModel'; id: string; name: string }>;
};

export type ViewDistributorBillingDetailsQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type ViewDistributorBillingDetailsQuery = {
  __typename?: 'Query';
  distributor?: {
    __typename?: 'Distributor';
    id: string;
    name: string;
    stripeId?: string | null;
    contact?: {
      __typename?: 'Contact';
      name: string;
      phone: string;
      email: string;
      id: string;
    } | null;
    invoices?: Array<{
      __typename?: 'Invoice';
      created: string;
      total: number;
      status: string;
      invoiceNumber: string;
    } | null> | null;
    nextInvoice?: {
      __typename?: 'Invoice';
      total: number;
      dueDate: string;
    } | null;
    billingAddress?: {
      __typename?: 'StripeAddress';
      postCode: string;
      line2?: string | null;
      line1: string;
      county?: string | null;
      country: string;
      city: string;
    } | null;
    shipping?: {
      __typename?: 'StripeShipping';
      city?: string | null;
      country?: string | null;
      line1?: string | null;
      county?: string | null;
      line2?: string | null;
      postCode?: string | null;
      name?: string | null;
      phone?: string | null;
    } | null;
  } | null;
};

export type ViewDistributorInvoicesQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type ViewDistributorInvoicesQuery = {
  __typename?: 'Query';
  distributor?: {
    __typename?: 'Distributor';
    id: string;
    name: string;
    stripeId?: string | null;
    invoices?: Array<{
      __typename?: 'Invoice';
      created: string;
      total: number;
      status: string;
      invoiceNumber: string;
      pdf: string;
      dueDate: string;
      items: Array<{
        __typename?: 'InvoiceItem';
        tax: number;
        description: string;
        total: number;
        quantity: number;
      }>;
    } | null> | null;
    nextInvoice?: {
      __typename?: 'Invoice';
      total: number;
      dueDate: string;
    } | null;
    billingAddress?: {
      __typename?: 'StripeAddress';
      city: string;
      county?: string | null;
      country: string;
      postCode: string;
      line1: string;
      line2?: string | null;
    } | null;
    shipping?: {
      __typename?: 'StripeShipping';
      city?: string | null;
      county?: string | null;
      country?: string | null;
      postCode?: string | null;
      line1?: string | null;
      line2?: string | null;
    } | null;
  } | null;
};

export type ViewDistributorPaymentsQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type ViewDistributorPaymentsQuery = {
  __typename?: 'Query';
  distributor?: {
    __typename?: 'Distributor';
    id: string;
    name: string;
    billingAddress?: {
      __typename?: 'StripeAddress';
      city: string;
      county?: string | null;
      country: string;
      postCode: string;
      line1: string;
      line2?: string | null;
    } | null;
    payments?: Array<{
      __typename?: 'Payment';
      date: string;
      invoiceNumber: string;
      paid: boolean;
      status: string;
      total: number;
      totalCollected: number;
      type: string;
    } | null> | null;
  } | null;
};

export type DistributorUsersQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type DistributorUsersQuery = {
  __typename?: 'Query';
  distributor?: {
    __typename?: 'Distributor';
    dealers: Array<{
      __typename?: 'Dealer';
      id: string;
      name: string;
      customers: Array<{
        __typename?: 'Customer';
        id: string;
        name: string;
        deviceUsers: Array<{ __typename?: 'DeviceUser'; id: string }>;
      }>;
    }>;
  } | null;
};

export type DistributorsQueryVariables = Exact<{ [key: string]: never }>;

export type DistributorsQuery = {
  __typename?: 'Query';
  distributors: Array<{ __typename?: 'Distributor'; id: string; name: string }>;
};

export type ViewDistributorCustomersQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type ViewDistributorCustomersQuery = {
  __typename?: 'Query';
  distributor?: {
    __typename?: 'Distributor';
    id: string;
    customers: Array<{
      __typename?: 'Customer';
      id: string;
      name: string;
      totalDeviceUsers?: number | null;
    }>;
  } | null;
};

export type ViewDistributorDealersQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type ViewDistributorDealersQuery = {
  __typename?: 'Query';
  distributor?: {
    __typename?: 'Distributor';
    id: string;
    dealers: Array<{
      __typename?: 'Dealer';
      id: string;
      name: string;
      totalDeviceUsers?: number | null;
      totalCustomers?: number | null;
    }>;
  } | null;
};

export type ViewDemoDeviceUsersDistQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type ViewDemoDeviceUsersDistQuery = {
  __typename?: 'Query';
  distributor?: {
    __typename?: 'Distributor';
    id: string;
    totalDemoDeviceUsers?: number | null;
    totalDemoDispatchUsers?: number | null;
  } | null;
};

export type ViewDistributorDetailsQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type ViewDistributorDetailsQuery = {
  __typename?: 'Query';
  distributor?: {
    __typename?: 'Distributor';
    id: string;
    name: string;
    contact?: {
      __typename?: 'Contact';
      name: string;
      phone: string;
      email: string;
      id: string;
    } | null;
    billingAddress?: {
      __typename?: 'StripeAddress';
      postCode: string;
      line2?: string | null;
      line1: string;
      county?: string | null;
      country: string;
      city: string;
    } | null;
    shipping?: {
      __typename?: 'StripeShipping';
      city?: string | null;
      county?: string | null;
      country?: string | null;
      postCode?: string | null;
      line1?: string | null;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
    } | null;
    addresses: Array<{
      __typename?: 'Address';
      city: string;
      county: string;
      country: string;
      postcode: string;
      street1: string;
      street2?: string | null;
      type: AddressTypeEnum;
      id: string;
    }>;
  } | null;
};

export type ViewDistributorOverviewQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type ViewDistributorOverviewQuery = {
  __typename?: 'Query';
  distributor?: { __typename?: 'Distributor'; id: string; name: string } | null;
};

export type ViewDistributorPaymentMethodsQueryVariables = Exact<{
  where: PaymentMethodWhereCustomerIdAndPaymentType;
}>;

export type ViewDistributorPaymentMethodsQuery = {
  __typename?: 'Query';
  stripePaymentMethods?: Array<{
    __typename?: 'PaymentMethod';
    id: string;
    type?: string | null;
    default?: boolean | null;
    details?: {
      __typename?: 'PaymentMethodDetails';
      last4?: string | null;
      expMonth?: number | null;
      brand?: string | null;
      expYear?: number | null;
      sortCode?: string | null;
    } | null;
  } | null> | null;
};

export type CreateDownloadMutationVariables = Exact<{
  data: DownloadCreateInput;
  downloadNameWhere: TranslationContentWhere;
  downloadDescriptionWhere: TranslationContentWhere;
}>;

export type CreateDownloadMutation = {
  __typename?: 'Mutation';
  createOneDownload: {
    __typename?: 'Download';
    id: string;
    name?: string | null;
    description?: string | null;
    url: string;
    type: DownloadType;
    modelList?: string | null;
    models: Array<{ __typename?: 'DeviceModel'; id: string; name: string }>;
  };
};

export type UpdateDownloadMutationVariables = Exact<{
  data: DownloadUpdateInput;
  where: DownloadWhereUniqueInput;
  downloadNameWhere: TranslationContentWhere;
  downloadDescWhere: TranslationContentWhere;
}>;

export type UpdateDownloadMutation = {
  __typename?: 'Mutation';
  updateOneDownload?: {
    __typename?: 'Download';
    id: string;
    modelList?: string | null;
    name?: string | null;
    description?: string | null;
    type: DownloadType;
    url: string;
    models: Array<{ __typename?: 'DeviceModel'; id: string; name: string }>;
  } | null;
};

export type DownloadQueryVariables = Exact<{
  where: DownloadWhereUniqueInput;
  downloadNameWhere: TranslationContentWhere;
  downloadDescriptionWhere: TranslationContentWhere;
}>;

export type DownloadQuery = {
  __typename?: 'Query';
  download?: {
    __typename?: 'Download';
    name?: string | null;
    modelList?: string | null;
    id: string;
    type: DownloadType;
    description?: string | null;
    url: string;
    models: Array<{ __typename?: 'DeviceModel'; id: string; name: string }>;
  } | null;
};

export type DownloadsQueryVariables = Exact<{
  downloadNameWhere: TranslationContentWhere;
  downloadDescriptionWhere: TranslationContentWhere;
}>;

export type DownloadsQuery = {
  __typename?: 'Query';
  downloads: Array<{
    __typename?: 'Download';
    id: string;
    description?: string | null;
    modelList?: string | null;
    name?: string | null;
    type: DownloadType;
    url: string;
  }>;
};

export type CreateManualChapterMutationVariables = Exact<{
  data: ManualChapterCreateInput;
  titleWhere: TranslationContentWhere;
}>;

export type CreateManualChapterMutation = {
  __typename?: 'Mutation';
  createOneManualChapter?: {
    __typename?: 'ManualChapter';
    id: string;
    title?: string | null;
  } | null;
};

export type CreateOneManualMutationVariables = Exact<{
  data: ManualCreateInput;
  manualTitleWhere: TranslationContentWhere;
  manualDescriptionWhere: TranslationContentWhere;
}>;

export type CreateOneManualMutation = {
  __typename?: 'Mutation';
  createOneManual?: {
    __typename?: 'Manual';
    id: string;
    title?: string | null;
    description?: string | null;
    totalPages: number;
  } | null;
};

export type CreateManualPageMutationVariables = Exact<{
  data: ManualPageCreateInput;
  titleWhere: TranslationContentWhere;
}>;

export type CreateManualPageMutation = {
  __typename?: 'Mutation';
  createOneManualPage?: {
    __typename?: 'ManualPage';
    id: string;
    title?: string | null;
  } | null;
};

export type DeleteManualPageMutationVariables = Exact<{
  data: ManualUpdateInput;
  where: ManualWhereUniqueInput;
}>;

export type DeleteManualPageMutation = {
  __typename?: 'Mutation';
  updateOneManual?: {
    __typename?: 'Manual';
    id: string;
    chapters: Array<{
      __typename?: 'ManualChapter';
      id: string;
      pages: Array<{ __typename?: 'ManualPage'; id: string }>;
    }>;
  } | null;
};

export type UpdateManualMutationVariables = Exact<{
  data: ManualUpdateInput;
  where: ManualWhereUniqueInput;
  contentWhere: TranslationContentWhere;
  manualTitleWhere: TranslationContentWhere;
  manualDescriptionWhere: TranslationContentWhere;
  chapterTitleWhere: TranslationContentWhere;
  pageTitleWhere: TranslationContentWhere;
}>;

export type UpdateManualMutation = {
  __typename?: 'Mutation';
  updateOneManual?: {
    __typename?: 'Manual';
    id: string;
    title?: string | null;
    description?: string | null;
    chapters: Array<{
      __typename?: 'ManualChapter';
      id: string;
      title?: string | null;
      order?: number | null;
      pages: Array<{
        __typename?: 'ManualPage';
        id: string;
        title?: string | null;
        content?: string | null;
        chapterId: string;
        order?: number | null;
      }>;
    }>;
  } | null;
};

export type UpdateManualPageMutationVariables = Exact<{
  where: UpdateOneManualPageWhereInput;
  data: ManualPageUpdateWithoutManualInput;
  titleWhere: TranslationContentWhere;
  contentWhere: TranslationContentWhere;
}>;

export type UpdateManualPageMutation = {
  __typename?: 'Mutation';
  updateOneManualPage?: {
    __typename?: 'ManualPage';
    id: string;
    title?: string | null;
    content?: string | null;
  } | null;
};

export type ManualsQueryVariables = Exact<{
  manualDescriptionWhere: TranslationContentWhere;
  manualTitleWhere: TranslationContentWhere;
}>;

export type ManualsQuery = {
  __typename?: 'Query';
  manuals: Array<{
    __typename?: 'Manual';
    id: string;
    title?: string | null;
    description?: string | null;
    totalPages: number;
  }>;
};

export type ManualQueryVariables = Exact<{
  where: ManualWhereUniqueInput;
  manualDescriptionWhere: TranslationContentWhere;
  manualTitleWhere: TranslationContentWhere;
  chapterTitleWhere: TranslationContentWhere;
  pageTitleWhere: TranslationContentWhere;
  pageContentWhere: TranslationContentWhere;
}>;

export type ManualQuery = {
  __typename?: 'Query';
  manual?: {
    __typename?: 'Manual';
    id: string;
    title?: string | null;
    description?: string | null;
    chapters: Array<{
      __typename?: 'ManualChapter';
      id: string;
      title?: string | null;
      order?: number | null;
      pages: Array<{
        __typename?: 'ManualPage';
        id: string;
        title?: string | null;
        content?: string | null;
        chapterId: string;
        order?: number | null;
      }>;
    }>;
  } | null;
};

export type CompleteOnboardingDistributorMutationVariables = Exact<{
  data: DistributorUpdateInput;
  where: DistributorWhereUniqueInput;
}>;

export type CompleteOnboardingDistributorMutation = {
  __typename?: 'Mutation';
  updateOneDistributor?: {
    __typename?: 'Distributor';
    id: string;
    termAgreement?: {
      __typename?: 'TermsAgreement';
      id: string;
      expired: boolean;
    } | null;
  } | null;
};

export type CompleteOnboardingMutationVariables = Exact<{
  data: DealerUpdateInput;
  where: DealerWhereUniqueInput;
}>;

export type CompleteOnboardingMutation = {
  __typename?: 'Mutation';
  updateOneDealer?: {
    __typename?: 'Dealer';
    id: string;
    termAgreement?: {
      __typename?: 'TermsAgreement';
      id: string;
      expired: boolean;
    } | null;
  } | null;
};

export type DealerDetailsOnboardingMutationVariables = Exact<{
  where: DealerWhereUniqueInput;
  data: DealerDetailsOnboardingInput;
}>;

export type DealerDetailsOnboardingMutation = {
  __typename?: 'Mutation';
  dealerDetailsOnboarding?: {
    __typename?: 'Dealer';
    id: string;
    name: string;
    currency?: string | null;
    onboarding: OnboardingStepEnum;
    billingAddress?: {
      __typename?: 'StripeAddress';
      line1: string;
      line2?: string | null;
      city: string;
      county?: string | null;
      country: string;
      postCode: string;
    } | null;
    contact?: {
      __typename?: 'Contact';
      email: string;
      name: string;
      phone: string;
      id: string;
    } | null;
  } | null;
};

export type DistributorDetailsOnboardingMutationVariables = Exact<{
  where: DistributorWhereUniqueInput;
  data: DistributorDetailsOnboardingInput;
}>;

export type DistributorDetailsOnboardingMutation = {
  __typename?: 'Mutation';
  distributorDetailsOnboarding?: {
    __typename?: 'Distributor';
    id: string;
    name: string;
    currency?: string | null;
    onboarding: OnboardingStepEnum;
    billingAddress?: {
      __typename?: 'StripeAddress';
      line1: string;
      line2?: string | null;
      city: string;
      county?: string | null;
      country: string;
      postCode: string;
    } | null;
    contact?: {
      __typename?: 'Contact';
      email: string;
      name: string;
      phone: string;
      id: string;
    } | null;
    vatId?: { __typename?: 'VatId'; id: string; value: string } | null;
  } | null;
};

export type SignDistributorTermsMutationVariables = Exact<{
  where: DistributorWhereUniqueInput;
  data: DistributorUpdateInput;
}>;

export type SignDistributorTermsMutation = {
  __typename?: 'Mutation';
  updateOneDistributor?: {
    __typename?: 'Distributor';
    id: string;
    onboarding: OnboardingStepEnum;
    termAgreement?: {
      __typename?: 'TermsAgreement';
      id: string;
      expired: boolean;
    } | null;
  } | null;
};

export type DistributorBusinessDetailsOnboardingQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type DistributorBusinessDetailsOnboardingQuery = {
  __typename?: 'Query';
  distributor?: {
    __typename?: 'Distributor';
    id: string;
    name: string;
    currency?: string | null;
    billingAddress?: {
      __typename?: 'StripeAddress';
      line1: string;
      line2?: string | null;
      city: string;
      county?: string | null;
      country: string;
      postCode: string;
    } | null;
    contact?: {
      __typename?: 'Contact';
      email: string;
      name: string;
      phone: string;
      id: string;
    } | null;
    vatId?: { __typename?: 'VatId'; id: string; value: string } | null;
  } | null;
};

export type BusinessDetailsOnboardingQueryVariables = Exact<{
  where: DealerWhereUniqueInput;
}>;

export type BusinessDetailsOnboardingQuery = {
  __typename?: 'Query';
  dealer?: {
    __typename?: 'Dealer';
    id: string;
    name: string;
    currency?: string | null;
    billingAddress?: {
      __typename?: 'StripeAddress';
      line1: string;
      line2?: string | null;
      city: string;
      county?: string | null;
      country: string;
      postCode: string;
    } | null;
    contact?: {
      __typename?: 'Contact';
      email: string;
      name: string;
      phone: string;
      id: string;
    } | null;
    vatId?: { __typename?: 'VatId'; id: string; value: string } | null;
  } | null;
};

export type GetDealerPaymentMethodsQueryVariables = Exact<{
  where: DealerWhereUniqueInput;
}>;

export type GetDealerPaymentMethodsQuery = {
  __typename?: 'Query';
  dealer?: {
    __typename?: 'Dealer';
    id: string;
    stripeId?: string | null;
    availablePaymentMethods?: Array<string | null> | null;
  } | null;
};

export type GetDistributorPaymentMethodsQueryVariables = Exact<{
  where: DistributorWhereUniqueInput;
}>;

export type GetDistributorPaymentMethodsQuery = {
  __typename?: 'Query';
  distributor?: {
    __typename?: 'Distributor';
    id: string;
    stripeId?: string | null;
    availablePaymentMethods?: Array<string | null> | null;
  } | null;
};

export type UpdateStripeProductMetadataMutationVariables = Exact<{
  where: UniqueId;
  data: ProductUpdateInput;
}>;

export type UpdateStripeProductMetadataMutation = {
  __typename?: 'Mutation';
  updateStripeProductMetadata?: {
    __typename?: 'StripeProduct';
    id: string;
    metadata?: {
      __typename?: 'Translations';
      fr_description?: string | null;
      de_description?: string | null;
      el_description?: string | null;
      en_description?: string | null;
    } | null;
  } | null;
};

export type OrderSimsMutationVariables = Exact<{
  data: OrderSimsInput;
}>;

export type OrderSimsMutation = {
  __typename?: 'Mutation';
  orderSims?: string | null;
};

export type RegisterManySimCardsMutationVariables = Exact<{
  data: Array<InputMaybe<RegisterSimInput>> | InputMaybe<RegisterSimInput>;
}>;

export type RegisterManySimCardsMutation = {
  __typename?: 'Mutation';
  registerManySimCards?: Array<{
    __typename?: 'SimCard';
    id: string;
    iccid: string;
    groupCode?: string | null;
    groupChanged: any;
    customerId?: string | null;
    canChangeGroup?: boolean | null;
    tariff?: string | null;
    warning: number;
    status: SimStatusEnum;
    limit: number;
    imei?: string | null;
    msidn?: string | null;
    pastUsage?: Array<{
      __typename?: 'PastUsage';
      createdAt: number;
      value: number;
    } | null> | null;
    usage?: {
      __typename?: 'UsageComparison';
      status?: UsageComparisonStatus | null;
      allowance?: number | null;
      value?: number | null;
      text?: string | null;
    } | null;
    customer?: { __typename?: 'Customer'; id: string; name: string } | null;
  } | null> | null;
};

export type EditManySimMutationVariables = Exact<{
  data: EditManySimInput;
}>;

export type EditManySimMutation = {
  __typename?: 'Mutation';
  editManySim?: Array<{
    __typename?: 'SimCard';
    id: string;
    iccid: string;
    canChangeGroup?: boolean | null;
    groupCode?: string | null;
  } | null> | null;
};

export type ListChangeableDealerSimsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UniqueId>;
}>;

export type ListChangeableDealerSimsQuery = {
  __typename?: 'Query';
  listDealerSims?: {
    __typename?: 'ListDealerSims';
    sims: Array<{
      __typename?: 'SimCard';
      id: string;
      iccid: string;
      canChangeGroup?: boolean | null;
    }>;
  } | null;
};

export type ListDealerSimsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UniqueId>;
}>;

export type ListDealerSimsQuery = {
  __typename?: 'Query';
  listDealerSims?: {
    __typename?: 'ListDealerSims';
    total?: number | null;
    sims: Array<{
      __typename?: 'SimCard';
      id: string;
      iccid: string;
      groupCode?: string | null;
      groupChanged: any;
      customerId?: string | null;
      canChangeGroup?: boolean | null;
      tariff?: string | null;
      warning: number;
      status: SimStatusEnum;
      limit: number;
      imei?: string | null;
      msidn?: string | null;
      pastUsageMonthly?: Array<{
        __typename?: 'PastUsageMonthly';
        value: number;
        createdAt: string;
        year: string;
        month: string;
      } | null> | null;
      pastUsage?: Array<{
        __typename?: 'PastUsage';
        month: string;
        day: number;
        createdAt: number;
        value: number;
      } | null> | null;
      usage?: {
        __typename?: 'UsageComparison';
        status?: UsageComparisonStatus | null;
        allowance?: number | null;
        value?: number | null;
        text?: string | null;
      } | null;
      customer?: { __typename?: 'Customer'; id: string; name: string } | null;
    }>;
  } | null;
};

export type SimCardQueryVariables = Exact<{
  where: SimCardWhereUniqueInput;
}>;

export type SimCardQuery = {
  __typename?: 'Query';
  simCard?: {
    __typename?: 'SimCard';
    id: string;
    iccid: string;
    groupCode?: string | null;
    groupChanged: any;
    customerId?: string | null;
    canChangeGroup?: boolean | null;
    tariff?: string | null;
    warning: number;
    status: SimStatusEnum;
    limit: number;
    imei?: string | null;
    msidn?: string | null;
    pastUsageMonthly?: Array<{
      __typename?: 'PastUsageMonthly';
      value: number;
      createdAt: string;
      year: string;
      month: string;
    } | null> | null;
    pastUsage?: Array<{
      __typename?: 'PastUsage';
      month: string;
      day: number;
      createdAt: number;
      value: number;
    } | null> | null;
    usage?: {
      __typename?: 'UsageComparison';
      status?: UsageComparisonStatus | null;
      allowance?: number | null;
      value?: number | null;
      text?: string | null;
    } | null;
    customer?: { __typename?: 'Customer'; id: string; name: string } | null;
  } | null;
};

export type SopSyncMutationVariables = Exact<{ [key: string]: never }>;

export type SopSyncMutation = {
  __typename?: 'Mutation';
  SOP_SYNC?: {
    __typename?: 'SOPSync';
    metrics?: {
      __typename?: 'SOPSyncMetrics';
      upserts?: {
        __typename?: 'SOPSyncUpsertsAndDeletes';
        distributors?: number | null;
        dealers?: number | null;
        customers?: number | null;
        deviceTemplates?: number | null;
        deviceUsers?: number | null;
        dispatchTemplates?: number | null;
      } | null;
      deletes?: {
        __typename?: 'SOPSyncUpsertsAndDeletes';
        distributors?: number | null;
        dealers?: number | null;
        customers?: number | null;
        deviceTemplates?: number | null;
        dispatchTemplates?: number | null;
        deviceUsers?: number | null;
      } | null;
    } | null;
    upsertDistributorsRecord?: {
      __typename?: 'RecordUpdated';
      updated?: Array<string | null> | null;
      created?: Array<string | null> | null;
    } | null;
    upsertDealersRecord?: {
      __typename?: 'RecordUpdated';
      updated?: Array<string | null> | null;
      created?: Array<string | null> | null;
    } | null;
    upsertCustomersRecord?: {
      __typename?: 'RecordUpdated';
      updated?: Array<string | null> | null;
      created?: Array<string | null> | null;
    } | null;
    upsertDeviceTemplatesRecord?: {
      __typename?: 'RecordUpdated';
      updated?: Array<string | null> | null;
      created?: Array<string | null> | null;
    } | null;
    upsertDispatchTemplatesRecord?: {
      __typename?: 'RecordUpdated';
      updated?: Array<string | null> | null;
      created?: Array<string | null> | null;
    } | null;
    upsertDeviceUsersRecord?: {
      __typename?: 'RecordUpdated';
      updated?: Array<string | null> | null;
      created?: Array<string | null> | null;
    } | null;
    deleteDeviceUsersRecord?: {
      __typename?: 'RecordDeleted';
      removedIdentifier?: Array<string | null> | null;
      deleted?: Array<string | null> | null;
    } | null;
    deleteDispatchTemplatesRecord?: {
      __typename?: 'RecordDeleted';
      removedIdentifier?: Array<string | null> | null;
      deleted?: Array<string | null> | null;
    } | null;
    deleteDeviceTemplatesRecord?: {
      __typename?: 'RecordDeleted';
      removedIdentifier?: Array<string | null> | null;
      deleted?: Array<string | null> | null;
    } | null;
    deleteCustomersRecord?: {
      __typename?: 'RecordDeleted';
      removedIdentifier?: Array<string | null> | null;
      deleted?: Array<string | null> | null;
    } | null;
    deleteDealersRecord?: {
      __typename?: 'RecordDeleted';
      removedIdentifier?: Array<string | null> | null;
      deleted?: Array<string | null> | null;
    } | null;
    deleteDistributorsRecord?: {
      __typename?: 'RecordDeleted';
      removedIdentifier?: Array<string | null> | null;
      deleted?: Array<string | null> | null;
    } | null;
  } | null;
};

export type CreatePaymentMethodMutationVariables = Exact<{
  where: WhereUniqueCustomerOrDealerInput;
  data: PaymentMethodCreateInput;
}>;

export type CreatePaymentMethodMutation = {
  __typename?: 'Mutation';
  createStripePaymentMethod?: {
    __typename?: 'CheckoutSession';
    id: string;
  } | null;
};

export type CreateStripeProductWithPriceMutationVariables = Exact<{
  data: ProductWithPriceCreateInput;
}>;

export type CreateStripeProductWithPriceMutation = {
  __typename?: 'Mutation';
  createStripeProductWithPrice?: {
    __typename?: 'StripeProduct';
    id: string;
    name?: string | null;
  } | null;
};

export type StripeSubscriptionSyncMutationVariables = Exact<{
  [key: string]: never;
}>;

export type StripeSubscriptionSyncMutation = {
  __typename?: 'Mutation';
  STRIPE_SOP_SUBSCRIPTION_SYNC?: {
    __typename?: 'RecordUpdated';
    updated?: Array<string | null> | null;
    created?: Array<string | null> | null;
  } | null;
};

export type InvoiceReportQueryVariables = Exact<{
  customerWhere: CustomerWhereInput;
  where?: InputMaybe<UniqueId>;
}>;

export type InvoiceReportQuery = {
  __typename?: 'Query';
  viewDemCompanies?: {
    __typename?: 'DemCompanies';
    id: string;
    devices?: Array<{
      __typename?: 'DemDevices';
      id: string;
      demo?: boolean | null;
      serialNumber?: string | null;
      createdAt?: string | null;
      company?: {
        __typename?: 'DemCompany';
        id: string;
        name?: string | null;
      } | null;
    } | null> | null;
  } | null;
  customers: Array<{
    __typename?: 'Customer';
    id: string;
    name: string;
    deviceUsers: Array<{
      __typename?: 'DeviceUser';
      id: string;
      name: string;
      demo: boolean;
      expireDate: any;
      createdAt: any;
      devices: Array<{ __typename?: 'Device'; id: string; name: string }>;
      deviceTemplate?: {
        __typename?: 'DeviceTemplate';
        name: string;
        products: Array<{
          __typename?: 'Product';
          id: string;
          price?: number | null;
        }>;
      } | null;
      dispatchTemplate?: {
        __typename?: 'DispatchTemplate';
        name: string;
        products: Array<{
          __typename?: 'Product';
          id: string;
          price?: number | null;
        }>;
      } | null;
    }>;
    demBundles: Array<{
      __typename?: 'BundleOnUser';
      id: string;
      quantity: number;
      bundle?: {
        __typename?: 'DemBundle';
        id: string;
        name: string;
        price: number;
      } | null;
    }>;
  }>;
};

export type UpdateBundleOnCompanyMutationVariables = Exact<{
  bundleOnUser: Scalars['String'];
  customerId: Scalars['String'];
  quantity: Scalars['Int'];
}>;

export type UpdateBundleOnCompanyMutation = {
  __typename?: 'Mutation';
  updateBundleOnCompany?: {
    __typename?: 'CustomerAssigned';
    quantity: number;
    customerId: string;
    bundleOnUserId: string;
  } | null;
};

export const CreateUserDocument = gql`
  mutation CreateUser($data: CreateUserInput) {
    createUser(data: $data) {
      id
      name
      email
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const Auth0UsersDocument = gql`
  query auth0Users($data: UniqueId!) {
    auth0Users(data: $data) {
      id
      name
      email
    }
  }
`;

/**
 * __useAuth0UsersQuery__
 *
 * To run a query within a React component, call `useAuth0UsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuth0UsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuth0UsersQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAuth0UsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    Auth0UsersQuery,
    Auth0UsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Auth0UsersQuery, Auth0UsersQueryVariables>(
    Auth0UsersDocument,
    options
  );
}
export function useAuth0UsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Auth0UsersQuery,
    Auth0UsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Auth0UsersQuery, Auth0UsersQueryVariables>(
    Auth0UsersDocument,
    options
  );
}
export type Auth0UsersQueryHookResult = ReturnType<typeof useAuth0UsersQuery>;
export type Auth0UsersLazyQueryHookResult = ReturnType<
  typeof useAuth0UsersLazyQuery
>;
export type Auth0UsersQueryResult = Apollo.QueryResult<
  Auth0UsersQuery,
  Auth0UsersQueryVariables
>;
export const UpdateOneDeviceUserDocument = gql`
  mutation UpdateOneDeviceUser(
    $data: DeviceUserUpdateInput!
    $where: DeviceUserWhereUniqueInput!
  ) {
    updateOneDeviceUser(data: $data, where: $where) {
      demo
      id
    }
  }
`;
export type UpdateOneDeviceUserMutationFn = Apollo.MutationFunction<
  UpdateOneDeviceUserMutation,
  UpdateOneDeviceUserMutationVariables
>;

/**
 * __useUpdateOneDeviceUserMutation__
 *
 * To run a mutation, you first call `useUpdateOneDeviceUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneDeviceUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneDeviceUserMutation, { data, loading, error }] = useUpdateOneDeviceUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneDeviceUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOneDeviceUserMutation,
    UpdateOneDeviceUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOneDeviceUserMutation,
    UpdateOneDeviceUserMutationVariables
  >(UpdateOneDeviceUserDocument, options);
}
export type UpdateOneDeviceUserMutationHookResult = ReturnType<
  typeof useUpdateOneDeviceUserMutation
>;
export type UpdateOneDeviceUserMutationResult =
  Apollo.MutationResult<UpdateOneDeviceUserMutation>;
export type UpdateOneDeviceUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneDeviceUserMutation,
  UpdateOneDeviceUserMutationVariables
>;
export const ListCustomersDocument = gql`
  query ListCustomers($where: CustomerWhereInput) {
    customers(where: $where) {
      id
      name
      status
    }
  }
`;

/**
 * __useListCustomersQuery__
 *
 * To run a query within a React component, call `useListCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListCustomersQuery,
    ListCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListCustomersQuery, ListCustomersQueryVariables>(
    ListCustomersDocument,
    options
  );
}
export function useListCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListCustomersQuery,
    ListCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListCustomersQuery, ListCustomersQueryVariables>(
    ListCustomersDocument,
    options
  );
}
export type ListCustomersQueryHookResult = ReturnType<
  typeof useListCustomersQuery
>;
export type ListCustomersLazyQueryHookResult = ReturnType<
  typeof useListCustomersLazyQuery
>;
export type ListCustomersQueryResult = Apollo.QueryResult<
  ListCustomersQuery,
  ListCustomersQueryVariables
>;
export const ViewCustomerDeviceUsersDocument = gql`
  query ViewCustomerDeviceUsers($where: CustomerWhereUniqueInput!) {
    customer(where: $where) {
      id
      deviceUsers {
        id
        name
        userId
        license
        devices {
          id
          name
        }
        expireDate
      }
    }
  }
`;

/**
 * __useViewCustomerDeviceUsersQuery__
 *
 * To run a query within a React component, call `useViewCustomerDeviceUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCustomerDeviceUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCustomerDeviceUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewCustomerDeviceUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewCustomerDeviceUsersQuery,
    ViewCustomerDeviceUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewCustomerDeviceUsersQuery,
    ViewCustomerDeviceUsersQueryVariables
  >(ViewCustomerDeviceUsersDocument, options);
}
export function useViewCustomerDeviceUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewCustomerDeviceUsersQuery,
    ViewCustomerDeviceUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewCustomerDeviceUsersQuery,
    ViewCustomerDeviceUsersQueryVariables
  >(ViewCustomerDeviceUsersDocument, options);
}
export type ViewCustomerDeviceUsersQueryHookResult = ReturnType<
  typeof useViewCustomerDeviceUsersQuery
>;
export type ViewCustomerDeviceUsersLazyQueryHookResult = ReturnType<
  typeof useViewCustomerDeviceUsersLazyQuery
>;
export type ViewCustomerDeviceUsersQueryResult = Apollo.QueryResult<
  ViewCustomerDeviceUsersQuery,
  ViewCustomerDeviceUsersQueryVariables
>;
export const ViewCustomerInvoicesDocument = gql`
  query ViewCustomerInvoices($where: CustomerWhereUniqueInput!) {
    customer(where: $where) {
      id
      name
      stripeId
      invoices {
        created
        total
        status
        invoiceNumber
        pdf
        items {
          tax
          description
          total
          quantity
        }
      }
      nextInvoice {
        total
        dueDate
      }
      billingAddress {
        city
        county
        country
        postCode
        line1
        line2
      }
      shipping {
        city
        county
        country
        postCode
        line1
        line2
      }
    }
  }
`;

/**
 * __useViewCustomerInvoicesQuery__
 *
 * To run a query within a React component, call `useViewCustomerInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCustomerInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCustomerInvoicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewCustomerInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewCustomerInvoicesQuery,
    ViewCustomerInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewCustomerInvoicesQuery,
    ViewCustomerInvoicesQueryVariables
  >(ViewCustomerInvoicesDocument, options);
}
export function useViewCustomerInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewCustomerInvoicesQuery,
    ViewCustomerInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewCustomerInvoicesQuery,
    ViewCustomerInvoicesQueryVariables
  >(ViewCustomerInvoicesDocument, options);
}
export type ViewCustomerInvoicesQueryHookResult = ReturnType<
  typeof useViewCustomerInvoicesQuery
>;
export type ViewCustomerInvoicesLazyQueryHookResult = ReturnType<
  typeof useViewCustomerInvoicesLazyQuery
>;
export type ViewCustomerInvoicesQueryResult = Apollo.QueryResult<
  ViewCustomerInvoicesQuery,
  ViewCustomerInvoicesQueryVariables
>;
export const ViewCustomerOverviewDocument = gql`
  query ViewCustomerOverview($where: CustomerWhereUniqueInput!) {
    customer(where: $where) {
      id
      name
      totalDeviceUsers
      sopLogin {
        username
        decryptedPassword
      }
      invoices {
        created
        total
      }
      nextInvoice {
        total
      }
      distributor {
        id
        name
      }
      billingAddress {
        postCode
        line2
        line1
        county
        country
        city
      }
      shipping {
        city
        country
        line1
        county
        line2
        postCode
        name
        phone
      }
      contact {
        id
        email
        name
        phone
      }
    }
  }
`;

/**
 * __useViewCustomerOverviewQuery__
 *
 * To run a query within a React component, call `useViewCustomerOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCustomerOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCustomerOverviewQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewCustomerOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewCustomerOverviewQuery,
    ViewCustomerOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewCustomerOverviewQuery,
    ViewCustomerOverviewQueryVariables
  >(ViewCustomerOverviewDocument, options);
}
export function useViewCustomerOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewCustomerOverviewQuery,
    ViewCustomerOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewCustomerOverviewQuery,
    ViewCustomerOverviewQueryVariables
  >(ViewCustomerOverviewDocument, options);
}
export type ViewCustomerOverviewQueryHookResult = ReturnType<
  typeof useViewCustomerOverviewQuery
>;
export type ViewCustomerOverviewLazyQueryHookResult = ReturnType<
  typeof useViewCustomerOverviewLazyQuery
>;
export type ViewCustomerOverviewQueryResult = Apollo.QueryResult<
  ViewCustomerOverviewQuery,
  ViewCustomerOverviewQueryVariables
>;
export const ViewCustomerPaymentsDocument = gql`
  query ViewCustomerPayments($where: CustomerWhereUniqueInput!) {
    customer(where: $where) {
      id
      name
      billingAddress {
        city
        county
        country
        postCode
        line1
        line2
      }
      payments {
        date
        invoiceNumber
        paid
        status
        total
        totalCollected
        type
      }
    }
  }
`;

/**
 * __useViewCustomerPaymentsQuery__
 *
 * To run a query within a React component, call `useViewCustomerPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCustomerPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCustomerPaymentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewCustomerPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewCustomerPaymentsQuery,
    ViewCustomerPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewCustomerPaymentsQuery,
    ViewCustomerPaymentsQueryVariables
  >(ViewCustomerPaymentsDocument, options);
}
export function useViewCustomerPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewCustomerPaymentsQuery,
    ViewCustomerPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewCustomerPaymentsQuery,
    ViewCustomerPaymentsQueryVariables
  >(ViewCustomerPaymentsDocument, options);
}
export type ViewCustomerPaymentsQueryHookResult = ReturnType<
  typeof useViewCustomerPaymentsQuery
>;
export type ViewCustomerPaymentsLazyQueryHookResult = ReturnType<
  typeof useViewCustomerPaymentsLazyQuery
>;
export type ViewCustomerPaymentsQueryResult = Apollo.QueryResult<
  ViewCustomerPaymentsQuery,
  ViewCustomerPaymentsQueryVariables
>;
export const ViewCustomerSimsDocument = gql`
  query ViewCustomerSims($where: CustomerWhereUniqueInput!) {
    customer(where: $where) {
      id
      simCards {
        id
        iccid
        tariff
        groupCode
        groupChanged
        status
        usage {
          status
          text
        }
      }
    }
  }
`;

/**
 * __useViewCustomerSimsQuery__
 *
 * To run a query within a React component, call `useViewCustomerSimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewCustomerSimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewCustomerSimsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewCustomerSimsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewCustomerSimsQuery,
    ViewCustomerSimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewCustomerSimsQuery, ViewCustomerSimsQueryVariables>(
    ViewCustomerSimsDocument,
    options
  );
}
export function useViewCustomerSimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewCustomerSimsQuery,
    ViewCustomerSimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewCustomerSimsQuery,
    ViewCustomerSimsQueryVariables
  >(ViewCustomerSimsDocument, options);
}
export type ViewCustomerSimsQueryHookResult = ReturnType<
  typeof useViewCustomerSimsQuery
>;
export type ViewCustomerSimsLazyQueryHookResult = ReturnType<
  typeof useViewCustomerSimsLazyQuery
>;
export type ViewCustomerSimsQueryResult = Apollo.QueryResult<
  ViewCustomerSimsQuery,
  ViewCustomerSimsQueryVariables
>;
export const DealerVatOnboardingDocument = gql`
  mutation DealerVATOnboarding(
    $where: DealerWhereUniqueInput!
    $data: DealerVatOnboardingInput!
  ) {
    dealerVATOnboarding(where: $where, data: $data) {
      id
      name
      currency
      onboarding
      billingAddress {
        line1
        line2
        city
        county
        country
        postCode
      }
      contact {
        email
        name
        phone
        id
      }
      vatId {
        id
        value
      }
    }
  }
`;
export type DealerVatOnboardingMutationFn = Apollo.MutationFunction<
  DealerVatOnboardingMutation,
  DealerVatOnboardingMutationVariables
>;

/**
 * __useDealerVatOnboardingMutation__
 *
 * To run a mutation, you first call `useDealerVatOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealerVatOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealerVatOnboardingMutation, { data, loading, error }] = useDealerVatOnboardingMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDealerVatOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DealerVatOnboardingMutation,
    DealerVatOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DealerVatOnboardingMutation,
    DealerVatOnboardingMutationVariables
  >(DealerVatOnboardingDocument, options);
}
export type DealerVatOnboardingMutationHookResult = ReturnType<
  typeof useDealerVatOnboardingMutation
>;
export type DealerVatOnboardingMutationResult =
  Apollo.MutationResult<DealerVatOnboardingMutation>;
export type DealerVatOnboardingMutationOptions = Apollo.BaseMutationOptions<
  DealerVatOnboardingMutation,
  DealerVatOnboardingMutationVariables
>;
export const EditSimDocument = gql`
  mutation EditSim($data: EditSimInput!) {
    editSim(data: $data) {
      id
      iccid
      status
      groupChanged
      groupCode
      tariff
      limit
      warning
      canChangeGroup
      usage {
        status
        allowance
        value
        text
      }
      customer {
        id
        name
      }
    }
  }
`;
export type EditSimMutationFn = Apollo.MutationFunction<
  EditSimMutation,
  EditSimMutationVariables
>;

/**
 * __useEditSimMutation__
 *
 * To run a mutation, you first call `useEditSimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSimMutation, { data, loading, error }] = useEditSimMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditSimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditSimMutation,
    EditSimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditSimMutation, EditSimMutationVariables>(
    EditSimDocument,
    options
  );
}
export type EditSimMutationHookResult = ReturnType<typeof useEditSimMutation>;
export type EditSimMutationResult = Apollo.MutationResult<EditSimMutation>;
export type EditSimMutationOptions = Apollo.BaseMutationOptions<
  EditSimMutation,
  EditSimMutationVariables
>;
export const UpdateStripeCustomerDocument = gql`
  mutation UpdateStripeCustomer(
    $data: CustomerObjectUpdateInput!
    $where: UniqueId!
  ) {
    updateStripeCustomer(data: $data, where: $where)
  }
`;
export type UpdateStripeCustomerMutationFn = Apollo.MutationFunction<
  UpdateStripeCustomerMutation,
  UpdateStripeCustomerMutationVariables
>;

/**
 * __useUpdateStripeCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateStripeCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeCustomerMutation, { data, loading, error }] = useUpdateStripeCustomerMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateStripeCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStripeCustomerMutation,
    UpdateStripeCustomerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStripeCustomerMutation,
    UpdateStripeCustomerMutationVariables
  >(UpdateStripeCustomerDocument, options);
}
export type UpdateStripeCustomerMutationHookResult = ReturnType<
  typeof useUpdateStripeCustomerMutation
>;
export type UpdateStripeCustomerMutationResult =
  Apollo.MutationResult<UpdateStripeCustomerMutation>;
export type UpdateStripeCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateStripeCustomerMutation,
  UpdateStripeCustomerMutationVariables
>;
export const UpdateDealerDistributorsDocument = gql`
  mutation UpdateDealerDistributors($where: [String!]!, $data: String!) {
    updateDealerDistributors(where: $where, data: $data) {
      id
    }
  }
`;
export type UpdateDealerDistributorsMutationFn = Apollo.MutationFunction<
  UpdateDealerDistributorsMutation,
  UpdateDealerDistributorsMutationVariables
>;

/**
 * __useUpdateDealerDistributorsMutation__
 *
 * To run a mutation, you first call `useUpdateDealerDistributorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDealerDistributorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDealerDistributorsMutation, { data, loading, error }] = useUpdateDealerDistributorsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDealerDistributorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDealerDistributorsMutation,
    UpdateDealerDistributorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDealerDistributorsMutation,
    UpdateDealerDistributorsMutationVariables
  >(UpdateDealerDistributorsDocument, options);
}
export type UpdateDealerDistributorsMutationHookResult = ReturnType<
  typeof useUpdateDealerDistributorsMutation
>;
export type UpdateDealerDistributorsMutationResult =
  Apollo.MutationResult<UpdateDealerDistributorsMutation>;
export type UpdateDealerDistributorsMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDealerDistributorsMutation,
    UpdateDealerDistributorsMutationVariables
  >;
export const UpdateSopLoginDocument = gql`
  mutation UpdateSopLogin(
    $data: DealerUpdateInput!
    $where: DealerWhereUniqueInput!
  ) {
    updateOneDealer(data: $data, where: $where) {
      id
    }
  }
`;
export type UpdateSopLoginMutationFn = Apollo.MutationFunction<
  UpdateSopLoginMutation,
  UpdateSopLoginMutationVariables
>;

/**
 * __useUpdateSopLoginMutation__
 *
 * To run a mutation, you first call `useUpdateSopLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSopLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSopLoginMutation, { data, loading, error }] = useUpdateSopLoginMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateSopLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSopLoginMutation,
    UpdateSopLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSopLoginMutation,
    UpdateSopLoginMutationVariables
  >(UpdateSopLoginDocument, options);
}
export type UpdateSopLoginMutationHookResult = ReturnType<
  typeof useUpdateSopLoginMutation
>;
export type UpdateSopLoginMutationResult =
  Apollo.MutationResult<UpdateSopLoginMutation>;
export type UpdateSopLoginMutationOptions = Apollo.BaseMutationOptions<
  UpdateSopLoginMutation,
  UpdateSopLoginMutationVariables
>;
export const DealerCurrencyDocument = gql`
  query DealerCurrency($where: DealerWhereUniqueInput!) {
    dealer(where: $where) {
      id
      currency
    }
  }
`;

/**
 * __useDealerCurrencyQuery__
 *
 * To run a query within a React component, call `useDealerCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealerCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealerCurrencyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDealerCurrencyQuery(
  baseOptions: Apollo.QueryHookOptions<
    DealerCurrencyQuery,
    DealerCurrencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealerCurrencyQuery, DealerCurrencyQueryVariables>(
    DealerCurrencyDocument,
    options
  );
}
export function useDealerCurrencyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DealerCurrencyQuery,
    DealerCurrencyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealerCurrencyQuery, DealerCurrencyQueryVariables>(
    DealerCurrencyDocument,
    options
  );
}
export type DealerCurrencyQueryHookResult = ReturnType<
  typeof useDealerCurrencyQuery
>;
export type DealerCurrencyLazyQueryHookResult = ReturnType<
  typeof useDealerCurrencyLazyQuery
>;
export type DealerCurrencyQueryResult = Apollo.QueryResult<
  DealerCurrencyQuery,
  DealerCurrencyQueryVariables
>;
export const DealerSopLoginDocument = gql`
  query DealerSopLogin($where: DealerWhereUniqueInput!) {
    dealer(where: $where) {
      id
      sopLogin {
        id
        username
        decryptedPassword
      }
    }
  }
`;

/**
 * __useDealerSopLoginQuery__
 *
 * To run a query within a React component, call `useDealerSopLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealerSopLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealerSopLoginQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDealerSopLoginQuery(
  baseOptions: Apollo.QueryHookOptions<
    DealerSopLoginQuery,
    DealerSopLoginQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealerSopLoginQuery, DealerSopLoginQueryVariables>(
    DealerSopLoginDocument,
    options
  );
}
export function useDealerSopLoginLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DealerSopLoginQuery,
    DealerSopLoginQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealerSopLoginQuery, DealerSopLoginQueryVariables>(
    DealerSopLoginDocument,
    options
  );
}
export type DealerSopLoginQueryHookResult = ReturnType<
  typeof useDealerSopLoginQuery
>;
export type DealerSopLoginLazyQueryHookResult = ReturnType<
  typeof useDealerSopLoginLazyQuery
>;
export type DealerSopLoginQueryResult = Apollo.QueryResult<
  DealerSopLoginQuery,
  DealerSopLoginQueryVariables
>;
export const DealerDocument = gql`
  query Dealer($where: DealerWhereUniqueInput!) {
    dealer(where: $where) {
      id
      customers {
        id
        name
      }
      simCards {
        id
        iccid
        status
        usage {
          status
        }
        customer {
          name
        }
      }
    }
  }
`;

/**
 * __useDealerQuery__
 *
 * To run a query within a React component, call `useDealerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealerQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDealerQuery(
  baseOptions: Apollo.QueryHookOptions<DealerQuery, DealerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DealerQuery, DealerQueryVariables>(
    DealerDocument,
    options
  );
}
export function useDealerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DealerQuery, DealerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DealerQuery, DealerQueryVariables>(
    DealerDocument,
    options
  );
}
export type DealerQueryHookResult = ReturnType<typeof useDealerQuery>;
export type DealerLazyQueryHookResult = ReturnType<typeof useDealerLazyQuery>;
export type DealerQueryResult = Apollo.QueryResult<
  DealerQuery,
  DealerQueryVariables
>;
export const ViewDealerCustomersDocument = gql`
  query ViewDealerCustomers($where: DealerWhereUniqueInput!) {
    dealer(where: $where) {
      id
      totalDemoDeviceUsers
      customers {
        id
        name
        deviceUsers {
          id
        }
      }
    }
  }
`;

/**
 * __useViewDealerCustomersQuery__
 *
 * To run a query within a React component, call `useViewDealerCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDealerCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDealerCustomersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDealerCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDealerCustomersQuery,
    ViewDealerCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDealerCustomersQuery,
    ViewDealerCustomersQueryVariables
  >(ViewDealerCustomersDocument, options);
}
export function useViewDealerCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDealerCustomersQuery,
    ViewDealerCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDealerCustomersQuery,
    ViewDealerCustomersQueryVariables
  >(ViewDealerCustomersDocument, options);
}
export type ViewDealerCustomersQueryHookResult = ReturnType<
  typeof useViewDealerCustomersQuery
>;
export type ViewDealerCustomersLazyQueryHookResult = ReturnType<
  typeof useViewDealerCustomersLazyQuery
>;
export type ViewDealerCustomersQueryResult = Apollo.QueryResult<
  ViewDealerCustomersQuery,
  ViewDealerCustomersQueryVariables
>;
export const ViewDemoDeviceUsersDealerDocument = gql`
  query ViewDemoDeviceUsersDealer($where: DealerWhereUniqueInput!) {
    dealer(where: $where) {
      id
      totalDemoDeviceUsers
      totalDemoDispatchUsers
    }
  }
`;

/**
 * __useViewDemoDeviceUsersDealerQuery__
 *
 * To run a query within a React component, call `useViewDemoDeviceUsersDealerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDemoDeviceUsersDealerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDemoDeviceUsersDealerQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDemoDeviceUsersDealerQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDemoDeviceUsersDealerQuery,
    ViewDemoDeviceUsersDealerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDemoDeviceUsersDealerQuery,
    ViewDemoDeviceUsersDealerQueryVariables
  >(ViewDemoDeviceUsersDealerDocument, options);
}
export function useViewDemoDeviceUsersDealerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDemoDeviceUsersDealerQuery,
    ViewDemoDeviceUsersDealerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDemoDeviceUsersDealerQuery,
    ViewDemoDeviceUsersDealerQueryVariables
  >(ViewDemoDeviceUsersDealerDocument, options);
}
export type ViewDemoDeviceUsersDealerQueryHookResult = ReturnType<
  typeof useViewDemoDeviceUsersDealerQuery
>;
export type ViewDemoDeviceUsersDealerLazyQueryHookResult = ReturnType<
  typeof useViewDemoDeviceUsersDealerLazyQuery
>;
export type ViewDemoDeviceUsersDealerQueryResult = Apollo.QueryResult<
  ViewDemoDeviceUsersDealerQuery,
  ViewDemoDeviceUsersDealerQueryVariables
>;
export const AddBundleDocument = gql`
  mutation AddBundle($data: [AddBundleInput]!) {
    addBundle(data: $data) {
      id
    }
  }
`;
export type AddBundleMutationFn = Apollo.MutationFunction<
  AddBundleMutation,
  AddBundleMutationVariables
>;

/**
 * __useAddBundleMutation__
 *
 * To run a mutation, you first call `useAddBundleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBundleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBundleMutation, { data, loading, error }] = useAddBundleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddBundleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddBundleMutation,
    AddBundleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddBundleMutation, AddBundleMutationVariables>(
    AddBundleDocument,
    options
  );
}
export type AddBundleMutationHookResult = ReturnType<
  typeof useAddBundleMutation
>;
export type AddBundleMutationResult = Apollo.MutationResult<AddBundleMutation>;
export type AddBundleMutationOptions = Apollo.BaseMutationOptions<
  AddBundleMutation,
  AddBundleMutationVariables
>;
export const CreateCompanyDocument = gql`
  mutation CreateCompany($data: CreateDemCompanyInput!) {
    createCompanyMutation(data: $data) {
      id
      name
      tier
      demGroups {
        id
        name
        groupId
      }
    }
  }
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CreateCompanyDocument, options);
}
export type CreateCompanyMutationHookResult = ReturnType<
  typeof useCreateCompanyMutation
>;
export type CreateCompanyMutationResult =
  Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const CreateDeviceDocument = gql`
  mutation CreateDevice($data: CreateDemDeviceInput!) {
    registerDevice(data: $data) {
      serialNumber
    }
  }
`;
export type CreateDeviceMutationFn = Apollo.MutationFunction<
  CreateDeviceMutation,
  CreateDeviceMutationVariables
>;

/**
 * __useCreateDeviceMutation__
 *
 * To run a mutation, you first call `useCreateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeviceMutation, { data, loading, error }] = useCreateDeviceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDeviceMutation,
    CreateDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDeviceMutation,
    CreateDeviceMutationVariables
  >(CreateDeviceDocument, options);
}
export type CreateDeviceMutationHookResult = ReturnType<
  typeof useCreateDeviceMutation
>;
export type CreateDeviceMutationResult =
  Apollo.MutationResult<CreateDeviceMutation>;
export type CreateDeviceMutationOptions = Apollo.BaseMutationOptions<
  CreateDeviceMutation,
  CreateDeviceMutationVariables
>;
export const CreateGroupDocument = gql`
  mutation CreateGroup($data: CreateDemGroupInput!) {
    createDemGroup(data: $data) {
      id
      name
    }
  }
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(
    CreateGroupDocument,
    options
  );
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult =
  Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const CreateDemUserDocument = gql`
  mutation CreateDemUser($data: CreateDemUserInput!) {
    createDemUser(data: $data) {
      name
      id
    }
  }
`;
export type CreateDemUserMutationFn = Apollo.MutationFunction<
  CreateDemUserMutation,
  CreateDemUserMutationVariables
>;

/**
 * __useCreateDemUserMutation__
 *
 * To run a mutation, you first call `useCreateDemUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDemUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDemUserMutation, { data, loading, error }] = useCreateDemUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDemUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDemUserMutation,
    CreateDemUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDemUserMutation,
    CreateDemUserMutationVariables
  >(CreateDemUserDocument, options);
}
export type CreateDemUserMutationHookResult = ReturnType<
  typeof useCreateDemUserMutation
>;
export type CreateDemUserMutationResult =
  Apollo.MutationResult<CreateDemUserMutation>;
export type CreateDemUserMutationOptions = Apollo.BaseMutationOptions<
  CreateDemUserMutation,
  CreateDemUserMutationVariables
>;
export const EditBundlesDocument = gql`
  mutation EditBundles($data: [AddBundleInput]!) {
    editBundles(data: $data) {
      id
    }
  }
`;
export type EditBundlesMutationFn = Apollo.MutationFunction<
  EditBundlesMutation,
  EditBundlesMutationVariables
>;

/**
 * __useEditBundlesMutation__
 *
 * To run a mutation, you first call `useEditBundlesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBundlesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBundlesMutation, { data, loading, error }] = useEditBundlesMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditBundlesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditBundlesMutation,
    EditBundlesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditBundlesMutation, EditBundlesMutationVariables>(
    EditBundlesDocument,
    options
  );
}
export type EditBundlesMutationHookResult = ReturnType<
  typeof useEditBundlesMutation
>;
export type EditBundlesMutationResult =
  Apollo.MutationResult<EditBundlesMutation>;
export type EditBundlesMutationOptions = Apollo.BaseMutationOptions<
  EditBundlesMutation,
  EditBundlesMutationVariables
>;
export const DemoStatusMutationDocument = gql`
  mutation DemoStatusMutation($data: DemoDeviceInput!) {
    demoStatusMutation(data: $data) {
      id
      model {
        name
        id
      }
      demo
      serialNumber
      demGroups {
        id
        name
        groupId
      }
    }
  }
`;
export type DemoStatusMutationMutationFn = Apollo.MutationFunction<
  DemoStatusMutationMutation,
  DemoStatusMutationMutationVariables
>;

/**
 * __useDemoStatusMutationMutation__
 *
 * To run a mutation, you first call `useDemoStatusMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDemoStatusMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [demoStatusMutationMutation, { data, loading, error }] = useDemoStatusMutationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDemoStatusMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DemoStatusMutationMutation,
    DemoStatusMutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DemoStatusMutationMutation,
    DemoStatusMutationMutationVariables
  >(DemoStatusMutationDocument, options);
}
export type DemoStatusMutationMutationHookResult = ReturnType<
  typeof useDemoStatusMutationMutation
>;
export type DemoStatusMutationMutationResult =
  Apollo.MutationResult<DemoStatusMutationMutation>;
export type DemoStatusMutationMutationOptions = Apollo.BaseMutationOptions<
  DemoStatusMutationMutation,
  DemoStatusMutationMutationVariables
>;
export const UpdateDemDeviceDocument = gql`
  mutation UpdateDemDevice($data: EditDemDeviceInput!) {
    updatedDemDevice(data: $data) {
      id
      model {
        name
        id
      }
      serialNumber
      demGroups {
        groupId
        name
        id
      }
    }
  }
`;
export type UpdateDemDeviceMutationFn = Apollo.MutationFunction<
  UpdateDemDeviceMutation,
  UpdateDemDeviceMutationVariables
>;

/**
 * __useUpdateDemDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDemDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDemDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDemDeviceMutation, { data, loading, error }] = useUpdateDemDeviceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDemDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDemDeviceMutation,
    UpdateDemDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDemDeviceMutation,
    UpdateDemDeviceMutationVariables
  >(UpdateDemDeviceDocument, options);
}
export type UpdateDemDeviceMutationHookResult = ReturnType<
  typeof useUpdateDemDeviceMutation
>;
export type UpdateDemDeviceMutationResult =
  Apollo.MutationResult<UpdateDemDeviceMutation>;
export type UpdateDemDeviceMutationOptions = Apollo.BaseMutationOptions<
  UpdateDemDeviceMutation,
  UpdateDemDeviceMutationVariables
>;
export const UpdateUserGroupsDocument = gql`
  mutation UpdateUserGroups(
    $data: DemUserInput!
    $where: UserWhereUniqueInput!
  ) {
    updateOneDemUser(data: $data, where: $where) {
      id
      name
    }
  }
`;
export type UpdateUserGroupsMutationFn = Apollo.MutationFunction<
  UpdateUserGroupsMutation,
  UpdateUserGroupsMutationVariables
>;

/**
 * __useUpdateUserGroupsMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupsMutation, { data, loading, error }] = useUpdateUserGroupsMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateUserGroupsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserGroupsMutation,
    UpdateUserGroupsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserGroupsMutation,
    UpdateUserGroupsMutationVariables
  >(UpdateUserGroupsDocument, options);
}
export type UpdateUserGroupsMutationHookResult = ReturnType<
  typeof useUpdateUserGroupsMutation
>;
export type UpdateUserGroupsMutationResult =
  Apollo.MutationResult<UpdateUserGroupsMutation>;
export type UpdateUserGroupsMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserGroupsMutation,
  UpdateUserGroupsMutationVariables
>;
export const AvailableUsersDocument = gql`
  query AvailableUsers($where: DemUserWhereInput!) {
    availableUsers(where: $where) {
      id
      name
      role
      email
    }
  }
`;

/**
 * __useAvailableUsersQuery__
 *
 * To run a query within a React component, call `useAvailableUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAvailableUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableUsersQuery,
    AvailableUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailableUsersQuery, AvailableUsersQueryVariables>(
    AvailableUsersDocument,
    options
  );
}
export function useAvailableUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableUsersQuery,
    AvailableUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailableUsersQuery, AvailableUsersQueryVariables>(
    AvailableUsersDocument,
    options
  );
}
export type AvailableUsersQueryHookResult = ReturnType<
  typeof useAvailableUsersQuery
>;
export type AvailableUsersLazyQueryHookResult = ReturnType<
  typeof useAvailableUsersLazyQuery
>;
export type AvailableUsersQueryResult = Apollo.QueryResult<
  AvailableUsersQuery,
  AvailableUsersQueryVariables
>;
export const AvailableBundlesDocument = gql`
  query AvailableBundles($where: UniqueId) {
    availableBundles(where: $where) {
      id
      name
      price
      data
    }
  }
`;

/**
 * __useAvailableBundlesQuery__
 *
 * To run a query within a React component, call `useAvailableBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableBundlesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAvailableBundlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AvailableBundlesQuery,
    AvailableBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailableBundlesQuery, AvailableBundlesQueryVariables>(
    AvailableBundlesDocument,
    options
  );
}
export function useAvailableBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableBundlesQuery,
    AvailableBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AvailableBundlesQuery,
    AvailableBundlesQueryVariables
  >(AvailableBundlesDocument, options);
}
export type AvailableBundlesQueryHookResult = ReturnType<
  typeof useAvailableBundlesQuery
>;
export type AvailableBundlesLazyQueryHookResult = ReturnType<
  typeof useAvailableBundlesLazyQuery
>;
export type AvailableBundlesQueryResult = Apollo.QueryResult<
  AvailableBundlesQuery,
  AvailableBundlesQueryVariables
>;
export const ActiveBundlesDocument = gql`
  query ActiveBundles($where: UniqueId) {
    activeBundles(where: $where) {
      id
      quantity
      assignedTo {
        quantity
        customerId
      }
      bundle {
        data
        id
        name
        price
      }
    }
  }
`;

/**
 * __useActiveBundlesQuery__
 *
 * To run a query within a React component, call `useActiveBundlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveBundlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveBundlesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useActiveBundlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActiveBundlesQuery,
    ActiveBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActiveBundlesQuery, ActiveBundlesQueryVariables>(
    ActiveBundlesDocument,
    options
  );
}
export function useActiveBundlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActiveBundlesQuery,
    ActiveBundlesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActiveBundlesQuery, ActiveBundlesQueryVariables>(
    ActiveBundlesDocument,
    options
  );
}
export type ActiveBundlesQueryHookResult = ReturnType<
  typeof useActiveBundlesQuery
>;
export type ActiveBundlesLazyQueryHookResult = ReturnType<
  typeof useActiveBundlesLazyQuery
>;
export type ActiveBundlesQueryResult = Apollo.QueryResult<
  ActiveBundlesQuery,
  ActiveBundlesQueryVariables
>;
export const GetDemCompaniesDocument = gql`
  query GetDemCompanies($where: UniqueId) {
    viewDemCompanies(where: $where) {
      id
      demoGbAvailable
      totalGbUsed
      numberOfDemo
      company {
        id
        name
        tier
        demGroups {
          id
          name
          groupId
        }
      }
      devices {
        id
        dataUsed
        model {
          id
          name
        }
        demo
        serialNumber
        company {
          name
          demGroups {
            id
            name
            groupId
          }
          id
        }
        demGroups {
          id
          name
          groupId
        }
      }
      demUsers {
        role
        id
        name
        email
        companyRoles {
          id
          roleName
          company {
            id
          }
        }
        companies {
          name
          id
        }
        demGroupOnUser {
          id
          name
          groupId
        }
      }
    }
  }
`;

/**
 * __useGetDemCompaniesQuery__
 *
 * To run a query within a React component, call `useGetDemCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemCompaniesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDemCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDemCompaniesQuery,
    GetDemCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDemCompaniesQuery, GetDemCompaniesQueryVariables>(
    GetDemCompaniesDocument,
    options
  );
}
export function useGetDemCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDemCompaniesQuery,
    GetDemCompaniesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDemCompaniesQuery,
    GetDemCompaniesQueryVariables
  >(GetDemCompaniesDocument, options);
}
export type GetDemCompaniesQueryHookResult = ReturnType<
  typeof useGetDemCompaniesQuery
>;
export type GetDemCompaniesLazyQueryHookResult = ReturnType<
  typeof useGetDemCompaniesLazyQuery
>;
export type GetDemCompaniesQueryResult = Apollo.QueryResult<
  GetDemCompaniesQuery,
  GetDemCompaniesQueryVariables
>;
export const ViewDemCompanyDocument = gql`
  query ViewDemCompany($where: UniqueId) {
    viewDemCompany(where: $where) {
      id
      name
      demGroups {
        groupId
        name
        id
      }
      device {
        dataUsed
        demGroups {
          groupId
          name
          id
        }
        serialNumber
        demo
        model {
          id
          name
        }
        id
      }
      users {
        id
        role
        email
        name
        companyRoles {
          id
          roleName
          company {
            id
          }
        }
        demGroupOnUser {
          name
          groupId
          id
        }
      }
    }
  }
`;

/**
 * __useViewDemCompanyQuery__
 *
 * To run a query within a React component, call `useViewDemCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDemCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDemCompanyQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDemCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewDemCompanyQuery,
    ViewDemCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewDemCompanyQuery, ViewDemCompanyQueryVariables>(
    ViewDemCompanyDocument,
    options
  );
}
export function useViewDemCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDemCompanyQuery,
    ViewDemCompanyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewDemCompanyQuery, ViewDemCompanyQueryVariables>(
    ViewDemCompanyDocument,
    options
  );
}
export type ViewDemCompanyQueryHookResult = ReturnType<
  typeof useViewDemCompanyQuery
>;
export type ViewDemCompanyLazyQueryHookResult = ReturnType<
  typeof useViewDemCompanyLazyQuery
>;
export type ViewDemCompanyQueryResult = Apollo.QueryResult<
  ViewDemCompanyQuery,
  ViewDemCompanyQueryVariables
>;
export const DemDeviceModelsDocument = gql`
  query DemDeviceModels {
    demDeviceModels {
      id
      name
    }
  }
`;

/**
 * __useDemDeviceModelsQuery__
 *
 * To run a query within a React component, call `useDemDeviceModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemDeviceModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemDeviceModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDemDeviceModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DemDeviceModelsQuery,
    DemDeviceModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DemDeviceModelsQuery, DemDeviceModelsQueryVariables>(
    DemDeviceModelsDocument,
    options
  );
}
export function useDemDeviceModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DemDeviceModelsQuery,
    DemDeviceModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DemDeviceModelsQuery,
    DemDeviceModelsQueryVariables
  >(DemDeviceModelsDocument, options);
}
export type DemDeviceModelsQueryHookResult = ReturnType<
  typeof useDemDeviceModelsQuery
>;
export type DemDeviceModelsLazyQueryHookResult = ReturnType<
  typeof useDemDeviceModelsLazyQuery
>;
export type DemDeviceModelsQueryResult = Apollo.QueryResult<
  DemDeviceModelsQuery,
  DemDeviceModelsQueryVariables
>;
export const GroupsListDocument = gql`
  query GroupsList($where: DemGroupsWhereInput!) {
    groupsList(where: $where) {
      id
      name
    }
  }
`;

/**
 * __useGroupsListQuery__
 *
 * To run a query within a React component, call `useGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsListQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGroupsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GroupsListQuery,
    GroupsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupsListQuery, GroupsListQueryVariables>(
    GroupsListDocument,
    options
  );
}
export function useGroupsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GroupsListQuery,
    GroupsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupsListQuery, GroupsListQueryVariables>(
    GroupsListDocument,
    options
  );
}
export type GroupsListQueryHookResult = ReturnType<typeof useGroupsListQuery>;
export type GroupsListLazyQueryHookResult = ReturnType<
  typeof useGroupsListLazyQuery
>;
export type GroupsListQueryResult = Apollo.QueryResult<
  GroupsListQuery,
  GroupsListQueryVariables
>;
export const ViewDemDeviceDocument = gql`
  query ViewDemDevice($where: UniqueId) {
    viewDemDevice(where: $where) {
      id
      model {
        name
        id
      }
      serialNumber
      demGroups {
        groupId
        name
        id
      }
    }
  }
`;

/**
 * __useViewDemDeviceQuery__
 *
 * To run a query within a React component, call `useViewDemDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDemDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDemDeviceQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDemDeviceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ViewDemDeviceQuery,
    ViewDemDeviceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewDemDeviceQuery, ViewDemDeviceQueryVariables>(
    ViewDemDeviceDocument,
    options
  );
}
export function useViewDemDeviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDemDeviceQuery,
    ViewDemDeviceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewDemDeviceQuery, ViewDemDeviceQueryVariables>(
    ViewDemDeviceDocument,
    options
  );
}
export type ViewDemDeviceQueryHookResult = ReturnType<
  typeof useViewDemDeviceQuery
>;
export type ViewDemDeviceLazyQueryHookResult = ReturnType<
  typeof useViewDemDeviceLazyQuery
>;
export type ViewDemDeviceQueryResult = Apollo.QueryResult<
  ViewDemDeviceQuery,
  ViewDemDeviceQueryVariables
>;
export const DeviceModelsDocument = gql`
  query DeviceModels {
    deviceModels {
      id
      name
    }
  }
`;

/**
 * __useDeviceModelsQuery__
 *
 * To run a query within a React component, call `useDeviceModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeviceModelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DeviceModelsQuery,
    DeviceModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DeviceModelsQuery, DeviceModelsQueryVariables>(
    DeviceModelsDocument,
    options
  );
}
export function useDeviceModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeviceModelsQuery,
    DeviceModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DeviceModelsQuery, DeviceModelsQueryVariables>(
    DeviceModelsDocument,
    options
  );
}
export type DeviceModelsQueryHookResult = ReturnType<
  typeof useDeviceModelsQuery
>;
export type DeviceModelsLazyQueryHookResult = ReturnType<
  typeof useDeviceModelsLazyQuery
>;
export type DeviceModelsQueryResult = Apollo.QueryResult<
  DeviceModelsQuery,
  DeviceModelsQueryVariables
>;
export const ViewDistributorBillingDetailsDocument = gql`
  query ViewDistributorBillingDetails($where: DistributorWhereUniqueInput!) {
    distributor(where: $where) {
      id
      name
      stripeId
      contact {
        name
        phone
        email
        id
      }
      invoices {
        created
        total
        status
        invoiceNumber
      }
      nextInvoice {
        total
        dueDate
      }
      billingAddress {
        postCode
        line2
        line1
        county
        country
        city
      }
      shipping {
        city
        country
        line1
        county
        line2
        postCode
        name
        phone
      }
    }
  }
`;

/**
 * __useViewDistributorBillingDetailsQuery__
 *
 * To run a query within a React component, call `useViewDistributorBillingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDistributorBillingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDistributorBillingDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDistributorBillingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDistributorBillingDetailsQuery,
    ViewDistributorBillingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDistributorBillingDetailsQuery,
    ViewDistributorBillingDetailsQueryVariables
  >(ViewDistributorBillingDetailsDocument, options);
}
export function useViewDistributorBillingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDistributorBillingDetailsQuery,
    ViewDistributorBillingDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDistributorBillingDetailsQuery,
    ViewDistributorBillingDetailsQueryVariables
  >(ViewDistributorBillingDetailsDocument, options);
}
export type ViewDistributorBillingDetailsQueryHookResult = ReturnType<
  typeof useViewDistributorBillingDetailsQuery
>;
export type ViewDistributorBillingDetailsLazyQueryHookResult = ReturnType<
  typeof useViewDistributorBillingDetailsLazyQuery
>;
export type ViewDistributorBillingDetailsQueryResult = Apollo.QueryResult<
  ViewDistributorBillingDetailsQuery,
  ViewDistributorBillingDetailsQueryVariables
>;
export const ViewDistributorInvoicesDocument = gql`
  query ViewDistributorInvoices($where: DistributorWhereUniqueInput!) {
    distributor(where: $where) {
      id
      name
      stripeId
      invoices {
        created
        total
        status
        invoiceNumber
        pdf
        dueDate
        items {
          tax
          description
          total
          quantity
        }
      }
      nextInvoice {
        total
        dueDate
      }
      billingAddress {
        city
        county
        country
        postCode
        line1
        line2
      }
      shipping {
        city
        county
        country
        postCode
        line1
        line2
      }
    }
  }
`;

/**
 * __useViewDistributorInvoicesQuery__
 *
 * To run a query within a React component, call `useViewDistributorInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDistributorInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDistributorInvoicesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDistributorInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDistributorInvoicesQuery,
    ViewDistributorInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDistributorInvoicesQuery,
    ViewDistributorInvoicesQueryVariables
  >(ViewDistributorInvoicesDocument, options);
}
export function useViewDistributorInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDistributorInvoicesQuery,
    ViewDistributorInvoicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDistributorInvoicesQuery,
    ViewDistributorInvoicesQueryVariables
  >(ViewDistributorInvoicesDocument, options);
}
export type ViewDistributorInvoicesQueryHookResult = ReturnType<
  typeof useViewDistributorInvoicesQuery
>;
export type ViewDistributorInvoicesLazyQueryHookResult = ReturnType<
  typeof useViewDistributorInvoicesLazyQuery
>;
export type ViewDistributorInvoicesQueryResult = Apollo.QueryResult<
  ViewDistributorInvoicesQuery,
  ViewDistributorInvoicesQueryVariables
>;
export const ViewDistributorPaymentsDocument = gql`
  query ViewDistributorPayments($where: DistributorWhereUniqueInput!) {
    distributor(where: $where) {
      id
      name
      billingAddress {
        city
        county
        country
        postCode
        line1
        line2
      }
      payments {
        date
        invoiceNumber
        paid
        status
        total
        totalCollected
        type
      }
    }
  }
`;

/**
 * __useViewDistributorPaymentsQuery__
 *
 * To run a query within a React component, call `useViewDistributorPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDistributorPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDistributorPaymentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDistributorPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDistributorPaymentsQuery,
    ViewDistributorPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDistributorPaymentsQuery,
    ViewDistributorPaymentsQueryVariables
  >(ViewDistributorPaymentsDocument, options);
}
export function useViewDistributorPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDistributorPaymentsQuery,
    ViewDistributorPaymentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDistributorPaymentsQuery,
    ViewDistributorPaymentsQueryVariables
  >(ViewDistributorPaymentsDocument, options);
}
export type ViewDistributorPaymentsQueryHookResult = ReturnType<
  typeof useViewDistributorPaymentsQuery
>;
export type ViewDistributorPaymentsLazyQueryHookResult = ReturnType<
  typeof useViewDistributorPaymentsLazyQuery
>;
export type ViewDistributorPaymentsQueryResult = Apollo.QueryResult<
  ViewDistributorPaymentsQuery,
  ViewDistributorPaymentsQueryVariables
>;
export const DistributorUsersDocument = gql`
  query DistributorUsers($where: DistributorWhereUniqueInput!) {
    distributor(where: $where) {
      dealers {
        id
        name
        customers {
          id
          name
          deviceUsers {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useDistributorUsersQuery__
 *
 * To run a query within a React component, call `useDistributorUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDistributorUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    DistributorUsersQuery,
    DistributorUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DistributorUsersQuery, DistributorUsersQueryVariables>(
    DistributorUsersDocument,
    options
  );
}
export function useDistributorUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorUsersQuery,
    DistributorUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributorUsersQuery,
    DistributorUsersQueryVariables
  >(DistributorUsersDocument, options);
}
export type DistributorUsersQueryHookResult = ReturnType<
  typeof useDistributorUsersQuery
>;
export type DistributorUsersLazyQueryHookResult = ReturnType<
  typeof useDistributorUsersLazyQuery
>;
export type DistributorUsersQueryResult = Apollo.QueryResult<
  DistributorUsersQuery,
  DistributorUsersQueryVariables
>;
export const DistributorsDocument = gql`
  query Distributors {
    distributors {
      id
      name
    }
  }
`;

/**
 * __useDistributorsQuery__
 *
 * To run a query within a React component, call `useDistributorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDistributorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DistributorsQuery,
    DistributorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DistributorsQuery, DistributorsQueryVariables>(
    DistributorsDocument,
    options
  );
}
export function useDistributorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorsQuery,
    DistributorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DistributorsQuery, DistributorsQueryVariables>(
    DistributorsDocument,
    options
  );
}
export type DistributorsQueryHookResult = ReturnType<
  typeof useDistributorsQuery
>;
export type DistributorsLazyQueryHookResult = ReturnType<
  typeof useDistributorsLazyQuery
>;
export type DistributorsQueryResult = Apollo.QueryResult<
  DistributorsQuery,
  DistributorsQueryVariables
>;
export const ViewDistributorCustomersDocument = gql`
  query ViewDistributorCustomers($where: DistributorWhereUniqueInput!) {
    distributor(where: $where) {
      id
      customers {
        id
        name
        totalDeviceUsers
      }
    }
  }
`;

/**
 * __useViewDistributorCustomersQuery__
 *
 * To run a query within a React component, call `useViewDistributorCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDistributorCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDistributorCustomersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDistributorCustomersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDistributorCustomersQuery,
    ViewDistributorCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDistributorCustomersQuery,
    ViewDistributorCustomersQueryVariables
  >(ViewDistributorCustomersDocument, options);
}
export function useViewDistributorCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDistributorCustomersQuery,
    ViewDistributorCustomersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDistributorCustomersQuery,
    ViewDistributorCustomersQueryVariables
  >(ViewDistributorCustomersDocument, options);
}
export type ViewDistributorCustomersQueryHookResult = ReturnType<
  typeof useViewDistributorCustomersQuery
>;
export type ViewDistributorCustomersLazyQueryHookResult = ReturnType<
  typeof useViewDistributorCustomersLazyQuery
>;
export type ViewDistributorCustomersQueryResult = Apollo.QueryResult<
  ViewDistributorCustomersQuery,
  ViewDistributorCustomersQueryVariables
>;
export const ViewDistributorDealersDocument = gql`
  query ViewDistributorDealers($where: DistributorWhereUniqueInput!) {
    distributor(where: $where) {
      id
      dealers {
        id
        name
        totalDeviceUsers
        totalCustomers
      }
    }
  }
`;

/**
 * __useViewDistributorDealersQuery__
 *
 * To run a query within a React component, call `useViewDistributorDealersQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDistributorDealersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDistributorDealersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDistributorDealersQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDistributorDealersQuery,
    ViewDistributorDealersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDistributorDealersQuery,
    ViewDistributorDealersQueryVariables
  >(ViewDistributorDealersDocument, options);
}
export function useViewDistributorDealersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDistributorDealersQuery,
    ViewDistributorDealersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDistributorDealersQuery,
    ViewDistributorDealersQueryVariables
  >(ViewDistributorDealersDocument, options);
}
export type ViewDistributorDealersQueryHookResult = ReturnType<
  typeof useViewDistributorDealersQuery
>;
export type ViewDistributorDealersLazyQueryHookResult = ReturnType<
  typeof useViewDistributorDealersLazyQuery
>;
export type ViewDistributorDealersQueryResult = Apollo.QueryResult<
  ViewDistributorDealersQuery,
  ViewDistributorDealersQueryVariables
>;
export const ViewDemoDeviceUsersDistDocument = gql`
  query ViewDemoDeviceUsersDist($where: DistributorWhereUniqueInput!) {
    distributor(where: $where) {
      id
      totalDemoDeviceUsers
      totalDemoDispatchUsers
    }
  }
`;

/**
 * __useViewDemoDeviceUsersDistQuery__
 *
 * To run a query within a React component, call `useViewDemoDeviceUsersDistQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDemoDeviceUsersDistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDemoDeviceUsersDistQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDemoDeviceUsersDistQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDemoDeviceUsersDistQuery,
    ViewDemoDeviceUsersDistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDemoDeviceUsersDistQuery,
    ViewDemoDeviceUsersDistQueryVariables
  >(ViewDemoDeviceUsersDistDocument, options);
}
export function useViewDemoDeviceUsersDistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDemoDeviceUsersDistQuery,
    ViewDemoDeviceUsersDistQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDemoDeviceUsersDistQuery,
    ViewDemoDeviceUsersDistQueryVariables
  >(ViewDemoDeviceUsersDistDocument, options);
}
export type ViewDemoDeviceUsersDistQueryHookResult = ReturnType<
  typeof useViewDemoDeviceUsersDistQuery
>;
export type ViewDemoDeviceUsersDistLazyQueryHookResult = ReturnType<
  typeof useViewDemoDeviceUsersDistLazyQuery
>;
export type ViewDemoDeviceUsersDistQueryResult = Apollo.QueryResult<
  ViewDemoDeviceUsersDistQuery,
  ViewDemoDeviceUsersDistQueryVariables
>;
export const ViewDistributorDetailsDocument = gql`
  query ViewDistributorDetails($where: DistributorWhereUniqueInput!) {
    distributor(where: $where) {
      id
      name
      contact {
        name
        phone
        email
        id
      }
      billingAddress {
        postCode
        line2
        line1
        county
        country
        city
      }
      shipping {
        city
        county
        country
        postCode
        line1
        line2
        name
        phone
      }
      addresses {
        city
        county
        country
        postcode
        street1
        street2
        type
        id
      }
    }
  }
`;

/**
 * __useViewDistributorDetailsQuery__
 *
 * To run a query within a React component, call `useViewDistributorDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDistributorDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDistributorDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDistributorDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDistributorDetailsQuery,
    ViewDistributorDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDistributorDetailsQuery,
    ViewDistributorDetailsQueryVariables
  >(ViewDistributorDetailsDocument, options);
}
export function useViewDistributorDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDistributorDetailsQuery,
    ViewDistributorDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDistributorDetailsQuery,
    ViewDistributorDetailsQueryVariables
  >(ViewDistributorDetailsDocument, options);
}
export type ViewDistributorDetailsQueryHookResult = ReturnType<
  typeof useViewDistributorDetailsQuery
>;
export type ViewDistributorDetailsLazyQueryHookResult = ReturnType<
  typeof useViewDistributorDetailsLazyQuery
>;
export type ViewDistributorDetailsQueryResult = Apollo.QueryResult<
  ViewDistributorDetailsQuery,
  ViewDistributorDetailsQueryVariables
>;
export const ViewDistributorOverviewDocument = gql`
  query ViewDistributorOverview($where: DistributorWhereUniqueInput!) {
    distributor(where: $where) {
      id
      name
    }
  }
`;

/**
 * __useViewDistributorOverviewQuery__
 *
 * To run a query within a React component, call `useViewDistributorOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDistributorOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDistributorOverviewQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDistributorOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDistributorOverviewQuery,
    ViewDistributorOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDistributorOverviewQuery,
    ViewDistributorOverviewQueryVariables
  >(ViewDistributorOverviewDocument, options);
}
export function useViewDistributorOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDistributorOverviewQuery,
    ViewDistributorOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDistributorOverviewQuery,
    ViewDistributorOverviewQueryVariables
  >(ViewDistributorOverviewDocument, options);
}
export type ViewDistributorOverviewQueryHookResult = ReturnType<
  typeof useViewDistributorOverviewQuery
>;
export type ViewDistributorOverviewLazyQueryHookResult = ReturnType<
  typeof useViewDistributorOverviewLazyQuery
>;
export type ViewDistributorOverviewQueryResult = Apollo.QueryResult<
  ViewDistributorOverviewQuery,
  ViewDistributorOverviewQueryVariables
>;
export const ViewDistributorPaymentMethodsDocument = gql`
  query ViewDistributorPaymentMethods(
    $where: PaymentMethodWhereCustomerIdAndPaymentType!
  ) {
    stripePaymentMethods(where: $where) {
      id
      type
      default
      details {
        last4
        expMonth
        brand
        expYear
        sortCode
      }
    }
  }
`;

/**
 * __useViewDistributorPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useViewDistributorPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDistributorPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDistributorPaymentMethodsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useViewDistributorPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ViewDistributorPaymentMethodsQuery,
    ViewDistributorPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ViewDistributorPaymentMethodsQuery,
    ViewDistributorPaymentMethodsQueryVariables
  >(ViewDistributorPaymentMethodsDocument, options);
}
export function useViewDistributorPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ViewDistributorPaymentMethodsQuery,
    ViewDistributorPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ViewDistributorPaymentMethodsQuery,
    ViewDistributorPaymentMethodsQueryVariables
  >(ViewDistributorPaymentMethodsDocument, options);
}
export type ViewDistributorPaymentMethodsQueryHookResult = ReturnType<
  typeof useViewDistributorPaymentMethodsQuery
>;
export type ViewDistributorPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useViewDistributorPaymentMethodsLazyQuery
>;
export type ViewDistributorPaymentMethodsQueryResult = Apollo.QueryResult<
  ViewDistributorPaymentMethodsQuery,
  ViewDistributorPaymentMethodsQueryVariables
>;
export const CreateDownloadDocument = gql`
  mutation CreateDownload(
    $data: DownloadCreateInput!
    $downloadNameWhere: TranslationContentWhere!
    $downloadDescriptionWhere: TranslationContentWhere!
  ) {
    createOneDownload(data: $data) {
      id
      name(where: $downloadNameWhere)
      description(where: $downloadDescriptionWhere)
      url
      type
      modelList
      models {
        id
        name
      }
    }
  }
`;
export type CreateDownloadMutationFn = Apollo.MutationFunction<
  CreateDownloadMutation,
  CreateDownloadMutationVariables
>;

/**
 * __useCreateDownloadMutation__
 *
 * To run a mutation, you first call `useCreateDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDownloadMutation, { data, loading, error }] = useCreateDownloadMutation({
 *   variables: {
 *      data: // value for 'data'
 *      downloadNameWhere: // value for 'downloadNameWhere'
 *      downloadDescriptionWhere: // value for 'downloadDescriptionWhere'
 *   },
 * });
 */
export function useCreateDownloadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDownloadMutation,
    CreateDownloadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDownloadMutation,
    CreateDownloadMutationVariables
  >(CreateDownloadDocument, options);
}
export type CreateDownloadMutationHookResult = ReturnType<
  typeof useCreateDownloadMutation
>;
export type CreateDownloadMutationResult =
  Apollo.MutationResult<CreateDownloadMutation>;
export type CreateDownloadMutationOptions = Apollo.BaseMutationOptions<
  CreateDownloadMutation,
  CreateDownloadMutationVariables
>;
export const UpdateDownloadDocument = gql`
  mutation UpdateDownload(
    $data: DownloadUpdateInput!
    $where: DownloadWhereUniqueInput!
    $downloadNameWhere: TranslationContentWhere!
    $downloadDescWhere: TranslationContentWhere!
  ) {
    updateOneDownload(data: $data, where: $where) {
      id
      models {
        id
        name
      }
      modelList
      name(where: $downloadNameWhere)
      description(where: $downloadDescWhere)
      type
      url
    }
  }
`;
export type UpdateDownloadMutationFn = Apollo.MutationFunction<
  UpdateDownloadMutation,
  UpdateDownloadMutationVariables
>;

/**
 * __useUpdateDownloadMutation__
 *
 * To run a mutation, you first call `useUpdateDownloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDownloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDownloadMutation, { data, loading, error }] = useUpdateDownloadMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *      downloadNameWhere: // value for 'downloadNameWhere'
 *      downloadDescWhere: // value for 'downloadDescWhere'
 *   },
 * });
 */
export function useUpdateDownloadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDownloadMutation,
    UpdateDownloadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDownloadMutation,
    UpdateDownloadMutationVariables
  >(UpdateDownloadDocument, options);
}
export type UpdateDownloadMutationHookResult = ReturnType<
  typeof useUpdateDownloadMutation
>;
export type UpdateDownloadMutationResult =
  Apollo.MutationResult<UpdateDownloadMutation>;
export type UpdateDownloadMutationOptions = Apollo.BaseMutationOptions<
  UpdateDownloadMutation,
  UpdateDownloadMutationVariables
>;
export const DownloadDocument = gql`
  query Download(
    $where: DownloadWhereUniqueInput!
    $downloadNameWhere: TranslationContentWhere!
    $downloadDescriptionWhere: TranslationContentWhere!
  ) {
    download(where: $where) {
      name(where: $downloadNameWhere)
      modelList
      id
      type
      description(where: $downloadDescriptionWhere)
      url
      models {
        id
        name
      }
    }
  }
`;

/**
 * __useDownloadQuery__
 *
 * To run a query within a React component, call `useDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadQuery({
 *   variables: {
 *      where: // value for 'where'
 *      downloadNameWhere: // value for 'downloadNameWhere'
 *      downloadDescriptionWhere: // value for 'downloadDescriptionWhere'
 *   },
 * });
 */
export function useDownloadQuery(
  baseOptions: Apollo.QueryHookOptions<DownloadQuery, DownloadQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadQuery, DownloadQueryVariables>(
    DownloadDocument,
    options
  );
}
export function useDownloadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadQuery,
    DownloadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadQuery, DownloadQueryVariables>(
    DownloadDocument,
    options
  );
}
export type DownloadQueryHookResult = ReturnType<typeof useDownloadQuery>;
export type DownloadLazyQueryHookResult = ReturnType<
  typeof useDownloadLazyQuery
>;
export type DownloadQueryResult = Apollo.QueryResult<
  DownloadQuery,
  DownloadQueryVariables
>;
export const DownloadsDocument = gql`
  query Downloads(
    $downloadNameWhere: TranslationContentWhere!
    $downloadDescriptionWhere: TranslationContentWhere!
  ) {
    downloads {
      id
      description(where: $downloadDescriptionWhere)
      modelList
      name(where: $downloadNameWhere)
      type
      url
    }
  }
`;

/**
 * __useDownloadsQuery__
 *
 * To run a query within a React component, call `useDownloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadsQuery({
 *   variables: {
 *      downloadNameWhere: // value for 'downloadNameWhere'
 *      downloadDescriptionWhere: // value for 'downloadDescriptionWhere'
 *   },
 * });
 */
export function useDownloadsQuery(
  baseOptions: Apollo.QueryHookOptions<DownloadsQuery, DownloadsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DownloadsQuery, DownloadsQueryVariables>(
    DownloadsDocument,
    options
  );
}
export function useDownloadsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DownloadsQuery,
    DownloadsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DownloadsQuery, DownloadsQueryVariables>(
    DownloadsDocument,
    options
  );
}
export type DownloadsQueryHookResult = ReturnType<typeof useDownloadsQuery>;
export type DownloadsLazyQueryHookResult = ReturnType<
  typeof useDownloadsLazyQuery
>;
export type DownloadsQueryResult = Apollo.QueryResult<
  DownloadsQuery,
  DownloadsQueryVariables
>;
export const CreateManualChapterDocument = gql`
  mutation CreateManualChapter(
    $data: ManualChapterCreateInput!
    $titleWhere: TranslationContentWhere!
  ) {
    createOneManualChapter(data: $data) {
      id
      title(where: $titleWhere)
    }
  }
`;
export type CreateManualChapterMutationFn = Apollo.MutationFunction<
  CreateManualChapterMutation,
  CreateManualChapterMutationVariables
>;

/**
 * __useCreateManualChapterMutation__
 *
 * To run a mutation, you first call `useCreateManualChapterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualChapterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualChapterMutation, { data, loading, error }] = useCreateManualChapterMutation({
 *   variables: {
 *      data: // value for 'data'
 *      titleWhere: // value for 'titleWhere'
 *   },
 * });
 */
export function useCreateManualChapterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManualChapterMutation,
    CreateManualChapterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateManualChapterMutation,
    CreateManualChapterMutationVariables
  >(CreateManualChapterDocument, options);
}
export type CreateManualChapterMutationHookResult = ReturnType<
  typeof useCreateManualChapterMutation
>;
export type CreateManualChapterMutationResult =
  Apollo.MutationResult<CreateManualChapterMutation>;
export type CreateManualChapterMutationOptions = Apollo.BaseMutationOptions<
  CreateManualChapterMutation,
  CreateManualChapterMutationVariables
>;
export const CreateOneManualDocument = gql`
  mutation CreateOneManual(
    $data: ManualCreateInput!
    $manualTitleWhere: TranslationContentWhere!
    $manualDescriptionWhere: TranslationContentWhere!
  ) {
    createOneManual(data: $data) {
      id
      title(where: $manualTitleWhere)
      description(where: $manualDescriptionWhere)
      totalPages
    }
  }
`;
export type CreateOneManualMutationFn = Apollo.MutationFunction<
  CreateOneManualMutation,
  CreateOneManualMutationVariables
>;

/**
 * __useCreateOneManualMutation__
 *
 * To run a mutation, you first call `useCreateOneManualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneManualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneManualMutation, { data, loading, error }] = useCreateOneManualMutation({
 *   variables: {
 *      data: // value for 'data'
 *      manualTitleWhere: // value for 'manualTitleWhere'
 *      manualDescriptionWhere: // value for 'manualDescriptionWhere'
 *   },
 * });
 */
export function useCreateOneManualMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneManualMutation,
    CreateOneManualMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneManualMutation,
    CreateOneManualMutationVariables
  >(CreateOneManualDocument, options);
}
export type CreateOneManualMutationHookResult = ReturnType<
  typeof useCreateOneManualMutation
>;
export type CreateOneManualMutationResult =
  Apollo.MutationResult<CreateOneManualMutation>;
export type CreateOneManualMutationOptions = Apollo.BaseMutationOptions<
  CreateOneManualMutation,
  CreateOneManualMutationVariables
>;
export const CreateManualPageDocument = gql`
  mutation CreateManualPage(
    $data: ManualPageCreateInput!
    $titleWhere: TranslationContentWhere!
  ) {
    createOneManualPage(data: $data) {
      id
      title(where: $titleWhere)
    }
  }
`;
export type CreateManualPageMutationFn = Apollo.MutationFunction<
  CreateManualPageMutation,
  CreateManualPageMutationVariables
>;

/**
 * __useCreateManualPageMutation__
 *
 * To run a mutation, you first call `useCreateManualPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateManualPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createManualPageMutation, { data, loading, error }] = useCreateManualPageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      titleWhere: // value for 'titleWhere'
 *   },
 * });
 */
export function useCreateManualPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateManualPageMutation,
    CreateManualPageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateManualPageMutation,
    CreateManualPageMutationVariables
  >(CreateManualPageDocument, options);
}
export type CreateManualPageMutationHookResult = ReturnType<
  typeof useCreateManualPageMutation
>;
export type CreateManualPageMutationResult =
  Apollo.MutationResult<CreateManualPageMutation>;
export type CreateManualPageMutationOptions = Apollo.BaseMutationOptions<
  CreateManualPageMutation,
  CreateManualPageMutationVariables
>;
export const DeleteManualPageDocument = gql`
  mutation DeleteManualPage(
    $data: ManualUpdateInput!
    $where: ManualWhereUniqueInput!
  ) {
    updateOneManual(data: $data, where: $where) {
      id
      chapters {
        id
        pages {
          id
        }
      }
    }
  }
`;
export type DeleteManualPageMutationFn = Apollo.MutationFunction<
  DeleteManualPageMutation,
  DeleteManualPageMutationVariables
>;

/**
 * __useDeleteManualPageMutation__
 *
 * To run a mutation, you first call `useDeleteManualPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManualPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteManualPageMutation, { data, loading, error }] = useDeleteManualPageMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteManualPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteManualPageMutation,
    DeleteManualPageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteManualPageMutation,
    DeleteManualPageMutationVariables
  >(DeleteManualPageDocument, options);
}
export type DeleteManualPageMutationHookResult = ReturnType<
  typeof useDeleteManualPageMutation
>;
export type DeleteManualPageMutationResult =
  Apollo.MutationResult<DeleteManualPageMutation>;
export type DeleteManualPageMutationOptions = Apollo.BaseMutationOptions<
  DeleteManualPageMutation,
  DeleteManualPageMutationVariables
>;
export const UpdateManualDocument = gql`
  mutation UpdateManual(
    $data: ManualUpdateInput!
    $where: ManualWhereUniqueInput!
    $contentWhere: TranslationContentWhere!
    $manualTitleWhere: TranslationContentWhere!
    $manualDescriptionWhere: TranslationContentWhere!
    $chapterTitleWhere: TranslationContentWhere!
    $pageTitleWhere: TranslationContentWhere!
  ) {
    updateOneManual(data: $data, where: $where) {
      id
      title(where: $manualTitleWhere)
      description(where: $manualDescriptionWhere)
      chapters {
        id
        title(where: $chapterTitleWhere)
        order
        pages {
          id
          title(where: $pageTitleWhere)
          content(where: $contentWhere)
          chapterId
          order
        }
      }
    }
  }
`;
export type UpdateManualMutationFn = Apollo.MutationFunction<
  UpdateManualMutation,
  UpdateManualMutationVariables
>;

/**
 * __useUpdateManualMutation__
 *
 * To run a mutation, you first call `useUpdateManualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManualMutation, { data, loading, error }] = useUpdateManualMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *      contentWhere: // value for 'contentWhere'
 *      manualTitleWhere: // value for 'manualTitleWhere'
 *      manualDescriptionWhere: // value for 'manualDescriptionWhere'
 *      chapterTitleWhere: // value for 'chapterTitleWhere'
 *      pageTitleWhere: // value for 'pageTitleWhere'
 *   },
 * });
 */
export function useUpdateManualMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManualMutation,
    UpdateManualMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateManualMutation,
    UpdateManualMutationVariables
  >(UpdateManualDocument, options);
}
export type UpdateManualMutationHookResult = ReturnType<
  typeof useUpdateManualMutation
>;
export type UpdateManualMutationResult =
  Apollo.MutationResult<UpdateManualMutation>;
export type UpdateManualMutationOptions = Apollo.BaseMutationOptions<
  UpdateManualMutation,
  UpdateManualMutationVariables
>;
export const UpdateManualPageDocument = gql`
  mutation UpdateManualPage(
    $where: UpdateOneManualPageWhereInput!
    $data: ManualPageUpdateWithoutManualInput!
    $titleWhere: TranslationContentWhere!
    $contentWhere: TranslationContentWhere!
  ) {
    updateOneManualPage(where: $where, data: $data) {
      id
      title(where: $titleWhere)
      content(where: $contentWhere)
    }
  }
`;
export type UpdateManualPageMutationFn = Apollo.MutationFunction<
  UpdateManualPageMutation,
  UpdateManualPageMutationVariables
>;

/**
 * __useUpdateManualPageMutation__
 *
 * To run a mutation, you first call `useUpdateManualPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManualPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManualPageMutation, { data, loading, error }] = useUpdateManualPageMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *      titleWhere: // value for 'titleWhere'
 *      contentWhere: // value for 'contentWhere'
 *   },
 * });
 */
export function useUpdateManualPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateManualPageMutation,
    UpdateManualPageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateManualPageMutation,
    UpdateManualPageMutationVariables
  >(UpdateManualPageDocument, options);
}
export type UpdateManualPageMutationHookResult = ReturnType<
  typeof useUpdateManualPageMutation
>;
export type UpdateManualPageMutationResult =
  Apollo.MutationResult<UpdateManualPageMutation>;
export type UpdateManualPageMutationOptions = Apollo.BaseMutationOptions<
  UpdateManualPageMutation,
  UpdateManualPageMutationVariables
>;
export const ManualsDocument = gql`
  query Manuals(
    $manualDescriptionWhere: TranslationContentWhere!
    $manualTitleWhere: TranslationContentWhere!
  ) {
    manuals {
      id
      title(where: $manualTitleWhere)
      description(where: $manualDescriptionWhere)
      totalPages
    }
  }
`;

/**
 * __useManualsQuery__
 *
 * To run a query within a React component, call `useManualsQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualsQuery({
 *   variables: {
 *      manualDescriptionWhere: // value for 'manualDescriptionWhere'
 *      manualTitleWhere: // value for 'manualTitleWhere'
 *   },
 * });
 */
export function useManualsQuery(
  baseOptions: Apollo.QueryHookOptions<ManualsQuery, ManualsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManualsQuery, ManualsQueryVariables>(
    ManualsDocument,
    options
  );
}
export function useManualsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManualsQuery, ManualsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManualsQuery, ManualsQueryVariables>(
    ManualsDocument,
    options
  );
}
export type ManualsQueryHookResult = ReturnType<typeof useManualsQuery>;
export type ManualsLazyQueryHookResult = ReturnType<typeof useManualsLazyQuery>;
export type ManualsQueryResult = Apollo.QueryResult<
  ManualsQuery,
  ManualsQueryVariables
>;
export const ManualDocument = gql`
  query Manual(
    $where: ManualWhereUniqueInput!
    $manualDescriptionWhere: TranslationContentWhere!
    $manualTitleWhere: TranslationContentWhere!
    $chapterTitleWhere: TranslationContentWhere!
    $pageTitleWhere: TranslationContentWhere!
    $pageContentWhere: TranslationContentWhere!
  ) {
    manual(where: $where) {
      id
      title(where: $manualTitleWhere)
      description(where: $manualDescriptionWhere)
      chapters {
        id
        title(where: $chapterTitleWhere)
        order
        pages {
          id
          title(where: $pageTitleWhere)
          content(where: $pageContentWhere)
          chapterId
          order
        }
      }
    }
  }
`;

/**
 * __useManualQuery__
 *
 * To run a query within a React component, call `useManualQuery` and pass it any options that fit your needs.
 * When your component renders, `useManualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManualQuery({
 *   variables: {
 *      where: // value for 'where'
 *      manualDescriptionWhere: // value for 'manualDescriptionWhere'
 *      manualTitleWhere: // value for 'manualTitleWhere'
 *      chapterTitleWhere: // value for 'chapterTitleWhere'
 *      pageTitleWhere: // value for 'pageTitleWhere'
 *      pageContentWhere: // value for 'pageContentWhere'
 *   },
 * });
 */
export function useManualQuery(
  baseOptions: Apollo.QueryHookOptions<ManualQuery, ManualQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ManualQuery, ManualQueryVariables>(
    ManualDocument,
    options
  );
}
export function useManualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ManualQuery, ManualQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ManualQuery, ManualQueryVariables>(
    ManualDocument,
    options
  );
}
export type ManualQueryHookResult = ReturnType<typeof useManualQuery>;
export type ManualLazyQueryHookResult = ReturnType<typeof useManualLazyQuery>;
export type ManualQueryResult = Apollo.QueryResult<
  ManualQuery,
  ManualQueryVariables
>;
export const CompleteOnboardingDistributorDocument = gql`
  mutation CompleteOnboardingDistributor(
    $data: DistributorUpdateInput!
    $where: DistributorWhereUniqueInput!
  ) {
    updateOneDistributor(data: $data, where: $where) {
      id
      termAgreement {
        id
        expired
      }
    }
  }
`;
export type CompleteOnboardingDistributorMutationFn = Apollo.MutationFunction<
  CompleteOnboardingDistributorMutation,
  CompleteOnboardingDistributorMutationVariables
>;

/**
 * __useCompleteOnboardingDistributorMutation__
 *
 * To run a mutation, you first call `useCompleteOnboardingDistributorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnboardingDistributorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnboardingDistributorMutation, { data, loading, error }] = useCompleteOnboardingDistributorMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompleteOnboardingDistributorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteOnboardingDistributorMutation,
    CompleteOnboardingDistributorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteOnboardingDistributorMutation,
    CompleteOnboardingDistributorMutationVariables
  >(CompleteOnboardingDistributorDocument, options);
}
export type CompleteOnboardingDistributorMutationHookResult = ReturnType<
  typeof useCompleteOnboardingDistributorMutation
>;
export type CompleteOnboardingDistributorMutationResult =
  Apollo.MutationResult<CompleteOnboardingDistributorMutation>;
export type CompleteOnboardingDistributorMutationOptions =
  Apollo.BaseMutationOptions<
    CompleteOnboardingDistributorMutation,
    CompleteOnboardingDistributorMutationVariables
  >;
export const CompleteOnboardingDocument = gql`
  mutation CompleteOnboarding(
    $data: DealerUpdateInput!
    $where: DealerWhereUniqueInput!
  ) {
    updateOneDealer(data: $data, where: $where) {
      id
      termAgreement {
        id
        expired
      }
    }
  }
`;
export type CompleteOnboardingMutationFn = Apollo.MutationFunction<
  CompleteOnboardingMutation,
  CompleteOnboardingMutationVariables
>;

/**
 * __useCompleteOnboardingMutation__
 *
 * To run a mutation, you first call `useCompleteOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnboardingMutation, { data, loading, error }] = useCompleteOnboardingMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useCompleteOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteOnboardingMutation,
    CompleteOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteOnboardingMutation,
    CompleteOnboardingMutationVariables
  >(CompleteOnboardingDocument, options);
}
export type CompleteOnboardingMutationHookResult = ReturnType<
  typeof useCompleteOnboardingMutation
>;
export type CompleteOnboardingMutationResult =
  Apollo.MutationResult<CompleteOnboardingMutation>;
export type CompleteOnboardingMutationOptions = Apollo.BaseMutationOptions<
  CompleteOnboardingMutation,
  CompleteOnboardingMutationVariables
>;
export const DealerDetailsOnboardingDocument = gql`
  mutation DealerDetailsOnboarding(
    $where: DealerWhereUniqueInput!
    $data: DealerDetailsOnboardingInput!
  ) {
    dealerDetailsOnboarding(where: $where, data: $data) {
      id
      name
      currency
      onboarding
      billingAddress {
        line1
        line2
        city
        county
        country
        postCode
      }
      contact {
        email
        name
        phone
        id
      }
    }
  }
`;
export type DealerDetailsOnboardingMutationFn = Apollo.MutationFunction<
  DealerDetailsOnboardingMutation,
  DealerDetailsOnboardingMutationVariables
>;

/**
 * __useDealerDetailsOnboardingMutation__
 *
 * To run a mutation, you first call `useDealerDetailsOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDealerDetailsOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dealerDetailsOnboardingMutation, { data, loading, error }] = useDealerDetailsOnboardingMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDealerDetailsOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DealerDetailsOnboardingMutation,
    DealerDetailsOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DealerDetailsOnboardingMutation,
    DealerDetailsOnboardingMutationVariables
  >(DealerDetailsOnboardingDocument, options);
}
export type DealerDetailsOnboardingMutationHookResult = ReturnType<
  typeof useDealerDetailsOnboardingMutation
>;
export type DealerDetailsOnboardingMutationResult =
  Apollo.MutationResult<DealerDetailsOnboardingMutation>;
export type DealerDetailsOnboardingMutationOptions = Apollo.BaseMutationOptions<
  DealerDetailsOnboardingMutation,
  DealerDetailsOnboardingMutationVariables
>;
export const DistributorDetailsOnboardingDocument = gql`
  mutation DistributorDetailsOnboarding(
    $where: DistributorWhereUniqueInput!
    $data: DistributorDetailsOnboardingInput!
  ) {
    distributorDetailsOnboarding(where: $where, data: $data) {
      id
      name
      currency
      onboarding
      billingAddress {
        line1
        line2
        city
        county
        country
        postCode
      }
      contact {
        email
        name
        phone
        id
      }
      vatId {
        id
        value
      }
    }
  }
`;
export type DistributorDetailsOnboardingMutationFn = Apollo.MutationFunction<
  DistributorDetailsOnboardingMutation,
  DistributorDetailsOnboardingMutationVariables
>;

/**
 * __useDistributorDetailsOnboardingMutation__
 *
 * To run a mutation, you first call `useDistributorDetailsOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistributorDetailsOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [distributorDetailsOnboardingMutation, { data, loading, error }] = useDistributorDetailsOnboardingMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDistributorDetailsOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DistributorDetailsOnboardingMutation,
    DistributorDetailsOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DistributorDetailsOnboardingMutation,
    DistributorDetailsOnboardingMutationVariables
  >(DistributorDetailsOnboardingDocument, options);
}
export type DistributorDetailsOnboardingMutationHookResult = ReturnType<
  typeof useDistributorDetailsOnboardingMutation
>;
export type DistributorDetailsOnboardingMutationResult =
  Apollo.MutationResult<DistributorDetailsOnboardingMutation>;
export type DistributorDetailsOnboardingMutationOptions =
  Apollo.BaseMutationOptions<
    DistributorDetailsOnboardingMutation,
    DistributorDetailsOnboardingMutationVariables
  >;
export const SignDistributorTermsDocument = gql`
  mutation SignDistributorTerms(
    $where: DistributorWhereUniqueInput!
    $data: DistributorUpdateInput!
  ) {
    updateOneDistributor(where: $where, data: $data) {
      id
      onboarding
      termAgreement {
        id
        expired
      }
    }
  }
`;
export type SignDistributorTermsMutationFn = Apollo.MutationFunction<
  SignDistributorTermsMutation,
  SignDistributorTermsMutationVariables
>;

/**
 * __useSignDistributorTermsMutation__
 *
 * To run a mutation, you first call `useSignDistributorTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignDistributorTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signDistributorTermsMutation, { data, loading, error }] = useSignDistributorTermsMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSignDistributorTermsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignDistributorTermsMutation,
    SignDistributorTermsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignDistributorTermsMutation,
    SignDistributorTermsMutationVariables
  >(SignDistributorTermsDocument, options);
}
export type SignDistributorTermsMutationHookResult = ReturnType<
  typeof useSignDistributorTermsMutation
>;
export type SignDistributorTermsMutationResult =
  Apollo.MutationResult<SignDistributorTermsMutation>;
export type SignDistributorTermsMutationOptions = Apollo.BaseMutationOptions<
  SignDistributorTermsMutation,
  SignDistributorTermsMutationVariables
>;
export const DistributorBusinessDetailsOnboardingDocument = gql`
  query distributorBusinessDetailsOnboarding(
    $where: DistributorWhereUniqueInput!
  ) {
    distributor(where: $where) {
      id
      name
      currency
      billingAddress {
        line1
        line2
        city
        county
        country
        postCode
      }
      contact {
        email
        name
        phone
        id
      }
      vatId {
        id
        value
      }
    }
  }
`;

/**
 * __useDistributorBusinessDetailsOnboardingQuery__
 *
 * To run a query within a React component, call `useDistributorBusinessDetailsOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributorBusinessDetailsOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributorBusinessDetailsOnboardingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDistributorBusinessDetailsOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    DistributorBusinessDetailsOnboardingQuery,
    DistributorBusinessDetailsOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DistributorBusinessDetailsOnboardingQuery,
    DistributorBusinessDetailsOnboardingQueryVariables
  >(DistributorBusinessDetailsOnboardingDocument, options);
}
export function useDistributorBusinessDetailsOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DistributorBusinessDetailsOnboardingQuery,
    DistributorBusinessDetailsOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DistributorBusinessDetailsOnboardingQuery,
    DistributorBusinessDetailsOnboardingQueryVariables
  >(DistributorBusinessDetailsOnboardingDocument, options);
}
export type DistributorBusinessDetailsOnboardingQueryHookResult = ReturnType<
  typeof useDistributorBusinessDetailsOnboardingQuery
>;
export type DistributorBusinessDetailsOnboardingLazyQueryHookResult =
  ReturnType<typeof useDistributorBusinessDetailsOnboardingLazyQuery>;
export type DistributorBusinessDetailsOnboardingQueryResult =
  Apollo.QueryResult<
    DistributorBusinessDetailsOnboardingQuery,
    DistributorBusinessDetailsOnboardingQueryVariables
  >;
export const BusinessDetailsOnboardingDocument = gql`
  query businessDetailsOnboarding($where: DealerWhereUniqueInput!) {
    dealer(where: $where) {
      id
      name
      currency
      billingAddress {
        line1
        line2
        city
        county
        country
        postCode
      }
      contact {
        email
        name
        phone
        id
      }
      vatId {
        id
        value
      }
    }
  }
`;

/**
 * __useBusinessDetailsOnboardingQuery__
 *
 * To run a query within a React component, call `useBusinessDetailsOnboardingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessDetailsOnboardingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessDetailsOnboardingQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBusinessDetailsOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    BusinessDetailsOnboardingQuery,
    BusinessDetailsOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BusinessDetailsOnboardingQuery,
    BusinessDetailsOnboardingQueryVariables
  >(BusinessDetailsOnboardingDocument, options);
}
export function useBusinessDetailsOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BusinessDetailsOnboardingQuery,
    BusinessDetailsOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BusinessDetailsOnboardingQuery,
    BusinessDetailsOnboardingQueryVariables
  >(BusinessDetailsOnboardingDocument, options);
}
export type BusinessDetailsOnboardingQueryHookResult = ReturnType<
  typeof useBusinessDetailsOnboardingQuery
>;
export type BusinessDetailsOnboardingLazyQueryHookResult = ReturnType<
  typeof useBusinessDetailsOnboardingLazyQuery
>;
export type BusinessDetailsOnboardingQueryResult = Apollo.QueryResult<
  BusinessDetailsOnboardingQuery,
  BusinessDetailsOnboardingQueryVariables
>;
export const GetDealerPaymentMethodsDocument = gql`
  query GetDealerPaymentMethods($where: DealerWhereUniqueInput!) {
    dealer(where: $where) {
      id
      stripeId
      availablePaymentMethods
    }
  }
`;

/**
 * __useGetDealerPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetDealerPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDealerPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDealerPaymentMethodsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDealerPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDealerPaymentMethodsQuery,
    GetDealerPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDealerPaymentMethodsQuery,
    GetDealerPaymentMethodsQueryVariables
  >(GetDealerPaymentMethodsDocument, options);
}
export function useGetDealerPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDealerPaymentMethodsQuery,
    GetDealerPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDealerPaymentMethodsQuery,
    GetDealerPaymentMethodsQueryVariables
  >(GetDealerPaymentMethodsDocument, options);
}
export type GetDealerPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetDealerPaymentMethodsQuery
>;
export type GetDealerPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetDealerPaymentMethodsLazyQuery
>;
export type GetDealerPaymentMethodsQueryResult = Apollo.QueryResult<
  GetDealerPaymentMethodsQuery,
  GetDealerPaymentMethodsQueryVariables
>;
export const GetDistributorPaymentMethodsDocument = gql`
  query GetDistributorPaymentMethods($where: DistributorWhereUniqueInput!) {
    distributor(where: $where) {
      id
      stripeId
      availablePaymentMethods
    }
  }
`;

/**
 * __useGetDistributorPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetDistributorPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistributorPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistributorPaymentMethodsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetDistributorPaymentMethodsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDistributorPaymentMethodsQuery,
    GetDistributorPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDistributorPaymentMethodsQuery,
    GetDistributorPaymentMethodsQueryVariables
  >(GetDistributorPaymentMethodsDocument, options);
}
export function useGetDistributorPaymentMethodsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDistributorPaymentMethodsQuery,
    GetDistributorPaymentMethodsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDistributorPaymentMethodsQuery,
    GetDistributorPaymentMethodsQueryVariables
  >(GetDistributorPaymentMethodsDocument, options);
}
export type GetDistributorPaymentMethodsQueryHookResult = ReturnType<
  typeof useGetDistributorPaymentMethodsQuery
>;
export type GetDistributorPaymentMethodsLazyQueryHookResult = ReturnType<
  typeof useGetDistributorPaymentMethodsLazyQuery
>;
export type GetDistributorPaymentMethodsQueryResult = Apollo.QueryResult<
  GetDistributorPaymentMethodsQuery,
  GetDistributorPaymentMethodsQueryVariables
>;
export const UpdateStripeProductMetadataDocument = gql`
  mutation UpdateStripeProductMetadata(
    $where: UniqueId!
    $data: ProductUpdateInput!
  ) {
    updateStripeProductMetadata(where: $where, data: $data) {
      id
      metadata {
        fr_description
        de_description
        el_description
        en_description
      }
    }
  }
`;
export type UpdateStripeProductMetadataMutationFn = Apollo.MutationFunction<
  UpdateStripeProductMetadataMutation,
  UpdateStripeProductMetadataMutationVariables
>;

/**
 * __useUpdateStripeProductMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateStripeProductMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStripeProductMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStripeProductMetadataMutation, { data, loading, error }] = useUpdateStripeProductMetadataMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateStripeProductMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateStripeProductMetadataMutation,
    UpdateStripeProductMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateStripeProductMetadataMutation,
    UpdateStripeProductMetadataMutationVariables
  >(UpdateStripeProductMetadataDocument, options);
}
export type UpdateStripeProductMetadataMutationHookResult = ReturnType<
  typeof useUpdateStripeProductMetadataMutation
>;
export type UpdateStripeProductMetadataMutationResult =
  Apollo.MutationResult<UpdateStripeProductMetadataMutation>;
export type UpdateStripeProductMetadataMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateStripeProductMetadataMutation,
    UpdateStripeProductMetadataMutationVariables
  >;
export const OrderSimsDocument = gql`
  mutation OrderSims($data: OrderSimsInput!) {
    orderSims(data: $data)
  }
`;
export type OrderSimsMutationFn = Apollo.MutationFunction<
  OrderSimsMutation,
  OrderSimsMutationVariables
>;

/**
 * __useOrderSimsMutation__
 *
 * To run a mutation, you first call `useOrderSimsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderSimsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderSimsMutation, { data, loading, error }] = useOrderSimsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useOrderSimsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OrderSimsMutation,
    OrderSimsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderSimsMutation, OrderSimsMutationVariables>(
    OrderSimsDocument,
    options
  );
}
export type OrderSimsMutationHookResult = ReturnType<
  typeof useOrderSimsMutation
>;
export type OrderSimsMutationResult = Apollo.MutationResult<OrderSimsMutation>;
export type OrderSimsMutationOptions = Apollo.BaseMutationOptions<
  OrderSimsMutation,
  OrderSimsMutationVariables
>;
export const RegisterManySimCardsDocument = gql`
  mutation RegisterManySimCards($data: [RegisterSimInput]!) {
    registerManySimCards(data: $data) {
      id
      iccid
      groupCode
      groupChanged
      customerId
      canChangeGroup
      tariff
      warning
      status
      limit
      pastUsage {
        createdAt
        value
      }
      imei
      msidn
      usage {
        status
        allowance
        value
        text
      }
      customer {
        id
        name
      }
    }
  }
`;
export type RegisterManySimCardsMutationFn = Apollo.MutationFunction<
  RegisterManySimCardsMutation,
  RegisterManySimCardsMutationVariables
>;

/**
 * __useRegisterManySimCardsMutation__
 *
 * To run a mutation, you first call `useRegisterManySimCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterManySimCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerManySimCardsMutation, { data, loading, error }] = useRegisterManySimCardsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRegisterManySimCardsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterManySimCardsMutation,
    RegisterManySimCardsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterManySimCardsMutation,
    RegisterManySimCardsMutationVariables
  >(RegisterManySimCardsDocument, options);
}
export type RegisterManySimCardsMutationHookResult = ReturnType<
  typeof useRegisterManySimCardsMutation
>;
export type RegisterManySimCardsMutationResult =
  Apollo.MutationResult<RegisterManySimCardsMutation>;
export type RegisterManySimCardsMutationOptions = Apollo.BaseMutationOptions<
  RegisterManySimCardsMutation,
  RegisterManySimCardsMutationVariables
>;
export const EditManySimDocument = gql`
  mutation EditManySim($data: EditManySimInput!) {
    editManySim(data: $data) {
      id
      iccid
      canChangeGroup
      groupCode
    }
  }
`;
export type EditManySimMutationFn = Apollo.MutationFunction<
  EditManySimMutation,
  EditManySimMutationVariables
>;

/**
 * __useEditManySimMutation__
 *
 * To run a mutation, you first call `useEditManySimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditManySimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editManySimMutation, { data, loading, error }] = useEditManySimMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditManySimMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditManySimMutation,
    EditManySimMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditManySimMutation, EditManySimMutationVariables>(
    EditManySimDocument,
    options
  );
}
export type EditManySimMutationHookResult = ReturnType<
  typeof useEditManySimMutation
>;
export type EditManySimMutationResult =
  Apollo.MutationResult<EditManySimMutation>;
export type EditManySimMutationOptions = Apollo.BaseMutationOptions<
  EditManySimMutation,
  EditManySimMutationVariables
>;
export const ListChangeableDealerSimsDocument = gql`
  query ListChangeableDealerSims($take: Int, $skip: Int, $where: UniqueId) {
    listDealerSims(take: $take, skip: $skip, where: $where) {
      sims {
        id
        iccid
        canChangeGroup
      }
    }
  }
`;

/**
 * __useListChangeableDealerSimsQuery__
 *
 * To run a query within a React component, call `useListChangeableDealerSimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListChangeableDealerSimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListChangeableDealerSimsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListChangeableDealerSimsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListChangeableDealerSimsQuery,
    ListChangeableDealerSimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListChangeableDealerSimsQuery,
    ListChangeableDealerSimsQueryVariables
  >(ListChangeableDealerSimsDocument, options);
}
export function useListChangeableDealerSimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListChangeableDealerSimsQuery,
    ListChangeableDealerSimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListChangeableDealerSimsQuery,
    ListChangeableDealerSimsQueryVariables
  >(ListChangeableDealerSimsDocument, options);
}
export type ListChangeableDealerSimsQueryHookResult = ReturnType<
  typeof useListChangeableDealerSimsQuery
>;
export type ListChangeableDealerSimsLazyQueryHookResult = ReturnType<
  typeof useListChangeableDealerSimsLazyQuery
>;
export type ListChangeableDealerSimsQueryResult = Apollo.QueryResult<
  ListChangeableDealerSimsQuery,
  ListChangeableDealerSimsQueryVariables
>;
export const ListDealerSimsDocument = gql`
  query ListDealerSims($take: Int, $skip: Int, $where: UniqueId) {
    listDealerSims(take: $take, skip: $skip, where: $where) {
      sims {
        id
        iccid
        groupCode
        groupChanged
        customerId
        canChangeGroup
        tariff
        warning
        status
        limit
        pastUsageMonthly {
          value
          createdAt
          year
          month
        }
        pastUsage {
          month
          day
          createdAt
          value
        }
        imei
        msidn
        usage {
          status
          allowance
          value
          text
        }
        customer {
          id
          name
        }
      }
      total
    }
  }
`;

/**
 * __useListDealerSimsQuery__
 *
 * To run a query within a React component, call `useListDealerSimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDealerSimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDealerSimsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListDealerSimsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListDealerSimsQuery,
    ListDealerSimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListDealerSimsQuery, ListDealerSimsQueryVariables>(
    ListDealerSimsDocument,
    options
  );
}
export function useListDealerSimsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListDealerSimsQuery,
    ListDealerSimsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListDealerSimsQuery, ListDealerSimsQueryVariables>(
    ListDealerSimsDocument,
    options
  );
}
export type ListDealerSimsQueryHookResult = ReturnType<
  typeof useListDealerSimsQuery
>;
export type ListDealerSimsLazyQueryHookResult = ReturnType<
  typeof useListDealerSimsLazyQuery
>;
export type ListDealerSimsQueryResult = Apollo.QueryResult<
  ListDealerSimsQuery,
  ListDealerSimsQueryVariables
>;
export const SimCardDocument = gql`
  query SimCard($where: SimCardWhereUniqueInput!) {
    simCard(where: $where) {
      id
      iccid
      groupCode
      groupChanged
      customerId
      canChangeGroup
      tariff
      warning
      status
      limit
      pastUsageMonthly {
        value
        createdAt
        year
        month
      }
      pastUsage {
        month
        day
        createdAt
        value
      }
      imei
      msidn
      usage {
        status
        allowance
        value
        text
      }
      customer {
        id
        name
      }
    }
  }
`;

/**
 * __useSimCardQuery__
 *
 * To run a query within a React component, call `useSimCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimCardQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSimCardQuery(
  baseOptions: Apollo.QueryHookOptions<SimCardQuery, SimCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SimCardQuery, SimCardQueryVariables>(
    SimCardDocument,
    options
  );
}
export function useSimCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SimCardQuery, SimCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SimCardQuery, SimCardQueryVariables>(
    SimCardDocument,
    options
  );
}
export type SimCardQueryHookResult = ReturnType<typeof useSimCardQuery>;
export type SimCardLazyQueryHookResult = ReturnType<typeof useSimCardLazyQuery>;
export type SimCardQueryResult = Apollo.QueryResult<
  SimCardQuery,
  SimCardQueryVariables
>;
export const SopSyncDocument = gql`
  mutation SopSync {
    SOP_SYNC {
      metrics {
        upserts {
          distributors
          dealers
          customers
          deviceTemplates
          deviceUsers
          dispatchTemplates
        }
        deletes {
          distributors
          dealers
          customers
          deviceTemplates
          dispatchTemplates
          deviceUsers
        }
      }
      upsertDistributorsRecord {
        updated
        created
      }
      upsertDealersRecord {
        updated
        created
      }
      upsertCustomersRecord {
        updated
        created
      }
      upsertDeviceTemplatesRecord {
        updated
        created
      }
      upsertDispatchTemplatesRecord {
        updated
        created
      }
      upsertDeviceUsersRecord {
        updated
        created
      }
      deleteDeviceUsersRecord {
        removedIdentifier
        deleted
      }
      deleteDispatchTemplatesRecord {
        removedIdentifier
        deleted
      }
      deleteDeviceTemplatesRecord {
        removedIdentifier
        deleted
      }
      deleteCustomersRecord {
        removedIdentifier
        deleted
      }
      deleteDealersRecord {
        removedIdentifier
        deleted
      }
      deleteDistributorsRecord {
        removedIdentifier
        deleted
      }
    }
  }
`;
export type SopSyncMutationFn = Apollo.MutationFunction<
  SopSyncMutation,
  SopSyncMutationVariables
>;

/**
 * __useSopSyncMutation__
 *
 * To run a mutation, you first call `useSopSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSopSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sopSyncMutation, { data, loading, error }] = useSopSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useSopSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SopSyncMutation,
    SopSyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SopSyncMutation, SopSyncMutationVariables>(
    SopSyncDocument,
    options
  );
}
export type SopSyncMutationHookResult = ReturnType<typeof useSopSyncMutation>;
export type SopSyncMutationResult = Apollo.MutationResult<SopSyncMutation>;
export type SopSyncMutationOptions = Apollo.BaseMutationOptions<
  SopSyncMutation,
  SopSyncMutationVariables
>;
export const CreatePaymentMethodDocument = gql`
  mutation CreatePaymentMethod(
    $where: WhereUniqueCustomerOrDealerInput!
    $data: PaymentMethodCreateInput!
  ) {
    createStripePaymentMethod(where: $where, data: $data) {
      id
    }
  }
`;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentMethodMutation,
    CreatePaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePaymentMethodMutation,
    CreatePaymentMethodMutationVariables
  >(CreatePaymentMethodDocument, options);
}
export type CreatePaymentMethodMutationHookResult = ReturnType<
  typeof useCreatePaymentMethodMutation
>;
export type CreatePaymentMethodMutationResult =
  Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;
export const CreateStripeProductWithPriceDocument = gql`
  mutation CreateStripeProductWithPrice($data: ProductWithPriceCreateInput!) {
    createStripeProductWithPrice(data: $data) {
      id
      name
    }
  }
`;
export type CreateStripeProductWithPriceMutationFn = Apollo.MutationFunction<
  CreateStripeProductWithPriceMutation,
  CreateStripeProductWithPriceMutationVariables
>;

/**
 * __useCreateStripeProductWithPriceMutation__
 *
 * To run a mutation, you first call `useCreateStripeProductWithPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeProductWithPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeProductWithPriceMutation, { data, loading, error }] = useCreateStripeProductWithPriceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateStripeProductWithPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeProductWithPriceMutation,
    CreateStripeProductWithPriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStripeProductWithPriceMutation,
    CreateStripeProductWithPriceMutationVariables
  >(CreateStripeProductWithPriceDocument, options);
}
export type CreateStripeProductWithPriceMutationHookResult = ReturnType<
  typeof useCreateStripeProductWithPriceMutation
>;
export type CreateStripeProductWithPriceMutationResult =
  Apollo.MutationResult<CreateStripeProductWithPriceMutation>;
export type CreateStripeProductWithPriceMutationOptions =
  Apollo.BaseMutationOptions<
    CreateStripeProductWithPriceMutation,
    CreateStripeProductWithPriceMutationVariables
  >;
export const StripeSubscriptionSyncDocument = gql`
  mutation stripeSubscriptionSync {
    STRIPE_SOP_SUBSCRIPTION_SYNC {
      updated
      created
    }
  }
`;
export type StripeSubscriptionSyncMutationFn = Apollo.MutationFunction<
  StripeSubscriptionSyncMutation,
  StripeSubscriptionSyncMutationVariables
>;

/**
 * __useStripeSubscriptionSyncMutation__
 *
 * To run a mutation, you first call `useStripeSubscriptionSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStripeSubscriptionSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stripeSubscriptionSyncMutation, { data, loading, error }] = useStripeSubscriptionSyncMutation({
 *   variables: {
 *   },
 * });
 */
export function useStripeSubscriptionSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StripeSubscriptionSyncMutation,
    StripeSubscriptionSyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StripeSubscriptionSyncMutation,
    StripeSubscriptionSyncMutationVariables
  >(StripeSubscriptionSyncDocument, options);
}
export type StripeSubscriptionSyncMutationHookResult = ReturnType<
  typeof useStripeSubscriptionSyncMutation
>;
export type StripeSubscriptionSyncMutationResult =
  Apollo.MutationResult<StripeSubscriptionSyncMutation>;
export type StripeSubscriptionSyncMutationOptions = Apollo.BaseMutationOptions<
  StripeSubscriptionSyncMutation,
  StripeSubscriptionSyncMutationVariables
>;
export const InvoiceReportDocument = gql`
  query InvoiceReport($customerWhere: CustomerWhereInput!, $where: UniqueId) {
    viewDemCompanies(where: $where) {
      id
      devices {
        id
        demo
        serialNumber
        createdAt
        company {
          id
          name
        }
      }
    }
    customers(where: $customerWhere) {
      id
      name
      deviceUsers {
        id
        name
        demo
        expireDate
        devices {
          id
          name
        }
        deviceTemplate {
          name
          products {
            id
            price
          }
        }
        dispatchTemplate {
          name
          products {
            id
            price
          }
        }
        createdAt
      }
      demBundles {
        id
        quantity
        bundle {
          id
          name
          price
        }
      }
    }
  }
`;

/**
 * __useInvoiceReportQuery__
 *
 * To run a query within a React component, call `useInvoiceReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceReportQuery({
 *   variables: {
 *      customerWhere: // value for 'customerWhere'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useInvoiceReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    InvoiceReportQuery,
    InvoiceReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InvoiceReportQuery, InvoiceReportQueryVariables>(
    InvoiceReportDocument,
    options
  );
}
export function useInvoiceReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InvoiceReportQuery,
    InvoiceReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InvoiceReportQuery, InvoiceReportQueryVariables>(
    InvoiceReportDocument,
    options
  );
}
export type InvoiceReportQueryHookResult = ReturnType<
  typeof useInvoiceReportQuery
>;
export type InvoiceReportLazyQueryHookResult = ReturnType<
  typeof useInvoiceReportLazyQuery
>;
export type InvoiceReportQueryResult = Apollo.QueryResult<
  InvoiceReportQuery,
  InvoiceReportQueryVariables
>;
export const UpdateBundleOnCompanyDocument = gql`
  mutation UpdateBundleOnCompany(
    $bundleOnUser: String!
    $customerId: String!
    $quantity: Int!
  ) {
    updateBundleOnCompany(
      bundleOnUser: $bundleOnUser
      customerId: $customerId
      quantity: $quantity
    ) {
      quantity
      customerId
      bundleOnUserId
    }
  }
`;
export type UpdateBundleOnCompanyMutationFn = Apollo.MutationFunction<
  UpdateBundleOnCompanyMutation,
  UpdateBundleOnCompanyMutationVariables
>;

/**
 * __useUpdateBundleOnCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateBundleOnCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBundleOnCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBundleOnCompanyMutation, { data, loading, error }] = useUpdateBundleOnCompanyMutation({
 *   variables: {
 *      bundleOnUser: // value for 'bundleOnUser'
 *      customerId: // value for 'customerId'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateBundleOnCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBundleOnCompanyMutation,
    UpdateBundleOnCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateBundleOnCompanyMutation,
    UpdateBundleOnCompanyMutationVariables
  >(UpdateBundleOnCompanyDocument, options);
}
export type UpdateBundleOnCompanyMutationHookResult = ReturnType<
  typeof useUpdateBundleOnCompanyMutation
>;
export type UpdateBundleOnCompanyMutationResult =
  Apollo.MutationResult<UpdateBundleOnCompanyMutation>;
export type UpdateBundleOnCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateBundleOnCompanyMutation,
  UpdateBundleOnCompanyMutationVariables
>;
