import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import OnboardingLayout from 'components/Layout/OnboardingLayout';
import AppLayout from 'components/Layout/AppLayout';
import AuthLayout from 'components/Layout/AuthLayout';
import AppLocale from 'lang';
import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  DEMO_PREFIX_PATH,
  ONBOARDING_PREFIX_PATH,
} from 'configs/AppConfig';
import AppRouter from 'navigation/App/Router';
import AuthRouter from 'navigation/Auth/Router';
import OnboardingRouter from 'navigation/Onboarding/Router';

import { useStoreState } from 'state';
import useAuth from 'hooks/useAuth';
import { OnboardingStep } from 'graphql/organisations/enums';
import { OrgType } from 'state/user-model';
import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from 'react-jss';
import theme from 'configs/ThemeConfig';
import Loading from '../views/Auth/Authentication/Loading';
import { DemoRouter } from './Demo/Router';

type Props = RouteComponentProps<{ id: string }>;

const Router = (props: Props) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { getCurrentUser, rehydrateAuth, loading } = useAuth();

  const locale = useStoreState((state) => state.theme.locale) as
    | 'en-GB'
    | 'fr-FR'
    | 'de-DE'
    | 'el-GR'
    | 'sk-SK'
    | 'cs-CZ'
    | 'hr-HR';
  const loggedIn = useStoreState((state) => state.auth.loggedIn);
  const isSet = useStoreState((state) => state.auth.isSet);
  const userOnboarded = useStoreState((state) => state.user.onboarding);
  const orgType = useStoreState((state) => state.user.type);
  const demo = useStoreState((state) => state.theme.demoMode);
  const orgOnboarded = useStoreState(
    (state) => state.user.organisation?.onboarding
  );
  const onboarded =
    userOnboarded === OnboardingStep.complete &&
    orgOnboarded === OnboardingStep.complete;

  const { location } = props;
  const currentTheme = useStoreState(
    (state) => state.theme.currentTheme
  ).toLowerCase() as 'dark' | 'light';

  const currentAppLocale = AppLocale[locale];
  useEffect(() => {
    if (isAuthenticated) {
      getCurrentUser();
    }
    rehydrateAuth();
  }, []);

  const toPrefix = () => {
    if (isAuthenticated) {
      if (!isLoading) {
        if (demo) return DEMO_PREFIX_PATH;
        if (orgType === OrgType.nvoyy || onboarded) return APP_PREFIX_PATH;
        // if (orgType === OrgType.distributor || onboarded)
        //   return APP_PREFIX_PATH;
        if (orgType === OrgType.hytera || onboarded) return APP_PREFIX_PATH;
        return ONBOARDING_PREFIX_PATH;
      }
    }
    return AUTH_PREFIX_PATH;
  };

  if (isLoading || loading || (isAuthenticated && orgType === undefined)) {
    return (
      <ThemeProvider theme={theme[currentTheme]}>
        <Loading />;
      </ThemeProvider>
    );
  }

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
      onError={(err) => {
        if (err.code === 'MISSING_TRANSLATION') {
          // eslint-disable-next-line no-console
          console.warn('Missing translation', err.message);
          return;
        }
        throw err;
      }}
    >
      <ThemeProvider theme={theme[currentTheme]}>
        <ConfigProvider locale={currentAppLocale.antd}>
          {isSet ? (
            <Switch>
              <Route exact path="/">
                <Redirect to={toPrefix()} />
              </Route>
              <Route path={AUTH_PREFIX_PATH}>
                <AuthLayout>
                  <AuthRouter />
                </AuthLayout>
              </Route>
              <Route path={APP_PREFIX_PATH}>
                {!loggedIn ? (
                  <Redirect to={AUTH_PREFIX_PATH} />
                ) : (
                  <AppLayout location={location}>
                    <AppRouter />
                  </AppLayout>
                )}
              </Route>
              <Route path={ONBOARDING_PREFIX_PATH}>
                {!loggedIn ? (
                  <Redirect to={AUTH_PREFIX_PATH} />
                ) : (
                  <OnboardingLayout location={location}>
                    <OnboardingRouter />
                  </OnboardingLayout>
                )}
              </Route>
              <Route path={DEMO_PREFIX_PATH}>
                {!loggedIn ? (
                  <Redirect to={AUTH_PREFIX_PATH} />
                ) : (
                  <AppLayout location={location}>
                    <DemoRouter />
                  </AppLayout>
                )}
              </Route>
            </Switch>
          ) : (
            <Loading />
          )}
        </ConfigProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default withRouter(Router);
