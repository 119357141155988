import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ONBOARDING_PREFIX_PATH } from 'configs/AppConfig';
import { useStoreState } from 'state';
import { OrgType } from 'state/user-model';
import DealerOnboarding from '../../views/Onboarding/Dealer/DealerOnboarding';
import DistributorOnboarding from '../../views/Onboarding/Distributor/DistributorOnboarding';

const OnboardingViews = (): JSX.Element => {
  const demo = useStoreState((state) => state.theme.demoMode);
  const orgType = useStoreState((state) => state.user.type);
  const to = () => {
    if (demo) {
      return `${ONBOARDING_PREFIX_PATH}/dealer`;
    }
    if (orgType === OrgType.dealer) {
      return `${ONBOARDING_PREFIX_PATH}/dealer`;
    }
    if (orgType === OrgType.distributor) {
      return `${ONBOARDING_PREFIX_PATH}/distributor`;
    }
    return `${ONBOARDING_PREFIX_PATH}/dealer`;
  };

  return (
    <Switch>
      <Redirect exact from={`${ONBOARDING_PREFIX_PATH}`} to={to()} />
      <Route
        path={`${ONBOARDING_PREFIX_PATH}/dealer`}
        component={DealerOnboarding}
      />
      <Route
        path={`${ONBOARDING_PREFIX_PATH}/distributor`}
        component={DistributorOnboarding}
      />
    </Switch>
  );
};

export default OnboardingViews;
