import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import View from './VatId.view';
import useVatDetails from './useVatId';

type Props = Omit<RouteComponentProps, 'location' | 'match'>;

const VatDetails = ({ history }: Props): JSX.Element => {
  const { details, handleSubmit, loading, saving, demo } = useVatDetails({
    history,
  });

  return (
    <View
      details={details}
      handleSubmit={handleSubmit}
      loading={loading}
      saving={saving}
      demo={demo}
    />
  );
};
export default VatDetails;
