/* eslint-disable no-param-reassign */
import { action, Action } from 'easy-peasy';
import { OnboardingStep } from 'graphql/organisations/enums';
import Role from 'graphql/users/enums';

export enum OrgType {
  dealer = 'DEALER',
  customer = 'CUSTOMER',
  distributor = 'DISTRIBUTOR',
  hytalk = 'HYTALK',
  nvoyy = 'NVOYY',
  hytera = 'HYTERA',
}

interface Organisation {
  id: string;
  name: string;
  onboarding: OnboardingStep;
  stripeId: string;
  locale: {
    currency: string;
    preferred_locales: string;
  };
}

export interface SetUserPayload {
  id: string;
  email: string;
  name: string;
  image: string;
  role: Role;
  onboarding: OnboardingStep;
  type: OrgType;
  organisation?: Organisation;
  termsAgreement?: TermsAgreement;
  hytera: boolean;
  firstLogin: boolean;
}

export interface SetUserCurrencyPayload {
  id: string;
  name: string;
  symbol: string;
}

interface SetOrgPayload {
  onboarding: OnboardingStep;
}
interface SetOnboardingPayload {
  onboarding: OnboardingStep;
}
interface SetFirstLoginPayload {
  firstLogin: boolean;
}
interface TermsAgreement {
  id: string;
  expired: boolean;
}

export interface UserModel {
  id: string;
  email: string;
  name: string;
  image: string;
  role: Role;
  type: OrgType | undefined;
  onboarding: OnboardingStep;
  termsAgreement: TermsAgreement | undefined;
  organisation: Organisation | undefined;
  setUser: Action<UserModel, SetUserPayload>;
  setOrgOnboarding: Action<UserModel, SetOrgPayload>;
  setFirstLogin: Action<UserModel, SetFirstLoginPayload>;
  setUserOnboarding: Action<UserModel, SetOnboardingPayload>;
  clearUser: Action<UserModel>;
  hytera: boolean;
  firstLogin: boolean;
}

const userModel: UserModel = {
  id: '',
  email: '',
  name: '',
  image: '',
  type: undefined,
  role: Role.user,
  onboarding: OnboardingStep.terms,
  organisation: undefined,
  termsAgreement: undefined,
  hytera: false,
  firstLogin: false,

  setUser: action((state, payload) => {
    state.id = payload.id;
    state.email = payload.email;
    state.name = payload.name;
    state.image = payload.image;
    state.role = payload.role;
    state.type = payload.type;
    state.onboarding = payload.onboarding;
    state.organisation = payload.organisation;
    state.termsAgreement = payload.termsAgreement;
    state.hytera = payload.hytera;
    state.firstLogin = payload.firstLogin;
  }),

  clearUser: action((state) => {
    state.id = '';
    state.email = '';
    state.name = '';
    state.image = '';
    state.onboarding = OnboardingStep.terms;
    state.organisation = undefined;
    state.termsAgreement = undefined;
    state.hytera = false;
    state.firstLogin = false;
  }),

  setOrgOnboarding: action((state, payload) => {
    if (state.organisation) state.organisation.onboarding = payload.onboarding;
  }),

  setUserOnboarding: action((state, payload) => {
    state.onboarding = payload.onboarding;
  }),

  setFirstLogin: action((state, payload) => {
    state.firstLogin = payload.firstLogin;
  }),
};

export default userModel;
