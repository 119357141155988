import React from 'react';
import { Row, Col } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  max-height: 100vh;
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
`;

interface OnboardingProps
  extends Omit<RouteComponentProps, 'match' | 'history'> {
  children: React.ReactElement;
}

const Onboarding = ({ children }: OnboardingProps): JSX.Element => {
  const backgroundStyle = {
    height: '100vh',
  };

  return (
    <div style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={20}>
            <Container>{children}</Container>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Onboarding;
