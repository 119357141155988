import React from 'react';
import { Layout } from 'antd';
import { SIDE_NAV_WIDTH } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import { useStoreState, SideNavTheme, NavType } from 'state';
import { NavItem } from 'configs/NavigationConfig';
import MenuContent from './MenuContent';

const { Sider } = Layout;

interface Props {
  routeInfo: NavItem;
  hideGroupTitle?: boolean | undefined;
  localization?: boolean | undefined;
}

export const SideNav = ({
  routeInfo,
  hideGroupTitle,
  localization = true,
}: Props): JSX.Element => {
  const sideNavTheme = useStoreState((state) => state.theme.sideNavTheme);

  return (
    <Sider
      className={`side-nav ${
        sideNavTheme === SideNavTheme.DARK ? 'side-nav-dark' : ''
      }`}
      width={SIDE_NAV_WIDTH}
    >
      <Scrollbars autoHide>
        <MenuContent
          type={NavType.SIDE}
          routeInfo={routeInfo}
          hideGroupTitle={hideGroupTitle}
          localization={localization}
        />
      </Scrollbars>
    </Sider>
  );
};

SideNav.defaultProps = {
  hideGroupTitle: undefined,
  localization: undefined,
};

export default SideNav;
