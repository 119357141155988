import antdGrGR from 'antd/es/locale/el_GR';
import grMsg from '../locales/el_GR.json';

const GrLang = {
  antd: antdGrGR,
  locale: 'el-GR',
  messages: {
    ...grMsg,
  },
};
export default GrLang;
