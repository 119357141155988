import React, { createContext, useReducer, Dispatch } from 'react';
import { useQuery } from '@apollo/client';

import {
  TermsWithContent,
  TermsWithContentArgs,
  TermsWithContentRes,
} from 'graphql/terms/queries';
import { useStoreState } from 'state';
import TermsType from 'graphql/terms/enums';
import { useDistributorBusinessDetailsOnboardingQuery } from 'graphql/generated/graphql';

export type Term = TermsWithContentRes['terms'][0];
export type Details = {
  id: string;
  currency?: string | null | undefined;
  billingAddress?:
    | {
        line1: string;
        line2?: string | null;
        city: string;
        county?: string | null;
        country: string;
        postCode: string;
      }
    | null
    | undefined;
  contact?:
    | {
        name: string;
        email: string;
        phone: string;
      }
    | null
    | undefined;
  name: string;
  vatId?:
    | {
        id: string;
        value: string;
      }
    | null
    | undefined;
};

interface State {
  userTerms: Term | undefined;
  businessTerms: Term | undefined;
  details: Details | undefined;
}

interface SetDataPayload {
  userTerms?: Term;
  businessTerms?: Term;
  details?: Details;
}

type Action = { type: 'SET_DATA'; data: SetDataPayload };

type DistributorOnboardingReducer<S, A> = (prevState: S, action: A) => S;

const distributorOnboardingReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

interface DistributorOnboardingContextType {
  state: State;
  dispatch: Dispatch<Action>;
}

const initState: State = {
  userTerms: undefined,
  businessTerms: undefined,
  details: undefined,
};

const DistributorOnboardingContext = createContext<
  DistributorOnboardingContextType | undefined
>(undefined);

interface Props {
  children: React.ReactChild;
}

export const DistributorOnboardingProvider = ({
  children,
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer<
    DistributorOnboardingReducer<State, Action>
  >(distributorOnboardingReducer, initState);
  const demo = useStoreState((storeState) => storeState.theme.demoMode);
  const id = !demo
    ? useStoreState((storeState) => storeState.user.organisation?.id)
    : '';

  useQuery<TermsWithContentRes, TermsWithContentArgs>(TermsWithContent, {
    variables: {
      where: {
        OR: [{ defaultTerms: { equals: true } }],
      },
    },
    onCompleted: (data) => {
      dispatch({
        data: {
          userTerms: data.terms.find(
            ({ defaultTerms, type }) => defaultTerms && TermsType.user === type
          ),
          businessTerms: data.terms.find(
            ({ defaultTerms, type }) =>
              defaultTerms && TermsType.dealer === type
          ),
        },
        type: 'SET_DATA',
      });
    },
    fetchPolicy: 'cache-and-network',
  });

  useDistributorBusinessDetailsOnboardingQuery({
    variables: {
      where: {
        id: id || '',
      },
    },
    onCompleted: (data) => {
      if (data.distributor)
        dispatch({
          data: {
            details: {
              id: data.distributor.id,
              name: data.distributor.name,
              billingAddress: data.distributor.billingAddress,
              contact: data.distributor.contact,
              currency: '',
              vatId: data.distributor.vatId,
            },
          },
          type: 'SET_DATA',
        });
    },
    fetchPolicy: 'cache-and-network',
  });

  const value = { state, dispatch };
  return (
    <DistributorOnboardingContext.Provider value={value}>
      {children}
    </DistributorOnboardingContext.Provider>
  );
};

export const useDistributorOnboarding =
  (): DistributorOnboardingContextType => {
    const context = React.useContext(DistributorOnboardingContext);
    if (context === undefined) {
      throw new Error(
        'useDistributor must be used within the DistributorOnboardingProvider'
      );
    }
    return context;
  };
