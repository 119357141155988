import { Locale } from 'antd/es/locale-provider';

import enLang from './entries/en_US';
import frLang from './entries/fr_FR';
import deLang from './entries/de_DE';
import grLang from './entries/el_GR';
import csLang from './entries/cs_CZ';
import skLang from './entries/sk_SK';
import hrLang from './entries/hr_HR';

interface LocaleType {
  antd: Locale;
  locale: string;
  messages: { [key: string]: string };
}

interface AppLocaleType {
  'en-GB': LocaleType;
  'fr-FR': LocaleType;
  'de-DE': LocaleType;
  'el-GR': LocaleType;
  'cs-CZ': LocaleType;
  'sk-SK': LocaleType;
  'hr-HR': LocaleType;
}
const AppLocale: AppLocaleType = {
  'en-GB': enLang,
  'fr-FR': frLang,
  'de-DE': deLang,
  'el-GR': grLang,
  'cs-CZ': csLang,
  'sk-SK': skLang,
  'hr-HR': hrLang,
};

export default AppLocale;
