import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import navigationConfig, { NavItem } from 'configs/NavigationConfig';
import { useStoreState, NavType, SideNavTheme } from 'state';
import { OrgType } from 'state/user-model';
import { FormattedMessage } from 'react-intl';
import { APP_PREFIX_PATH, DEMO_PREFIX_PATH } from 'configs/AppConfig';

const { SubMenu } = Menu;

const setLocale = (isLocaleOn: boolean, localeKey: string) =>
  isLocaleOn ? (
    <FormattedMessage defaultMessage={localeKey} id={localeKey} />
  ) : (
    localeKey.toString()
  );

const setDefaultOpen = (key: string) => {
  const keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index += 1) {
      const elm = arr[index];
      if (index === 0) {
        keyString = elm;
      } else {
        keyString = `${keyString}-${elm}`;
      }
      keyList.push(keyString);
    }
  }
  return keyList;
};

const Icon = ({
  icon,
  size,
}: {
  icon: FontAwesomeIconProps['icon'];
  size?: number;
}): JSX.Element => (
  <FontAwesomeIcon
    fixedWidth
    icon={icon}
    style={{ fontSize: size || 22, marginRight: 10 }}
  />
);
Icon.defaultProps = {
  size: undefined,
};

interface SideNavContentProps {
  sideNavTheme: SideNavTheme;
  routeInfo: NavItem;
  hideGroupTitle: boolean | undefined;
  localization: boolean;
}

const SideNavContent = (props: SideNavContentProps): JSX.Element => {
  const { sideNavTheme, routeInfo, hideGroupTitle, localization } = props;
  const orgType = useStoreState((state) => state.user.type);
  const demoMode = useStoreState((state) => state.theme.demoMode);
  const role = useStoreState((state) => state.user.role);
  return (
    <Menu
      theme={sideNavTheme === SideNavTheme.LIGHT ? 'light' : 'dark'}
      mode="inline"
      style={{ height: '100%', borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {navigationConfig
        .filter(
          ({ orgTypes, roles }) =>
            orgTypes.includes(
              (demoMode ? OrgType.dealer : orgType) || OrgType.dealer
            ) && roles?.includes(role)
        )
        .map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.SubMenu
              key={menu.key}
              icon={menu.icon && <Icon icon={menu.icon} />}
              title={
                <FormattedMessage defaultMessage={menu.key} id={menu.title} />
              }
            >
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon icon={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={
                      <FormattedMessage
                        defaultMessage={menu.key}
                        id={menu.title}
                      />
                    }
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon icon={subMenuSecond?.icon} />
                        ) : null}

                        {subMenuSecond.externalPath ? (
                          <a
                            target="_blank"
                            href={subMenuSecond.externalPath}
                            rel="noopener noreferrer"
                          >
                            <span>
                              {setLocale(localization, subMenuSecond.title)}
                            </span>
                          </a>
                        ) : (
                          <>
                            <span>
                              {setLocale(localization, subMenuSecond.title)}
                            </span>
                            <Link
                              to={`${
                                demoMode ? DEMO_PREFIX_PATH : APP_PREFIX_PATH
                              }${subMenuFirst.path}`}
                            />
                          </>
                        )}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon size={15} icon={subMenuFirst.icon} />
                    ) : null}
                    {subMenuFirst.externalPath ? (
                      <a
                        target="_blank"
                        href={subMenuFirst.externalPath}
                        rel="noopener noreferrer"
                      >
                        <span>
                          {setLocale(localization, subMenuFirst.title)}
                        </span>
                      </a>
                    ) : (
                      <>
                        <span>
                          {setLocale(localization, subMenuFirst.title)}
                        </span>

                        <Link
                          to={`${
                            demoMode ? DEMO_PREFIX_PATH : APP_PREFIX_PATH
                          }${subMenuFirst.path}`}
                        />
                      </>
                    )}
                  </Menu.Item>
                )
              )}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon icon={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? (
                <Link
                  to={`${demoMode ? DEMO_PREFIX_PATH : APP_PREFIX_PATH}${
                    menu.path
                  }`}
                />
              ) : null}
            </Menu.Item>
          )
        )}
    </Menu>
  );
};

interface TopNavContentProps {
  localization: boolean;
}

const TopNavContent = (props: TopNavContentProps): JSX.Element => {
  const { localization } = props;
  return (
    <Menu mode="horizontal">
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon icon={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon icon={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon icon={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon icon={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

interface Props {
  localization: boolean;
  type: NavType;
  routeInfo: NavItem;
  hideGroupTitle: boolean | undefined;
}

const MenuContent = ({
  localization,
  type,
  routeInfo,
  hideGroupTitle,
}: Props): JSX.Element => {
  const sideNavTheme = useStoreState((state) => state.theme.sideNavTheme);

  return type === NavType.SIDE ? (
    <SideNavContent
      localization={localization}
      routeInfo={routeInfo}
      hideGroupTitle={hideGroupTitle}
      sideNavTheme={sideNavTheme}
    />
  ) : (
    <TopNavContent localization={localization} />
  );
};

export default MenuContent;
