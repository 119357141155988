import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Steps } from 'antd';
import styled from 'styled-components';

import { useAuth } from 'hooks';
import { APP_PREFIX_PATH, ONBOARDING_PREFIX_PATH } from 'configs/AppConfig';
import { OnboardingStep } from 'graphql/organisations/enums';
import { useIntl } from 'react-intl';
import HeaderNav from 'components/Layout/Navigation/HeaderNav';
import TermConditions from '../Views/TermsConditions';
import BusinessDetails from '../Views/BusinessDetails';
import TermsOfBusiness from '../Views/TermsOfBusiness';
import PaymentMethod from '../Views/PaymentMethod';
import GettingStarted from '../Views/GettingStarted';
import VatId from '../Views/VatId';

const { Step } = Steps;

const Content = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

interface Props {
  currentStep: number;
  userOnboarded: OnboardingStep | undefined;
  orgOnboarding: OnboardingStep | undefined;
}

const DealerOnboarding = ({
  currentStep,
  orgOnboarding,
  userOnboarded,
}: Props): JSX.Element => {
  const intl = useIntl();

  const userComplete = userOnboarded === OnboardingStep.complete;
  const orgComplete = orgOnboarding === OnboardingStep.complete;

  const { getCurrentUser } = useAuth();

  useEffect(() => {
    getCurrentUser();
  }, []);

  const to = () => {
    if (userComplete) {
      if (orgOnboarding === OnboardingStep.details)
        return `${ONBOARDING_PREFIX_PATH}/dealer/business-details`;
      if (orgOnboarding === OnboardingStep.terms)
        return `${ONBOARDING_PREFIX_PATH}/dealer/terms-of-business`;
      if (orgOnboarding === OnboardingStep.vat)
        return `${ONBOARDING_PREFIX_PATH}/dealer/vat-id`;
      if (orgOnboarding === OnboardingStep.payment)
        return `${ONBOARDING_PREFIX_PATH}/dealer/payment-method/processing`;
      if (orgOnboarding === OnboardingStep.complete)
        return `${APP_PREFIX_PATH}/app/dashboard`;
    }
    return `${ONBOARDING_PREFIX_PATH}/dealer/terms-conditions`;
  };

  return (
    <>
      <HeaderNav isMobile={false} isOnboarding />

      <Steps
        className="my-5"
        current={currentStep}
        labelPlacement="vertical"
        size="small"
      >
        {!userComplete && (
          <Step
            title={intl.formatMessage({
              defaultMessage: 'User Terms',
              id: 'userTerms',
            })}
          />
        )}
        {!orgComplete && (
          <Step
            title={intl.formatMessage({
              defaultMessage: 'Business Details',
              id: 'businessDetails',
            })}
          />
        )}
        {!orgComplete && (
          <Step
            title={intl.formatMessage({
              defaultMessage: 'Terms Of Business',
              id: 'businessTerms',
            })}
          />
        )}
        {!orgComplete && (
          <Step
            title={intl.formatMessage({
              defaultMessage: 'Vat Id',
              id: 'vatId',
            })}
          />
        )}
        {!orgComplete && (
          <Step
            title={intl.formatMessage({
              defaultMessage: 'Payment Method',
              id: 'paymentMethod',
            })}
          />
        )}
        {/* {!orgComplete && <Step title="Getting Started" />} */}
      </Steps>
      <Content className="my-4">
        <Switch>
          <Redirect exact from={`${ONBOARDING_PREFIX_PATH}/dealer`} to={to()} />
          <Route
            path={`${ONBOARDING_PREFIX_PATH}/dealer/terms-conditions`}
            component={TermConditions}
          />
          <Route
            path={`${ONBOARDING_PREFIX_PATH}/dealer/business-details`}
            component={BusinessDetails}
          />
          <Route
            path={`${ONBOARDING_PREFIX_PATH}/dealer/terms-of-business`}
            component={TermsOfBusiness}
          />
          <Route
            path={`${ONBOARDING_PREFIX_PATH}/dealer/vat-id`}
            component={VatId}
          />
          <Route
            path={`${ONBOARDING_PREFIX_PATH}/dealer/payment-method/:status`}
            component={PaymentMethod}
          />
          <Route
            path={`${ONBOARDING_PREFIX_PATH}/dealer/getting-started`}
            component={GettingStarted}
          />
        </Switch>
      </Content>
    </>
  );
};

export default DealerOnboarding;
