import { gql } from '@apollo/client';
import { OnboardingStep } from 'graphql/organisations/enums';

export const CurrentUser = gql`
  query currentUser {
    currentUser {
      id
      onboarding
      dealer {
        id
        name
        onboarding
        stripeId
        locale {
          currency
          preferred_locales
        }
      }
      customer {
        id
        name
        onboarding
      }
      distributor {
        id
        name
        onboarding
        stripeId
        locale {
          currency
          preferred_locales
        }
      }
      termsAgreement {
        id
        expired
      }
    }
  }
`;

export interface Org {
  id: string;
  name: string;
  onboarding: OnboardingStep;
  stripeId: string;
  locale: {
    preferred_locales: string;
    currency: string;
  };
}

export interface CurrentUserRes {
  currentUser: {
    id: string;
    onboarding: OnboardingStep;
    customer: Org;
    dealer: Org;
    distributor: Org;
    termsAgreement: {
      id: string;
      expired: boolean;
    };
  };
}
