import { gql } from '@apollo/client';
import { OrganisationType } from '../enums';

export const CreateOrganisation = gql`
  mutation createOneOrganisation(
    $name: String!
    $type: OrganisationType!
    $parent: OrganisationCreateNestedOneWithoutChildrenInput
    $tier: OrganisationTierCreateNestedOneWithoutOrganisationInput
  ) {
    createOneOrganisation(data: { name: $name, type: $type, parent: $parent }) {
      id
      name
      type
      parent {
        id
        name
      }
      tier {
        id
        name
      }
    }
  }
`;

export interface CreateOrganisationArgs {
  name: string;
  type: OrganisationType;
  parent?: { connect: { id: string } };
  tier?: { connect: { id: string } };
}

export interface CreateOrganisationRes {
  createOneOrganisation: {
    id: string;
    name: string;
    type: OrganisationType;
    parent?: {
      id: string;
      name: string;
    };
    tier?: {
      id: string;
      name: string;
    };
  };
}
